import Button from "@components/UI/button";
import { useRouter } from "next/router";
import { storyblokEditable } from "@storyblok/react";
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import { motion } from "framer-motion";

const LaundryTesterBanner = ({
  blok: {
    blok,
    title,
    description,
    button_1,
    button_link_1,
    button_2,
    button_link_2,
    image
  }
}) => {
  const router = useRouter();

  const renderOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h1
            className={`sofia-pro-b text-blue-gradient mb-5 text-[32px] leading-[1.15] lg:text-[48px]`}>
            {children}
          </h1>
        );
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`mb-9 text-[22px] leading-[1.2] lg:text-[24px]`}>
            {children}
          </p>
        );
      }
    }
  };

  return (
    <section className="relative z-[2] overflow-hidden pt-3 pb-0 md:pt-0 md:pb-24">
      <div className="custom-container">
        <div className="justify-space relative flex flex-col-reverse items-center md:flex-row">
          <motion.div
            initial={{ y: 40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              delay: 0.3,
              duration: 0.6
            }}
            className="relative z-[2] w-full pt-1 pb-12 md:w-[52%] md:max-w-[550px] md:pb-20 md:pl-[8.5%] md:pt-24">
            {render(title, renderOptions)}
            {render(description, renderOptions)}
            <div className="flex flex-col items-center gap-5 sm:max-w-[326px] sm:flex-row">
              <Button
                variant="primary"
                fontSize="text-[18px]"
                className="w-full"
                onClick={() => {
                  if (button_link_1.cached_url.includes("#")) {
                    const element = document.querySelector(
                      button_link_1.cached_url
                    );
                    window.scrollTo({
                      top: element.offsetTop,
                      behavior: "smooth"
                    });
                  } else {
                    router.push(button_link_1.cached_url);
                  }
                }}
                {...storyblokEditable(blok)}>
                {button_1}
              </Button>
              <Button
                variant="outline"
                fontSize="text-[18px]"
                className="w-full"
                onClick={() => {
                  if (button_link_2.cached_url.includes("#")) {
                    const element = document.querySelector(
                      button_link_2.cached_url
                    );
                    window.scrollTo({
                      top: element.offsetTop,
                      behavior: "smooth"
                    });
                  } else {
                    router.push(button_link_2.cached_url);
                  }
                }}
                {...storyblokEditable(blok)}>
                {button_2}
              </Button>
            </div>
          </motion.div>
          <div className="relative right-0 z-[1] w-full md:absolute md:top-1/2 md:w-[56%] md:-translate-y-1/2">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 20,
                delay: 0.3,
                duration: 0.6
              }}
              className="mx-auto w-[90%] md:-mb-[20%] md:w-full"
              src={image?.filename}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LaundryTesterBanner;

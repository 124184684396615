import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import DynamicComponent from "../../../services/DynamicComponentsRenderer";
import { storyblokEditable } from "@storyblok/react";

export default function Noticed({ blok }) {
  const ref = useRef();
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true
  });

  return (
    <>
      <div className="mx-auto flex w-full bg-[#EDF2F4] pt-[22px] pb-[35px] lg:pt-[80px] lg:pb-[110px]">
        <div className="flex h-full w-full flex-col justify-center">
          <div className="mb-[30px] flex w-full justify-center px-[18px] lg:px-[48px] xl:px-[48px]">
            <h3
              className="sofia-pro-s text-[22px]"
              {...storyblokEditable(blok)}
            >
              {blok.title}
            </h3>
          </div>
          <div
            {...events}
            ref={ref}
            className="mx-auto flex w-full flex-shrink-0 flex-row items-center justify-start gap-[70px] overflow-auto pl-[18px] pb-[20px] scrollbar-hide lg:justify-center lg:pl-0"
          >
            {blok?.images?.map(blok => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

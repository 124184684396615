import Image from "next/image";

export default function NavBoxLink({ blok }) {
  return (
    <>
      <div>
        <a href={blok.link.cached_url}>
          <div className="flex h-[174px] w-[196px] justify-center overflow-hidden rounded-[5px] bg-gl_light_grey align-middle">
            {blok.is_video ? (
              <video
                autoPlay
                muted
                loop
                className="outine-none h-full w-full scale-[3.3] select-none overflow-hidden pr-[3.8rem]"
              >
                <source
                  src={blok.video.filename}
                  className="flex h-full w-full"
                />
              </video>
            ) : (
              <Image
                src={`${blok.image.filename}` + "/m/"}
                width={196}
                height={174}
                quality={100}
                className="h-full w-full select-none object-cover"
              />
            )}
          </div>
          <div className="mt-[14px]">
            <h4 className="sofia-pro-r text-18 leading-[1.1]">
              {blok.link_text}
            </h4>
          </div>
        </a>
      </div>
    </>
  );
}

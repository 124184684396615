import { isUndefined } from "lodash";
import { storyblokEditable } from "@storyblok/react";

import Components from "../blokComponents";
import Placeholder from "@components/bloks/PlaceHolder";

const DynamicComponent = props => {
  const { blok } = props;
  const Component = Components[blok.component];
  if (isUndefined(Component)) {
    return <Placeholder componentName={blok.component} />;
  }
  return <Component blok={blok} {...props} {...storyblokEditable(blok)} />;
};

export default DynamicComponent;

import StoryblokClient from "storyblok-js-client";
import { getStoryblokApi } from "@storyblok/react";

import { getStoryblokContentVersion } from "@utils/helpers";

class StoryblokService {
  constructor() {
    this.devMode = process.env.NODE_ENV !== "production";
    this.client = new StoryblokClient({
      accessToken: process.env.STORY_BLOK_API_ACCESSTOKEN,
      cache: {
        clear: "auto",
        type: "memory"
      }
    });
    this.query = {};
  }

  getQuery(param) {
    return this.query[param];
  }

  get(slug, params) {
    params = params || {};

    if (
      this.getQuery("_storyblok") ||
      this.devMode ||
      (typeof window !== "undefined" && window.storyblok)
    ) {
      params.version = "draft";
    }

    if (
      typeof window !== "undefined" &&
      typeof window.StoryblokCacheVersion !== "undefined"
    ) {
      params.cv = window.StoryblokCacheVersion;
    }

    return this.client.get(slug, params);
  }

  async getStory({ requestString, resolveRelations = [] }) {
    const storyblokApi = getStoryblokApi();
    return await storyblokApi.get(requestString, {
      version: getStoryblokContentVersion(),
      resolve_relations: resolveRelations
    });
  }
}

const storyblokInstance = new StoryblokService();

export default storyblokInstance;

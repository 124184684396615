import React from "react";

type RatingsBlokProps = {
  data: {
    rating: string;
    img_src: string;
  };
};

const RatingsBlok = ({ data: { rating, img_src } }: RatingsBlokProps) => {
  const starsArray = Array(+rating).fill(null);
  return (
    <>
      {starsArray.map((item, index) => (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={`${item}-${index}`}
        >
          <path
            d="M8.49957 12.9399L4.43477 15.9061C4.0372 16.1991 3.66578 16.2357 3.3205 16.016C2.97524 15.7963 2.89155 15.4301 3.0694 14.9174L4.67019 10.1621L0.495606 7.16463C0.213119 6.96584 0.0587992 6.74351 0.0326485 6.49763C0.00648476 6.25176 0.0771039 6.03728 0.244505 5.85419C0.411906 5.6711 0.657778 5.57955 0.982122 5.57955H6.12972L7.65211 0.855685C7.79852 0.395327 8.07834 0.162535 8.49158 0.157313C8.90495 0.152078 9.18484 0.384869 9.33125 0.855685L10.8535 5.57955H15.9855C16.3098 5.57955 16.5557 5.6711 16.723 5.85419C16.8905 6.03728 16.9611 6.25176 16.935 6.49763C16.9088 6.74351 16.7544 6.96584 16.472 7.16463L12.3288 10.1621L13.9139 14.9174C14.0813 15.4301 13.995 15.7963 13.6549 16.016C13.3149 16.2357 12.9461 16.1991 12.5484 15.9061L8.49957 12.9399Z"
            fill="#F1C532"
          />
        </svg>
      ))}
    </>
  );
};

export default RatingsBlok;

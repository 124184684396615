import StoryBlokService from "@services/storyblok-service";

export class StoryblokProductDAO {
  async getProductsDataByUuids(uuids) {
    const {
      data: {
        stories: [data]
      }
    } = await StoryBlokService.getStories({
      starts_with: "products",
      by_uuids: uuids
    });
    return data;
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import Article from "./Article/Article";
import XLArticle from "./XLArticle/XLArticle";
import { storyblokEditable } from "@storyblok/react";
import { useState, useEffect, useRef } from "react";
import storyblokInstance from "@services/storyblok-service";
import { motion } from "framer-motion";
import { useDraggable } from "react-use-draggable-scroll";

export default function Latest({ article, blok }) {
  const [articles, setArticles] = useState([]);

  const ref = useRef();
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true
  });

  useEffect(() => {
    const getArticles = async () => {
      const { data } = await storyblokInstance.get("cdn/stories", {
        starts_with: "blog/",
        resolve_relations: "author",
        filter_query: { component: { in: "post" } }
      });

      const filteredArticles = data.stories.filter(
        article => article.tag_list[0] != ""
      );

      setArticles(prev =>
        filteredArticles.map(article => {
          article.content.full_slug = article.full_slug;
          article.content.tag_list = article.tag_list;
          return article;
        })
      );
    };

    getArticles();
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 1.4,
        duration: 0.4
      }}
      className="mx-auto flex h-full w-full max-w-[120rem] flex-col"
      {...storyblokEditable(article)}
    >
      <div className="mx-auto flex h-full w-full max-w-[120rem] flex-col px-[18px] lg:px-[48px] xl:px-[48px]">
        <div className="mx-auto flex w-full max-w-[81.5rem] flex-col border-t-2 border-[#F5F9FF] pt-[25px] lg:pt-[50px]">
          <div className="mb-[20px] lg:mb-[40px]">
            <h3
              className="sofia-pro-b text-[32px] leading-[1.1] lg:text-[34px]"
              {...storyblokEditable(blok)}
            >
              {blok.title}
            </h3>
          </div>
          <div className="justify-left w-full">
            {articles.slice(0, 1).map(article => (
              <XLArticle
                article={article.content}
                key={article.uuid}
                {...storyblokEditable(article)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto mt-[30px] flex h-full w-full max-w-[120rem] items-center justify-center pb-[60px]">
        <div
          {...events}
          ref={ref}
          className="ml-auto flex h-fit w-full select-none flex-row justify-start gap-[16px] overflow-y-hidden overflow-x-scroll px-[18px] scrollbar-hide lg:px-[48px] xl:hidden xl:px-[48px]"
        >
          {articles.slice(1, 4).map(article => (
            <Article
              article={article.content}
              key={article.uuid}
              {...storyblokEditable(article)}
            />
          ))}
        </div>
        <div
          className="hidden h-full w-full flex-row justify-start gap-[20px] overflow-y-hidden overflow-x-scroll scrollbar-hide xl:flex xl:justify-center"
          {...storyblokEditable(blok)}
        >
          {articles.slice(1, 4).map(article => (
            <Article article={article.content} key={article.uuid} />
          ))}
        </div>
      </div>
    </motion.div>
  );
}

import { storyblokEditable } from "@storyblok/react";
import { isEmpty } from "lodash";
import Image from "next/image";

const CollectionSliderItem = ({ blok }) => {
  const { related_collection: relatedCollection } = blok;
  if (isEmpty(relatedCollection)) {
    return null;
  }
  const [
    {
      product_url,
      collection_item: {
        full_slug: fullSlug,
        name,
        content: { image, slider_card_image }
      }
    }
  ] = relatedCollection;

  return (
    <div
      className="keen-slider__slide flex h-fit w-fit min-w-[312px] max-w-[312px] flex-shrink-0 flex-col"
      {...storyblokEditable(blok)}>
      <a
        href={product_url?.cached_url || fullSlug || blok?.link?.cached_url}
        className="flex w-full">
        <div className="flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-8 bg-gl_light_grey py-[20px] pr-[14px] pl-[8px] align-middle lg:py-[36px] lg:pr-[15px] lg:pl-[43px]">
          <div className="relative h-[284px] w-[257px] pl-[10px] lg:pl-[0px]">
            <Image
              layout="fill"
              src={slider_card_image?.filename || image.filename}
              alt=""
            />
          </div>
        </div>
      </a>
      <a href={product_url?.cached_url || fullSlug}>
        <div className="mt-[10px] flex h-full w-fit">
          <h2 className="sofia-pro-b text-[22px]">{name}</h2>
        </div>
      </a>
      <div className="mt-[15px] flex h-full w-fit">
        <a
          href={product_url?.cached_url || fullSlug}
          className="sofia-pro-m h-max w-fit rounded-8 bg-gl-blue py-2 px-[20px] text-center text-white transition-all hover:bg-[#07496c] lg:w-fit">
          {blok?.link_text}
        </a>
      </div>
    </div>
  );
};
export default CollectionSliderItem;

export const PRODUCT_QUERY = `
	id
	title
	handle
	description
	productType
	options {
		name
		values
		id
	}
	priceRange {
		minVariantPrice {
			amount
		}
		maxVariantPrice {
			amount
		}
	}
	images(first: 5) {
		edges {
			node {
				url
				altText
			}
		}
	}
	requiresSellingPlan
	collections(first: 1) {
		edges {
			node {
				products(first: 5) {
					edges {
						node {
							priceRange {
								minVariantPrice {
									amount
								}
							}
							handle
							title
							id
							images(first: 5) {
								edges {
									node {
										url
										altText
									}
								}
							}
						}
					}
				}
			}
		}
	}
	sellingPlanGroups(first: 1) {
		edges {
			node {
				name
				options {
						name
						values
				}
				sellingPlans(first: 10) {
				edges {
						node {
						id
						name
						description
						recurringDeliveries
						priceAdjustments {
								orderCount
								adjustmentValue {
								__typename
								... on SellingPlanPercentagePriceAdjustment {
										adjustmentPercentage
								}
								... on SellingPlanFixedAmountPriceAdjustment {
										adjustmentAmount {
										amount
										currencyCode
										}
								}
								... on SellingPlanFixedPriceAdjustment {
										price {
										amount
										currencyCode
										}
										}
								}
								}
						}
						}
				}
			}
		}
	}
	variants(first: 100) {
		edges {
			node {
				selectedOptions {
					name
					value
				}
				image {
					url
					altText
				}
				compareAtPrice{
					amount
				}
				title
				sku
				id
				availableForSale
				priceV2 {
					amount
				}
			}
		}
	}
`;

export const productsInCollectionQuery = `
		{
			collection(handle: "frontpage") {
				title
				products(first: 25) {
					edges {
						node {
							id
							title
							handle
							priceRange {
								minVariantPrice {
									amount
								}
							}
							images(first: 5) {
								edges {
									node {
										url
										altText
									}
								}
							}
						}
					}
				}
			}
		}`;

export const allProductsQuery = `
		{
		products(first: 25) {
			edges {
				node {
					id
					title
					handle
					productType
					priceRange {
						minVariantPrice {
							amount
						}
					}
					images(first: 5) {
						edges {
							node {
								originalSrc
								altText
							}
						}
					}
					options {
						name
						values
						id
					}
					tags
					requiresSellingPlan
					sellingPlanGroups(first: 1) {
						edges {
							node {
								name
								options {
										name
										values
								}
								sellingPlans(first: 10) {
									edges {
										node {
										id
										name
										description
										recurringDeliveries
										priceAdjustments {
											orderCount
											adjustmentValue {
												__typename
												... on SellingPlanPercentagePriceAdjustment {
													adjustmentPercentage
												}
												... on SellingPlanFixedAmountPriceAdjustment {
													adjustmentAmount {
														amount
														currencyCode
													}
												}
												... on SellingPlanFixedPriceAdjustment {
														price {
															amount
															currencyCode
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
					variants(first: 25) {
						edges {
							node {
								selectedOptions {
									name
									value
								}
								image {
									url
									altText
								}
								sku
								title
								compareAtPrice{
									amount
								}
								id
								availableForSale
								priceV2 {
									amount
								}
							}
						}
					}
				}
			}
		}
	}
	`;

export const getProductQueryByHandle = handle => `
	{
		product(handle: "${handle}") {
			${PRODUCT_QUERY}
		}
	}
`;

export const getProductQueryById = id => `
	{
		product(id: "gid://shopify/Product/${id}") {
			${PRODUCT_QUERY}
		}
	}
`;

export const recursiveCatalogQuery = cursor => {
  return `{
		products(${cursor !== "" ? `after: "${cursor}",` : ""}first: 250) {
			edges {
				cursor
				node {
					id
					handle
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
	`;
};

export const getCollectionProductsListById = collectionId => `{
	collection(id: "${collectionId}") {
					id
	title
	products(first: 5) {
							edges {
								node {
									priceRange {
										minVariantPrice {
											amount
										}
									}
									handle
									title
									id
									images(first: 1) {
										edges {
											node {
												url
												altText
											}
										}
									}
									variants(first: 25) {
										edges {
											node {
												sku
												selectedOptions {
													name
													value
												}
												image {
													url
													altText
												}
												title
												id
												availableForSale
												priceV2 {
													amount
												}
												compareAtPrice{
													amount
												}
											}
										}
									}
									requiresSellingPlan
									sellingPlanGroups(first: 4) {
											edges {
													node {
															name
															options {
																			name
																			values
															}
															sellingPlans(first: 10) {
															edges {
																			node {
																			id
																			name
																			description
																			recurringDeliveries
																			}
																	}
															}
													}
											}
									}
								}
							}
						}
					}
}`;

export default function Info({ blok }) {
  return (
    <>
      <div className="flex flex-row items-center space-x-[15px]">
        <div className="relative h-[40px] w-[40px]">
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="20.0547"
              cy="20.5439"
              r="20.0273"
              fill="#EDF2F4"
            ></circle>
          </svg>
          <div className="absolute top-0 flex h-full w-full items-center justify-center">
            <img src={blok.svg.filename} />
          </div>
        </div>
        <div className="flex flex-col">
          <h4 className="sofia-pro-r text-18 leading-[1.2]">
            {blok.subheading}
            <br />
            <span className="sofia-pro-b">{blok.text}</span>
          </h4>
        </div>
      </div>
    </>
  );
}

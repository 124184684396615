import { last, trim, lowerCase, capitalize, isUndefined } from "lodash";

import { RechargeBundleProductDAO } from "../services/RechargeBundleDAO";
import { ShopifyProductDAO } from "../services/ShopifyProductDAO";

export const formatter = (value, currency) =>
  currency &&
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  }).format(value);

export const getProductFromListById = (productsList, idToGet) =>
  productsList &&
  productsList.find(({ id }) => last(id.split("/")) === idToGet);

export const concatProductData = (
  storyBlokProductsList,
  allShopifyProducts
) => {
  const concatedProductDataList = storyBlokProductsList.map(product => {
    const {
      content: { shopify_product_id: productShopifyId }
    } = product;

    const shopifyProductData = getProductFromListById(
      allShopifyProducts,
      productShopifyId
    );

    return { ...product, shopifyProductData };
  });
  return concatedProductDataList;
};

export const cartServiceDataAdapter = fullProductData => {
  return {
    productVariantId: fullProductData.productCartData.id,
    productSellingPlanData:
      fullProductData.sellingPlanAllocation?.sellingPlan.id
  };
};

export const getStoryComponent = (storiesList, componentKey) =>
  storiesList?.find(({ component }) => component === componentKey);

export const isVariantAlreadyAdded = (allAddedVariantsList, currentVariant) => {
  const { question, answer } = currentVariant;
  const serializedCurrentQuestion = JSON.stringify(question);
  const serializedCurrentAnswer = JSON.stringify(answer);
  return allAddedVariantsList.some(
    variant =>
      JSON.stringify(variant.question) === serializedCurrentQuestion &&
      JSON.stringify(variant.answer) === serializedCurrentAnswer
  );
};

export const getQuizProductsRecommendations = ({
  productsList,
  quizResults,
  maximumResultsForLayout
}) => {
  const allMatchingProducts = quizResults
    .map(({ matchingProductIdsList }) => {
      return !isUndefined(matchingProductIdsList) && matchingProductIdsList;
    })
    .flat()
    .filter(item => item);

  const matchesCount = allMatchingProducts.reduce(
    (acc, value) => ({
      ...acc,
      [value]: (acc[value] || 0) + 1
    }),
    {}
  );
  const matchingArray = Object.keys(matchesCount).map(item => ({
    productId: item,
    matchers: matchesCount[item]
  }));
  const recommendations = productsList
    .map(product => {
      const {
        content: { shopify_product_id: shopifyProductId }
      } = product;
      const matchingInQuiz = matchingArray.find(
        ({ productId }) => productId === shopifyProductId
      );
      const matches = matchingInQuiz?.matchers || 0;
      return { ...product, matches };
    })
    .filter(item => !!item.matches);
  const ratedRecommendations = recommendations.sort(
    (a, b) => b.matches - a.matches
  );
  return ratedRecommendations.slice(0, maximumResultsForLayout);
};

export const getShopifyShortId = fullId => {
  const parts = fullId.split("/");
  return parts[parts.length - 1];
};

export const calculateQuantity = (actionType, currentQuantity) => {
  const actionVariants = {
    increment: currentQuantity + 1,
    decrement: currentQuantity > 0 ? currentQuantity - 1 : 0
  };
  return actionVariants[actionType];
};

export const isObjectsEquals = (firstObject, secondObject) =>
  JSON.stringify(firstObject) === JSON.stringify(secondObject);

export const buildSelectedBundleObject = ({
  bundleObject,
  selectedBundleProductVariant,
  selectedBundleItemsList
}) => {
  const bundleDAO = new RechargeBundleProductDAO(bundleObject);
  const bundleProductId = bundleDAO.bundleProductId;
  const bundleCollectionId = bundleDAO.getExternalCollectionId();
  const externalVariantId = getShopifyShortId(selectedBundleProductVariant.id);
  const selections = selectedBundleItemsList.map(bundleSelectionItem => {
    const product = new ShopifyProductDAO(bundleSelectionItem);
    return {
      collectionId: bundleCollectionId,
      externalProductId: JSON.stringify(product.productId),
      externalVariantId: getShopifyShortId(product.getFirstVariantId()),
      quantity: bundleSelectionItem.quantity
    };
  });
  const selection = {
    externalVariantId,
    externalProductId: bundleProductId,
    selections
  };
  return selection;
};

export const buildContentsString = itemsArray => {
  let string = "";
  itemsArray.forEach(({ title, quantity }, index) => {
    const isLastItem = index === itemsArray.length - 1;
    const itemString = ` ${quantity}x${title}${!isLastItem ? "," : ""}`;
    string = string + itemString;
  });
  return string;
};

export const getPresetsFromUrl = url => {
  const presetsString = url.query?.presets;
  const decodedPresetsString = presetsString ? decodeURI(presetsString) : "";
  return decodedPresetsString ? JSON.parse(decodedPresetsString) : {};
};

export const formatString = string => {
  return string && lowerCase(trim(string));
};

export const capitalizeFirstLetter = string => {
  return string && capitalize(string);
};

export const isStartsWithCapital = string => /[A-Z]/.test(string.charAt(0));

export const getSingleBlok = (blokesList, key) =>
  blokesList.find(({ component }) => component === key);

export const numberFormatter = Intl.NumberFormat("en-US");

export const checkInventory = (productInventoryList, variantId) => {
  if (isUndefined(productInventoryList)) {
    return false;
  }
  return productInventoryList.find(({ node: { id } }) => id === variantId)?.node
    ?.availableForSale;
};

export const getStoryblokContentVersion = () => {
  const nonProdEnvs = ["development", "staging"];
  if (
    nonProdEnvs.includes(process.env.NODE_ENV?.toLowerCase()) ||
    nonProdEnvs.includes(process.env.NEXT_PUBLIC_ENV?.toLowerCase())
  ) {
    return "draft";
  }
  return "published";
};

export const getSeoMetadata = seoObjectsArray => {
  return {
    title: seoObjectsArray?.[0]?.title || "",
    description: seoObjectsArray?.[0]?.description || ""
  };
};

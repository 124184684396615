import React from "react";
import Image from "next/image";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@src/hooks";
import { motion } from "framer-motion";

import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import {
  MARK_BOLD,
  NODE_QUOTE,
  render
} from "storyblok-rich-text-react-renderer";

const ImagePart = ({ activeTab, items }) => {
  return (
    <div className="z-1 relative h-full w-full overflow-hidden">
      <div className="">
        {items.map((blok, index) => (
          <motion.div
            initial={{ opacity: index !== activeTab ? 1 : 0 }}
            animate={{ opacity: index === activeTab ? 1 : 0 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              delay: 0.1,
              duration: 0.4
            }}
            className="relative mt-[40px] h-[50vh] w-full overflow-hidden rounded-8 transition-all lg:mt-0 lg:h-[500px]"
            style={{ display: index === activeTab ? "block" : "none" }}
            key={index}
          >
            <Image
              src={`${blok?.left_image?.filename}` + "/m/"}
              layout="fill"
              objectFit="cover"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const TextPart = ({ blok, idx, setActive, activeTab, isDesktop }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (activeTab !== idx) {
      const min = isDesktop ? 0.25 : 0.15;
      const max = isDesktop ? 0.78 : 0.53;
      const isActive = progress > min && progress < max ? true : false;
      if (isActive) {
        setActive(idx);
      }
    }
  }, [progress]);

  const richtextOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <span className="relative ml-2 text-[#2589BD]">{children}</span>;
      }
    },
    nodeResolvers: {
      [NODE_QUOTE]: children => {
        return <div className="text-white">{children}</div>;
      }
    }
  };

  return (
    <Parallax onProgressChange={progress => setProgress(progress)}>
      <div
        className="relative flex items-end justify-center transition"
        style={{ opacity: 1 }}
      >
        <div className="pb-[25vh] pt-[25vh] text-center lg:pb-[50vh] lg:pt-[50vh] lg:text-left ">
          {blok?.subheading && (
            <h4
              className="sofia-pro-s text-underline-blue-home pb-[13px] text-[20px] uppercase leading-[1] text-[#2589BD] lg:text-[22px]"
              {...storyblokEditable(blok)}
            >
              {blok.subheading}
            </h4>
          )}
          {blok?.title && (
            <h3
              className="sofia-pro-b text-[30px] leading-[1.1] lg:text-[34px]"
              {...storyblokEditable(blok)}
            >
              {blok.title}
            </h3>
          )}
          {blok?.paragraph && (
            <div
              className="sofia-pro-r mt-[20px] text-[20px] leading-[1.2] text-[#424B5B]"
              {...storyblokEditable(blok)}
            >
              {render(blok?.paragraph, richtextOptions)}
            </div>
          )}
          {blok.link.cached_url && blok.link_text && (
            <div className="w-full pt-[15px] lg:w-fit lg:pt-[25px]">
              <a
                href={blok.link.cached_url}
                className="sofia-pro-m flex w-full justify-center rounded-8 bg-[#E2F5FF] px-[12px] py-[14px] text-center text-[18px] leading-[1] transition-all hover:bg-[#b5d7ed] lg:w-[190px]"
                {...storyblokEditable(blok)}
              >
                {blok.link_text}
              </a>
            </div>
          )}
        </div>
      </div>
    </Parallax>
  );
};

const Paralax = ({ blok: { items: itemsList } }) => {
  const [activeTab, setActiveTab] = useState(0);
  const matches = useMediaQuery(`(min-width: ${992}px)`);

  const onActiveChange = (number: number) => {
    setActiveTab(number);
  };
  return (
    <div className="m-auto mb-[30px] flex h-full w-full max-w-[120rem] flex-col flex-wrap px-[18px] lg:px-[48px] xl:px-[48px]">
      <ParallaxProvider>
        <div className="mx-auto mb-[40px] flex w-full max-w-[81.5rem]  flex-col items-start lg:flex-row">
          <div className="sticky top-0 z-10 flex h-[50vh] w-full items-center bg-white lg:h-[100vh] lg:w-1/2 lg:bg-transparent">
            <div className="relative w-full">
              <div className="rounded ">
                <ImagePart activeTab={activeTab} items={itemsList} />
              </div>
            </div>
          </div>
          <div className="w-full pl-0 lg:w-1/2 lg:pl-[60px]">
            <div className="lg:max-w-475 mx-auto w-full">
              {itemsList?.map((customBlok, index) => (
                <TextPart
                  key={customBlok._uid}
                  isDesktop={matches}
                  blok={customBlok}
                  idx={index}
                  setActive={onActiveChange}
                  activeTab={activeTab}
                />
              ))}
            </div>
          </div>
        </div>
      </ParallaxProvider>
    </div>
  );
};

export default Paralax;

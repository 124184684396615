import { storyblokEditable } from "@storyblok/react";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import React from "react";
import Image from "next/image";

const SimpleSection = ({ blok: { text, image }, blok }) => {
  const renderOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <b className={`sofia-pro-b`}>{children}</b>;
      }
    }
  };

  return (
    <div
      className="mx-auto w-full max-w-[120rem] px-[18px] text-center"
      {...storyblokEditable(blok)}
    >
      <div
        className={`mx-auto mx-auto w-full max-w-[81.5rem] max-w-4xl text-[20px] md:text-[24px]`}
      >
        <div className={`relative mx-auto mb-7 h-[143px] w-[188px]`}>
          <Image src={image?.filename} layout="fill" objectFit="contain" />
        </div>
        {render(text, renderOptions)}
      </div>
    </div>
  );
};

export default SimpleSection;

import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";

const BrandAssets = ({ blok: { title, assets } }) => {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h4 className="sofia-pro-b mb-5 text-[32px] leading-none text-gl-blue transition-all duration-500 group-hover:text-[#07496c] lg:text-[34px]">
            {children}
          </h4>
        );
      }
    }
  };

  const renderDescriptionOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r mb-0 text-[22px] leading-[1.2] text-gl-black">
            {children}
          </p>
        );
      }
    }
  };

  return (
    <section className="bg-[#F5F9FA] py-12 md:py-20">
      <div className="custom-container">
        <h3 className="sofia-pro-b mb-8 text-center text-[32px] lg:text-[44px]">
          {title}
        </h3>
        {assets.length > 0 && (
          <ul className="flex flex-wrap items-start justify-evenly gap-y-8">
            {assets.map(asset => (
              <li className="w-full text-center md:w-[32%]">
                <a
                  href={asset.link.cached_url}
                  target="_blank"
                  className="group">
                  {render(asset.title, renderTitleOptions)}
                  <div className="mx-auto max-w-[250px]">
                    {render(asset.subtitle, renderDescriptionOptions)}
                  </div>
                  <img
                    className="mx-auto mt-7 transition-all duration-500 group-hover:scale-110"
                    style={{ width: `${asset.icon_size}px` }}
                    src={asset.icon_image.filename}
                    alt=""
                  />
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};
export default BrandAssets;

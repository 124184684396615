async function fetchAPI(query, { variables, preview } = {}) {
  const res = await fetch("https://gapi.storyblok.com/v1/api", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: "5aZnF84rLFfpj4QqBm7PlQtt",
      Version: preview ? "draft" : "published"
    },
    body: JSON.stringify({
      query,
      variables
    })
  });

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }

  return json.data;
}

export async function fetchReviewProductAPI(id) {
  const res = await fetch(
    `https://api-cdn.yotpo.com/v1/widget/${process.env.NEXT_PUBLIC_YOTPO_APP_KEY}/products/${id}/reviews.json`
  );

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors, "fetchReviewProductAPI");
    throw new Error("Failed to fetch API");
  }

  return json.response;
}

export async function fetchAllReviewsAPI() {
  const res = await fetch(
    `https://api.yotpo.com/v1/apps/${process.env.NEXT_PUBLIC_YOTPO_APP_KEY}/reviews?utoken=${process.env.NEXT_PUBLIC_YOTPO_USER_TOKEN}&count=15&page=1`
  );

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors, "fetchAllReviewsAPI");
    throw new Error("Failed to fetch API");
  }

  return json;
}

export async function getAllPostsWithSlug() {
  const data = await fetchAPI(`
        {
          PostItems {
            items {
              slug
            }
          }
        }
      `);
  return data?.PostItems.items;
}

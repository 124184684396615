import React, { useContext } from "react";
import Image from "next/image";
import { isEmpty } from "lodash";

import { Link } from "@components/UI";
import ProductCardQuickAdd from "./ProductCardQuickAdd";
import { ShopifyProductDAO } from "@services/ShopifyProductDAO";
import { ShopContext } from "@context/shopContext";

const ProductCard = ({ product, blok, data, cardVariant = "clickable" }) => {
  if (isEmpty(product)) {
    return null;
  }
  const { cartCurrency } = useContext(ShopContext);
  const productDAO = new ShopifyProductDAO(product, cartCurrency);

  const productCardVariants = {
    clickable: (
      <div className="group h-full w-full max-w-[250px] cursor-pointer">
        <div className="w-full overflow-hidden">
          <div className="relative flex h-full w-full flex-col items-center">
            <Link
              href={`/products/${productDAO.handle}`}
              className="mb-2 h-full w-full"
            >
              {/* <div className="relative flex w-[100%] justify-center rounded-8 bg-gl_light_grey p-5">
                <Image
                  alt="product card"
                  width="200"
                  height="220"
                  src={productDAO?.mainImage}
                />
              </div> */}
            </Link>
            <div className="my-[5px] mb-3">
              <span className="sofia-pro-s text-18 leading-[1.1]">
                {productDAO?.title}
              </span>
            </div>
            <ProductCardQuickAdd
              data={{
                ...data,
                product,
                price: productDAO.getProductVariantPrice(
                  productDAO.firstVariant
                ).discountedPrice
              }}
            />
          </div>
        </div>
      </div>
    )
  };

  return productCardVariants[cardVariant];
};

export default ProductCard;

import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

import { Button, Image } from "@components/UI";

export default function DiscountProposition({
  propositionText,
  richtextOptions,
  onApply
}) {
  return (
    <div className="flex w-full items-center justify-center bg-gl-blue">
      <div className="relative flex w-full flex-col items-center justify-center pt-[5px] pb-[14px] lg:w-auto lg:flex-row lg:py-[25px] lg:pt-[17px]">
        <div className="absolute left-0 bottom-0 hidden h-[140px] w-[185px] lg:block">
          <Image
            alt="discount-badge"
            layout="fill"
            objectFit="contain"
            src="/discount-badge.png"
          />
        </div>
        <div className="absolute left-0 bottom-0 block h-[130px] w-[75px] lg:hidden">
          <Image
            alt="discount-badge"
            layout="fill"
            objectFit="contain"
            src="/discount-badge-mobile.png"
          />
        </div>
        <div className="sofia-pro-s mb-[5px] flex px-[75px] text-center text-[20px] leading-[1.2] text-white sm:px-[0] sm:text-left lg:mr-[30px] lg:mb-0 lg:pl-[205px] lg:text-[24px]  ">
          {render(propositionText, richtextOptions)}
        </div>
        <Button
          variant="white"
          onClick={onApply}
          className="px-[13px] text-[12px] uppercase leading-[1.6] lg:text-[14px]"
        >
          Apply Discount
        </Button>
      </div>
    </div>
  );
}

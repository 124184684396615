import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const BlokWrapper = ({
  blok: { blocks, top_padding: topPadding, is_grey_gradient_on_block = false }
}) => {
  return (
    <div
      className={`${
        is_grey_gradient_on_block && "bg-partner"
      } mx-auto mb-[60px] flex h-full w-full max-w-[120rem] flex-col   pt-[${topPadding}px] px-[18px] lg:mb-[100px] lg:px-[48px] xl:px-[48px]`}
    >
      {blocks.map(blok => (
        <StoryblokComponent
          blok={blok}
          key={blok._uid}
          {...storyblokEditable(blok)}
        />
      ))}
    </div>
  );
};
export default BlokWrapper;

import React from "react";

import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import { useMediaQuery } from "@src/hooks";

export default function GivingBack({ blok }) {
  const customOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <span className="text-[#2589BD]">{children}</span>;
      }
    }
  };

  const isMedium = useMediaQuery("(max-width: 992px)");

  return (
    <div
      style={{ minHeight: !isMedium && `${blok.desktop_height}px` }}
      className="relative mx-auto mb-[90px] flex h-full w-full max-w-[81.5rem]"
    >
      <div className="absolute left-0 top-0 -z-10 h-full w-full">
        <Image
          src={blok?.background_image?.filename}
          alt="bundleContentItem"
          layout="fill"
          objectFit="cover"
          className="xl:rounded-8"
        />
      </div>
      <div className="ml-[20px] mr-4 flex w-full max-w-[522px] flex-col justify-center py-20 text-white sm:ml-[50px] md:py-32 lg:ml-[100px]">
        <h3
          style={{ color: blok.title_color?.color }}
          className="sofia-pro-b mb-8 text-[32px] leading-[1] lg:text-[44px]"
          {...storyblokEditable(blok)}
        >
          {render(blok.title, customOptions)}
        </h3>
        <p
          className="sofia-pro-r text-[26px] leading-[1.2] lg:text-[34px]"
          {...storyblokEditable(blok)}
        >
          {blok.paragraph}
        </p>
      </div>
    </div>
  );
}

import React from "react";
import cn from "classnames";
import { useKeenSlider } from "keen-slider/react";
import {
  NODE_BR,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";

const GivingBack = ({
  blok: { background_color, title, subtitle, items, list_items, image }
}) => {
  const [sliderRef] = useKeenSlider({
    loop: true,
    mode: "free",
    rtl: false,
    slides: { origin: "center", perView: "auto", spacing: 20 },
    breakpoints: {
      "(max-width: 758px)": {
        slides: { origin: "center", perView: "auto", spacing: 5 }
      }
    }
  });

  const renderListOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r mb-0 text-[20px] lg:text-[34px]`}>
            {children}
          </p>
        );
      },
      [NODE_BR]: () => {
        return <br className={`hidden md:block`} />;
      }
    }
  };

  return (
    <section
      style={{ background: background_color.color }}
      className="pt-12 text-center md:pt-20">
      <div className="custom-container">
        <h3 className="sofia-pro-s mb-2 text-[32px] lg:text-[44px]">{title}</h3>
        <p className="sofia-pro-r mx-auto mb-0 max-w-[936px] text-[20px] leading-[1.2] lg:text-[22px]">
          {subtitle}
        </p>
      </div>
      {items.length > 0 && (
        <div
          ref={sliderRef}
          className="keen-slider mt-9 h-full h-[78px] w-full overflow-visible md:mt-14 md:h-[276px]">
          {items.map(item => (
            <div className="keen-slider__slide w-[116px] min-w-[116px] md:w-[412px] md:min-w-[412px]">
              <img
                src={item.image?.filename}
                className="flex h-full w-full rounded-8 object-cover"
                alt=""
              />
            </div>
          ))}
        </div>
      )}
      <div className="py-6 md:py-20">
        <div className="custom-container">
          <div className="flex flex-wrap items-center justify-center gap-4 text-left md:gap-12">
            {list_items?.length > 0 && (
              <ul className="w-full max-w-[630px] md:w-[69%]">
                {list_items.map((item, index) => (
                  <li
                    key={index}
                    className={cn(
                      "flex items-center py-4 leading-[1.2] md:min-h-[160px]",
                      { "border-dark border-b": index === 0 }
                    )}>
                    <img
                      style={{ height: `${item.icon_size}px` }}
                      className="mr-5 max-h-[100px] w-[116px] min-w-[116px] object-contain"
                      src={item.icon_image.filename}
                      alt=""
                    />
                    {render(item.title, renderListOptions)}
                  </li>
                ))}
              </ul>
            )}
            <div className="relative z-10 w-[50%] md:w-[30%]">
              <div className="absolute top-0 left-0 -z-10 h-full w-full bg-gl-blue opacity-50 blur-[100px]" />
              <img src={image?.filename} className="w-full" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GivingBack;

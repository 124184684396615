import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import { motion } from "framer-motion";

const QuickFacts = ({ blok: { background_color, title, facts } }) => {
  const renderOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r mb-0 text-[22px] leading-[1.2] text-gl-dark-blue">
            {children}
          </p>
        );
      },
      [NODE_HEADING]: children => {
        return (
          <h4 className="sofia-pro-s text-[34px] leading-none text-gl-blue">
            {children}
          </h4>
        );
      }
    }
  };

  return (
    <motion.section
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 20,
        delay: 1,
        duration: 0.8
      }}
      className="relative z-10 pb-12 md:pb-24">
      <div className="custom-container">
        <div
          style={{ background: background_color.color }}
          className="rounded pt-12 pb-12 text-center md:pt-20 md:pb-20">
          <h3 className="sofia-pro-b text-[32px] lg:text-[34px]">{title}</h3>
          {facts.length > 0 && (
            <ul className="mx-auto mt-10 flex max-w-[1140px] flex-wrap items-start justify-evenly gap-y-10 md:mt-14">
              {facts.map(fact => (
                <li className="w-[48%] max-w-[180px] md:w-[19%]">
                  <img
                    className="mx-auto mb-3 md:mb-6"
                    style={{ width: `${fact.icon_size}px` }}
                    src={fact.icon_image.filename}
                    alt=""
                  />
                  {render(fact.title, renderOptions)}
                  {render(fact.subtitle, renderOptions)}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </motion.section>
  );
};

export default QuickFacts;

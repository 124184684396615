const WhyJoinUs = ({ blok }) => {
  return (
    <div className="mx-auto w-full max-w-[calc(81.5rem-36px)] px-[18px] pt-[50px] pb-[87px] md:pt-[100px] lg:pt-[139px]">
      <h3 className="sofia-pro-b mb-[40px] text-center text-[34px] text-[#15233C]">
        {" "}
        {blok.title}
      </h3>
      <ul className="grid  grid-cols-12 gap-x-[10px] gap-y-[30px]">
        {blok.list.map(d => (
          <li className="col-span-12 flex flex-col items-center  text-center sm:col-span-6  md:col-span-6 lg:col-span-3">
            <section className="mb-[18px] h-[129px]  w-[129px] overflow-hidden rounded-full bg-[#F5F9FA]">
              <img className="h-full w-full" src={d.image}></img>
            </section>
            <h4 className="sofia-pro-b text-[22px] text-[#15233C]">
              {d.title}
            </h4>
            <p className="sofia-pro-r text-[18px] leading-[23px] text-[#424B5B]">
              {d.paragraph}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhyJoinUs;

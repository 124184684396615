import React from "react";
import Image from "next/image";
import { motion } from "framer-motion";

const EarthPageAnsvwerVariantItem = props => {
  const {
    answer: { answer_img, answer_value, description },
    data: { setValue, value }
  } = props;

  const variants = {
    enter: {
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.5, delayChildren: 0.2 }
    },
    exit: {
      y: -20,
      opacity: 0,
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  return (
    <motion.div
      variants={variants}
      onClick={() => setValue(answer_value)}
      className={`flex h-full w-[305px] cursor-pointer select-none flex-row items-center rounded-8 border-[3px] ${
        value === answer_value ? "border-[#2589BD]" : "border-transparent"
      } bg-white py-[17px] pr-[27px] pl-[17px] transition-all hover:border-[#2589BD]`}
    >
      <div className="w-full space-y-[5px] text-left">
        <h4 className="sofia-pro-s text-[22px] leading-[1.1]">
          {answer_value}
        </h4>
        <p className="sofia-pro-r text-18 leading-[1.1]">{description}</p>
      </div>
      <div className="flex w-fit justify-end">
        <div className="relative flex h-[88px] w-[88px] justify-end">
          <Image
            src={answer_img?.filename}
            alt={answer_img?.alt}
            layout="fill"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default EarthPageAnsvwerVariantItem;

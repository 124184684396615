import React, { useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";

import PlusSign from "../SVG/plus";
import Accordion from "../UI/accordionWrapper";

type AccordionItemProps = {
  content: { title: string; accordion_content: any };
};

const AccordionItem = ({
  content: { title, accordion_content }
}: AccordionItemProps) => {
  const [openedState, setOpenedState] = useState(false);

  return (
    <div
      className="mb-3 flex w-full flex-col rounded-8 bg-[#EFFAFB] py-3 px-3.5"
      onClick={() => setOpenedState(!openedState)}
    >
      <div className="flex w-full items-center justify-between">
        <div>
          <span>{title}</span>
        </div>
        x
        <div>
          <span>
            <PlusSign />
          </span>
        </div>
      </div>
      <Accordion isOpened={openedState}>
        <div> {render(accordion_content)}</div>
      </Accordion>
    </div>
  );
};

export default AccordionItem;

import React, { useState, useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import ButtonNextPrev from "@components/UI/ButtonsNextPrev";
import { getSingleBlok } from "@utils/helpers";
import { ShopContext } from "@context/shopContext";
import QuestionFooter from "@components/QuizQuestionFooter";
import QuizSearchingView from "@components/QuizSearchingView";
import QuizLastStepSection from "@components/QuizLastStepSection";

const QuizContent = props => {
  if (isEmpty(props.blok.items_list)) {
    return null;
  }
  const { items_list } = props.blok;
  const {
    isAnyOptionChosen,
    setIsAnyOptionChosen,
    searchInProgress,
    setActiveQuizStep,
    setQuizQuestionsProgress,
    setSearchLoading
  } = useContext(ShopContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchICompleted, setSearchIsCompleted] = useState(false);
  const [isNextBtnAvailable, setShowNextBtn] = useState(false);
  const {
    data: { nextStepText = "Next", prevStepText = "Prev", body: quizBody },
    data
  } = props;
  const { quiz_steps } = getSingleBlok(quizBody, "quiz_nav");

  useEffect(() => {
    setActiveQuizStep(quiz_steps[0].text);
  }, []);
  useEffect(() => {
    const adapter = [
      {
        index: 1,
        isActive: searchInProgress
      },
      {
        index: 2,
        isActive: searchICompleted && !searchInProgress
      }
    ];
    const active = adapter.find(({ isActive }) => isActive);
    active && setActiveQuizStep(quiz_steps[active.index].text);
    setQuizQuestionsProgress(
      Math.round(((pageIndex + 1) / items_list.length) * 100).toString()
    );
  }, [searchInProgress, searchICompleted, pageIndex]);

  const nextButtonClick = () => {
    if (!isLastPage) {
      setPageIndex(pageIndex + 1);
      setIsAnyOptionChosen(false);
      setQuizQuestionsProgress((pageIndex / items_list.length) * 100);
    } else {
      setSearchIsCompleted(true);
    }
  };
  const maxAvailableQuestionsIndex = items_list.length - 1;
  const isLastPage = pageIndex === maxAvailableQuestionsIndex;
  const isFirstPage = pageIndex <= 0;
  const isNextDisabled = isLastPage || !isAnyOptionChosen;
  return (
    <>
      {searchInProgress && <QuizSearchingView {...data} />}
      {!searchICompleted && !searchInProgress && (
        <div
          className="relative mx-auto flex w-full grow flex-col justify-between overflow-hidden bg-[#edf2f4] sm:h-full"
          {...storyblokEditable(props.blok)}
        >
          {items_list.map((question, index) => {
            if (pageIndex !== index) {
              return null;
            }
            const isMultiAnswerAllowed = question?.is_multi_answers_allowed;
            const data = {
              isLastPage,
              nextButtonClick: !isMultiAnswerAllowed
                ? nextButtonClick
                : () => {},
              isActivePage: pageIndex === index,
              setShowNextBtn
            };
            return (
              <StoryblokComponent
                key={question._uid}
                blok={question}
                {...storyblokEditable(question)}
                data={data}
              />
            );
          })}
          <div className="mb-10 flex flex-col items-center sm:flex-row sm:justify-center sm:gap-8">
            <ButtonNextPrev
              onClick={() => {
                !isFirstPage && setPageIndex(pageIndex - 1);
              }}
              isDisabled={isFirstPage}
              isUnderlinedText={true}
            >
              {prevStepText}
            </ButtonNextPrev>
            {isNextBtnAvailable && (
              <ButtonNextPrev
                onClick={() => !isNextDisabled && nextButtonClick()}
                isDisabled={isNextDisabled}
                classes={"order-1 sm:order-2 mb-5 sm:mb-0"}
              >
                {nextStepText}
              </ButtonNextPrev>
            )}
          </div>
          {items_list.map((question, index) => {
            if (pageIndex !== index) {
              return null;
            }
            return <QuestionFooter key={question._uid} data={question} />;
          })}
        </div>
      )}
      {searchICompleted && !searchInProgress && (
        <QuizLastStepSection setSearchLoading={setSearchLoading} />
      )}
    </>
  );
};

export default QuizContent;

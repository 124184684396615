import { useState, useContext, useEffect, useCallback } from "react";
import { ShopContext } from "@context/shopContext";
import config from "@src/config.json";
import { ShopifyProductDAO } from "@services/ShopifyProductDAO";
import { Replay, Arrow } from "@components/SVG";

const SubscriptionProcess = ({ cartLineDAO }) => {
  const { handleUpdatePlan, handleRemovePlan, cartCurrency } =
    useContext(ShopContext);
  const [uploaded, setUploaded] = useState(false);
  const [unsubscribedMessage, setUnsubscribedMessage] = useState("");
  const [subscribedMessage, setSubscribedMessage] = useState("");

  const {
    availableSubscritions,
    selectedSellingPlanId,
    cartLine: {
      merchandise: {
        product: { handle },
        product
      }
    }
  } = cartLineDAO;
  const [productDao, setProductDao] = useState<any>();

  useEffect(() => {
    const productDAO = new ShopifyProductDAO(product, cartCurrency);
    setProductDao(productDAO);
    setUnsubscribedMessage(
      `${config.cart_drawer.unsubscribed_message} ${productDAO.subscriptionFirstOptionDiscount}%`
    );
    setSubscribedMessage(
      `${config.cart_drawer.subscribed_message.before_text} ${productDAO.subscriptionFirstOptionDiscount}%${config.cart_drawer.subscribed_message.after_text}`
    );
  }, []);

  const onUpload = useCallback(() => {
    if (selectedSellingPlanId && availableSubscritions[0].id) {
      handleRemovePlan(cartLineDAO);
    } else {
      handleUpdatePlan(availableSubscritions[0].id, cartLineDAO);
    }
    setUploaded(true);
  }, [selectedSellingPlanId, cartLineDAO, availableSubscritions]);

  const onChangePlan = useCallback(
    event => {
      handleUpdatePlan(event.target.value, cartLineDAO);
    },
    [cartLineDAO]
  );

  if (!productDao?.subscriptionFirstOptionDiscount) {
    return null;
  }

  return (
    <div className={"flex flex-col items-start sm:mt-1"}>
      <label className="mb-[7px] flex cursor-pointer items-center transition-all hover:opacity-70">
        <input
          type="checkbox"
          defaultChecked={selectedSellingPlanId}
          onChange={() => onUpload()}
          value=""
          name="bordered-checkbox"
          className="h-[13px] w-[13px] rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
        />
        <div className="sofia-pro-r ml-2 w-full text-[14px] text-[#424B5B]">
          Subscribe & <span className="text-[#2589BD]">Save 20%</span>
        </div>
      </label>
      {selectedSellingPlanId && (
        <div className="mb-2 w-full sm:max-w-[174px]">
          <div className="relative flex items-center">
            <span className="absolute right-[10px]">
              <Arrow />
            </span>
            <span className="absolute left-[10px]">
              <Replay />
            </span>
            <select
              defaultValue={selectedSellingPlanId}
              onChange={onChangePlan}
              className="relative block w-full w-full appearance-none rounded-full border border-gray-300 bg-transparent p-1.5 pl-[34px] text-[14px] text-gray-900 focus:border-blue-500"
            >
              {availableSubscritions.map(s => (
                <option value={s.id} key={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionProcess;

const CrossInCircle = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.95703 0.0341797C3.47852 0.0341797 0.660156 2.85254 0.660156 6.33105C0.660156 9.80957 3.47852 12.6279 6.95703 12.6279C10.4355 12.6279 13.2539 9.80957 13.2539 6.33105C13.2539 2.85254 10.4355 0.0341797 6.95703 0.0341797ZM6.95703 11.4092C4.13867 11.4092 1.87891 9.14941 1.87891 6.33105C1.87891 3.53809 4.13867 1.25293 6.95703 1.25293C9.75 1.25293 12.0352 3.53809 12.0352 6.33105C12.0352 9.14941 9.75 11.4092 6.95703 11.4092ZM9.52148 4.75684C9.64844 4.65527 9.64844 4.45215 9.52148 4.3252L8.96289 3.7666C8.83594 3.63965 8.63281 3.63965 8.53125 3.7666L6.95703 5.34082L5.35742 3.7666C5.25586 3.63965 5.05273 3.63965 4.92578 3.7666L4.36719 4.3252C4.24023 4.45215 4.24023 4.65527 4.36719 4.75684L5.94141 6.33105L4.36719 7.93066C4.24023 8.03223 4.24023 8.23535 4.36719 8.3623L4.92578 8.9209C5.05273 9.04785 5.25586 9.04785 5.35742 8.9209L6.95703 7.34668L8.53125 8.9209C8.63281 9.04785 8.83594 9.04785 8.96289 8.9209L9.52148 8.3623C9.64844 8.23535 9.64844 8.03223 9.52148 7.93066L7.94727 6.33105L9.52148 4.75684Z"
      fill="#E36877"
    />
  </svg>
);
export default CrossInCircle;

import Image from "next/image";

import DynamicComponent from "@services/DynamicComponentsRenderer";

export default function TwoGridSection({ blok }) {
  return (
    <div className="mx-auto mb-[50px] flex w-full max-w-[120rem] px-[18px] lg:mb-[100px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto grid w-full max-w-[81.5rem] grid-cols-1 gap-[20px] rounded-8 align-middle lg:grid-cols-2 lg:gap-[36px]">
        <div
          className={`order-1 flex h-full w-full flex-col items-start justify-center space-y-[12px] py-[10px] align-middle max-sm:w-full md:w-[95%] ${
            blok.is_reversed ? "md:ml-5 lg:order-2" : ""
          }`}
        >
          <div className="space-y-[20px] lg:space-y-[15px]">
            {blok?.body?.map(blok => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>
        <div
          className={`relative order-2 flex h-[270px] w-full sm:h-[460px] ${
            blok.is_reversed ? "lg:order-1" : ""
          }`}
        >
          <Image
            className={"rounded-8"}
            src={blok?.image?.filename}
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  );
}

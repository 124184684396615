const Logo = () => (
  <svg
    width="192"
    height="33"
    viewBox="0 0 192 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M76.3586 2.03749L80.9119 0.683961C81.2911 0.537064 81.6975 0.47695 82.1022 0.507918C82.6993 0.507918 82.6993 0.507925 82.6993 1.11428V21.6286C82.6993 23.0877 82.8033 23.0877 83.7509 24.1557L84.1015 24.5469C84.4867 25.0124 84.556 25.0124 84.556 25.654C84.556 26.2955 84.5214 26.2916 83.9281 26.2916H76.6745C75.6921 26.2916 75.6921 26.2564 75.6921 25.2941C75.6921 24.9733 75.6921 24.9733 75.8693 24.7973L76.6051 24.0501C77.4796 23.1582 77.5142 23.0877 77.5142 21.8046V5.17489C77.5172 4.837 77.4031 4.50879 77.1918 4.24775C76.9806 3.9867 76.6856 3.80944 76.3586 3.74702C75.6921 3.57098 75.6921 3.60619 75.6921 2.96463C75.6921 2.24874 75.6921 2.24874 76.3586 2.03749Z"
      fill="#15233C"
    />
    <path
      d="M93.7219 8.23804C98.5256 8.23804 100.629 11.9035 100.629 17.6033V21.0262C100.629 22.2741 100.979 23.6981 101.892 24.6213C102.121 24.8741 102.246 25.2055 102.243 25.5484V25.658C102.249 25.7436 102.236 25.8294 102.207 25.9097C102.177 25.99 102.13 26.0628 102.07 26.1233C102.01 26.1837 101.938 26.2304 101.859 26.2602C101.78 26.2899 101.695 26.302 101.611 26.2956H99.6119C98.9718 26.3 98.3398 26.1505 97.7674 25.8596C97.195 25.5686 96.6984 25.1443 96.3183 24.6213L96.2451 24.5157C96.0953 24.3093 95.9771 24.0812 95.8945 23.8389C94.8775 25.3724 93.406 26.546 91.5107 26.546C88.5676 26.546 85.7324 24.7308 85.7324 21.9886C85.7324 16.2888 95.3629 17.1808 95.3629 12.0874C95.1164 10.7691 94.6271 9.95148 93.5755 9.95148C90.7326 9.95148 92.4507 14.5793 89.1532 14.5793C85.586 14.5754 86.5683 8.23804 93.7219 8.23804ZM92.8782 24.1206C94.5963 24.1206 95.5093 21.3431 95.5093 20.0952V17.3177C95.544 16.5353 95.4746 16.5001 94.8775 17.0321C93.406 18.3152 91.2295 19.704 91.2295 21.9847C91.2295 23.4086 91.9691 24.1206 92.8782 24.1206Z"
      fill="#15233C"
    />
    <path
      d="M102.663 10.159L107.22 8.80546C107.601 8.65874 108.008 8.59864 108.414 8.62942C109.011 8.62942 109.011 8.62942 109.011 9.23577V19.9897C109.011 21.4489 108.869 24.7623 111.011 24.7623C113.218 24.7623 113.361 21.3081 113.361 19.7394V13.2925C113.362 12.9641 113.25 12.6456 113.045 12.3921C112.839 12.1385 112.553 11.9657 112.236 11.9037C111.535 11.6925 111.535 11.7277 111.535 11.0861C111.535 10.3742 111.535 10.3742 112.201 10.159L116.762 8.80546C117.141 8.65882 117.548 8.59871 117.952 8.62942C118.549 8.62942 118.584 8.62942 118.584 9.23577V21.0264C118.584 23.0567 118.549 23.2367 120.025 24.6215C120.41 24.9775 120.41 25.0127 120.41 25.5486V25.6582C120.41 26.2958 120.41 26.2958 119.778 26.2958H116.901C115.395 26.2958 115.325 26.2606 114.447 25.1222C114.2 24.8014 114.131 24.8014 113.85 25.1574C113.479 25.607 113.01 25.9631 112.481 26.1979C111.951 26.4328 111.376 26.5399 110.799 26.511C104.1 26.511 103.819 21.7032 103.819 19.2113V13.2925C103.819 12.5805 103.399 12.1541 102.663 11.9037C101.997 11.6925 101.997 11.7277 101.997 11.0861C101.997 10.3742 101.997 10.3742 102.663 10.159Z"
      fill="#15233C"
    />
    <path
      d="M121.532 25.4659C121.532 25.0043 121.532 24.9652 121.848 24.6444L123.043 23.4356C123.358 23.1148 123.358 23.0796 123.358 22.618V13.2841C123.358 12.5017 122.831 11.9697 121.987 11.8249C121.567 11.7545 121.532 11.7193 121.532 11.3281V10.8469C121.532 10.3853 121.532 10.3853 121.952 10.2406L126.375 8.78143C126.676 8.66603 126.998 8.61799 127.318 8.64059H127.565C128.02 8.64059 128.054 8.6406 128.093 9.10221L128.162 9.99413C128.197 10.4557 128.197 10.4557 128.478 10.0998C128.941 9.54994 129.517 9.10909 130.165 8.80781C130.813 8.50653 131.517 8.35202 132.23 8.35502C135.35 8.35502 138.613 11.1325 138.613 16.0107V22.6611C138.613 23.1227 138.578 23.1579 138.929 23.4787L140.119 24.6522C140.47 24.973 140.435 25.0121 140.435 25.4738V25.7945C140.435 26.2561 140.435 26.2561 139.981 26.2561H132.087C131.633 26.2561 131.633 26.2561 131.633 25.7945V25.4738C131.633 25.0121 131.633 25.0121 131.983 24.6522L133.139 23.4787C133.494 23.1227 133.494 23.1227 133.494 22.6611V15.6195C133.494 12.4508 132.23 10.7413 130.967 10.7413C129.811 10.7413 128.655 12.1653 128.513 15.1227V22.6376C128.513 23.0992 128.547 23.0992 128.863 23.4552L130.019 24.664C130.335 25.0239 130.369 25.0239 130.369 25.4855V25.8415C130.369 26.3031 130.335 26.3031 129.88 26.3031H121.987C121.532 26.3031 121.532 26.3031 121.532 25.8415V25.4659Z"
      fill="#15233C"
    />
    <path
      d="M141.668 17.5054C141.668 11.7 145.07 8.28099 148.367 8.28099C149.07 8.26198 149.764 8.44651 150.367 8.81301C150.515 8.9446 150.696 9.03135 150.891 9.06338C151.029 9.06338 151.068 8.88342 151.068 8.45702V5.18271C151.069 4.84522 150.955 4.5178 150.744 4.25707C150.533 3.99634 150.238 3.8187 149.912 3.75485C149.246 3.57881 149.211 3.61402 149.211 2.97246C149.211 2.26048 149.246 2.22528 149.877 2.04533L154.489 0.683961C154.868 0.537064 155.274 0.47695 155.679 0.507918C156.241 0.507918 156.276 0.507925 156.276 1.11428V20.8423C156.276 23.0525 156.311 23.1581 157.855 24.7268C157.949 24.7937 158.021 24.8864 158.064 24.9939C158.107 25.1014 158.119 25.2191 158.098 25.3332V25.7244C158.098 26.2916 158.098 26.2916 157.54 26.2916H154.458C153.16 26.2916 152.878 26.4364 152.281 25.2237L152.247 25.1532C152.069 24.8325 151.896 24.7268 151.757 24.7268C151.619 24.7268 151.441 24.8677 151.16 25.1885C150.792 25.6095 150.338 25.9428 149.828 26.1646C149.319 26.3865 148.767 26.4913 148.213 26.4716C144.927 26.4716 141.668 23.3029 141.668 17.5054ZM149.7 24.0227C151.068 24.0227 151.029 21.8124 151.029 17.5054C151.029 13.0184 151.102 10.855 149.804 10.855C146.156 10.855 146.087 24.0149 149.7 24.0149V24.0227Z"
      fill="#15233C"
    />
    <path
      d="M159.272 25.4739C159.272 25.0123 159.272 25.0123 159.623 24.6524L160.779 23.4788C161.129 23.1228 161.129 23.1228 161.129 22.6612V13.2725C161.129 12.5253 160.567 11.9542 159.727 11.8134C159.307 11.7429 159.269 11.7077 159.269 11.2813V10.8901C159.269 10.4285 159.269 10.4285 159.727 10.2838L164.6 8.82461C164.89 8.7068 165.202 8.6586 165.513 8.68379H165.756C166.214 8.68379 166.249 8.68379 166.249 9.1454V11.4613C166.249 11.8877 166.249 11.8877 166.461 11.4965C167.512 9.64613 169.053 8.22217 171.264 8.22217C174.631 8.22217 174.562 13.0652 171.615 13.0652C170.598 13.0652 169.827 12.3884 168.949 12.3884C166.53 12.3884 166.318 16.805 166.253 18.405V22.6808C166.253 23.1424 166.218 23.1776 166.568 23.4984L167.763 24.672C168.113 24.9927 168.079 25.0319 168.079 25.4935V25.8142C168.079 26.2758 168.079 26.2759 167.62 26.2759H159.731C159.272 26.2759 159.272 26.2758 159.272 25.8142V25.4739Z"
      fill="#15233C"
    />
    <path
      d="M177.094 27.1833C178.635 27.1833 180.669 27.8601 182.529 25.6889C182.984 25.153 182.984 25.0826 182.668 24.4058L176.743 11.7976C176.531 11.336 176.497 11.336 176.077 11.0856L174.744 10.2289C174.32 9.94722 174.32 9.94722 174.32 9.44649V9.16092C174.32 8.6641 174.32 8.6641 174.813 8.6641H182.845C183.334 8.6641 183.334 8.6641 183.334 9.16092V9.41128C183.334 9.9081 183.369 9.94722 183.019 10.3032L182.456 10.8548C182.071 11.246 182.109 11.246 182.317 11.7115L185.122 17.5794C185.299 17.9706 185.403 18.1506 185.507 18.1506C185.611 18.1506 185.646 17.9706 185.784 17.5442L187.68 11.9541C187.857 11.4885 187.818 11.4885 187.472 11.1325L186.524 10.2406C186.38 10.1571 186.266 10.03 186.197 9.87702C186.128 9.72408 186.107 9.55301 186.139 9.38781V9.13744C186.139 8.64062 186.17 8.64063 186.663 8.64063H191.189C191.678 8.64063 191.678 8.64062 191.678 9.13744V9.38781C191.678 9.88463 191.678 9.88463 191.293 10.2406L190.137 11.3125C189.861 11.5087 189.663 11.7987 189.579 12.1301L186.174 22.2073C184.879 26.0919 182.564 32.4996 177.583 32.4996C173.935 32.5231 174.043 27.1833 177.094 27.1833Z"
      fill="#15233C"
    />
    <path
      d="M0.919617 26.8235C1.19697 26.4676 1.1623 26.3971 0.954285 26.0412C-0.0280257 24.402 0.357197 21.9453 1.65539 20.451C2.17929 19.8446 2.21395 19.8094 1.72472 19.2031C0.805277 18.057 0.309267 16.6206 0.322525 15.1424C0.322525 11.7938 2.84572 8.44909 7.89595 8.44909C8.28209 8.45288 8.66779 8.47638 9.05161 8.5195C9.54084 8.55471 9.54084 8.55471 9.71804 8.12831C10.735 5.63248 13.2235 4.35327 14.4871 4.35327C17.9926 4.35327 17.954 8.58992 15.0842 8.58992C14.3445 8.58992 13.3622 8.41388 12.5224 8.41388C12.1022 8.38588 11.6813 8.45823 11.2936 8.62513C10.8737 8.84028 10.943 8.9107 11.4015 9.12586C12.5874 9.60668 13.6049 10.4363 14.3235 11.5082C15.0421 12.5802 15.429 13.8457 15.4347 15.1424C15.4347 18.4911 12.9423 21.8397 7.85742 21.8397C6.85727 21.8409 5.86304 21.6838 4.91049 21.3742C4.59911 21.222 4.26857 21.114 3.92818 21.0534C3.67787 21.0775 3.43717 21.1635 3.22709 21.3038C2.14077 21.8749 2.14076 23.1933 3.15774 23.1933C5.26104 23.1933 9.61018 22.1605 12.2412 22.1605C14.6257 22.1605 16.5557 22.9077 16.5557 25.6813C16.5557 28.5683 13.6088 32.093 7.8921 32.093C0.919619 32.0969 -0.675194 28.8186 0.919617 26.8235ZM3.61616 28.1067C4.31726 29.3546 6.07 30.137 8.31584 30.137C12.9385 30.137 14.6257 26.6827 12.3491 26.6827C9.65255 26.6827 6.59391 27.6803 4.07072 27.6803H3.89351C3.40814 27.6803 3.37346 27.6803 3.62001 28.1067H3.61616ZM7.79964 20.1302C11.3783 20.1302 11.3051 10.1938 8.01152 10.1938C4.42512 10.1938 4.42512 20.1302 7.79964 20.1302Z"
      fill="#15233C"
    />
    <path
      d="M18.1394 17.4232C18.1394 12.9362 20.8359 8.44922 26.3484 8.44922C31.8609 8.44922 34.519 12.9362 34.519 17.4232C34.519 21.9807 31.8224 26.4677 26.2753 26.4677C20.8398 26.4677 18.1394 21.9807 18.1394 17.4232ZM26.229 24.6525C29.5958 24.6525 29.773 10.4091 26.3369 10.4091C22.9007 10.4091 22.9816 24.6525 26.2406 24.6525H26.229Z"
      fill="#15233C"
    />
    <path
      d="M36.4502 17.4232C36.4502 12.9362 39.1467 8.44922 44.6554 8.44922C50.164 8.44922 52.8298 12.9362 52.8298 17.4232C52.8298 21.9807 50.1332 26.4677 44.586 26.4677C39.1506 26.4677 36.4502 21.9807 36.4502 17.4232ZM44.5398 24.6525C47.9066 24.6525 48.0838 10.4091 44.6438 10.4091C41.2038 10.4091 41.2886 24.6525 44.5514 24.6525H44.5398Z"
      fill="#15233C"
    />
    <path
      d="M54.7566 17.4935C54.7566 11.6882 58.1581 8.26913 61.4556 8.26913C62.1586 8.2513 62.8521 8.43721 63.4549 8.80507C63.604 8.93512 63.785 9.02165 63.9788 9.05544C64.1174 9.05544 64.156 8.87549 64.156 8.44908V5.17086C64.1573 4.83384 64.0425 4.50696 63.8314 4.24688C63.6204 3.9868 63.3263 3.80993 63.0003 3.74691C62.3339 3.57087 62.2992 3.60608 62.2992 2.96452C62.2992 2.24863 62.3339 2.21734 62.9656 2.03739L67.5613 0.683847C67.9406 0.537199 68.3469 0.4771 68.7516 0.50781C69.3141 0.50781 69.3487 0.507807 69.3487 1.11025V20.85C69.3487 23.0563 69.3834 23.1659 70.9281 24.7306C71.0215 24.7975 71.0939 24.8902 71.1369 24.9977C71.18 25.1052 71.1917 25.2229 71.1708 25.337V25.7282C71.1708 26.2993 71.1708 26.2993 70.6122 26.2993H67.5305C66.2323 26.2993 65.9511 26.4402 65.354 25.2314L65.3193 25.157C65.146 24.8402 64.9688 24.7306 64.8301 24.7306C64.6914 24.7306 64.5142 24.8754 64.233 25.1962C63.8644 25.6161 63.4093 25.9484 62.9 26.1695C62.3907 26.3906 61.8396 26.495 61.2861 26.4754C58.0156 26.4676 54.7566 23.2989 54.7566 17.4935ZM62.7884 24.0108C64.156 24.0108 64.1174 21.8045 64.1174 17.4935C64.1174 13.0065 64.1906 10.8432 62.8924 10.8432C59.2444 10.8354 59.1751 24.0108 62.7884 24.0108Z"
      fill="#15233C"
    />
  </svg>
);

export default Logo;

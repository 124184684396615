import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";

export default function Article({ article }) {
  return (
    <>
      <a
        href={`${article.full_slug}`}
        className="flex h-full w-fit flex-shrink-0"
        {...storyblokEditable(article)}
      >
        <div className="relative flex h-fit max-h-full w-fit max-w-[414px] flex-shrink-0 flex-col">
          <div className="flex h-fit w-full">
            <Image
              width="414"
              height="300"
              src={`${article?.thumbnail_image?.filename}` + "/m/"}
              alt=""
              className="flex h-full w-full rounded-8 object-cover"
            />
          </div>
          <div className="mt-[10px] flex w-full items-start justify-start">
            <h1 className="sofia-pro-s text-[22px] leading-[1]">
              {article?.title}
            </h1>
          </div>
        </div>
      </a>
    </>
  );
}

const Leaf = ({ isActive }) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.87109 2.18393C6.41484 2.18393 4.33672 3.79331 3.63047 6.01206C4.68047 5.48081 5.86484 5.18393 7.12109 5.18393H9.87109C10.1461 5.18393 10.3711 5.40893 10.3711 5.68393C10.3711 5.95893 10.1461 6.18393 9.87109 6.18393H7.12109C6.60234 6.18393 6.09922 6.24331 5.61172 6.35268C4.80234 6.53706 4.05234 6.86518 3.38047 7.31206C1.56797 8.52143 0.371094 10.5871 0.371094 12.9339V13.4339C0.371094 13.8496 0.705469 14.1839 1.12109 14.1839C1.53672 14.1839 1.87109 13.8496 1.87109 13.4339V12.9339C1.87109 11.4121 2.51797 10.0433 3.55234 9.08393C4.17109 11.4433 6.31797 13.1839 8.87109 13.1839H8.90234C13.0305 13.1621 16.3711 9.09331 16.3711 4.07768C16.3711 2.74643 16.1367 1.48081 15.7117 0.340182C15.6305 0.124557 15.3148 0.133932 15.2055 0.337057C14.618 1.43706 13.4555 2.18393 12.1211 2.18393H8.87109Z"
      fill={isActive ? "white" : "#298DCD"}
    />
  </svg>
);
export default Leaf;

import { isEmpty } from "lodash";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";

const StepSheetBundle = ({
  blok: { steps, title, label1, label2, label3, image1, image2, image3 },
  blok
}) => {
  if (isEmpty(steps)) {
    return null;
  }
  return (
    <div
      className="mx-auto mb-[40px] w-full max-w-[120rem] px-[18px] lg:mb-[120px] lg:px-[48px] xl:px-[48px]"
      {...storyblokEditable(blok)}
    >
      {title && (
        <div className="mx-auto max-w-[81.5rem] rounded-tl-lg rounded-tr-lg text-center">
          <h2
            className={`sofia-pro-b mb-10 text-[32px] leading-[1.1] md:mb-16 lg:text-[44px]`}
          >
            {title}
          </h2>
        </div>
      )}
      <div className="mx-auto hidden w-full max-w-[81.5rem] lg:block">
        <div className="flex w-full">
          <div className="w-full" />
          <div className="mx-2 ml-auto w-[226px] min-w-[226px] text-center">
            <h3 className="sofia-pro-b mx-auto mb-6 max-w-[200px] text-[22px] leading-[1.2]">
              {label1}
            </h3>
          </div>
          <div className="mx-2 w-[226px] min-w-[226px] text-center">
            <h3 className="sofia-pro-b mx-auto mb-6 max-w-[200px] text-[22px] leading-[1.2]">
              {label2}
            </h3>
          </div>
          <div className="mx-2 w-[226px] min-w-[226px] text-center">
            <h3 className="sofia-pro-b mx-auto mb-6 max-w-[200px] text-[22px] leading-[1.2]">
              {label3}
            </h3>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-full" />
          <div className="relative mx-2 ml-auto w-[226px] min-w-[226px] text-center">
            <span className="absolute left-0 bottom-0 -z-10 h-1/2 w-full bg-[#F1FAFF]" />
            <div className="sofia-pro-b relative mx-auto -mb-4 h-[188px] w-[192px] text-[22px]">
              <Image src={image1?.filename} layout="fill" objectFit="contain" />
            </div>
          </div>
          <div className="relative mx-2 w-[226px] min-w-[226px] text-center">
            <span className="absolute left-0 bottom-0 -z-10 h-1/2 w-full bg-[#F1FAFF]" />
            <div className="sofia-pro-b relative mx-auto -mb-4 h-[188px] w-[192px] text-[22px]">
              <Image src={image2?.filename} layout="fill" objectFit="contain" />
            </div>
          </div>
          <div className="relative mx-2 w-[226px] min-w-[226px] text-center">
            <span className="absolute left-0 bottom-0 -z-10 h-1/2 w-full bg-[#F1FAFF]" />
            <div className="sofia-pro-b relative mx-auto -mb-4 h-[188px] w-[192px] text-[22px]">
              <Image src={image3?.filename} layout="fill" objectFit="contain" />
            </div>
          </div>
        </div>
        <div className="w-full">
          {steps.map((item, index) => {
            const { _uid, title, subtitle, value1, value2, value3 } = item;
            return (
              <div
                className="flex w-full"
                key={_uid}
                {...storyblokEditable(item)}
              >
                <div
                  className={`w-full py-10 pr-2 ${
                    index !== 2 ? "border-b" : ""
                  }`}
                >
                  <h4 className="sofia-pro-b mb-1 text-[22px] text-[#2589BD]">
                    {title}
                  </h4>
                  <p className="max-w-[430px] text-[22px] leading-[1.2]">
                    {subtitle}
                  </p>
                </div>
                <div
                  className={`mx-2 ml-auto w-[226px] min-w-[226px] bg-[#F1FAFF] text-center ${
                    index !== 2 ? "border-b" : ""
                  }`}
                >
                  <h3 className="sofia-pro-b mb-2 flex h-full items-center justify-center text-[34px] text-[#2589BD]">
                    {value1}
                  </h3>
                </div>
                <div
                  className={`mx-2 w-[226px] min-w-[226px] bg-[#F1FAFF] text-center ${
                    index !== 2 ? "border-b" : ""
                  }`}
                >
                  <h3 className="sofia-pro-b mb-2 flex h-full items-center justify-center text-[34px] text-[#2589BD]">
                    {value2}
                  </h3>
                </div>
                <div
                  className={`mx-2 w-[226px] min-w-[226px] bg-[#F1FAFF] text-center ${
                    index !== 2 ? "border-b" : ""
                  }`}
                >
                  <h3 className="sofia-pro-b mb-2 flex h-full items-center justify-center text-[34px] text-[#2589BD]">
                    {value3}
                  </h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-wrap justify-between gap-y-12 lg:hidden">
        {steps.map((item, index) => {
          const { _uid, title, subtitle, value1, value2, value3 } = item;
          return (
            <div
              key={_uid}
              className="w-full text-center md:w-[32%]"
              {...storyblokEditable(item)}
            >
              <h3 className="sofia-pro-b mx-auto mb-4 max-w-[200px] text-[22px] leading-[1.2]">
                {index === 1 ? label1 : index === 2 ? label2 : label3}
              </h3>
              <div className="sofia-pro-b relative mx-auto mb-2 -mb-20 h-[188px] w-[192px] text-[22px]">
                <Image
                  src={
                    index === 1
                      ? image1?.filename
                      : index === 2
                      ? image2?.filename
                      : image3?.filename
                  }
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <h3 className="sofia-pro-b border-b bg-[#F1FAFF] pt-24 pb-8 text-[26px] text-[#2589BD]">
                {value1}
              </h3>
              <h3 className="sofia-pro-b border-b bg-[#F1FAFF] py-8 text-[26px] text-[#2589BD]">
                {value2}
              </h3>
              <h3 className="sofia-pro-b bg-[#F1FAFF] py-8 text-[26px] text-[#2589BD]">
                {value3}
              </h3>
              <h4 className="sofia-pro-b mt-2 mb-2 text-[22px] text-[#2589BD]">
                {title}
              </h4>
              <p className="mx-auto max-w-[430px] text-[22px]">{subtitle}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default StepSheetBundle;

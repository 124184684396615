import React from "react";

import SiteReviews from "@components/Product/SiteReviews/SiteReviews";


const Reviews = () => {
  return (
    <div className="reviews mx-auto mb-[20px] flex w-full max-w-[120rem] px-[18px] lg:mb-[100px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col justify-center overflow-hidden text-left">
        <div className="mb-[35px]">
          <h3 className="sofia-pro-b text-[34px]">Reviews</h3>
        </div>
        <SiteReviews />
      </div>
    </div>
  );
};
export default Reviews;

import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";

const Benefits = ({ blok: { benefits } }) => {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h4 className="sofia-pro-s mb-4 text-[32px] leading-none lg:text-[34px]">
            {children}
          </h4>
        );
      }
    }
  };

  const renderDescriptionOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r mb-0 text-[22px] leading-[1.3] text-gl-black">
            {children}
          </p>
        );
      }
    }
  };

  return (
    <section className="pt-12 md:pt-24">
      <div className="custom-container">
        {benefits.length > 0 && (
          <ul className="flex flex-wrap justify-between gap-y-4">
            {benefits.map(benefit => (
              <li
                style={{ background: benefit.background_color.color }}
                className="w-full rounded sm:w-[49%] lg:w-[24%]">
                <img
                  className="w-full mix-blend-darken"
                  src={benefit.icon_image.filename}
                  alt=""
                />
                <div className="px-7 pt-2 pb-9 md:pb-14">
                  {render(benefit.title, renderTitleOptions)}
                  {render(benefit.subtitle, renderDescriptionOptions)}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default Benefits;

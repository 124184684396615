import { storyblokEditable } from "@storyblok/react";
import React, { useState, useEffect } from "react";
import AnswerVariantItem from "./AnswerVariantItem";
import { AnimatePresence, motion } from "framer-motion";
import EarthPageAnsvwerVariantItem from "./EarthPageAnsvwerVariantItem";

const variants = {
  enter: {
    transition: { staggerChildren: 0.2, delayChildren: 0.2 }
  },
  exit: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const QuestionAnswersListSingle = props => {
  const {
    blok: { answers },
    page,
    blok
  } = props;
  const isStretchedMobileCard = answers.length <= 4;
  const cardTypeStyles = isStretchedMobileCard
    ? "gap-[14px] sm:gap-[20px] justify-center"
    : "gap-[4vw] md:gap-1 justify-center md:justify-around";

  return (
    <div {...storyblokEditable(blok)}>
      {/** @ts-ignore */}
      <AnimatePresence>
        <motion.div
          variants={variants}
          initial="exit"
          animate="enter"
          exit="exit"
          className={`mx-auto ${
            page === "quiz" && "mt-4 lg:mt-[30px]"
          } flex h-max w-full flex-wrap pb-10 lg:h-fit ${cardTypeStyles} `}
        >
          {answers.map(answer =>
            page === "quiz" ? (
              <AnswerVariantItem
                key={answer._uid}
                answer={answer}
                isStretchedMobileCard={isStretchedMobileCard}
                {...props}
                {...storyblokEditable(answer)}
              />
            ) : (
              <EarthPageAnsvwerVariantItem
                key={answer._uid}
                answer={answer}
                {...props}
              />
            )
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default QuestionAnswersListSingle;

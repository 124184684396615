/* eslint-disable multiline-ternary */
import React, {
  Fragment,
  useContext,
  useRef,
  useState,
  useMemo,
  useEffect
} from "react";
import { isEmpty, difference } from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import CartLine from "../CartLine";
import CartAddonsList from "@components/CartAddons";
import { ShopContext } from "@context/shopContext";
import { LeftChevron, Lock } from "@components/SVG";
import { motion } from "framer-motion";
import { Purchase } from "./Purchase/Purchase";
import { useMediaQuery } from "../../hooks";
import { ShopifyCartService } from "@services/ShopifyCartService";
import Image from "next/image";

const shopifyCartService = new ShopifyCartService();

const EXPIRE_TIME = 600;

const ExpireTime = ({ cart }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const cart = shopifyCartService.getCartDataFromStorage();
      if (cart) {
        const expire = localStorage.getItem("cartExpire");
        if (expire) {
          const expireCart = JSON.parse(expire);
          if (expireCart.id === cart.id) {
            const currDate = Date.now();
            const diff = parseInt((currDate - expireCart.time) / 1000, 10);
            setSeconds(EXPIRE_TIME - diff);
          } else {
            if (cart.lines.edges.length) {
              localStorage.setItem(
                "cartExpire",
                JSON.stringify({ id: cart.id, time: Date.now() })
              );
            }
          }
        } else {
          if (cart.lines.edges.length) {
            localStorage.setItem(
              "cartExpire",
              JSON.stringify({ id: cart.id, time: Date.now() })
            );
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [cart]);

  const toHHMMSS = value => {
    const secNum = parseInt(value, 10);
    const hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  return (
    seconds > 0 &&
    seconds < EXPIRE_TIME && (
      <section className="sofia-pro-r bg-[#EBF8FF] py-[10px] text-center text-[14px]">
        <p>
          Your cart will expire in{" "}
          <span className="sofia-pro-b">{toHHMMSS(seconds)} minutes</span>!
        </p>
        <p>Checkout now before it sells out!</p>
      </section>
    )
  );
};

export default function MiniCart() {
  const cancelButtonRef = useRef();
  const {
    cart,
    cartOpen,
    setCartOpen,
    checkoutUrl,
    cartLoading,
    cartId,
    discountSum,
    cartSubtotalNumber,
    cartTotalAmount
  } = useContext(ShopContext);

  useEffect(() => {
    if (cartOpen) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1000);
    }
  }, [cartOpen]);

  const [dataToFilterAddons, setDataToFilterAddons] = useState("");
  // const [haveCoupon, setHaveCoupon] = useState(false);
  const freeShippingCompletness = (cartSubtotalNumber / 50) * 100;
  const hasSubscriptionItem = useMemo(
    () => Boolean(cart?.find(l => l.node.sellingPlanAllocation)),
    [cart]
  );
  const isFreeShipping = useMemo(
    () => hasSubscriptionItem || freeShippingCompletness > 100,
    [freeShippingCompletness, hasSubscriptionItem]
  );

  const continueToCheckout = () => {
    if (localStorage.getItem("quizResults")) {
      localStorage.removeItem("quizResults");
    }
    if (localStorage.getItem("appliedDiscount")) {
      localStorage.removeItem("appliedDiscount");
    }
    window.location.href = checkoutUrl;
  };

  const isBundle = useMemo(() => {
    const productTypes = [];
    const unscentedProductTypes = [];
    cart.forEach(item => {
      const { productType, tags } = item.node.merchandise.product;
      if (
        tags.includes("Unscented") &&
        !unscentedProductTypes.includes(productType)
      ) {
        unscentedProductTypes.push(productType);
      }
      if (!productTypes.includes(productType)) {
        productTypes.push(productType);
      }
    });
    if (
      difference(["Detergent Sheets", "Dryer Sheets"], unscentedProductTypes)
        .length === 0
    ) {
      return true;
    } else {
      return (
        difference(
          ["Detergent Sheets", "Scent Boosters", "Dryer Sheets"],
          productTypes
        ).length === 0
      );
    }
  }, [cart]);

  const isMedium = useMediaQuery("(max-width: 992px)");

  return (
    <Transition.Root show={cartOpen} as={Fragment}>
      <Dialog
        initialFocus={cancelButtonRef}
        as="div"
        className="fixed inset-0 z-[99] overflow-hidden"
        onClose={() => setCartOpen(false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClose={() => setCartOpen(false)}
              className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex w-full max-w-[537px] justify-end pl-0">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="flex h-full w-full flex-col bg-white">
                <div className="flex-1 overflow-y-auto bg-white">
                  <div className="sticky top-0 z-20 flex w-full items-center bg-white px-[15px] py-[3px] sm:py-[6px]">
                    <div className="absolute top-0 flex h-full items-center">
                      <button
                        ref={cancelButtonRef}
                        type="button"
                        onClick={() => setCartOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <LeftChevron />
                      </button>
                    </div>
                    <div className="flex w-full justify-center text-center">
                      <h3 className="sofia-pro-b text-[18px] sm:text-[22px]">
                        Your Cart:{" "}
                        <span className="sofia-pro-r">
                          {cart.length} {cart.length > 1 ? "items" : "item"}
                        </span>
                      </h3>
                    </div>
                  </div>
                  {!isEmpty(cart) && <ExpireTime cart={cart} />}
                  {isEmpty(cart) && (
                    <div className="flex w-full justify-center">
                      <a
                        href="/collections/all-products"
                        className="sofia-pro-m false  sofia-pro-b mt-[15px] flex h-fit max-w-[305px] select-none justify-center rounded-8 rounded-8 bg-[#2589BD] bg-[#2589BD] px-[20px] px-2 py-1 text-center text-[14px] leading-[25px] tracking-[2%] text-white"
                      >
                        SHOP ALL PRODUCTS →
                      </a>
                    </div>
                  )}

                  {cartSubtotalNumber > 0 && (
                    <div className="flow-root min-h-[60px] flex-col">
                      <div className="border-gray-20 flex w-full flex-col items-center space-x-[15px] border px-[34px] pt-[22px] pb-[42px] sm:px-[48px] sm:pb-[50px]">
                        <p
                          className="mb-6 whitespace-nowrap text-[16px] leading-4"
                          dangerouslySetInnerHTML={{
                            __html: isFreeShipping
                              ? 'You’re getting <span class="sofia-pro-b">FREE</span> shipping'
                              : `You’re  <span class="sofia-pro-b">$${(
                                  50 - cartSubtotalNumber
                                ).toFixed(2)}</span> away from free shipping!`
                          }}
                        ></p>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            delay: 0.5,
                            duration: 0.4
                          }}
                          className="w-100 !m-0 h-fit w-full rounded bg-white bg-[#69B645]/30"
                        >
                          <motion.span
                            initial={{ width: "0%" }}
                            animate={isFreeShipping ? "free" : "paid"}
                            variants={{
                              free: { background: "#69B645", width: "100%" },
                              paid: {
                                background: "#679657",
                                width: `${freeShippingCompletness}%`
                              }
                            }}
                            transition={{
                              delay: 0.5,
                              duration: 0.4,
                              background: {
                                delay: 1,
                                duration: 1
                              }
                            }}
                            className="m-w-full relative block h-[6px] !max-w-[100%] rounded"
                          >
                            <p className="sofia-pro-r absolute right-0 -top-[14px] translate-x-1/2 whitespace-nowrap text-[12px] -tracking-[0.7px] text-[#679657] sm:-top-[18px] sm:text-[14px]">
                              <div className="mx-auto mb-0.5 h-8 w-8 sm:h-9 sm:w-9">
                                <Image
                                  alt=""
                                  width="36"
                                  height="36"
                                  src="/shipping.svg"
                                />
                              </div>
                              FREE shipping
                            </p>
                          </motion.span>
                        </motion.div>
                      </div>
                    </div>
                  )}

                  <div className="flow-root w-full bg-white">
                    {!isEmpty(cart) ? (
                      <>
                        <ul
                          role="list"
                          className="mx-3 my-4 space-y-[12px] bg-white"
                        >
                          {cart.map(({ node: cartLineData }, index) => {
                            return (
                              <CartLine
                                isBundle={isBundle}
                                key={cartLineData.id}
                                data={{
                                  cartLineData,
                                  isScentForAddons: cart?.length - 1 === index,
                                  setDataToFilterAddons
                                }}
                              />
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <div className="flex min-h-[10vh] flex-col items-center justify-end space-y-[12px] px-[15px] pt-[15px]">
                        <p className="sofia-pro-s text-18">
                          Your cart is empty
                        </p>
                      </div>
                    )}
                  </div>
                  {cartSubtotalNumber > 0 && isMedium && (
                    <Purchase cartId={cartId} cart={cart} />
                  )}
                </div>

                <div className="border-t border-t-[#e3e7eb] bg-white pb-[15px]">
                  <CartAddonsList dataToFilterAddons={dataToFilterAddons} />

                  {!isEmpty(cart) ? (
                    <>
                      {cartSubtotalNumber > 0 && !isMedium && (
                        <Purchase cartId={cartId} cart={cart} />
                      )}
                      <div className="mt-[6px] flex justify-between px-[16px] sm:px-[26px]">
                        <span className="sofia-pro-b text-[14px] sm:text-[18px]">
                          Subtotal
                        </span>
                        <span className="sofia-pro-r text-[14px] sm:text-[18px]">
                          $
                          {(
                            Number(discountSum ?? 0) +
                            Number(cartTotalAmount ?? 0)
                          ).toFixed(2)}
                        </span>
                      </div>
                      {discountSum > 0 && (
                        <div className="flex justify-between px-[16px] sm:px-[26px]">
                          <span className="sofia-pro-b text-[14px] sm:text-[18px]">
                            Discount
                          </span>
                          <span className="text-[14px] sm:text-[16px]">
                            -${discountSum}
                          </span>
                        </div>
                      )}
                      {cartTotalAmount > 0 && (
                        <div className="sofia-pro-m mt-[4px] flex justify-between px-[16px] text-[20px] sm:px-[26px]">
                          <span>Total</span>
                          <span className="text-[#29753B]">
                            ${cartTotalAmount}
                          </span>
                        </div>
                      )}
                      <div className="mt-3 border-t border-t-[#e3e7eb] px-[15px] pt-4 ">
                        <button
                          onClick={continueToCheckout}
                          className={`flex w-full items-center justify-center rounded-md border border-transparent bg-[#2589bd] 
                         py-[12px] px-6 text-white transition-all hover:bg-dark ${
                           cartLoading ? "cursor-not-allowed" : "cursor-pointer"
                         }`}
                        >
                          <div className="flex w-fit justify-start">
                            <Lock />
                          </div>
                          <span className="flex w-full justify-center pb-[3px] text-[18px] leading-[1]">
                            Checkout • ${cartTotalAmount}
                          </span>
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

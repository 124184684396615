import Image from "next/image";
import {
  MARK_BOLD,
  MARK_UNDERLINE,
  NODE_PARAGRAPH,
  NODE_HEADING,
  render
} from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

const IconTitleSubtitleBlock = ({
  blok,
  blok: { subtitle, title, icon_image: iconImage }
}) => {
  const paragraphMaskRender = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <b className="sofia-pro-b text-bold">{children}</b>;
      },
      [MARK_UNDERLINE]: children => {
        return (
          <span className="text-underline-blue pb-[5px] text-[#15233C]">
            {children}
          </span>
        );
      }
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r text-center text-[28px] leading-[1.1]">
            {children}
          </p>
        );
      },
      [NODE_HEADING]: children => {
        return (
          <h3 className="sofia-pro-b text-[32px] leading-[1.1] text-[#2589BD] lg:text-[44px]">
            {children}
          </h3>
        );
      }
    }
  };
  return (
    <div className="mx-auto flex w-full max-w-[120rem] px-[18px] pt-[100px] lg:py-[200px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col items-center justify-center overflow-hidden align-middle">
        <div className="relative mb-[15px] h-[90px] w-[90px]">
          <Image
            src={iconImage?.filename}
            alt="Try logo"
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div
          className="sofia-pro-b text-[32px] leading-[1.1] text-[#2589BD] lg:text-[44px]"
          {...storyblokEditable(title)}
        >
          {render(title, paragraphMaskRender)}
        </div>
        <div className="mx-auto max-w-[45rem]" {...storyblokEditable(subtitle)}>
          {render(subtitle, paragraphMaskRender)}
        </div>
      </div>
    </div>
  );
};

export default IconTitleSubtitleBlock;

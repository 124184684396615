const Cut = ({ isActive }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.98792 6.18274L7.75354 4.94836C7.90666 4.55461 7.98792 4.12961 7.98792 3.68274C7.98792 1.74836 6.42229 0.182739 4.48792 0.182739C2.55354 0.182739 0.987915 1.74836 0.987915 3.68274C0.987915 5.61711 2.55354 7.18274 4.48792 7.18274C4.93479 7.18274 5.35979 7.09836 5.75354 6.94836L6.98792 8.18274L5.75354 9.41711C5.35979 9.26399 4.93479 9.18274 4.48792 9.18274C2.55354 9.18274 0.987915 10.7484 0.987915 12.6827C0.987915 14.6171 2.55354 16.1827 4.48792 16.1827C6.42229 16.1827 7.98792 14.6171 7.98792 12.6827C7.98792 12.2359 7.90354 11.8109 7.75354 11.4171L16.5879 2.58274C16.8098 2.36086 16.8098 2.00461 16.5879 1.78274C15.7035 0.898364 14.2723 0.898364 13.3879 1.78274L8.98792 6.18274ZM9.69417 10.889L13.3879 14.5827C14.2723 15.4671 15.7035 15.4671 16.5879 14.5827C16.8098 14.3609 16.8098 14.0046 16.5879 13.7827L11.6942 8.88899L9.69417 10.889ZM5.98792 3.68274C5.98792 4.51086 5.31604 5.18274 4.48792 5.18274C3.65979 5.18274 2.98792 4.51086 2.98792 3.68274C2.98792 2.85461 3.65979 2.18274 4.48792 2.18274C5.31604 2.18274 5.98792 2.85461 5.98792 3.68274ZM4.48792 14.1827C3.65979 14.1827 2.98792 13.5109 2.98792 12.6827C2.98792 11.8546 3.65979 11.1827 4.48792 11.1827C5.31604 11.1827 5.98792 11.8546 5.98792 12.6827C5.98792 13.5109 5.31604 14.1827 4.48792 14.1827Z"
      fill={isActive ? "white" : "#298DCD"}
    />
  </svg>
);
export default Cut;

import React from "react";
import { isEmpty } from "lodash";

import RatingsBlok from "../../RatingStars";

type ProductInfoBlokProps = {
  blok: {
    info_blocks_list: [];
    rating_svg_image: {
      filename: string;
    };
    rating: string;
  };
  productDAO: any;
};

const ProductInfoBlok = (props: ProductInfoBlokProps) => {
  const { blok, productDAO } = props;
  const { info_blocks_list, rating = "", rating_svg_image } = blok;
  const isInfoBloks = !isEmpty(info_blocks_list);
  const img_src = rating_svg_image?.filename;
  return (
    <>
      <div className="flex flex-col">
        {!isEmpty(rating) && (
          <div className="mt-[8px] flex flex-row items-center gap-[5px] border-b border-[#E9E9E9] pb-[17px]">
            <RatingsBlok data={{ rating, img_src }} />

            <div className="ml-[2px] flex h-full w-full pb-[1px] align-middle">
              <span className="sofia-pro-r text-[16px] leading-[1.2]">
                {rating}/5
              </span>
            </div>
          </div>
        )}
        <div className="mt-[22px] flex w-full ">
          <p className="sofia-pro-r text-18">{productDAO.description}</p>
        </div>
        <div className="grid grid-cols-1 gap-[24px] border-b border-[#E9E9E9] py-[25px] lg:grid-cols-2 lg:gap-0">
          {isInfoBloks &&
            info_blocks_list.map(
              ({ info_text, before_bold_text, svg_image }) => (
                <div
                  key={info_text}
                  className="flex flex-row items-center space-x-[15px]"
                >
                  <div className="relative h-[40px] w-[40px]">
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20.0547"
                        cy="20.5439"
                        r="20.0273"
                        fill="#EDF2F4"
                      />
                    </svg>
                    <div
                      className="absolute top-0 flex h-full w-full items-center justify-center"
                      dangerouslySetInnerHTML={{ __html: svg_image }}
                    ></div>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="sofia-pro-r text-18 leading-[1.2]">
                      {before_bold_text}
                      <br />
                      <span className="sofia-pro-b">{info_text}</span>
                    </h4>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </>
  );
};

export default ProductInfoBlok;

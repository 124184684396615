export const BLOCK_PADDING = {
  none: "",
  padding_sm: "p-3 md:p-5",
  padding_md: "p-5 md:p-7",
  padding_xl: "p-7 md:p-9"
};

export const BLOCK_ROUNDED = {
  none: "",
  rounded_xl: "rounded-xl",
  rounded_2xl: "rounded-2xl",
  rounded_3xl: "rounded-3xl"
};

export const BLOCK_WIDTH = {
  width_50: "w-1/2",
  width_33: "w-1/3",
  width_25: "w-1/4",
  width_full: "w-full"
};

export const BLOCK_TEXT_ALIGN = {
  left: "text-left",
  right: "text-right",
  center: "text-center"
};

export const BLOCK_TEXT_COLOR = {
  black: "text-gl-dark-blue",
  white: "text-white",
  brown: "text-brown",
  dark_blue: "text-dark-blue"
};

export const BACKGROUND_COLORS = {
  none: "",
  tan: "bg-tan",
  grey: "bg-grey",
  black: "bg-black",
  white: "bg-white",
  brown: "bg-brown",
  mocha: "bg-mocha",
  coffee: "bg-coffee",
  coconut: "bg-coconut",
  alabaster: "bg-alabaster"
};

export const BLOCK_TEXT_POSITION = {
  top_left: "top-[12%] left-[8%]",
  top_center: "top-[12%] mx-auto w-full ",
  top_right: "top-[12%] right-[8%]",
  left: "top-[47%] translate-x-[0] translate-y-[-47%] left-[8%] lg:left-[6%]",
  center: "top-[47%] translate-x-[0] translate-y-[-47%] mx-auto w-full",
  right:
    "top-[47%] translate-x-[0] translate-y-[-47%] right-[8%] lg:right-[6%]",
  bottom_left: "bottom-[14%] left-[8%] lg:left-[6%]",
  bottom_center: "bottom-[14%] mx-auto w-full ",
  bottom_right: "bottom-[14%] right-[8%] lg:right-[6%]"
};

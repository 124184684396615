import {
  ALL_COLLECTIONS_QUERY,
  ALL_COLLECTIONS_HANDLES_QUERY,
  getCollectionQueryById,
  getCollectionQueryByHandle
} from "./queries";
import { ShopifyService } from "../ShopifyService";

export class ShopifyCollectionService extends ShopifyService {
  async getAllCollections() {
    const response = await this.fetchShopifyData(ALL_COLLECTIONS_QUERY);
    const allCollections = response.data.collections.edges
      ? response.data.collections.edges.map(({ node }) => node)
      : [];
    return allCollections;
  }

  async getAllCollectionsHandles() {
    const response = await this.fetchShopifyData(ALL_COLLECTIONS_HANDLES_QUERY);
    const allCollections = response.data.collections.edges
      ? response.data.collections.edges.map(({ node }) => node)
      : [];
    return allCollections;
  }

  async getProductsListWithCollectionTag(allProductsList, collectionTag) {
    const filteredProductsByTag = allProductsList.filter(product => {
      const formattedTagsList = product.tags.map(tag => tag?.toLowerCase());
      return (
        formattedTagsList.includes(collectionTag?.toLowerCase()) && product
      );
    });
    const formattedArray = filteredProductsByTag.map(product => {
      const node = product;
      return { node };
    });
    return formattedArray;
  }

  async getCollectionByHandle(handle) {
    const query = getCollectionQueryByHandle(handle);
    const response = await this.fetchShopifyData(query);
    return response.data.collection || [];
  }

  async getCollectionById(collectionId) {
    const query = getCollectionQueryById(collectionId);
    const response = await this.fetchShopifyData(query);
    return response?.data?.collection || [];
  }
}

import {
  addDiscountCodeToCart,
  addItemsToCartMutationQuery,
  addItemToCartMutationQuery,
  checkoutUrlQuery,
  createCartMutationQuery,
  getCartdataMutationQuery,
  removeItemCartMutationQuery,
  updateCartItemsMutationQuery,
  updateItemCartMutationQuery
} from "./queries";

import { buildContentsString, formatter } from "@utils/helpers";
import { ShopifyService } from "../ShopifyService";

export class ShopifyCartService extends ShopifyService {
  async getCheckoutUrl(cartId) {
    const query = checkoutUrlQuery(cartId);

    const response = await this.fetchShopifyData(query);

    return response?.data?.cart?.checkoutUrl;
  }

  setCartDataToStorage(cartData) {
    const cartDataToString = JSON.stringify(cartData);
    localStorage.setItem("shopifyCart", cartDataToString);
  }

  getCartDataFromStorage() {
    if (typeof window !== "undefined") {
      const cartFromLocal = localStorage.getItem("shopifyCart");
      return JSON.parse(cartFromLocal) || null;
    } else {
      return null;
    }
  }

  async cartCreate() {
    const query = createCartMutationQuery();

    const response = await this.fetchShopifyData(query);
    const cart = response.data.cartCreate.cart
      ? response.data.cartCreate.cart
      : [];
    this.setCartDataToStorage(cart);
  }

  async setCheckoutUrlToFront(setCheckoutUrl) {
    const cart = this.getCartDataFromStorage();
    const checkoutUrl = await this.getCheckoutUrl(cart.id);
    setCheckoutUrl(checkoutUrl);
  }

  async getShopifyCartData() {
    const cart = this.getCartDataFromStorage();
    const query = getCartdataMutationQuery(cart.id);

    const response = await this.fetchShopifyData(query);
    return response.data.cart ? response.data.cart : [];
  }

  async latestCartData() {
    const cart = this.getCartDataFromStorage();
    if (!cart) {
      return null;
    }
    const query = getCartdataMutationQuery(cart.id);

    return await this.fetchShopifyData(query);
  }

  async cartAddItem({ variantId, quantity, sellingPlanId, attributes }) {
    const cart = this.getCartDataFromStorage();
    const queryParams = {
      cartId: cart.id,
      variantId,
      quantity,
      sellingPlanId,
      attributes
    };
    const query = addItemToCartMutationQuery(queryParams);
    const response = await this.fetchShopifyData(query);
    const updatedCart = response.data.cartLinesAdd.cart
      ? response.data.cartLinesAdd.cart
      : [];
    this.setCartDataToStorage(updatedCart);
  }

  async cartAddItems(items) {
    const cart = this.getCartDataFromStorage();

    const query = addItemsToCartMutationQuery(cart.id, items);
    const response = await this.fetchShopifyData(query);
    const updatedCart = response.data.cartLinesAdd.cart
      ? response.data.cartLinesAdd.cart
      : [];
    this.setCartDataToStorage(updatedCart);
  }

  async updateCartItems(items) {
    const cart = this.getCartDataFromStorage();

    const query = updateCartItemsMutationQuery(cart.id, items);
    const response = await this.fetchShopifyData(query);
    const updatedCart = response.data.cartLinesUpdate.cart
      ? response.data.cartLinesUpdate.cart
      : [];

    this.setCartDataToStorage(updatedCart);
  }

  #getCartLine(productVariantId, productSellingPlanData) {
    const {
      lines: { edges: cartLines }
    } = this.getCartDataFromStorage();
    const cartLine = cartLines.find(
      ({
        node: {
          merchandise: { id },
          sellingPlanAllocation: lineSellingPlanData
        }
      }) =>
        productVariantId === id &&
        lineSellingPlanData?.sellingPlan.id === productSellingPlanData
    );
    return cartLine?.node || null;
  }

  async cartRemoveItem(productData) {
    const cart = this.getCartDataFromStorage();
    const { productVariantId, productSellingPlanData } = productData;
    const cartLineWithVariantId = this.#getCartLine(
      productVariantId,
      productSellingPlanData
    );
    if (!cartLineWithVariantId) {
      return;
    }
    const queryParams = {
      cartId: cart.id,
      lineIds: JSON.stringify(cartLineWithVariantId.id)
    };
    const query = removeItemCartMutationQuery(queryParams);
    const response = await this.fetchShopifyData(query);

    const updatedCart = response.data.cartLinesRemove.cart
      ? response.data.cartLinesRemove.cart
      : [];
    this.setCartDataToStorage(updatedCart);
  }

  async updateCartLinePlan(productData) {
    const cart = this.getCartDataFromStorage();
    const {
      productSellingPlanData,
      cartLine: { id, attributes = null, quantity }
    } = productData;

    const queryParams = {
      cartId: cart.id,
      lineId: id,
      sellingPlanId: productSellingPlanData,
      quantity,
      attributes
    };

    const query = updateItemCartMutationQuery(queryParams);
    const response = await this.fetchShopifyData(query);
    const updatedCart = response.data.cartLinesUpdate.cart
      ? response.data.cartLinesUpdate.cart
      : [];
    this.setCartDataToStorage(updatedCart);
  }

  async updateCartLine(productData, actionType) {
    const cart = this.getCartDataFromStorage();
    const { attributes, productVariantId, productSellingPlanData } =
      productData;
    const cartLineWithVariantId = this.#getCartLine(
      productVariantId,
      productSellingPlanData
    );
    const { quantity } = cartLineWithVariantId;

    const actionVariants = {
      increment: quantity + 1,
      decrement: quantity - 1
    };
    const queryParams = {
      cartId: cart.id,
      lineId: cartLineWithVariantId.id,
      quantity: actionVariants[actionType],
      sellingPlanId: productSellingPlanData,
      attributes
    };

    const query = updateItemCartMutationQuery(queryParams);
    const response = await this.fetchShopifyData(query);
    const updatedCart = response.data.cartLinesUpdate.cart
      ? response.data.cartLinesUpdate.cart
      : [];

    this.setCartDataToStorage(updatedCart);
  }

  #getAllCartLinesId() {
    const {
      lines: { edges }
    } = this.getCartDataFromStorage();
    return edges.map(({ node: { id } }) => id);
  }

  async clearCart() {
    const cart = this.getCartDataFromStorage();
    const stringifyItems = this.#getAllCartLinesId().map(item =>
      JSON.stringify(item)
    );
    const queryParams = {
      cartId: cart.id,
      lineIds: stringifyItems.join(",")
    };
    const query = removeItemCartMutationQuery(queryParams);
    const response = await this.fetchShopifyData(query);

    const updatedCart = response.data.cartLinesRemove.cart
      ? response.data.cartLinesRemove.cart
      : [];
    this.setCartDataToStorage(updatedCart);
  }

  removeExistingCart() {
    localStorage.setItem("shopifyCart", null);
  }

  getBundleProductCartAttributes(bundleProductItems, rechargeBundleId) {
    const bundleString =
      bundleProductItems && buildContentsString(bundleProductItems);
    return bundleString && rechargeBundleId
      ? [
          {
            key: "Contents",
            value: bundleString
          },
          {
            key: "_rb_id",
            value: rechargeBundleId
          }
        ]
      : [];
  }

  get cartSubtotal() {
    const {
      cost: {
        subtotalAmount: { amount, currencyCode }
      }
    } = this.getCartDataFromStorage();
    return formatter(amount, currencyCode);
  }

  get cartTotal() {
    const {
      cost: {
        totalAmount: { amount }
      }
    } = this.getCartDataFromStorage();
    return amount;
  }

  get cartLinesList() {
    const { lines } = this.getCartDataFromStorage();

    return lines?.edges;
  }

  get cartCurrency() {
    const {
      cost: {
        subtotalAmount: { currencyCode }
      }
    } = this.getCartDataFromStorage();

    return currencyCode || "USD";
  }

  get discountSum() {
    const cart = this.getCartDataFromStorage() || {};
    const lines = cart?.lines?.edges || [];
    const totalAmount = cart?.cost?.totalAmount?.amount || 0;
    const sum = lines
      .reduce(
        (b, a) =>
          Number(a.node.merchandise.priceV2.amount) * a.node.quantity + b,
        0
      )
      .toFixed(2);

    // const discountAllocations = cart?.discountAllocations || [];

    return (sum - totalAmount).toFixed(2);
    // return discountAllocations
    //   .reduce((b, a) => Number(a.discountedAmount.amount) + b, 0)
    //   .toFixed(2);
  }

  get discountCodes() {
    const cart = this.getCartDataFromStorage() || {};
    const discountAllocations = cart?.discountCodes || [];
    return discountAllocations.filter(d => d.applicable).map(d => d?.code);
  }

  get cartSubtotalNumber() {
    if (!this.getCartDataFromStorage()) {
      return 0;
    }
    const {
      cost: {
        subtotalAmount: { amount }
      }
    } = this.getCartDataFromStorage();
    return Number(amount);
  }

  get totalCartItems() {
    let totalQuantity = 0;
    this.cartLinesList.forEach(({ node: { quantity } }) => {
      totalQuantity = totalQuantity + quantity;
    });
    return totalQuantity;
  }

  async addDiscountCode(discountCode) {
    const cart = this.getCartDataFromStorage();
    const queryParams = {
      cartId: cart.id,
      discountCode
    };
    const query = addDiscountCodeToCart(queryParams);
    const response = await this.fetchShopifyData(query);

    const updatedCart = response.data.cartDiscountCodesUpdate.cart
      ? response.data.cartDiscountCodesUpdate.cart
      : [];
    this.setCartDataToStorage(updatedCart);
  }

  get isAnySubscriptions() {
    return this.cartLinesList.some(
      ({ node: { sellingPlanAllocation } }) => sellingPlanAllocation !== null
    );
  }

  get isAnyBundlesInCart() {
    return this.cartLinesList.some(
      ({
        node: {
          merchandise: {
            product: { handle, productType }
          }
        }
      }) =>
        handle.includes("bundle") ||
        productType?.toLowerCase().includes("bundle")
    );
  }
}

const accountId = process.env.KLAVIYO_ACCOUNT_ID;

class KlaviyoService {
  async handleQuizResultsSubmit({ email, quizResults }) {
    const requestBody = {
      token: accountId,
      event: "Quiz Completed",
      customer_properties: { email },
      properties: { quizResults }
    };
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "text/html",
        "content-type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        data: JSON.stringify(requestBody)
      })
    };
    fetch(`https://a.klaviyo.com/api/track`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log("error", error));
  }
}

export default new KlaviyoService();

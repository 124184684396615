import { render } from "storyblok-rich-text-react-renderer";

export const FormSuccess = ({
  blok: { success_title, success_description },
  socials
}) => {
  return (
    <div className="w-full text-center md:-mt-1">
      <div className="simple-form-success relative mx-auto mb-8 max-w-[850px] rounded-[24px] px-8 pb-10 md:mb-12 md:px-[108px] md:pb-[90px]">
        <img
          className="absolute -top-[20px] left-[92%] w-[34px] -translate-x-full -rotate-[120deg] lg:top-0 lg:left-[102%] lg:w-[66px]"
          src="/odonata.png"
          alt=""
        />
        <h2 className="text-blue-gradient sofia-pro-b mb-3 text-[32px] text-gl-dark-blue md:text-[48px]">
          {success_title}
        </h2>
        <div className="text-[22px] leading-[1.2] text-gl-dark-blue md:text-[24px]">
          {render(success_description)}
        </div>
      </div>
      <p className="text-[22px] text-gl-dark-blue md:text-[24px]">
        follow the good
      </p>
      <h3 className="sofia-pro-b mb-7 text-[44px] text-gl-dark-blue">
        #goodlaundry
      </h3>
      <ul className="flex items-center justify-center gap-10">
        {socials.map(social => (
          <li
            key={social._uid}
            className="w-[56px] transition-all hover:rotate-[15deg]"
          >
            <a href={social.link.cached_url || social.link.url} target="_blank">
              <img src={`/${social.link_text.toLowerCase()}.svg`} alt="" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

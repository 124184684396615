import { isEmpty } from "lodash";
import cn from "classnames";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { useMediaQuery } from "@hooks/index";

const ThreeGridStep = ({
  blok: {
    items_list,
    title,
    title_top_padding_mobile,
    title_top_padding_desktop,
    pictures_size = 55,
    background_color,
    title_color,
    margin_bottom,
    margin_bottom_mobile
  },
  blok
}) => {
  const isExtraSmall = useMediaQuery("(max-width: 360px)");
  const isMedium = useMediaQuery("(max-width: 992px)");

  if (isEmpty(items_list)) {
    return null;
  }
  return (
    <div
      style={{
        marginBottom: isMedium
          ? margin_bottom
            ? `${margin_bottom}px`
            : "40px"
          : margin_bottom_mobile
          ? `${margin_bottom_mobile}px`
          : "90px"
      }}
      className="mx-auto w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]"
      {...storyblokEditable(blok)}
    >
      {title && (
        <div
          style={{
            background: background_color?.color
              ? background_color.color
              : "#F5F9FA"
          }}
          className={cn(
            "mx-auto max-w-[81.5rem] rounded-tl-lg rounded-tr-lg text-center",
            title_top_padding_mobile ? title_top_padding_mobile : "pt-12",
            title_top_padding_desktop ? title_top_padding_desktop : "md:pt-24"
          )}
        >
          <h2
            className={`sofia-pro-b ${
              !isExtraSmall ? "px-[20px]" : ""
            } pb-[24px] pb-[48px] text-[32px] leading-[1.1] lg:px-[unset] lg:text-[44px]`}
          >
            {title}
          </h2>
        </div>
      )}
      <div
        style={{
          background: background_color?.color
            ? background_color.color
            : "#F5F9FA"
        }}
        className={`mx-auto flex w-full max-w-[81.5rem] flex-wrap justify-evenly gap-y-8 rounded-bl-lg rounded-br pb-12 md:pb-24`}
      >
        {items_list.map(item => {
          const { _uid, title, text, text_font_size, image } = item;
          return (
            <div
              key={_uid}
              className="flex w-full flex-col items-center px-2 text-center sm:w-1/2 lg:w-1/3"
              {...storyblokEditable(item)}
            >
              <div
                className={`relative`}
                style={{
                  height: `${pictures_size}px`,
                  width: `${pictures_size}px`
                }}
              >
                <Image
                  src={image?.filename}
                  layout="fill"
                  objectFit="contain"
                />{" "}
              </div>
              <div className="mt-[14px]">
                <h3
                  style={{
                    color: title_color?.color ? title_color.color : "#2589BD"
                  }}
                  className="sofia-pro-s text-[22px] leading-[1.2]"
                >
                  {title}
                </h3>
              </div>
              <div className="mx-auto mt-[10px] flex max-w-[19rem]">
                <p
                  className={cn(
                    "sofia-pro-r leading-[1.1] text-gl-black",
                    text_font_size
                      ? text_font_size
                      : "text-[26px] md:text-[34px]"
                  )}
                >
                  {text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThreeGridStep;

import React from "react";

import ProductReviews from "@components/Product/ProductReviews/ProductReviews";

export interface IReview {
  name: string;
  text: string;
}

interface IProductReviews {
  blok: { title: string };
  data: { productId: string; skuList: any[] };
}

const Reviews = ({ blok, data }: IProductReviews) => {
  return (
    <div className="mx-auto mb-[20px] flex w-full max-w-[120rem] px-[18px] lg:mb-[100px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col justify-center overflow-hidden text-left">
        <div className="mb-[20px] flex h-fit w-full flex-col gap-[10px] text-left lg:text-center">
          {blok.title && (
            <h3 className="sofia-pro-s text-[28px] leading-[1.2] lg:text-[34px]">
              {blok.title}
            </h3>
          )}
        </div>
        <ProductReviews productId={data.productId} />
      </div>
    </div>
  );
};
export default Reviews;

import { motion } from "framer-motion";
import { render } from "storyblok-rich-text-react-renderer";

const QuestionFooter = ({ data }) => {
  const footerText = data?.footer_text;
  const image = data?.footer_img;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.2,
        duration: 0.2
      }}
      className="footer_decorator flex h-fit h-[90px] w-full justify-center pt-[20px] lg:h-[160px] lg:pt-[50px]"
    >
      {image?.filename && (
        <div className="absolute bottom-0 left-0 max-sm:bottom-[-10px] max-sm:h-[110px] max-sm:w-[93px]">
          <img src={image?.filename} alt={image?.alt} />
        </div>
      )}
      <div className="mx-auto flex flex-col items-end justify-center sm:items-center sm:justify-start md:mb-[-8rem] md:w-full">
        <div className="sofia-pro-r coloredText w-[77%] items-center justify-center text-center text-[16px] leading-[1.1] lg:text-[22px]">
          {render(footerText)}
        </div>
      </div>
    </motion.div>
  );
};
export default QuestionFooter;

import { LinkVariant } from "@components/UI";

export default function Footer({ blok: { copyright, links } }) {
  return (
    <section className="mt-auto py-9 md:py-16">
      <div className="mx-auto flex max-w-[1200px] flex-col items-center justify-between gap-4 px-4 px-4 md:flex-row">
        <p className="sofia-pro-r text-center text-[16px] text-white">
          {copyright}
        </p>
        <ul className="flex flex-col items-center justify-end gap-y-2 gap-x-8 text-[16px] text-white md:flex-row">
          {links.length > 0 &&
            links.map(item => (
              <li key={item._uid}>
                <LinkVariant
                  className="underline hover:no-underline"
                  linkType={"internal"}
                  href={item.link.cached_url || item.link.url}
                >
                  {item.link_text}
                </LinkVariant>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
}

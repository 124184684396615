export class QuizResultsProcessor {
  constructor() {
    this.adapterObject = {
      "featured-products": MatchingProductsList,
      option_selector: ProductOptionName,
      subscription_selector: ProductOptionName
    };
  }

  getOption(answerVariant) {
    const optionData = answerVariant?.matching_products;
    const optionComponent = optionData[0]?.component;
    if (!optionComponent) {
      return;
    }
    return new this.adapterObject[optionComponent]().getValue(optionData);
  }

  getPresetUrlQuery(quizResults) {
    const options = quizResults
      .map(({ presets }) => presets)
      .filter(item => typeof item !== "undefined");
    const transformedInToObject = Object.assign({}, ...options);
    const stringifiedTransformedInToObject = JSON.stringify(
      transformedInToObject
    );
    return encodeURI(stringifiedTransformedInToObject);
  }
}

class AnswerAnalyzer {
  getValue() {
    console.log("Unknown option type");
  }
}

class ProductOptionName extends AnswerAnalyzer {
  getValue(optionData) {
    const [{ option_selector: selectedOption, option_name: optionName }] =
      optionData;
    return { presets: { [optionName]: selectedOption } };
  }
}

class MatchingProductsList extends AnswerAnalyzer {
  getValue(optionData) {
    const [{ products }] = optionData;
    return {
      matchingProductIdsList: products?.map(
        ({ content: { shopify_product_id: shopifyProductId } }) =>
          shopifyProductId
      )
    };
  }
}

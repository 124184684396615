import React from "react";
import Link from "next/link";
import { motion } from "framer-motion";

const InternalLink = ({
  children,
  className,
  href,
  isAccessibility,
  target
}) => {
  return isAccessibility ? (
    <motion.button
      type="button"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.4,
        duration: 0.4
      }}
      className={className}
      data-acsb-custom-trigger={isAccessibility}>
      {children}
    </motion.button>
  ) : (
    <Link passHref href={href}>
      <motion.a
        target={target}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.4,
          duration: 0.4
        }}
        className={className}>
        {children}
      </motion.a>
    </Link>
  );
};

export default InternalLink;

import { AnimatedText } from "./AnimatedText/AnimatedText";
import { motion } from "framer-motion";
import { storyblokEditable } from "@storyblok/react";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    fetchpriority?: string;
  }
}

export default function Landing({ blok }) {
  return (
    <>
      <div className="relative mx-auto flex h-full max-h-[800px] min-h-[550px] w-full max-w-[120rem] flex-col overflow-hidden bg-white lg:mb-[130px] lg:min-h-[400px] lg:bg-[#f9f9f9]">
        <div className="absolute flex h-full w-full">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.8,
              duration: 0.4
            }}
            src={`${blok.landing_image.filename}` + "/m/"}
            fetchpriority="high"
            className="hidden h-full w-full object-cover md:flex"
            {...storyblokEditable(blok)}
          />

          <div className="absolute top-0 left-0 flex max-h-[270px] w-full select-none justify-end overflow-hidden md:hidden">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.8,
                duration: 0.4
              }}
              src={`${blok?.landing_image_mobile?.filename}` + "/m/"}
              fetchpriority="high"
              className="mb-auto flex h-full w-fit object-cover align-top"
              {...storyblokEditable(blok)}
            />
          </div>
        </div>
        <div className="mx-auto flex w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]">
          <div className="min-h-full mx-auto flex h-[71vh] w-full max-w-[81.5rem] flex-col lg:h-[70vh] lg:min-h-[500px]">
            <div className="relative top-0 mx-auto flex h-full w-full py-[24px] md:absolute">
              <div className="flex h-full w-full max-w-[25rem] items-end align-bottom lg:max-w-[35rem] lg:items-center lg:align-middle">
                <div className="flex w-full flex-col">
                  <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 300,
                      damping: 20,
                      delay: 0.2,
                      duration: 0.4
                    }}
                  >
                    <img
                      src={blok?.image?.filename}
                      className="h-[74px] w-[74px] lg:h-[97px] lg:w-[98px]"
                      {...storyblokEditable(blok)}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.4
                    }}
                    className="h-full w-full overflow-hidden pt-[12px] lg:pt-[19px] lg:pb-[5px]"
                    {...storyblokEditable(blok)}
                  >
                    <AnimatedText text={blok?.title} />
                  </motion.div>
                  <div className="h-full pt-[9px] lg:pt-[15px]">
                    <motion.p
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                        delay: 0.6,
                        duration: 0.4
                      }}
                      className="sofia-pro-r text-[22px] leading-[26px] lg:text-[24px]"
                      {...storyblokEditable(blok)}
                    >
                      {blok?.paragraph}
                    </motion.p>
                  </div>
                  <div className="flex max-w-full flex-col gap-[20px] pr-[2px] pt-[45px] lg:max-w-[19rem]">
                    {blok.link_text_1 && <motion.div
                      whileHover={{ scale: 1.04 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        delay: 0.1
                      }}
                    >
                      <motion.a
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          delay: 0.4,
                          duration: 0.4
                        }}
                        href={blok.link_1.cached_url}
                        className="sofia-pro-m flex w-full justify-center rounded-8 bg-[#2589BD] px-[12px] py-[14px] text-center text-[18px] leading-[1] text-white"
                        {...storyblokEditable(blok)}
                      >
                        {blok.link_text_1}
                      </motion.a>
                    </motion.div>}
                    {blok.link_text_2 && <motion.div
                      whileHover={{ scale: 1.04 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        delay: 0.1
                      }}
                    >
                      <motion.a
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          delay: 0.7,
                          duration: 0.4
                        }}
                        href={blok.link_2.cached_url}
                        className="sofia-pro-m flex w-full justify-center rounded-8 border border-[#D7E1E4] bg-[#15233C] px-[12px] py-[14px] text-[18px] leading-[1] text-white"
                        {...storyblokEditable(blok)}
                      >
                        {blok.link_text_2}
                      </motion.a>
                    </motion.div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

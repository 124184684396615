import React from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import { StoryblokComponent } from "@storyblok/react";

import { useMediaQuery } from "@hooks/index";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKE,
  NODE_PARAGRAPH,
  NODE_HEADING,
  MARK_UNDERLINE
} from "storyblok-rich-text-react-renderer";

export default function Landing({ blok }) {
  const matches = useMediaQuery(`(min-width: ${1024}px)`);

  const {
    height_mobile = 500,
    height = 700,
    background: { color = "#FFFFFF" }
  } = blok;

  const renderOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <b className={`sofia-pro-b text-[#424B5B]`}>{children}</b>;
      },
      [MARK_UNDERLINE]: children => {
        return (
          <span
            className={`sofia-pro-b text-underline-landing-about text-white`}
          >
            {children}
          </span>
        );
      },
      [MARK_ITALIC]: children => {
        return (
          <span
            className={`sofia-pro-r text-underline-blue pb-[3px] text-[#424B5B]`}
          >
            {children}
          </span>
        );
      },
      [MARK_STRIKE]: children => {
        return (
          <span className={`sofia-pro-r underline-pink text-[#424B5B]`}>
            {children}
          </span>
        );
      }
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r line-height-[130%] max-w-[36rem]  max-w-[60rem]  text-[#424B5B]">
            {children}
          </p>
        );
      },
      [NODE_HEADING]: (children, { level }) => {
        switch (level) {
          case 1:
            return (
              <h1 className="sofia-pro-b line-through-[1.1] mt-[30px] text-[28px] text-white lg:mt-[90px] lg:text-[44px]">
                {children}
              </h1>
            );
          case 3:
            return (
              <h3 className="sofia-pro-s line-through-[1] lg:line-through-[1.1] mt-[20px] text-[30px] text-[#15233C] lg:text-[34px]">
                {children}
              </h3>
            );
          case 4:
            return (
              <h4 className="sofia-pro-r line-through-[1] lg:line-through-[1.1] max-w-[36rem] text-[20px] text-white lg:text-[24px]">
                {children}
              </h4>
            );
          default:
            <div>{children}</div>;
        }
      }
    }
  };

  return (
    <>
      <div
        className={`relative mx-auto mb-[30px] flex h-full w-full max-w-[120rem] flex-col justify-start overflow-hidden lg:mb-[60px]`}
        style={{
          height: matches ? `${height}px` : `${height_mobile}px`,
          backgroundColor: `${color}`
        }}
      >
        <div
          className={`absolute top-0 flex h-full h-[65%] w-full  min-w-full max-w-full`}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.8,
              duration: 0.4
            }}
            className="about-background top-0 h-full w-full"
          >
            <Image
              src={blok?.image?.filename}
              layout="fill"
              objectFit="cover"
            />
          </motion.div>
        </div>
        <div
          className="mx-auto flex w-full max-w-[81.5rem] flex-col px-[18px] lg:px-[48px] xl:px-[48px]"
          style={{
            height: matches ? `${height}px` : `${height_mobile}px`,
            backgroundColor: `${color}`
          }}
        >
          <div className="flex h-full w-full items-start justify-center align-top">
            <div className="z-10 mx-auto flex w-full flex-col justify-center text-center">
              {blok.content.map((blok, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: (index != 0 ? index / 2 : 0) + 0.15,
                    duration: 0.4
                  }}
                  key={blok._uid}
                  className="flex h-full w-full justify-center overflow-hidden pt-[12px] lg:pt-[19px]"
                >
                  <StoryblokComponent
                    blok={blok}
                    data={{ isAboutPage: true }}
                    renderOptions={renderOptions}
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

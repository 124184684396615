import React, { useEffect, useState } from "react";
import Image from "next/image";
import LazyLoad from "react-lazyload";
import cn from "classnames";

import { useMediaQuery } from "@hooks/index";
import {
  MARK_BOLD,
  NODE_QUOTE,
  NODE_LI,
  NODE_PARAGRAPH,
  NODE_HR,
  render
} from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

export default function Saving({ blok, data }) {
  const [isRightOrientation, setIsRightOrientation] = useState(false);
  const [isMediaFullWidth, SetIsMediaFullWidth] = useState(false);
  useEffect(() => {
    setIsRightOrientation(Boolean(blok.switched_to_right));
    SetIsMediaFullWidth(Boolean(blok.full_blok_width));
  }, []);
  const isMobile = useMediaQuery(`(max-width: ${580}px)`);
  const {
    paragraph_bottom_margin: paragraphBottomMargin,
    paragraph_font_size = 20,
    is_bold_text_blue = false,
    paragraph_font_size_mobile = 18,
    paragraph,
    title_font_size = 34,
    title_font_size_mobile = 28,
    title_margin_bottom = 0
  } = blok;
  const richtextOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return (
          <b className={`sofia-pro-s ${is_bold_text_blue && "text-[#2589BD]"}`}>
            {children}
          </b>
        );
      }
    },
    nodeResolvers: {
      [NODE_QUOTE]: children => {
        return <div className={`sofia-pro-b text-white`}>{children}</div>;
      },
      [NODE_LI]: children => {
        return (
          <li
            className={`checked-list-item text-[${paragraph_font_size_mobile}px] lg:text-[${paragraph_font_size}px]`}>
            {children}
          </li>
        );
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p
            className={`text-[${paragraph_font_size_mobile}px] lg:text-[${paragraph_font_size}px] sofia-pro-r`}>
            {children}
          </p>
        );
      },
      [NODE_HR]: () => {
        return (
          <div
            style={{
              marginBottom: `${
                isMobile ? paragraphBottomMargin * 0.7 : paragraphBottomMargin
              }px`
            }}></div>
        );
      }
    }
  };

  return (
    <>
      <div
        className={`mx-auto mb-[40px] flex h-full w-full  flex-col items-start justify-center  text-left align-middle lg:mb-[100px] ${
          !data?.isContactPage && "max-w-[120rem] px-[18px] lg:px-[48px]"
        }`}>
        <div className={`relative mx-auto flex w-full max-w-[81.5rem]`}>
          {isMediaFullWidth && blok?.left_image && (
            <div className="absolute h-full w-full overflow-hidden rounded-8">
              <Image
                src={`${blok?.left_image?.filename}` + "/m/"}
                layout="fill"
                objectFit="cover"
              />
            </div>
          )}
          <div
            className={`relative grid w-full grid-cols-1 gap-[0px] md:grid-cols-2 md:gap-[60px]`}>
            {isMediaFullWidth && blok?.left_image ? (
              <div
                className={`${
                  isRightOrientation ? "order-2" : "order-1"
                } flex h-full w-full`}
              />
            ) : (
              <div
                className={`${
                  isRightOrientation
                    ? "order-1 md:order-2"
                    : "order-2 md:order-1"
                } flex h-full w-full overflow-hidden rounded-8 bg-white`}
                {...storyblokEditable(blok)}>
                {blok?.is_video ? (
                  <>
                    {blok?.left_video?.filename && (
                      <video
                        autoPlay
                        muted
                        loop
                        className="outine-none h-full w-full select-none overflow-hidden rounded-8">
                        <source
                          src={blok.left_video.filename}
                          className="rounded-8"
                        />
                      </video>
                    )}
                    <LazyLoad once offset={100}>
                      {blok?.left_video_vidalytics_id && (
                        <video
                          className="video-fluid"
                          controls={false}
                          preload=""
                          poster=""
                          autoPlay
                          muted
                          loop
                          playsInline>
                          <source
                            src={`https://res.cloudinary.com/dfw0vrsfj/video/upload/w_auto/f_auto,q_auto/videos/${blok?.video_cloudinary_name}.mp4`}
                          />
                        </video>
                      )}
                    </LazyLoad>
                  </>
                ) : (
                  <div className="relative h-[300px] w-full overflow-hidden rounded-8 lg:h-[500px]">
                    <Image
                      src={`${blok?.left_image?.filename}` + "/m/"}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                )}
              </div>
            )}
            <div
              className={cn(
                isRightOrientation ? "order-1" : "order-2",
                isMediaFullWidth
                  ? "min-h-[300px] px-4 py-20 lg:min-h-[500px]"
                  : "py-3",
                "my-auto flex h-full w-full justify-center  align-middle"
              )}>
              <div className="flex h-full w-full flex-col items-start justify-center space-y-[12px] text-left align-middle">
                {blok?.subheading && (
                  <h4
                    className={` ${
                      !data?.isEarthPage
                        ? "sofia-pro-s text-underline-blue-home text-[20px] uppercase lg:text-[22px]"
                        : "sofia-pro-b text-[30px] lg:pb-[0] lg:text-[34px]"
                    } pb-[13px]  leading-[1] text-[#2589BD]`}
                    {...storyblokEditable(blok)}>
                    {blok.subheading}
                  </h4>
                )}
                {blok?.title && (
                  <h3
                    className={`sofia-pro-b text-[${title_font_size_mobile}px] leading-[1.1] lg:text-[${title_font_size}px] mb-[${title_margin_bottom}px]`}
                    {...storyblokEditable(blok)}>
                    {blok.title}
                  </h3>
                )}
                {blok?.paragraph && (
                  <div
                    className={`${
                      !blok.title && !blok.subheading
                        ? "sofia-pro-b text-[30px] lg:text-[44px]"
                        : "sofia-pro-r text-[16px] lg:text-[20px]"
                    } leading-[1.2] text-[#424B5B]`}
                    {...storyblokEditable(blok)}>
                    {render(paragraph, richtextOptions)}
                  </div>
                )}
                {blok.link.cached_url && blok.link_text && (
                  <div className="w-full pt-[13px] lg:w-fit lg:pt-[25px]">
                    <a
                      href={blok.link.cached_url}
                      className="sofia-pro-m flex w-full justify-center rounded-8 bg-[#E2F5FF] px-[12px] py-[14px] text-center text-[18px] leading-[1] transition-all hover:bg-[#b5d7ed] lg:w-[190px]"
                      {...storyblokEditable(blok)}>
                      {blok.link_text}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

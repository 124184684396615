const Lock = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.911133 17.8218C0.911133 19.2613 1.6102 19.981 3.00832 19.981H13.6597C15.0718 19.981 15.7778 19.2613 15.7778 17.8218V10.6807C15.7778 9.33287 15.1678 8.61969 13.9478 8.54117V6.89654C13.9478 5.09062 13.4303 3.63147 12.3953 2.51912C11.3603 1.40676 10.0066 0.850586 8.33403 0.850586C6.66162 0.850586 5.30792 1.40676 4.27294 2.51912C3.23797 3.63147 2.72048 5.09062 2.72048 6.89654V8.54117C1.51425 8.61969 0.911133 9.33287 0.911133 10.6807V17.8218ZM4.85915 8.52147V6.68062C4.85915 5.52902 5.17786 4.59987 5.8153 3.89319C6.45274 3.18653 7.29232 2.83319 8.33403 2.83319C9.37588 2.83319 10.2155 3.18653 10.853 3.89319C11.4904 4.59987 11.8091 5.52902 11.8091 6.68062V8.52147H4.85915Z"
      fill="white"
    />
  </svg>
);
export default Lock;

import React from "react";

import DynamicComponent from "@services/DynamicComponentsRenderer";

const Hero = ({ blok: { items_list: itemsList } }) => (
  <div className="mb-[30px] flex h-full w-full flex-wrap md:mb-0 md:flex-nowrap">
    {itemsList?.map(customBlok => (
      <div
        key={customBlok._uid}
        className={`first:m-l mb-[30px] flex h-full w-full flex-wrap
          overflow-hidden last:mr-0 last:mb-0 md:mr-[30px] md:flex-col lg:flex-row`}
      >
        <DynamicComponent blok={customBlok} />
      </div>
    ))}
  </div>
);

export default Hero;

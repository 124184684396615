import {
  MARK_LINK,
  NODE_BR,
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import React from "react";

const DoingGood = ({ blok: { title, description, images, list } }) => {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        if (level === 3) {
          return (
            <h3
              className={`sofia-pro-s mb-7 text-[32px] leading-[1.15] lg:mb-8 lg:text-[34px]`}>
              {children}
            </h3>
          );
        }
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r text-[32px] lg:text-[34px]`}>{children}</p>
        );
      }
    }
  };

  const renderDescriptionOptions = {
    markResolvers: {
      [MARK_LINK]: children => {
        return (
          <a
            className={`cursor-pointer text-gl-blue underline hover:no-underline`}>
            {children}
          </a>
        );
      }
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r mb-5 text-[20px] leading-[1.2]`}>
            {children}
          </p>
        );
      }
    }
  };

  const renderListOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r mb-0 text-[20px] lg:text-[24px]`}>
            {children}
          </p>
        );
      },
      [NODE_BR]: () => {
        return <br className={`hidden md:block`} />;
      }
    }
  };

  return (
    <section className="relative z-[1] pb-20 pt-12 md:pb-28">
      <div className="custom-container">
        <div className="flex flex-wrap items-center justify-between">
          <div className="w-full md:w-[45%]">
            {render(title, renderTitleOptions)}
            {render(description, renderDescriptionOptions)}
            {list?.length > 0 && (
              <ul className="mt-8 md:mt-10">
                {list.map((item, index) => (
                  <li
                    key={index}
                    className="border-dark flex min-h-[130px] items-center border-t border-b py-4 leading-[1.2]">
                    <img
                      style={{ height: `${item.icon_size}px` }}
                      className="mr-5 max-h-[100px] w-[116px] min-w-[116px] object-contain"
                      src={item.icon_image.filename}
                      alt=""
                    />
                    {render(item.title, renderListOptions)}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex w-full flex-wrap justify-between gap-y-3 pt-14 md:w-[49%] md:pt-0">
            {images?.length > 0 &&
              images.map((image, index) => (
                <img
                  key={index}
                  className="h-[138px] w-[49%] rounded object-cover sm:h-[243px]"
                  src={image.filename}
                  alt=""
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoingGood;

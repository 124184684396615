const Replay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
  >
    <path
      d="M6.9552 7.97713L8.53858 6.64685L10.1219 7.97713"
      stroke="#2589BD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6858 12.9639H10.7043C10.1297 12.9639 9.57857 12.7721 9.17225 12.4306C8.76592 12.0893 8.53772 11.6263 8.53772 11.1435V6.64685"
      stroke="#2589BD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8662 11.6323L15.2828 12.9625L13.6996 11.6323"
      stroke="#2589BD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1355 6.64551H13.1171C13.6917 6.64551 14.2428 6.83735 14.6491 7.17874C15.0554 7.52014 15.2837 7.9831 15.2837 8.46594V12.9625"
      stroke="#2589BD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Replay;

import { motion } from "framer-motion";
import { storyblokEditable } from "@storyblok/react";

export default function LongSmallBox({ blok }) {
  const imageMotion = {
    rest: {
      opacity: 1,
      scale: 1,
      duration: 0.4,
      transition: {
        duration: 0.3
      }
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <motion.a
        initial="rest"
        whileHover="hover"
        animate="rest"
        href={blok?.link?.cached_url}>
        <div className="lg:min-h-full relative mt-4 flex h-full min-h-[250px] w-full overflow-hidden rounded-8 lg:mt-0 lg:h-full	">
          <div className="flex h-full w-full overflow-hidden rounded-8 object-fill">
            <motion.img
              variants={imageMotion}
              src={`${blok?.image?.filename}` + "/m/"}
              className="flex h-full min-h-[250px] w-full object-cover"
              {...storyblokEditable(blok)}
            />
          </div>
          <div className="absolute z-10 mt-auto flex h-full w-full max-w-[30rem] flex-col justify-end px-[15px] pb-[28px] lg:max-w-[17rem] lg:pl-[27px]">
            <motion.div
              variants={{
                hover: {
                  y: -40,
                  transition: { duration: 0.7, ease: "backInOut" }
                }
              }}>
              <h2
                className="sofia-pro-b text-[22px] text-gl-dark-blue"
                {...storyblokEditable(blok)}>
                {blok?.title}
              </h2>
            </motion.div>
            <motion.div
              initial={{ y: 60 }}
              variants={{
                hover: {
                  y: -30,
                  transition: { duration: 0.7, ease: "backInOut" }
                }
              }}>
              <p className="sofia-pro-m absolute h-max w-fit rounded-8 bg-[#2589BD] py-2 px-[20px] text-center text-white transition-all hover:bg-[#07496c] lg:w-fit">
                Shop now
              </p>
            </motion.div>
          </div>
        </div>
      </motion.a>
    </>
  );
}

import React, { useContext } from "react";

import { ShopContext } from "@context/shopContext";

const QuizNavItem = ({ data: { text, index }, data }) => {
  const { activeQuizStep } = useContext(ShopContext);
  const titleStylesList = `sofia-pro-m flex flex-row items-center text-center text-[16px] ${
    activeQuizStep !== text && "opacity-50"
  }`;
  return (
    <h3 className={titleStylesList}>
      <div className=" sofia-pro-m ext-center mr-[9px] h-[20px]  w-[20px] rounded-full bg-[#15233C] align-middle text-[12px] text-white">
        {index + 1}
      </div>
      {text}
    </h3>
  );
};

export default QuizNavItem;

interface IButtonProps {
  children: any;
  isDisabled?: boolean;
  isUnderlinedText?: boolean;
  classes?: string;
  onClick: () => void;
}

const ButtonNextPrev = ({
  children,
  onClick,
  isDisabled,
  classes = "",
  isUnderlinedText = false
}: IButtonProps) =>
  !isUnderlinedText ? (
    <button
      className={`sofia-pro-m flex w-11/12 justify-center rounded-8 bg-[#2589BD] px-[12px] py-[14px] text-center text-18 leading-[1] text-white sm:w-[190px] ${classes}  ${
        isDisabled && "cursor-not-allowed opacity-50"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  ) : (
    <div
      className={`sofia-pro-m flex w-11/12 cursor-pointer justify-center rounded-8 px-[12px] py-[14px] text-center text-18 leading-[1] text-[#2589BD] underline underline-offset-4 sm:w-[190px] ${classes}  ${
        isDisabled && "cursor-not-allowed opacity-50"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
export default ButtonNextPrev;

import { useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

import { Accordion } from "@components/UI";

const AccordionItem = ({ blok }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      onClick={() => setIsActive(!isActive)}
      className="flex w-full cursor-pointer select-none flex-col border-b border-[#d5dbe2]"
    >
      <div className="flex w-full flex-col">
        <div className="flex w-full py-[20px]">
          <div className="flex h-full w-full flex-row items-center justify-between">
            <div className="flex w-full pr-[5px] text-left">
              <h3
                className={`sofia-pro-r text-[20px] leading-[1.1] transition-all lg:text-[22px] ${
                  isActive ? "text-[#2589BD]" : "text-[#15233C]"
                }`}
                {...storyblokEditable(blok)}
              >
                {blok.title}
              </h3>
            </div>
            <div className="flex h-full transition-all">
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="11"
                  y1="0.5"
                  x2="11"
                  y2="21.5"
                  stroke="#15233C"
                  strokeWidth="2"
                  className="transition-all"
                  style={{
                    transform: isActive ? "rotate(180deg)" : "rotate(0deg)"
                  }}
                />
                <line
                  y1="11"
                  x2="21"
                  y2="11"
                  stroke="#15233C"
                  strokeWidth="2"
                  className="transition-all"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Accordion
        isOpened={isActive}
        classList={
          "sofia-pro-r text-[18px] leading-[1.2] text-[#424B5B] lg:text-[20px] pb-[20px]"
        }
        {...storyblokEditable(blok)}
      >
        {render(blok.paragraph)}
      </Accordion>
    </div>
  );
};

export default AccordionItem;

import React, { useState, useEffect } from "react";
import Image from "next/image";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

import { Link } from "@components/UI";
import { useMediaQuery } from "@hooks/index";

export default function BannerWithButton({ blok, data }) {
  const [widthBlokClassName, setWidthBlokClassName] = useState("");
  const isLgScreen = useMediaQuery(`(min-width: ${1024}px)`);
  const isMdScreen = useMediaQuery(`(min-width: ${768}px)`);

  const paragraphMaskRender = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <span className="sofia-pro-b text-gl-blue">{children}</span>;
      }
    }
  };

  useEffect(() => {
    const widthBlockClassName =
      typeof blok.is_full_width !== "undefined" && blok.is_full_width
        ? "mx-auto flex w-full max-w-[120rem] h-[465px] lg:h-[520px]"
        : "mx-auto mb-0 lg:mb-[100px] flex w-full max-w-[81.5rem] overflow-hidden lg:rounded-8 h-[465px] lg:h-[520px]";
    setWidthBlokClassName(widthBlockClassName);
  }, []);

  return (
    <>
      <div className={widthBlokClassName}>
        <div className="relative mx-auto flex h-full w-full flex-col overflow-hidden bg-[#f0f0f2]">
          <div className="absolute min-h-[465px] w-full lg:mt-0 lg:h-full lg:min-h-[520px]">
            {blok.background_image.filename && isMdScreen && (
              <div className="lg:object-fit relative hidden h-full min-h-[520px] w-full select-none object-cover md:flex">
                <Image
                  src={`${blok.background_image.filename}` + "/m/"}
                  alt="Try background"
                  layout="fill"
                  objectFit={isLgScreen ? "cover" : "contain"}
                />
              </div>
            )}
            {!isMdScreen && (
              <div className="h-[365px] w-full">
                <img
                  src={`${blok.background_image_mobile.filename}` + "/m/"}
                  alt="Try background"
                  className="absolute bottom-0"
                />
              </div>
            )}
          </div>
          <div className="relative mx-auto flex min-h-[465px] w-full items-center justify-center overflow-hidden px-[30px] lg:min-h-[520px]">
            <div className="mt-[65px] flex h-full w-full justify-center text-center align-middle">
              <div
                className={`mx-auto flex h-full w-full ${
                  !data?.isGivenPage &&
                  !data?.isEarthPage &&
                  !data?.isAboutPage &&
                  "max-w-[30rem]"
                } flex-col items-center justify-start  space-y-[12px] align-middle`}
              >
                {blok?.image?.filename && (
                  <div className="relative h-[100px] w-[100px] overflow-hidden lg:h-[122px] lg:w-[122px]">
                    <Image
                      src={blok?.image?.filename}
                      alt="Try logo"
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                )}
                <div
                  className="sofia-pro-b max-w-[20rem] text-[32px] leading-[1.1] lg:max-w-full lg:text-[34px]"
                  style={{ marginTop: blok?.image?.filename ? "0" : "30px" }}
                  {...storyblokEditable(blok)}
                >
                  {render(blok?.title, paragraphMaskRender)}
                </div>
                <div
                  className="sofia-pro-r text-[20px] leading-[1.2] text-[#15233C]"
                  {...storyblokEditable(blok)}
                >
                  {render(blok?.paragraph)}
                </div>
                <div className="pt-[16px]">
                  <Link
                    href={blok.link.cached_url}
                    className="sofia-pro-m inline-block rounded-8 bg-gl-blue py-[10px] pl-[25px] pr-[40px] text-[18px] leading-[30px] text-white transition-all hover:bg-[#07496c]"
                    {...storyblokEditable(blok)}
                  >
                    {blok.link_text}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useCallback, useState } from "react";
import { Formik } from "formik";
import { object, string } from "yup";
import axios from "axios";

const ApplicationModal = ({
  onClose,
  blok: {
    title_first_part,
    title_second_part,
    footer_info,
    submit_button,
    ...rest
  }
}) => {
  const initialValues = {
    email: "",
    fullName: "",
    instagram: "",
    tiktok: "",
    youtube: "",
    facebook: "",
    twitter: ""
  };

  const onSubmit = useCallback(
    async (profile, { setSubmitting, resetForm }) => {
      try {
        await axios.post(
          `https://node.goodlaundry.com/api/influencer`,
          profile
        );
        resetForm();
      } catch (e) {}
    },
    []
  );

  return (
    <>
      <div className="z-200 fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative my-6 mx-auto mx-[20px] w-full max-w-[1072px]">
          {/*content*/}
          <div className="shadow-lg relative flex w-full flex-col rounded-lg border-0 bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="relative flex items-start justify-between rounded-t border-slate-200 p-5">
              <button
                onClick={onClose}
                className="absolute right-[27px] top-[28px] flex h-[25px] w-[25px] items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                >
                  <path
                    d="M11.0068 8.92604C11.2032 9.12429 11.3133 9.39204 11.3133 9.67108C11.3133 9.95012 11.2032 10.2179 11.0068 10.4161C10.807 10.6094 10.5398 10.7174 10.2618 10.7174C9.98376 10.7174 9.71662 10.6094 9.51675 10.4161L6.0791 6.9654L2.64145 10.4161C2.44158 10.6094 2.17443 10.7174 1.8964 10.7174C1.61838 10.7174 1.35123 10.6094 1.15136 10.4161C0.955004 10.2179 0.844849 9.95012 0.844849 9.67108C0.844849 9.39204 0.955004 9.12429 1.15136 8.92604L4.60208 5.48839L1.15136 2.05074C0.984616 1.84757 0.899402 1.58964 0.912295 1.32712C0.925189 1.0646 1.03527 0.816265 1.22112 0.630413C1.40697 0.44456 1.65531 0.334481 1.91783 0.321588C2.18035 0.308695 2.43828 0.393909 2.64145 0.560655L6.0791 4.01137L9.51675 0.560655C9.71992 0.393909 9.97784 0.308695 10.2404 0.321588C10.5029 0.334481 10.7512 0.44456 10.9371 0.630413C11.1229 0.816265 11.233 1.0646 11.2459 1.32712C11.2588 1.58964 11.1736 1.84757 11.0068 2.05074L7.55611 5.48839L11.0068 8.92604Z"
                    fill="#15233C"
                  />
                </svg>
              </button>
            </div>
            <section className="mb-[57px] px-[20px] text-center md:px-[64px]">
              <Formik
                initialValues={initialValues}
                validationSchema={applicationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }: /* and other goodies */
                any) => (
                  <div className="m-auto w-full">
                    <h2 className="sofia-pro-b my-[29px] text-center text-[44px] leading-tight">
                      {title_first_part}
                      <br />
                      <span className="text-[24px] text-[#2589BD]">
                        {title_second_part}
                      </span>
                    </h2>

                    <h5 className="sofia-pro-r mb-[14px] text-left text-[24px] text-[#424B5B]">
                      Basic information
                    </h5>
                    <div className="mb-[36px] grid grid-cols-1 md:grid-cols-2 md:gap-4">
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Full name
                        </label>
                        <Input
                          name="fullName"
                          type="text"
                          placeholder="Enter your full name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullName}
                        />
                        {errors.fullName && touched.fullName && (
                          <p className="pl-[15px] text-left text-[12px] text-[#ff5959]">
                            {errors.fullName}
                          </p>
                        )}
                      </section>
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Email
                        </label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="name@gmail.com"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <p className="pl-[15px] text-left text-[12px] text-[#ff5959]">
                            {errors.email}
                          </p>
                        )}
                      </section>
                    </div>
                    <h5 className="sofia-pro-r mb-[14px] text-left text-[24px] text-[#424B5B]">
                      Social accounts
                    </h5>
                    <div className="mb-[50px] grid grid-cols-1 gap-4 md:mb-[98px] md:grid-cols-2 md:gap-6">
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Instagram
                        </label>
                        <Input placeholder="@" type="text" />
                      </section>
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Tiktok
                        </label>
                        <Input placeholder="@" type="text" />
                      </section>
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Youtube
                        </label>
                        <Input placeholder="@" type="text" />
                      </section>
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Facebook
                        </label>
                        <Input placeholder="@" type="text" />
                      </section>
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Twitter
                        </label>
                        <Input placeholder="@" type="text" />
                      </section>
                    </div>

                    <div className="mb-[36px] grid grid-cols-1">
                      <section>
                        <label className="flex text-[18px] text-[#424242]">
                          Anything else you'd like to add?
                        </label>
                        <textarea
                          className="sofia-pro-m mt-[4px] w-full flex-shrink-0 rounded-[8px] border-[1px] border-[#C9C9C9] bg-white px-[12px] pt-[6px] pb-[8px] text-18 leading-[30px] text-gl-dark-blue placeholder:text-gl-dark-blue placeholder:opacity-50"
                          name="fullName"
                          placeholder="Optional"
                          rows={3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullName}
                        />
                      </section>
                    </div>

                    <button
                      disabled={isSubmitting}
                      onClick={() => handleSubmit()}
                      type="submit"
                      className="flex  h-[44px] w-full items-center justify-center rounded-8 border border-[#2589BD] bg-[#2589BD] py-[3px] px-[14px] leading-[1] text-white"
                    >
                      {!isSubmitting ? (
                        submit_button
                      ) : (
                        <div role="status" className="flex self-center">
                          <svg
                            aria-hidden="true"
                            className="mr-2 inline h-4 w-4 animate-spin fill-[#2589BD] text-gray-200 dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                    <p className="sofia-pro-r m-auto mt-[48px] max-w-[667px] text-center text-[#424B5B]">
                      {footer_info}
                    </p>
                  </div>
                )}
              </Formik>
            </section>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

const ThanksModal = ({ onClose }) => (
  <>
    <div className="z-200 fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
      <div className="relative my-6 mx-auto mx-[20px] w-auto max-w-[800px]">
        {/*content*/}
        <div className="shadow-lg relative flex w-full flex-col rounded-lg border-0 bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="relative flex items-start justify-between rounded-t border-slate-200 p-5">
            <button
              onClick={onClose}
              className="absolute right-[27px] top-[28px] flex h-[25px] w-[25px] items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
              >
                <path
                  d="M11.0068 8.92604C11.2032 9.12429 11.3133 9.39204 11.3133 9.67108C11.3133 9.95012 11.2032 10.2179 11.0068 10.4161C10.807 10.6094 10.5398 10.7174 10.2618 10.7174C9.98376 10.7174 9.71662 10.6094 9.51675 10.4161L6.0791 6.9654L2.64145 10.4161C2.44158 10.6094 2.17443 10.7174 1.8964 10.7174C1.61838 10.7174 1.35123 10.6094 1.15136 10.4161C0.955004 10.2179 0.844849 9.95012 0.844849 9.67108C0.844849 9.39204 0.955004 9.12429 1.15136 8.92604L4.60208 5.48839L1.15136 2.05074C0.984616 1.84757 0.899402 1.58964 0.912295 1.32712C0.925189 1.0646 1.03527 0.816265 1.22112 0.630413C1.40697 0.44456 1.65531 0.334481 1.91783 0.321588C2.18035 0.308695 2.43828 0.393909 2.64145 0.560655L6.0791 4.01137L9.51675 0.560655C9.71992 0.393909 9.97784 0.308695 10.2404 0.321588C10.5029 0.334481 10.7512 0.44456 10.9371 0.630413C11.1229 0.816265 11.233 1.0646 11.2459 1.32712C11.2588 1.58964 11.1736 1.84757 11.0068 2.05074L7.55611 5.48839L11.0068 8.92604Z"
                  fill="#15233C"
                />
              </svg>
            </button>
          </div>
          <section className="mb-[57px] px-[20px] text-center md:px-[37px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="m-auto"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
            >
              <path
                d="M61.25 35C61.25 20.5015 49.4985 8.75 35 8.75C20.5015 8.75 8.75 20.5015 8.75 35C8.75 49.4955 20.5015 61.25 35 61.25C49.4985 61.25 61.25 49.4955 61.25 35Z"
                stroke="#83B052"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.8943 35.0009L31.6313 41.7349L45.0996 28.2666"
                stroke="#83B052"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h3 className="sofia-pro-b mb-[10px] text-[30px] text-[#15233C] md:text-[44px]">
              Thanks for getting in touch!
            </h3>
            <p className="sofia-pro-r text-[16px] text-[#2589BD] md:text-[24px]">
              We'll get back you as soon as possible, generally within 24 hours!
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
  </>
);
let applicationSchema = object({
  fullName: string().required("Required"),
  email: string().email().required("Required"),
  instagram: string(),
  tiktok: string(),
  youtube: string(),
  facebook: string(),
  twitter: string()
});

type ApplicationFormProps = {
  blok: {
    title_first_part: string;
    title_second_part: string;
    footer_info: string;
    submit_button: string;
  };
};

const Input = (props: any) => (
  <input
    {...props}
    className="sofia-pro-m mt-[4px] w-full flex-shrink-0 rounded-[8px] border-[1px] border-[#C9C9C9] bg-white px-[12px] pt-[6px] pb-[8px] text-18 leading-[30px] text-gl-dark-blue placeholder:text-gl-dark-blue placeholder:opacity-50"
  />
);

const ApplicationForm = ({ blok }: ApplicationFormProps) => {
  const [modal, setModal] = useState("application");

  return (
    <section>
      {modal === "thank_you" && (
        <ThanksModal
          onClose={() => {
            setModal(null);
          }}
        />
      )}
      {modal === "application" && (
        <ApplicationModal
          blok={blok}
          onClose={() => {
            setModal(null);
          }}
        />
      )}
    </section>
  );
};

export default ApplicationForm;

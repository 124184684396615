import React from "react";
import { motion } from "framer-motion";

import { getScreenSize, useWindowUpdates } from "@utils/globalUtils";

import BackgroundImage from "./BackgroundImage";

const BackgroundMedia = ({
  data: { filename, media_type, blockRounded, media_mob }
}: BackgroundMediaProps) => {
  const { windowWidth } = useWindowUpdates();
  const { isMinDesktop } = getScreenSize(windowWidth);
  const backgroundMedia = {
    image: (
      <div className="flex h-full max-h-[900px] min-h-[480px] w-full lg:h-[75vh] xl:h-[92vh]">
        {isMinDesktop ? (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.8,
              duration: 0.4
            }}
            src={filename}
            className="h-full w-full object-cover"
          />
        ) : (
          <div className="relative mx-auto flex h-full max-h-[600px] min-h-[550px] w-full max-w-[120rem] overflow-hidden bg-[#f9f9f9] px-[18px]">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.8,
                duration: 0.4
              }}
              src={media_mob?.filename || filename}
              className="object-fit flex h-fit w-fit "
            />
            <div className="shadow-landing absolute bottom-0 h-[312px] w-full"></div>
          </div>
        )}
      </div>
    ),
    video: (
      <div
        className={`${blockRounded} relative flex min-h-[400px] w-full overflow-hidden pb-[44%]`}
      >
        <video
          loop
          muted
          autoPlay
          className="absolute top-[50%] left-[50%] h-full w-full translate-x-[-50%] translate-y-[-50%] object-cover"
        >
          <source src={filename} />
        </video>
      </div>
    )
  };
  return backgroundMedia[media_type];
};

export default BackgroundMedia;

type BackgroundMediaProps = {
  data: {
    filename: string;
    media_type: string;
    blockRounded: string;
    media_mob: { filename: string };
  };
};

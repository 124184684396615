import { Image } from "@components/UI";

const TitleTextWithBgImage = ({
  blok: {
    title,
    text,
    image: { filename },
    height,
    height_mobile,
    margin_bottom
  },
  data
}) => {
  return (
    <>
      <div
        className={`relative mx-auto flex h-full flex-col items-center justify-center min-h-[${height_mobile}px] w-full max-w-[120rem] px-[18px] lg:min-h-[${height}px] lg:px-[48px] xl:px-[48px] mb-[${margin_bottom}px] bg-partner`}
      >
        <div
          className={`min-h-full flex h-full w-full max-w-[81.5rem] flex-col  lg:max-h-[${height}px]`}
        >
          <div className="left-0 flex h-full w-full justify-center">
            <div
              className={`flex h-full w-full ${
                data?.isAboutPage
                  ? "items-start px-[18px] pt-[40px] lg:pt-[90px]"
                  : "max-w-[35rem] items-center lg:max-w-[45rem]"
              }`}
            >
              <div className="flex w-full flex-col">
                <div className="h-full overflow-hidden pt-[12px] lg:pt-[19px] lg:pb-[5px]">
                  <h2
                    className={`sofia-pro-b text-[32px] leading-[1.1] lg:text-[44px] ${
                      data?.isAboutPage ? "text-[#15233C]" : "text-white"
                    }  text-center`}
                  >
                    {title}
                  </h2>
                </div>
                <div
                  className={`h-full ${
                    !data?.isAboutPage && "pt-[9px] lg:pt-[15px]"
                  }`}
                >
                  <p
                    className={`${
                      data?.isAboutPage
                        ? "sofia-pro-b text-[32px] leading-[1.1] text-[#2589BD] lg:text-[44px]"
                        : "sofia-pro-r text-[22px] leading-[26px] text-white lg:text-[24px]"
                    } text-center`}
                  >
                    {text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-[430px] w-[100vw] select-none sm:h-[530px] sm:w-[540px] md:h-[630px] md:w-[930px]">
          <Image src={filename} layout="fill" objectFit="cover" />
        </div>
      </div>
    </>
  );
};

export default TitleTextWithBgImage;

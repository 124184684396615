import Image from "next/image";
import {
  MARK_BOLD,
  NODE_HEADING,
  render
} from "storyblok-rich-text-react-renderer";

import { LinkVariant } from "@components/UI";

const ImageWithAsideBlok = ({ blok }) => {
  const {
    before_title_text,
    title,
    sub_title,
    button_text,
    image: { alt, filename },
    button_link
  } = blok;
  const paragraphMaskRender = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <span className="sofia-pro-b text-gl-blue">{children}</span>;
      }
    },
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h3 className="sofia-pro-s h-fit text-[34px] leading-[1.1]">
            {children}
          </h3>
        );
      }
    }
  };
  return (
    <div className="relative mx-auto mt-[55px] mb-[60px]  w-full max-w-[120rem] flex-col rounded-8 px-[18px] lg:mb-[100px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto grid h-full min-h-[331px] w-full max-w-[81.5rem] grid-cols-1 items-center justify-items-end gap-4 rounded-8 bg-[#F5F9FA] max-sm:p-4 sm:grid-cols-2 sm:gap-11">
        <div className="flex h-fit w-[fit-content] flex-col gap-[20px] self-center">
          {render(blok?.title, paragraphMaskRender)}
          <LinkVariant
            linkType={"buttonLink"}
            href={button_link.cached_url || button_link.url}
            variant={"blue"}
            className={
              "flex h-fit w-[166px] items-center justify-center rounded-8 bg-[#2589BD] py-[10px] text-center text-white md:self-start"
            }
          >
            {button_text}
          </LinkVariant>
        </div>
        <div className="relative flex h-[23vh] w-full justify-end py-[15px]">
          <Image
            src={filename}
            alt={alt || "aside image"}
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  );
};
export default ImageWithAsideBlok;

import { isEmpty } from "lodash";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";

const ThreeGridSheet = ({
  blok: { items_list, title, subtitle, pictures_size = 55 },
  blok
}) => {
  if (isEmpty(items_list)) {
    return null;
  }
  return (
    <div
      className="mx-auto mb-[40px] w-full max-w-[120rem] px-[18px] lg:mb-[90px] lg:px-[48px] xl:px-[48px]"
      {...storyblokEditable(blok)}
    >
      {title && (
        <div className="mx-auto max-w-[81.5rem] rounded-tl-lg rounded-tr-lg bg-[#F1FAFF] pt-12 text-center md:pt-20">
          <h2
            className={`sofia-pro-b pb-3 text-[32px] leading-[1.1] lg:text-[44px]`}
          >
            {title}
          </h2>
          <h3
            className={`pb-[24px] pb-[48px] text-[22px] leading-[1.1] text-[#424B5B]`}
          >
            {subtitle}
          </h3>
        </div>
      )}
      <div
        className={`mx-auto flex w-full max-w-[81.5rem] flex-wrap justify-evenly gap-y-8 rounded-bl-lg rounded-br bg-[#F1FAFF] pb-12 md:pb-24`}
      >
        {items_list.map(item => {
          const { _uid, title, text, image } = item;
          return (
            <div
              key={_uid}
              className="flex w-full flex-col items-center px-2 text-center sm:w-1/2 lg:w-1/3"
              {...storyblokEditable(item)}
            >
              <h3 className="sofia-pro-s mb-2 text-[26px] leading-[1.1] text-[#2589BD] md:text-[34px]">
                {title}
              </h3>
              <p className="sofia-pro-r mb-5 text-[22px] text-gl-black">
                {text}
              </p>
              <div
                className={`relative`}
                style={{
                  height: `${pictures_size}px`,
                  width: `${pictures_size}px`
                }}
              >
                <Image
                  src={image?.filename}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThreeGridSheet;

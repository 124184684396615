import React from "react";
import Link from "next/link";
import { motion } from "framer-motion";

const ButtonLink = ({
  href,
  children,
  className,
  withHover = false,
  variant = "white"
}) => {
  const variants = {
    white: `bg-white `,
    black: `tbg-black`,
    blue: `bg-gl-blue`
  };
  return (
    <Link passHref href={href}>
      <motion.a
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.4,
          duration: 0.4
        }}
        className={` ${className} ${variants[variant]} `}
      >
        {children}
      </motion.a>
    </Link>
  );
};

export default ButtonLink;

import React, { useEffect } from "react";

export default function SiteReviews() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // @ts-ignore
      if (window.yotpoWidgetsContainer && window.yotpoWidgetsContainer.initWidgets)
        // @ts-ignore
        window.yotpoWidgetsContainer?.initWidgets();
    }
  }, []);

  return (
    <div className="yotpo-widget-instance" data-yotpo-instance-id="822866" />
  );
}

import Image from "next/image";
import Link from "next/link";
import { Button } from "@components/UI";

const LandingWithAsideImg = ({
  blok: { image, button_text = "Sign up", title, subtitle },
  setModal
}) => {
  return (
    <div className="relative mx-auto flex w-full max-w-[120rem] !overflow-visible">
      <div className="mx-auto grid w-full grid-cols-1 justify-center align-middle md:grid-cols-2">
        <div className="order-2 flex h-[31vh] items-center bg-[#F5F9FA] px-[18px] lg:h-[520px]">
          <div className="ml-auto flex w-full justify-center md:justify-start lg:max-w-[37.8rem]">
            <div className="flex h-fit flex-col text-center md:text-left">
              {title && (
                <div className="mb-[36px]">
                  <h3 className="sofia-pro-b text-[32px] leading-[1] lg:text-[44px]">
                    {title}
                  </h3>
                </div>
              )}
              {subtitle && (
                <div className="mb-[20px] flex max-w-[28rem]">
                  <p className="sofia-pro-r text-[22px] leading-[1.2] lg:text-[24px]">
                    {subtitle}
                  </p>
                </div>
              )}
              {button_text && (
                <a
                  target="_blank" 
                  href={"http://goodlaundry.com/partner-signup"}
                  className="sofia-pro-m false flex flex w-[166px] select-none items-center justify-center justify-center self-center rounded-8 bg-[#2589BD] py-[10px] text-center  text-center text-white hover:bg-dark md:self-start"
                >
                  {button_text}
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="relative order-1  h-[31vh] md:order-2 lg:h-[520px]">
          <Image
            src={`${image.filename}` + "/m/"}
            alt="Try background"
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  );
};
export default LandingWithAsideImg;

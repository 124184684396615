export const getIsInternalLink = (link: string) => {
  const regex = /.*(\.).-*/;
  const isExternalLink = regex.test(link);
  // examples:
  // external.link // true
  // www.external.link // true
  // https://external.link // true
  // /path // false
  // /path/path // false
  return !isExternalLink;
};

export const getLinkKey = isInternalLink => {
  enum BooleanLinkKeys {
    true = "internal",
    false = "external"
  }
  return BooleanLinkKeys[isInternalLink];
};

import React, { useContext, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import { ShopContext } from "@context/shopContext";
import SubscriptionProcess from "./SubscriptionProcess";
import { ShopifyCartLineDAO } from "@services/ShopifyCartLineDAO";

const useParseLineItemValues = cartLineDAO => {
  const discount = cartLineDAO.cartLine.discountAllocations
    .reduce((b, a) => Number(a.discountedAmount.amount) + b, 0)
    .toFixed(2);
  const initialValue =
    cartLineDAO.cartLine.quantity *
    Number(cartLineDAO.cartLine.merchandise.priceV2.amount);
  const withDiscount = (initialValue - discount).toFixed(2);
  return { discount, initialValue: initialValue.toFixed(2), withDiscount };
};

const CartLine = ({
  isBundle,
  data: { cartLineData, isScentForAddons, setDataToFilterAddons }
}: CartLineProps) => {
  const {
    cartLoading,
    setCartOpen,
    cartCurrency,
    removeCartItem,
    changeQuantity
  } = useContext(ShopContext);
  const cartLineDAO = new ShopifyCartLineDAO(cartLineData, cartCurrency);
  const { initialValue, withDiscount } = useParseLineItemValues(cartLineDAO);

  useEffect(() => {
    isScentForAddons &&
      setDataToFilterAddons(cartLineDAO.firstTagValueAndHandle);
  }, []);

  return (
    <li className="flex flex-col border-b border-gray-200 py-[6px] sm:px-[12px]">
      <div className="flex items-center">
        <div className="flex w-full  items-center">
          <div
            className={`absolute top-0 left-0 right-0 h-full ${
              cartLoading
                ? "animate-pulse rounded-8 bg-[#f0f0f0] opacity-10"
                : "hidden"
            }`}
          />
          <div className="relative h-24 w-24 flex-shrink-0 overflow-hidden">
            <Image
              src={cartLineDAO.image.url}
              alt={cartLineDAO.image.alt || cartLineDAO.title}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="ml-2 flex flex-1 flex-col self-start sm:ml-[20px]">
            <div className="text-base flex flex-col justify-between font-medium text-gray-900 sm:flex-row">
              <div className="flex flex-col justify-center sm:max-w-[220px]">
                <h3>
                  <Link href={`/products/${cartLineDAO.handle}`} passHref>
                    <a
                      className="sofia-pro-b text-[17px] leading-[1.2] transition-all hover:opacity-70"
                      onClick={() => setCartOpen(false)}
                    >
                      {cartLineDAO.title}{" "}
                      {isBundle && (
                        <span className="rounded-full bg-[#EEF8FF] py-1 px-2 text-[10px] text-[#3B668F]">
                          Bundled!
                        </span>
                      )}
                    </a>
                  </Link>
                </h3>

                <p className="sofia-pro-b text-[15px] text-[#6C6C6C]">
                  {cartLineDAO.optionName !== "Default Title" &&
                    cartLineDAO.optionName}
                </p>
                {!!cartLineDAO.attributeString && (
                  <p className="text-xsm text-gray-400">
                    {cartLineDAO.attributeString}
                  </p>
                )}
                <SubscriptionProcess {...{ cartLineDAO }} />
              </div>
              <div className="flex items-center justify-center sm:flex-col sm:text-right md:items-end">
                <div className="sm:ml-auto">
                  <div className="flex items-center whitespace-nowrap">
                    {withDiscount && withDiscount !== initialValue ? (
                      <span className="sofia-pro-m leading-[1] text-[16] text-[#15233C]/50">
                        <span className="text-[14px] line-through">
                          ${Number(initialValue).toFixed(2)}
                        </span>{" "}
                        <span className="text-[#2589BD]">${withDiscount}</span>
                      </span>
                    ) : cartLineDAO.selectedSellingPlanId ? (
                      <span className="sofia-pro-m leading-[1] text-[16] text-[#15233C]/50">
                        <span className="text-[14px] line-through">
                          ${Number(initialValue).toFixed(2)}
                        </span>{" "}
                        <span className="text-[#2589BD]">
                          ${(Number(initialValue) * 0.8).toFixed(2)}
                        </span>
                      </span>
                    ) : (
                      <span className="sofia-pro-m leading-[1] text-[16]">
                        ${Number(initialValue).toFixed(2)}
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() =>
                      removeCartItem(cartLineDAO.cartLineDataForActions)
                    }
                    type="button"
                    className="text-[12px] text-gl-black underline opacity-50 hover:text-gray-800 sm:mt-1.5"
                    disabled={cartLoading}
                  >
                    Remove
                  </button>
                </div>
                <div className="ml-auto text-right">
                  <div
                    className={
                      "mt-1 flex items-center rounded-full border sm:mt-4"
                    }
                  >
                    <button
                      className="px-2 pb-1 transition-all hover:opacity-30"
                      onClick={() =>
                        changeQuantity(
                          cartLineDAO.cartLineDataForActions,
                          "decrement"
                        )
                      }
                      disabled={cartLoading}
                    >
                      -
                    </button>
                    <span className="border-l border-r px-2">
                      {cartLineDAO.quantity}
                    </span>
                    <button
                      className="px-2 pb-1 transition-all hover:opacity-30"
                      onClick={() =>
                        changeQuantity(
                          cartLineDAO.cartLineDataForActions,
                          "increment"
                        )
                      }
                      disabled={cartLoading}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-sm mt-[4px] flex flex-1 items-end justify-between"></div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartLine;

type AttributeType = {
  key: string;
  value: string;
};
type CartLineProps = {
  isBundle: boolean;
  data: {
    cartLineData: {
      merchandise: {
        id: string;
        title: string;
        image: {
          url: string;
        };
        priceV2: {
          amount: string;
          currencyCode: string;
        };
        product: {
          handle: string;
          title: string;
        };
      };
      quantity: any;
      sellingPlanAllocation: {
        sellingPlan: {
          name: string;
        };
      };
      attributes: [AttributeType];
    };
    isScentForAddons: boolean;
    setDataToFilterAddons: (productDataForFilter: {}) => {};
  };
};

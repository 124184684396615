import {
  MARK_BOLD,
  NODE_UL,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_LI,
  render
} from "storyblok-rich-text-react-renderer";

const paragraphMaskRender = {
  markResolvers: {
    [MARK_BOLD]: children => {
      return <span className="sofia-pro-b">{children}</span>;
    }
  },
  nodeResolvers: {
    [NODE_LI]: children => {
      return <li className={`checked-list-item mb-[5px]`}>{children}</li>;
    },
    [NODE_UL]: children => {
      return <ul className="list-outside list-disc">{children}</ul>;
    },
    [NODE_PARAGRAPH]: children => {
      return (
        <p className="sofia-pro-r mb-[20px] text-[17px] leading-[36px] text-[#424B5B] md:text-[28px] lg:text-[28px]">
          {children}
        </p>
      );
    },

    [NODE_HEADING]: (children, { level }) => {
      console.log(level, "level");
      switch (level) {
        case 1:
          return (
            <h1 className="sofia-pro-b line-through-[1.1] mt-[30px] text-[28px] text-white lg:mt-[90px] lg:text-[44px]">
              {children}
            </h1>
          );
        case 3:
          return (
            <h3 className="sofia-pro-s line-through-[1] lg:line-through-[1.1]  text-[30px] text-[#15233C] lg:text-[34px]">
              {children}
            </h3>
          );
        case 4:
          return (
            <h4 className="sofia-pro-r line-through-[1] lg:line-through-[1.1] leadin-[28px] max-w-[36rem] text-[20px] lg:text-[24px]">
              {children}
            </h4>
          );
        default:
          <div>{children}</div>;
      }
    }
  }
};

const WantedGoodPeople = ({ blok }) => {
  return (
    <div className="m-auto max-w-[700px] px-[18px] pt-[123px] pb-[100px] text-center">
      <div className="m-auto mb-[15px] h-[91px] w-[91px] overflow-hidden rounded-full">
        <img src={blok.image} />
      </div>
      <h3 className="sofia-pro-b mb-[14px] flex justify-center sm:text-[25px] md:text-[35px] lg:text-[44px]">
        {blok.title_part_one && (
          <span className="text-[#2589BD]">{blok.title_part_one} </span>
        )}
        {blok.title_part_two && (
          <span className="relative ml-[5px] text-[#15233C]">
            {blok.title_part_two}
            <svg
              width="262"
              height="7"
              viewBox="0 0 262 7"
              className="absolute bottom-[1px] w-full"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.416016 3.70173C20.6232 3.46013 30.6599 4.13779 40.5628 4.7801C46.1833 5.12188 51.6701 5.49901 62.3759 5.48133C95.2963 5.41651 91.0137 3.04174 123.131 2.77657C139.056 2.64104 149.896 3.22442 157.524 3.73119C169.969 4.52082 186.697 5.39884 211.053 5.09241C216.835 5.01864 222.241 4.89661 226.978 4.73296C233.408 4.50247 238.847 4.22305 243.036 3.90798C249.728 3.44834 255.482 2.96514 261.236 2.48193"
                stroke="#2589BD"
                stroke-width="3"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        )}
      </h3>
      <p>{render(blok?.paragraph, paragraphMaskRender)}</p>
    </div>
  );
};

export default WantedGoodPeople;

import React from "react";

type SelectProps = {
  optionVariants: [
    {
      node: {
        name: string;
        id: string;
      };
    }
  ];
  onChange: (e: any) => void;
  value: string;
};

const Select = ({ optionVariants, onChange, value }: SelectProps) => {
  const optionVariantsNodes = optionVariants.map(({ node }) => node);
  const optionsItems = optionVariantsNodes.map(({ name, id }) => (
    <option key={id} value={id}>
      {name}
    </option>
  ));
  return (
    <select
      className="mb-3 mt-3 flex w-full flex-col rounded-8 border border-[#D7E1E4] py-3 px-3.5"
      value={value}
      onChange={onChange}
    >
      <option value="">Just once</option>
      {optionsItems}
    </select>
  );
};
export default Select;

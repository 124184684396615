import { render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

export default function Paragraph({ blok }) {
  return (
    <>
      <div
        className="sofia-pro-r text-[22px] leading-[1.2] text-[#424B5B]"
        {...storyblokEditable(blok)}
      >
        {render(blok.paragraph)}
      </div>
    </>
  );
}

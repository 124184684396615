import Image from "next/image";

export default function NavMobileBox({ blok }) {
  return (
    <>
      <div className="flex w-[109px]">
        <a href={blok?.link?.cached_url}>
          <div className="flex h-[109px] w-full justify-center overflow-hidden rounded-[5px] bg-gl_light_grey align-middle">
            <Image
              src={`${blok.image.filename}` + "/m/"}
              width={109}
              height={95}
              quality={100}
              className="h-full w-full object-cover"
            />
          </div>
          <div className="mx-auto mt-[6px] flex w-[109px] justify-center text-center">
            <h4 className="sofia-pro-r max-w-[80px] text-center text-[14px] leading-[1.1]">
              {blok?.link_text}
            </h4>
          </div>
        </a>
      </div>
    </>
  );
}

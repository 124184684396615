import { motion } from "framer-motion";
import { storyblokEditable } from "@storyblok/react";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    fetchpriority?: string;
  }
}

export default function Landing({ blok }) {
  return (
    <>
      <div className="mx-auto mb-[60px] flex h-full w-full max-w-[120rem] flex-col overflow-hidden lg:mb-[72px]">
        <div className="relative mx-auto flex h-full w-full max-w-full flex-col ">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.3,
              duration: 0.4
            }}
            className="absolute top-0 flex h-full w-full"
          >
            <img
              fetchpriority="high"
              src={`${blok?.image?.filename}` + "/m/"}
              className="flex h-full w-full object-cover"
              {...storyblokEditable(blok)}
            />
          </motion.div>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              delay: 0.6,
              duration: 0.4
            }}
            className="flex flex-col px-[18px] lg:px-[48px] xl:px-[48px]"
          >
            <div className="z-10 mx-auto flex h-[40vh] max-h-[600px] w-full max-w-[38rem] flex-col items-center justify-center text-center lg:h-[75vh]">
              <div className="mt-[15px] flex flex-col gap-[15px]">
                <h3
                  className="sofia-pro-b px-[1.2rem] text-[40px] leading-[1.1] lg:px-0 lg:text-[44px]"
                  {...storyblokEditable(blok)}
                >
                  {blok.title}
                </h3>
                <p
                  className="sofia-pro-r text-[22px] leading-[1.2] lg:text-[24px]"
                  {...storyblokEditable(blok)}
                >
                  {blok.paragraph}
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

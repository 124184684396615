import Image from "next/image";

export const CompanyItem = ({ customBlok: { image, background_color } }) => {
  return (
    <div className="keen-slider__slide flex w-full justify-center overflow-hidden rounded-8">
      <div
        style={{
          backgroundColor: background_color.color
        }}
        className="flex h-fit w-full flex-shrink-0 flex-col justify-center rounded-8 px-2 py-[20px] text-center"
      >
        <div className="relative mx-auto my-0 h-full min-h-[100px] w-full max-w-[80%] object-contain mix-blend-darken sm:max-w-[45%]">
          {image?.filename && (
            <Image src={image?.filename} layout="fill" objectFit="contain" />
          )}
        </div>
      </div>
    </div>
  );
};

export default function Try() {
  return (
    <>
      <div className="mx-auto flex w-full max-w-[120rem]">
        <div className="relative mx-auto flex h-full w-full flex-col overflow-hidden bg-[#f0f0f2]">
          <div className="mt-[200px] min-h-[400px] lg:mt-0 lg:h-full">
            <img
              src="/img_4313.png"
              className="lg:object-fit hidden h-full min-h-[400px] w-full object-cover lg:flex"
            />
            <img
              src="/img_200_mobile.png"
              className="flex h-full min-h-[400px] w-full object-cover lg:hidden"
            />
          </div>
          <div className="absolute mx-auto w-full overflow-hidden px-[16px] pt-[35px] pb-[95px] lg:pt-[150px]">
            <div className="flex h-full w-full justify-center text-center align-middle">
              <div className="mx-auto flex h-full w-full max-w-[30rem] flex-col items-center justify-center space-y-[12px] align-middle">
                <div className="h-[122px] w-[122px]">
                  <img src="/img_641.png" className="flex h-full w-full" />
                </div>
                <h3 className="sofia-pro-b max-w-[20rem] text-[34px] leading-[1] lg:max-w-full">
                  Try us for 30 days. Risk free.
                </h3>
                <p className="sofia-pro-r text-[20px] leading-[1.2] text-[#15233C]">
                  Shipping over $50 is on us. <br />
                  If it's not for you, it's all good!
                </p>
                <div className="pt-[12px]">
                  <button className="sofia-pro-m w-[190px] rounded-8 bg-[#2589BD] px-[12px] py-[14px] text-[18px] leading-[1] text-white">
                    Do Good Laundry
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";
import { motion } from "framer-motion";
import {
  MARK_BOLD,
  MARK_UNDERLINE,
  NODE_QUOTE,
  render
} from "storyblok-rich-text-react-renderer";
import { useMediaQuery } from "@hooks/index";
import { Number } from "@components/bloks/Impact/ Number/ Number";

export default function Impact({ rest, blok }) {
  const matches = useMediaQuery(`(min-width: ${1024}px)`);
  const widest = useMediaQuery(`(min-width: ${1940}px)`);
  const full_width = blok?.full_width || false;

  const richtextOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <b className="relative ml-2 text-[#2589BD]">{children}</b>;
      },
      [MARK_UNDERLINE]: children => {
        return (
          <span className="text-underline-blue relative ml-2">{children}</span>
        );
      }
    },
    nodeResolvers: {
      [NODE_QUOTE]: children => {
        return <div className="text-white">{children}</div>;
      }
    }
  };
  const blockHeight = blok?.block_height || "584";
  const mobileHeight = blok?.mobile_height || "300";

  return (
    <div
      className={`relative mx-auto flex w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]`}
      style={{
        height: matches ? `${blockHeight}px` : `${mobileHeight}px`,
        marginBottom: blok.margin_bottom ? `${blok.margin_bottom}px` : "60px"
      }}
    >
      <div
        className={`mx-auto flex w-full max-w-[81.5rem] justify-center overflow-hidden align-middle ${
          full_width ? "rounded-8" : ""
        }`}
      >
        <div
          className="flex h-fit w-full flex-col overflow-hidden object-cover "
          style={{
            border: "transparent",
            borderRadius: full_width ? (widest ? "8px" : "") : "8px"
          }}
        >
          {blok.is_video ? (
            <video
              autoPlay
              muted
              loop
              className="outine-none h-full w-full select-none overflow-hidden"
            >
              <source
                src={blok?.video?.filename}
                className="flex h-full min-h-[290px] w-full"
              />
            </video>
          ) : (
            <div
              className={`object-cover ${!full_width ? "relative" : ""} `}
              style={{
                height: matches ? `${blockHeight}px` : `${mobileHeight}px`
              }}
            >
              <Image
                src={`${blok?.image?.filename}` + "/m/"}
                layout="fill"
                objectFit="cover"
                style={{
                  border: "transparent",
                  borderRadius: full_width ? (widest ? "8px" : "") : "8px"
                }}
              />
            </div>
          )}
        </div>
        <div className="absolute mx-auto flex h-full w-full flex-col items-center justify-around pt-0 text-center align-middle lg:pt-[15px]">
          {(blok.logo_image.filename || blok?.first_title) && (
            <div className="flex flex-col items-center lg:mt-[-20px]">
              {blok.logo_image.filename && (
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                    delay: 0.6,
                    duration: 0.4
                  }}
                  className=" relative h-[88px] w-[88px]"
                >
                  <Image
                    src={blok?.logo_image?.filename}
                    layout="fill"
                    objectFit="cover"
                  />
                </motion.div>
              )}
              {(blok?.first_title || blok.first_paragraph) && (
                <div className="mt-[15px] flex flex-col gap-[15px]">
                  {blok?.first_title && (
                    <motion.h1
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                        delay: 0.8,
                        duration: 0.4
                      }}
                      className="sofia-pro-b text-[34px] leading-[1.1] lg:text-[44px]"
                    >
                      {render(blok?.first_title, richtextOptions)}
                    </motion.h1>
                  )}
                  {blok?.first_paragraph && (
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                        delay: 0.9,
                        duration: 0.4
                      }}
                      className="sofia-pro-r w-full max-w-[35rem] px-[18px] text-[18px] leading-[1.1] lg:px-[48px] lg:text-[24px]"
                    >
                      {render(blok?.first_paragraph, richtextOptions)}
                    </motion.div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="w-full">
            {blok?.first_title_number && (
              <motion.h4
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  delay: 0.9,
                  duration: 0.4
                }}
                className="sofia-pro-s mt-4 mb-8 w-full text-[26px] leading-[1.1] lg:text-[34px]"
              >
                {render(blok?.first_title_number, richtextOptions)}
              </motion.h4>
            )}
            <div className="mx-auto flex w-full max-w-2xl flex-wrap items-start justify-around gap-y-2 lg:max-w-5xl">
              {blok.number && (
                <div className="w-full sm:w-1/2">
                  <Number
                    paragraph={blok.paragraph}
                    number={blok.number}
                    rest={rest}
                    blok={blok}
                    matches={matches}
                    before_number_sign={blok.before_number_sign}
                  />
                </div>
              )}
              {blok.number_2 && (
                <div className="w-full border-l border-[#2589BD] sm:w-1/2">
                  <Number
                    paragraph={blok.paragraph_2}
                    number={blok.number_2}
                    rest={rest}
                    blok={blok}
                    matches={matches}
                    before_number_sign={blok.before_number_sign_2}
                  />
                </div>
              )}
            </div>
          </div>
          {blok.link.cached_url && blok.link_text && (
            <div className="mt-[30px] flex w-full justify-center">
              <a
                href={blok.link.cached_url}
                className="sofia-pro-m rounded-8 bg-white px-8 py-[14px] text-18 leading-[1] transition-all hover:bg-[#b5d7ed]"
                {...storyblokEditable(blok)}
              >
                {blok.link_text}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import { useRouter } from "next/router";
import Image from "next/image";
import cn from "classnames";
import Link from "next/link";
import { ShopifyCollectionService } from "@services/ShopifyCollectionService";

import ProductStars from "@components/Product/ProductStars/ProductStars";
import { ShopifyProductService } from "@services/ShopifyProductService";
import { ShopifyProductDAO } from "@services/ShopifyProductDAO";
import { ShopContext } from "@context/shopContext";
import { addedToCartFBEvent } from "@utils/pixel";
import { addToCartGAEvent } from "@utils/ga";

const shopifyCollectionService = new ShopifyCollectionService();

const AdditionalProduct = ({ blok }) => {
  const relatedProductData = blok?.related_product[0]?.products[0];
  const router = useRouter();
  const isProductPage = router.pathname.includes("product");
  const { content } = relatedProductData;
  const mainImage = content?.product_images_container[0]?.main_images[0]?.image;
  const cardProductImage = content?.card_product_image;
  const [currentProduct, setCurrentProduct] = useState(null);
  const [productDAO, setProductDAO] = useState(null);

  const { cartCurrency, addManyToToCart } = useContext(ShopContext);
  const shopifyProductService = new ShopifyProductService();

  const getProductInfo = async () => {
    return await shopifyProductService.getProductByHandle(
      relatedProductData.slug
    );
  };

  useEffect(() => {
    if (cartCurrency && relatedProductData) {
      getProductInfo()
        .then(product => {
          const newProductDAO = new ShopifyProductDAO(product, cartCurrency);
          setCurrentProduct({
            ...newProductDAO.firstVariant,
            variantsSku: newProductDAO?.variantsSku?.join(";")
          });
          setProductDAO(newProductDAO);
        })
        .catch(error => console.log(error, "error"));
    }
  }, [relatedProductData, cartCurrency]);

  return (
    <div
      className="keen-slider__slide flex flex-col rounded-lg p-2 transition-all hover:shadow-[0_4px_20px_0px_rgba(0,0,0,0.1)]"
      style={{ maxWidth: 300, minWidth: 300 }}>
      <Link
        href={
          isProductPage
            ? relatedProductData.slug
            : `products/${relatedProductData.slug}`
        }>
        <a>
          <div className="relative flex h-[315px] w-full max-w-[305px] justify-center overflow-hidden !rounded-8 bg-gl_light_grey py-[10%] align-middle">
            <Image
              src={cardProductImage?.filename || mainImage?.filename}
              objectFit="contain"
              height={240}
              width={200}
              {...storyblokEditable(blok)}
            />
          </div>
          <h2
            className="sofia-pro-b ellipsis-2 mt-[10px] text-left text-[22px]"
            {...storyblokEditable(blok)}>
            {relatedProductData.name}
          </h2>
          {currentProduct && (
            <h2 className="sofia-pro-b text-left text-[22px] text-[#83B052]">
              ${currentProduct.variantPrice}{" "}
              <span
                className={cn({
                  "sofia-pro-r text-[#15233C]/50 line-through":
                    currentProduct.compareAtPrice
                })}>
                {currentProduct.compareAtPrice &&
                  `$${currentProduct.compareAtPrice}`}
              </span>
            </h2>
          )}
          <div className="mt-3 text-left">
            {blok?.product_id && (
              <ProductStars productId={currentProduct?.productId} />
            )}
          </div>
          <div className="sofia-pro-r mt-4 rounded bg-[#679657]/10 py-2 px-5 text-left text-[18px]">
            <h4 className="sofia-pro-b text-[#679657]">
              Subscribe and save 20%
            </h4>
            📦 Free shipping
          </div>
        </a>
      </Link>
      <div className="mt-[15px] flex h-full w-full">
        <a
          onClick={() => {
            shopifyCollectionService
              .getCollectionByHandle(productDAO.handle)
              .then(c => {
                c.products.edges;
                const lineItems = c.products.edges.map(c => {
                  return {
                    id: c.node.variants.edges[0].node.id,
                    quantity: 1,
                    sellingPlanId: null
                  };
                });
                addedToCartFBEvent(productDAO);
                addToCartGAEvent(productDAO);
                addManyToToCart(lineItems);
              });
          }}
          className="sofia-pro-m flex h-max w-full cursor-pointer justify-center rounded-8 bg-gl-blue py-2 px-[20px] text-center text-white transition-all hover:bg-[#07496c]"
          {...storyblokEditable(blok)}>
          {blok.link_text}
        </a>
      </div>
    </div>
  );
};
export default AdditionalProduct;

import Image from "next/image";

const PaymentMethods = ({ additionClasses = "", paymentMethodsList = [] }) => (
  <div className={`flex flex-row flex-wrap gap-[13px] ${additionClasses}`}>
    {paymentMethodsList.map(({ image, _uid }) => {
      return (
        <div key={_uid} className="relative">
          <Image
            src={image?.filename}
            width={40}
            height={30}
            objectFit="contain"
          />
        </div>
      );
    })}
    <div></div>
  </div>
);
export default PaymentMethods;

import { isEmpty } from "lodash";
import Image from "next/image";

import { Link } from "@components/UI";

const BetterSection = ({ blok }) => {
  if (isEmpty(blok?.items_list)) {
    return null;
  }
  const {
    title,
    subtitle,
    background_image: { filename, alt },
    button,
    button_text,
    items_list
  } = blok;
  return (
    <>
      <div className="relative mx-auto mb-[90px] flex h-full w-full max-w-[120rem] lg:mb-[100px]">
        <div className="mx-auto flex min-h-[600px] w-full align-middle">
          <div className="absolute top-0 flex h-full w-full">
            <Image src={filename} alt={alt} layout="fill" objectFit="cover" />
          </div>
          <div className="z-10 mx-auto mb-auto flex flex-col justify-center overflow-hidden px-4 pt-[32px] pb-[40px] text-center align-middle lg:py-[85px]">
            <div className="flex h-full w-full flex-col">
              <h3 className="sofia-pro-b text-[32px] lg:text-[34px]">
                {title}
              </h3>
              <p className="sofia-pro-r text-[20px]">{subtitle}</p>
            </div>
            <div className="mx-auto grid w-full grid-cols-1 justify-between gap-[23px] pt-[23px] md:grid-cols-3 lg:grid-cols-3 lg:gap-[80px] lg:pt-[50px]">
              {items_list.map(({ _uid, title, text, svg_image }) => {
                return (
                  <div
                    key={_uid}
                    className="flex flex-col justify-start py-2 text-center"
                  >
                    {svg_image && (
                      <div
                        className="flex w-full justify-center"
                        dangerouslySetInnerHTML={{ __html: svg_image }}
                      ></div>
                    )}
                    <div className="mt-[8px] lg:mt-[24px]">
                      <h3 className="sofia-pro-b text-[20px] lg:text-[22px]">
                        {title}
                      </h3>
                    </div>
                    <div className="mx-auto mt-[10px] flex max-w-[22rem]">
                      <p className="sofia-pro-r text-18 leading-[21px] text-gl-black">
                        {text}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex w-full justify-center pt-[50px]">
              <Link
                linkType="buttonLink"
                variant={null}
                href={button.cached_url || button.url}
                className="sofia-pro-m flex w-[190px] justify-center rounded-8 bg-gl-blue py-3.5 text-center align-middle text-18 leading-[1] text-white"
              >
                {button_text}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetterSection;

import React, { useContext, useEffect } from "react";
import { ShopContext } from "@context/shopContext";

export default function NavCart({ blok }) {
  const {
    cartOpen = false,
    setCartOpen,
    totalCartItemsNumber
  } = useContext(ShopContext);

  useEffect(() => {
    const chatElement: HTMLElement = document.getElementById(
      "gorgias-chat-container"
    );
    if (chatElement) chatElement.style.display = cartOpen ? "none" : "block";
  }, [cartOpen]);

  const openMiniCart = () => {
    // @ts-ignore
    if (window?.GorgiasChat && window?.GorgiasChat.isOpen()) {
      // @ts-ignore
      window.GorgiasChat.close();
    }
    setCartOpen(!cartOpen);
  };

  return (
    <button
      onClick={openMiniCart}
      className="relative flex max-h-[26px] items-center pl-[14px]"
    >
      <img src={blok.svg.filename} />
      <div className="bg-cart-icon text- absolute right-[-10px] top-[-5px] h-4 w-4 rounded-full bg-gl-dark-blue text-[10px] text-white">
        {totalCartItemsNumber}
      </div>
    </button>
  );
}

import { LinkVariant } from "@components/UI";
import { isEmpty } from "lodash";

const FooterNavyBlock = ({ blok: { links_list, title } }) => {
  if (isEmpty(links_list)) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-[25px] border-t-[0.5px] border-[#D7F2F8] pt-[24px] pb-[42px] lg:space-y-[15px] lg:border-t-0 lg:pt-0 lg:pb-0">
      <h3 className="sofia-pro-l text-[24px] leading-[1] text-white">
        {title}
      </h3>
      <div className="flex flex-col space-y-[15px]">
        {links_list.map(linkItem => (
          <LinkVariant
            key={linkItem._uid}
            href={linkItem.link?.url || `/${linkItem.link.cached_url}`}
            linkType={"internal"}
            className={"sofia-pro-l text-18 leading-[1] text-[#EDF2F4]"}
          >
            {linkItem.link_text}
          </LinkVariant>
        ))}
      </div>
    </div>
  );
};
export default FooterNavyBlock;

import DynamicComponent from "@services/DynamicComponentsRenderer";
import { storyblokEditable } from "@storyblok/react";

export default function Showcase({ blok }) {
  return (
    <div className="mx-auto mb-[50px] mt-[32px] flex w-full max-w-[120rem] px-[18px] lg:mb-[100px] lg:mt-[75px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col">
        <div className="mb-[22px]">
          <h3
            className="sofia-pro-b text-[30px] text-[#15233C] lg:text-[34px]"
            {...storyblokEditable(blok)}
          >
            {blok.title}
          </h3>
        </div>
        <div className="grid h-full grid-cols-1 space-x-0 lg:grid-cols-2 lg:space-x-[20px]">
          {blok?.left_side?.map(blok => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))}
          <div className="flex h-full w-full">
            <div className="flex h-fit flex-col">
              {blok?.right_side?.map(blok => (
                <DynamicComponent blok={blok} key={blok._uid} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import cn from "classnames";

const RadioInput = ({ children, classList = "", value, checked, onChange }) => (
  <label
    className={cn(
      "relative flex w-full cursor-pointer select-none flex-row items-start rounded-8 border border-[#D7E1E4] py-[10px] px-[10px] transition-all hover:border-blue-500",
      classList,
      { "border-blue-500": checked }
    )}
  >
    <input
      type="radio"
      value={value}
      checked={checked}
      className="l-[-50px] invisible absolute"
      onChange={onChange}
    />
    <div className="relative z-[1] mr-[10px] flex h-[28px] w-[28px] flex-shrink-0 flex-col items-center justify-center rounded-full border border-[#D7E1E4]">
      {checked && (
        <>
          <img
            alt=""
            src="/checkInBlueRound.svg"
            className="absolute left-1/2 top-1/2 z-[2] h-[80%] w-[80%] -translate-x-1/2 -translate-y-1/2"
          />
        </>
      )}
    </div>
    {children}
  </label>
);
export default RadioInput;

/* eslint-disable react/jsx-key */
/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";

export default function Article({ article }) {
  return (
    <>
      <a
        href={`${article.full_slug}`}
        className="flex h-full w-fit flex-shrink-0"
        {...storyblokEditable(article)}
      >
        <div className="relative flex h-fit max-h-full w-fit max-w-[300px] flex-shrink-0 flex-col lg:max-w-[420px]">
          <div className="flex h-fit w-full">
            <Image
              width="420"
              height="300"
              src={`${article?.thumbnail_image?.filename}` + "/m/"}
              alt=""
              className="flex h-full w-full rounded-8 object-cover"
            />
          </div>
          <div className="mt-[10px] flex w-full flex-col items-start justify-start">
            <div>
              {article?.tag_list?.map(tag => (
                <div
                  key={`${tag}`}
                  className="mt-[4px] mb-[14px] h-[26px] justify-center rounded-4 bg-[#F5F9FF] px-[12px] text-center"
                >
                  <span className="sofia-pro-s text-[12px] leading-[1]">
                    {tag}
                  </span>
                </div>
              ))}
            </div>
            <h1 className="sofia-pro-s text-[22px] leading-[1]">
              {article?.title}
            </h1>
          </div>
        </div>
      </a>
    </>
  );
}

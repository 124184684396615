import { motion } from "framer-motion";
import { render } from "storyblok-rich-text-react-renderer";

export default function Header({ blok }) {
  return (
    <>
      <div className="relative mx-auto mb-[40px] flex h-full w-full max-w-[120rem] flex-col overflow-hidden bg-[#F7F7F7] lg:mb-[70px]">
        <div className="mx-auto flex min-h-[250px] w-full max-w-[81.5rem] flex-col px-[18px] lg:min-h-[300px] lg:px-[48px] xl:px-[48px]">
          <div className="my-auto flex h-full w-full items-center justify-center align-top">
            <div className="z-10 mx-auto flex w-full max-w-[36rem] flex-col items-center justify-center text-center">
              <div className="mt-[25px] flex flex-col gap-[15px]">
                <motion.h3
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                    delay: 0.8,
                    duration: 0.4
                  }}
                  className="sofia-pro-b text-[42px] leading-[1.1] lg:text-[44px]"
                >
                  {blok.title}
                </motion.h3>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                    delay: 0.9,
                    duration: 0.4
                  }}
                  className="sofia-pro-r text-[24px] leading-[1.1] lg:text-[24px]"
                >
                  {render(blok?.paragraph)}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

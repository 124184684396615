import { IProductCard } from "@interfaces/product";
import ProductList from "../bloks/ProductList";

type TabItemProps = {
  content: {
    tab_name: string;
    tab_related_products: [{ products: IProductCard }];
    activeTab: string;
  };
};

const TabItem = ({
  content: {
    tab_name,
    tab_related_products: [{ products }],
    activeTab
  }
}: TabItemProps) => {
  return (
    <div
      className={`${
        activeTab === tab_name ? "flex" : "hidden"
      } w-full flex-col`}
    >
      <ProductList blok={{ products, isTitle: false }} />
    </div>
  );
};

export default TabItem;

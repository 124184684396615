import { useState, useEffect } from "react";

export const breakpoints = {
  MAX_PHONE_BREAKPOINT: 576,
  MIN_TABLET_BREAKPOINT: 768,
  MAX_TABLET_BREAKPOINT: 991,
  MIN_DESKTOP_BREAKPOINT: 992,
  MAX_DESKTOP_BREAKPOINT: 1199,
  MIN_WD_SCREEN_BREAKPOINT: 1280,
  MIN_LG_SCREEN_BREAKPOINT: 1355
};

export const useWindow = () => (typeof window !== "undefined" ? window : {});

export const useWindowUpdates = () => {
  const isSSR = typeof window !== "undefined";
  const defaultWindowInnerWidth = 1200;
  const defaultWindowInnerHeight = 800;
  const defaultWindowScrollY = 0;
  const [windowSize, setWindowSize] = useState({
    windowWidth: isSSR ? window.innerWidth : defaultWindowInnerWidth,
    windowHeight: isSSR ? window.innerHeight : defaultWindowInnerHeight,
    windowScrollY: isSSR ? window.scrollY : defaultWindowScrollY
  });

  useEffect(() => {
    if (!isSSR) {
      return null;
    }

    const handleWindowUpdates = () =>
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        windowScrollY: window.scrollY
      });

    window.addEventListener("resize", handleWindowUpdates);
    window.addEventListener("scroll", handleWindowUpdates);

    return () => {
      window.removeEventListener("resize", handleWindowUpdates);
      window.removeEventListener("scroll", handleWindowUpdates);
    };
  });

  return windowSize;
};

export const getScreenSize = windowWidth => {
  return {
    isMobile: windowWidth <= breakpoints.MAX_PHONE_BREAKPOINT,
    isLgMobile:
      windowWidth > breakpoints.MAX_PHONE_BREAKPOINT &&
      windowWidth < breakpoints.MIN_TABLET_BREAKPOINT,
    isMinTablet: windowWidth >= breakpoints.MIN_TABLET_BREAKPOINT,
    isTablet:
      windowWidth >= breakpoints.MIN_TABLET_BREAKPOINT &&
      windowWidth <= breakpoints.MAX_TABLET_BREAKPOINT,
    isMinDesktop: windowWidth >= breakpoints.MIN_DESKTOP_BREAKPOINT,
    isDesktop:
      windowWidth >= breakpoints.MIN_DESKTOP_BREAKPOINT &&
      windowWidth <= breakpoints.MAX_DESKTOP_BREAKPOINT,
    isMinWide: windowWidth >= breakpoints.MIN_WD_SCREEN_BREAKPOINT,
    isWide: windowWidth >= breakpoints.MIN_LG_SCREEN_BREAKPOINT
  };
};

import NavCart from "./NavCart/NavCart";
import DynamicComponent from "@services/DynamicComponentsRenderer";
import { getStoryComponent } from "@utils/helpers";

export default function Nav({ blok }) {
  const cartBlock = getStoryComponent(blok.right_side, "navcart");

  return (
    <>
      <nav className="sofia-pro-m nav-shadow mx-auto flex h-full w-full bg-white px-[18px] py-[16px] text-18 lg:top-12 lg:py-[24px] lg:px-[28px] xl:px-[48px]">
        <div className="mx-auto flex w-full max-w-[81.5rem] items-center justify-between">
          <div className="hidden w-full grow-0 justify-start gap-[9px] xl:flex">
            {blok.left_side.map(customBlok => (
              <DynamicComponent key={customBlok._uid} blok={customBlok} />
            ))}
          </div>
          <div className="flex xl:hidden">
            {blok.mobile_menu.map(customBlok => (
              <DynamicComponent key={customBlok._uid} blok={customBlok} />
            ))}
          </div>
          <div className="flex flex-shrink-0 items-center justify-center">
            <a href="/" className="flex flex-shrink-0 items-center">
              <img src={blok.logo.filename} alt="" />
            </a>
          </div>
          <div className="flex xl:hidden">
            <NavCart blok={cartBlock} />
          </div>
          <div className="hidden w-full grow items-center justify-end gap-[9px] text-18 text-gl-dark-blue xl:flex">
            {blok.right_side.map(customBlok => (
              <DynamicComponent key={customBlok._uid} blok={customBlok} />
            ))}
          </div>
        </div>
      </nav>
    </>
  );
}

import React from "react";
import { motion } from "framer-motion";

export default function Quiz({ blok }) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1.4,
          duration: 0.4
        }}
        className="mx-auto mb-[50px] flex w-full max-w-[120rem] px-[18px] lg:mb-[95px] lg:px-[48px] xl:px-[48px]"
      >
        <div className="relative mx-auto flex h-full min-h-[345px] w-full max-w-[81.5rem] flex-col overflow-hidden rounded-8 lg:min-h-[0px]">
          <div className="absolute left-0 top-0 -z-10 h-full w-full bg-[#b3dbee] lg:w-[36%] lg:bg-[linear-gradient(to_right,_#2588ca_0%,_#b3dbee_100%)]"></div>
          <div className="">
            {blok.video.filename && (
              <video
                className="h-[144px] w-full max-w-[408px] -translate-x-[44%] object-cover lg:ml-auto lg:h-full lg:w-[64%] lg:max-w-none lg:translate-x-0"
                controls={false}
                preload=""
                poster=""
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={blok.video.filename} />
              </video>
            )}
          </div>
          <div className="absolute left-0 flex h-full flex-col justify-end px-[30px] pb-[25px] text-left align-middle lg:justify-center lg:px-[106px] lg:pb-0">
            {blok.mobile_image.filename && (
              <div className="mb-[10px] flex h-[100px] w-[100px] overflow-hidden rounded-[12px] lg:hidden">
                <img
                  src={blok.mobile_image.filename}
                  className="flex h-full w-full object-cover lg:hidden"
                  alt=""
                />
              </div>
            )}
            <div className="flex h-fit text-left">
              <h3 className="sofia-pro-b text-[32px] leading-[1.1] text-[#15233C] lg:text-[34px] lg:text-white">
                {blok.title}
              </h3>
            </div>
            <div className="mt-[10px] flex h-fit max-w-[18rem]">
              <p className="sofia-pro-r text-[16px] leading-[1.1] text-[#15233C] lg:text-18 lg:text-white">
                {blok.paragraph}
              </p>
            </div>
            <div className="mt-[20px] flex h-fit">
              <a
                href={blok.link.cached_url}
                className="sofia-pro-m flex w-[190px] justify-center rounded-8 bg-[#2589BD] py-[10px] text-center text-18 leading-[1] text-white lg:bg-white lg:text-[#15233C]"
              >
                {blok.link_text}
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

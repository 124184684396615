import React, { useEffect } from "react";

import { motion } from "framer-motion";
import { isEmpty } from "lodash";

import AnswersVariantsRenderer from "./AnswersVariant";

export interface IAnswerVariant {
  value: string;
  answer_value: string;
  matching_products: [
    {
      products: { content: { shopify_product_id: string } }[];
    }
  ];
}

export interface IQuestionItem {
  question: string;
  subtitle: string;
  answers: IAnswerVariant[];
  id: number;
  is_multi_answers_allowed: boolean;
  blok;
}

interface IQuizQuestion {
  blok: IQuestionItem;
  data: any;
}

const QuestionSection = (props: IQuizQuestion) => {
  const answers = props?.blok?.answers;
  if (isEmpty(answers)) {
    return null;
  }
  const {
    blok: {
      question,
      subtitle,
      is_multi_answers_allowed: isMultiAnswerAllowed = false
    },
    data: { isActivePage, setShowNextBtn, page = "quiz" }
  } = props;
  const isQuizPage = page === "quiz";
  useEffect(() => {
    isQuizPage && setShowNextBtn(isActivePage && isMultiAnswerAllowed);
  }, []);
  return (
    <div
      className={`relative mx-auto flex w-full flex-col ${
        isQuizPage ? "bg-[#edf2f4]" : "bg-[#F5F9FA]"
      }`}
    >
      <div className="lg:min-h-full mx-auto flex w-full max-w-[81.5rem] flex-col px-[18px] sm:h-fit lg:px-[48px] xl:px-[48px]">
        <div
          className={`flex w-full items-start justify-center ${
            isQuizPage && "pt-[40px] sm:pt-[50px] lg:pt-[55px]"
          } align-top`}
        >
          <div
            className={`mx-auto flex w-full ${
              isQuizPage
                ? "min-h-[105px] flex-col text-center"
                : "mb-[20px] flex-row justify-center"
            }`}
          >
            {isQuizPage ? (
              <>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.4,
                    delay: 0.2
                  }}
                  className="h-full overflow-hidden"
                >
                  <h1 className="sofia-pro-b text-[28px] leading-[1.3] lg:text-[34px] lg:leading-[1.4]">
                    {question}
                  </h1>
                </motion.div>
                <div className="h-full pt-[9px] lg:pt-[10px]">
                  <motion.p
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 300,
                      damping: 20,
                      delay: 0.2,
                      duration: 0.3
                    }}
                    className="sofia-pro-r mb-5 text-[20px] leading-[26px] text-[#15233C99] lg:text-[24px]"
                  >
                    {subtitle}
                  </motion.p>
                </div>
              </>
            ) : (
              <p className="sofia-pro-b text-[22px] leading-[1.1]">
                {question}
                <span className="sofia-pro-r ml-1">{subtitle}</span>
              </p>
            )}
          </div>
        </div>
        <AnswersVariantsRenderer
          isMultipleAnswers={isMultiAnswerAllowed}
          page={page}
          {...props}
        />
      </div>
    </div>
  );
};

export default QuestionSection;

import DynamicComponent from "../../../../services/DynamicComponentsRenderer";

export default function Instructions({ blok }) {
  return (
    <>
      <div className="flex w-full flex-col">
        {blok?.body?.map(blok => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}
      </div>
    </>
  );
}

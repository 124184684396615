export default function NavLink({ blok }) {
  return (
    <>
      <div>
        <a
          href={blok?.link.cached_url}
          className="sofia-pro-s relative flex w-fit flex-row gap-[12px] text-[22px] leading-[1.1]"
        >
          {blok?.link_text}
          {blok?.has_button && (
            <span className="flex h-full w-full min-w-[79px] items-center justify-center rounded-4 bg-[#69B6451A] px-[10px] py-[5px] align-middle text-[14px] text-[#69B645]">
              {blok?.button_text}
            </span>
          )}
          {blok?.has_scribble && <div className="scribble"></div>}
        </a>
      </div>
    </>
  );
}

import React, { useContext } from "react";
import { motion } from "framer-motion";

import { ShopContext } from "@context/shopContext";
import QuizNavItem from "./NavItem";
import { Logo, Cross } from "@components/SVG/index";

const QuizNav = ({ blok }) => {
  const { quiz_steps } = blok;
  const { quizQuestionsProgress } = useContext(ShopContext);
  return (
    <>
      <nav className="sofia-pro-m relative mx-auto flex h-fit w-full bg-white px-[18px] py-6 text-18 lg:px-[28px] xl:px-[48px]">
        <motion.div
          initial={{ y: -50, scale: 0.98, opacity: 0 }}
          animate={{ y: 0, scale: 1, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 17,
            delay: 0.4,
            duration: 0.4
          }}
          className="mx-auto flex w-full max-w-[81.5rem] items-center justify-between max-md:flex-col"
        >
          <div className="flex w-full space-x-[30px] max-md:mb-5 max-md:justify-center md:grow-0 md:justify-start">
            <div className="items-center">
              <a href="/">
                <Logo />
              </a>
            </div>

            <div className="w-fill absolute right-10 flex h-8 w-8 flex-col items-center justify-center rounded-full bg-[#F5F9FF] text-gl-dark-blue md:hidden">
              <a href="/" className="sofia-pro-m  text-[16px]">
                <Cross />
              </a>
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center justify-center gap-[50px]">
            {quiz_steps.map((step, index) => (
              <QuizNavItem key={step._uid} data={{ ...step, index }} />
            ))}
          </div>
          <div className="hidden w-full grow items-center justify-end gap-[20px] text-18 text-gl-dark-blue md:flex">
            <a
              href="/"
              className="sofia-pro-m flex flex-row items-center rounded-8 border border-[#D7E1E4] px-[12px] py-[4px] text-[16px]"
            >
              Back to site
            </a>
          </div>
        </motion.div>
      </nav>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.5,
          duration: 0.4
        }}
        className="h-fit h-[4px] w-full bg-[#d8dde2]"
      >
        <motion.span
          initial={{ width: "0%" }}
          animate={{ width: `${quizQuestionsProgress}%` }}
          transition={{
            delay: 0.5,
            duration: 0.4
          }}
          className="block  h-full bg-gl-light-blue"
        ></motion.span>
      </motion.div>
    </>
  );
};

export default QuizNav;

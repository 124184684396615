import { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import TabItem from "../../TabItem";

const Tabs = ({ blok: { tabs_list: tabsList } }) => {
  if (isEmpty(tabsList)) {
    return null;
  }
  const [activeTab, setActiveTab] = useState(tabsList[0]?.tab_name);

  return (
    <div className="mb-1 flex w-full flex-col lg:my-7">
      <div className="mb-5 flex justify-center lg:mt-5">
        {tabsList.map(tabItem => (
          <div
            key={tabItem._uid}
            onClick={() => setActiveTab(tabItem.tab_name)}
            // Used conditional render cause of delay for fetch new products list
            className={`${
              activeTab === tabItem.tab_name && "border-b-[2px] border-brown"
            } mr-5 cursor-pointer pb-1 font-extrabold uppercase tracking-wide text-brown sm:text-lg lg:text-middle`}
          >
            {tabItem.tab_name}
          </div>
        ))}
      </div>
      {tabsList.map(tabItem => (
        <TabItem key={tabItem._uid} content={{ ...tabItem, activeTab }} />
      ))}
    </div>
  );
};

export default Tabs;

import React, { useEffect, useState, useContext, useCallback } from "react";
import { MARK_UNDERLINE } from "storyblok-rich-text-react-renderer";
import DiscountApplied from "@components/DiscountBanner/DiscountApplied";
import DiscountProposition from "@components/DiscountBanner/DiscountProposition";
import { ShopContext } from "@context/shopContext";
import { useRouter } from "next/router";

export default function DiscountBanner({ blok }) {
  const [isAppliedDiscount, setIsAppliedDiscount] = useState(false);
  const [show, setShow] = useState(false);
  const router = useRouter();
  const { addDiscountToCart, discountCodes } = useContext(ShopContext);

  useEffect(() => {
    const handle = router.query.product;

    if (handle) {
      const discountedHandle = localStorage.getItem("discountedBundleHandle");
      if (discountedHandle === handle) {
        setShow(true);
        setIsAppliedDiscount(discountCodes.includes(blok.discount_code));
      }
    }
  }, [router.query.product, discountCodes, blok.discount_code]);

  const onApply = useCallback(async () => {
    await addDiscountToCart(blok.discount_code);
  }, [blok.discount_code]);

  const richtextOptions = {
    markResolvers: {
      [MARK_UNDERLINE]: children => {
        return (
          <span className="discount-underline relative ml-2">{children}</span>
        );
      }
    }
  };

  return (
    <>
      {show && (
        <div className="fixed left-0 bottom-0 z-[1] mx-auto flex w-full max-w-[100vw] justify-center transition-all">
          {isAppliedDiscount ? (
            <DiscountApplied
              appliedText={blok.applied_text}
              richtextOptions={richtextOptions}
            />
          ) : (
            <DiscountProposition
              onApply={onApply}
              propositionText={blok.proposition_text}
              richtextOptions={richtextOptions}
            />
          )}
        </div>
      )}
    </>
  );
}

import React from "react";
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";

export default function Difference({
  blok: { title, image, content, background }
}) {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        if (level === 3) {
          return (
            <h3
              className={`sofia-pro-s mb-7 text-[32px] leading-[1.15] md:mb-9 lg:text-[34px]`}>
              {children}
            </h3>
          );
        }
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r text-[32px] lg:text-[34px]`}>{children}</p>
        );
      }
    }
  };
  const renderContentOptions = {
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        if (level === 4) {
          return (
            <h4 className={`sofia-pro-r mb-6 text-[24px] leading-[1.2]`}>
              {children}
            </h4>
          );
        }
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r mb-5 text-[20px] leading-[1.2]`}>
            {children}
          </p>
        );
      }
    }
  };

  return (
    <section className="relative pt-20 pb-0 md:pt-[5%] md:pt-0">
      <img
        src={background?.filename}
        className="absolute left-0 top-0 -z-10 h-full w-full object-cover object-top mix-blend-darken md:-top-[10%] md:h-[110%]"
        alt=""
      />
      <div className="mx-auto max-w-[1400px] px-2 pl-0">
        <div className="flex flex-col-reverse flex-wrap items-center justify-between md:flex-row">
          <div className="w-full md:w-[51%]">
            <img src={image?.filename} alt="" />
          </div>
          <div className="w-full pl-4 md:w-[46.5%] md:pl-0">
            <div className="max-w-[600px]">
              {render(title, renderTitleOptions)}
              {render(content, renderContentOptions)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

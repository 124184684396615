import { useState } from "react";
import { Formik, Form } from "formik";
import { boolean, object, string } from "yup";
import { FormFields } from "@components/try/FormFields/FormFields";
import { tryGLAPI } from "@src/services";

let tryFromSchema = object({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  email: string().email("Invalid email address").required("Email is required"),
  phone: string()
    .matches(/^\+1 \(\d{3}\)\s\d{3}-\d{4}$/, "Invalid phone format: +1 (123) 456-7890")
    .required("Phone is required"),
  product: string().required("Product is required"),
  delivery_option: string().required("Is required"),
  agree: boolean()
    .oneOf([true], "You must accept the terms and conditions.")
    .required("You must accept the terms and conditions."),
  address: string()
    .when("delivery_option", {
      is: "drop off",
      then: schema => schema.required("Address is required")
    })
    .nullable()
    .default(""),
  city: string()
    .when("delivery_option", {
      is: "drop off",
      then: schema => schema.required("City is required")
    })
    .nullable()
    .default(""),
  state: string()
    .when("delivery_option", {
      is: "drop off",
      then: schema => schema.required("State is required")
    })
    .nullable()
    .default(""),
  zipCode: string()
    .when("delivery_option", {
      is: "drop off",
      then: schema => schema.required("Zip Code is required")
    })
    .nullable()
    .default("")
});

export const FormSimple = ({
  blok: { title, notice, products },
  setSuccessForm
}) => {
  const [serverError, setServerError] = useState(null);

  return (
    <>
      <div className="relative z-20 w-full lg:order-2 lg:w-5/12">
        <img
          className="relative mx-auto -mb-[74px] w-[260px] max-w-none lg:absolute lg:-left-[14%] lg:top-[35%] lg:w-[144%] lg:-translate-y-1/2"
          src="/books.png"
          alt=""
        />
      </div>
      <div className="try-form simple-form relative z-10 w-full rounded-[24px] p-7 pt-24 lg:order-1 lg:w-7/12 lg:max-w-[643px] lg:p-14">
        <Formik
          validationSchema={tryFromSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setServerError(null);
            setSubmitting(true);
            const currentData = { ...values };
            delete currentData.agree;

            try {
              await fetch("https://node.goodlaundry.com/api/try_first", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(currentData)
              });

              const { data } = await tryGLAPI("/api/v1/klaviyo/try-gl", values);

              if (data.detail) {
                setServerError(data.detail);
              } else {
                setSuccessForm(true);
              }
              setSubmitting(false);
            } catch (error) {
              setServerError(error);
              setSubmitting(false);
            }
          }}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            product: "",
            delivery_option: "drop off",
            agree: false,
            place: "",
            address: "",
            apartment: "",
            city: "",
            state: "Arizona",
            zipCode: ""
          }}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <img
                className="absolute -top-[170px] left-[55%] w-[44px] translate-x-full -rotate-[120deg] lg:top-[6%] lg:left-[87%] lg:w-[66px]"
                src="/odonata.png"
                alt=""
              />
              <img
                className="absolute bottom-0 right-[10%] w-[48px] translate-y-[40%]"
                src="/fish-2.png"
                alt=""
              />
              <h4 className="sofia-pro-r mb-6 text-[24px] text-gl-blue">
                {title}
              </h4>
              <FormFields
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                notice={notice}
                products={products}
              />
              {serverError && <p className="server-error">{serverError}</p>}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

import React, { useEffect, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DynamicComponent from "@services/DynamicComponentsRenderer";

export default function NavDropdown({ blok }) {
  const [isNavItemOpen, setIsNavItemOpen] = useState(false);
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);

  const handleDropdown = () => {
    setIsNavItemOpen(!isNavItemOpen);
  };

  const handleClickOutside = e => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnRef.current.contains(e.target)
    ) {
      setIsNavItemOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <button
        ref={menuBtnRef}
        onClick={handleDropdown}
        className={`flex h-fit flex-shrink-0 items-center gap-[8px] rounded-8 border border-transparent py-[6px] px-[14px] transition-all hover:bg-[#F5F9FF] ${
          isNavItemOpen ? "bg-[#F5F9FF]" : "bg-transparent"
        }`}
      >
        <span>{blok.button_text}</span>
        <svg
          width="11"
          height="7"
          viewBox="0 0 11 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`transition-all ${
            isNavItemOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <path
            d="M5.44018 4.4989L8.90413 0.9581L8.91252 0.949531L8.91271 0.949741C9.0527 0.81973 9.22366 0.75 9.41512 0.75C9.5438 0.75 9.66739 0.780184 9.77913 0.845592C9.88748 0.909012 9.97387 0.997181 10.0361 1.10602C10.1002 1.21819 10.1304 1.34159 10.1304 1.47035C10.1304 1.66454 10.0568 1.83611 9.92027 1.97314L5.9661 6.02438C5.89863 6.09636 5.81866 6.15249 5.72789 6.19138L5.44018 4.4989ZM5.44018 4.4989L1.97625 0.958101L1.97633 0.958023L1.97096 0.952849C1.83164 0.818685 1.65843 0.75 1.46523 0.75C1.33655 0.75 1.21296 0.780182 1.10121 0.845594L5.44018 4.4989ZM0.960146 1.97832C0.894255 1.91215 0.842162 1.83556 0.805643 1.74948L0.80564 1.74948C0.767906 1.66053 0.75 1.56661 0.75 1.47035C0.75 1.34159 0.780192 1.2182 0.844289 1.10603C0.906481 0.997187 0.992864 0.909017 1.10121 0.845596L0.960146 1.97832ZM0.960146 1.97832L4.91629 6.02647L4.91622 6.02654L4.92133 6.03149C5.0652 6.17056 5.24175 6.25 5.44274 6.25C5.54141 6.25 5.63709 6.23031 5.72777 6.19143L0.960146 1.97832Z"
            fill="#15233C"
            stroke="#15233C"
            strokeWidth="0.5"
          />
        </svg>
      </button>
      {/* 
// @ts-ignore */}
      <AnimatePresence>
        {isNavItemOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.1 }}
            className={`bg-[#fff] ${
              isNavItemOpen
                ? "nav-shadow absolute top-[136px] left-[0] z-30 w-full border-t border-[#fffff0D] bg-white"
                : "hidden"
            }`}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.1 }}
              ref={menuRef}
              className="z-40 mx-auto flex w-full max-w-[120rem] flex-col px-[18px] pt-[27px] pb-[40px]"
            >
              <div className="mx-auto flex h-full w-full max-w-[81.5rem] flex-row justify-between">
                <div className="flex flex-col gap-[21px]">
                  {blok?.left_side?.map(customBlok => (
                    <DynamicComponent key={customBlok._uid} blok={customBlok} />
                  ))}
                </div>
                <div className="flex flex-row flex-wrap justify-end gap-[12px]">
                  {blok?.right_side?.map(customBlok => (
                    <DynamicComponent key={customBlok._uid} blok={customBlok} />
                  ))}
                  <div className="flex w-full justify-end">
                    <a
                      href="/collections/all-products"
                      className="sofia-pro-m false sofia-pro-b mt-5 flex h-fit w-full max-w-[305px] select-none justify-center rounded-8 rounded-8 bg-[#2589BD] bg-[#2589BD] px-2 py-1 text-center text-18 leading-[40px] tracking-[2%] text-white"
                    >
                      SHOP ALL PRODUCTS →
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import Article from "./Article/Article";
import { storyblokEditable } from "@storyblok/react";
import { useState, useEffect } from "react";
import storyblokInstance from "@services/storyblok-service";
import { motion } from "framer-motion";

export default function TagSection({ blok }) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      const { data } = await storyblokInstance.get("cdn/stories", {
        starts_with: "blog/",
        resolve_relations: "author",
        filter_query: { component: { in: "post" } }
      });

      const filteredArticles = data.stories.filter(
        article => article.tag_list[0] === `${blok.tag}`
      );

      setArticles(prev =>
        filteredArticles.map(article => {
          article.content.full_slug = article.full_slug;
          article.content.tag_list = article.tag_list;
          return article;
        })
      );
    };

    getArticles();
  }, []);
  return (
    <>
      {blok.layout === "1" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 1.4,
            duration: 0.4
          }}
          className="mx-auto flex w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]"
        >
          <div className="mx-auto flex w-full max-w-[1300px] flex-col border-t-2 border-[#D3E7F4] pt-[25px] pb-[60px] lg:pt-[35px]">
            <div className="mb-[35px]">
              <h3
                className="sofia-pro-b text-[34px] leading-[1.1]"
                {...storyblokEditable(blok)}
              >
                {blok.title}
              </h3>
            </div>
            <div
              className="flex h-full w-full flex-row gap-[20px] overflow-auto overflow-y-hidden scrollbar-hide"
              {...storyblokEditable(blok)}
            >
              {articles.slice(0, 4).map(article => (
                <Article article={article.content} key={article.uuid} />
              ))}
            </div>
          </div>
        </motion.div>
      )}
      {blok.layout === "2" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 1.4,
            duration: 0.4
          }}
          className="mx-auto flex w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]"
        >
          <div className="mx-auto flex w-full max-w-[1300px] flex-col border-t-2 border-[#D3E7F4] pt-[25px] pb-[60px] lg:pt-[35px]">
            <div className="mb-[35px]">
              <h3 className="sofia-pro-b text-[34px] leading-[1.1]">
                {blok.title}
              </h3>
            </div>
            <div
              className="flex h-full w-full flex-row gap-[20px] overflow-auto overflow-y-hidden scrollbar-hide"
              {...storyblokEditable(blok)}
            >
              {articles.slice(0, 4).map(article => (
                <Article article={article.content} key={article.uuid} />
              ))}
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1.4,
          duration: 0.4
        }}
        className="mx-auto flex w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]"
      >
        <div className="mx-auto flex w-full max-w-[1300px] flex-col border-t-2 border-[#D3E7F4] pt-[25px] pb-[60px] lg:pt-[35px]">
          <div className="mb-[35px]">
            <h3 className="sofia-pro-b text-[34px] leading-[1.1]">
              {blok.title}
            </h3>
          </div>
          <div
            className="flex h-full w-full flex-row gap-[20px] overflow-auto overflow-y-hidden scrollbar-hide"
            {...storyblokEditable(blok)}
          >
            {articles.slice(0, 4).map(article => (
              <Article
                article={article.content}
                key={article.uuid}
                {...storyblokEditable(article)}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
}

import DynamicComponent from "../../../../services/DynamicComponentsRenderer";

export default function Accordions({ blok }) {
  return (
    <>
      <div className="mt-[30px] w-full">
        {blok.body.map(customBlok => (
          <DynamicComponent key={customBlok._uid} blok={customBlok} />
        ))}
      </div>
    </>
  );
}

import { useEffect, useState } from "react";

// libs
import { ErrorMessage, Field } from "formik";
import { render } from "storyblok-rich-text-react-renderer";
import Link from "next/link";
import InputMask from "react-input-mask";
import { get } from "lodash";
import Script from "next/script";

// components
import Button from "../../UI/button";

const initialState = { zip_code: "",  city: "", address: {} };
export const FormFields = ({
  isSubmitting,
  setFieldValue,
  notice,
  products
}) => {
  const [fullAddress, setFullAddress] = useState(initialState);

  useEffect(() => {
    const setIntervalId = setInterval(() => {
      const input = document.getElementsByClassName(`address-input`)[0];
      if (typeof window !== "undefined" && window["google"] && input) {
        clearInterval(setIntervalId);
        // Create the search box and link it to the UI element.
        const searchBox = new window["google"].maps.places.SearchBox(
          input as HTMLInputElement
        );
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();
          if (places.length === 0) {
            return;
          }

          let isArizona = false;

          places.forEach(place => {
            let newAddress = { route: "", street_number: "", subpremise: "" };
            if (place["address_components"]) {
              place["address_components"].map(address => {
                if (address["types"][0] === "administrative_area_level_1") {
                  if (address["long_name"] === "Arizona") isArizona = true;
                }
              });

              if (isArizona) {
                place["address_components"].map(address => {
                  if (address["types"][0] === "postal_code") {
                    fullAddress.zip_code = address["long_name"];
                  }
                  if (address["types"][0] === "locality") {
                    fullAddress.city = address["long_name"];
                  }
                  if (address["types"][0] === "route") {
                    newAddress.route = address["long_name"];
                  }
                  if (address["types"][0] === "street_number") {
                    newAddress.street_number = address["long_name"];
                  }
                  if (address["types"][0] === "subpremise") {
                    newAddress.subpremise = address["long_name"];
                  }
                });
              }

              if (isArizona) {
                fullAddress.address = newAddress;
                setFullAddress({ ...fullAddress });
                setFieldValue("city", fullAddress.city);
                setFieldValue("zipCode", fullAddress.zip_code);
                setFieldValue(
                  "address",
                  `${get(fullAddress, "address.street_number", "")} ${get(
                    fullAddress,
                    "address.route",
                    ""
                  )}  ${get(fullAddress, "address.subpremise", "")}`
                );
              } else {
                setFullAddress({ ...initialState });
                setFieldValue("city", "");
                setFieldValue("zipCode", "");
                setFieldValue("address", "");
              }
            }
            if (!place.geometry || !place.geometry.location) {
              console.log("Returned place contains no geometry");
            }
          });
        });
      }
    }, 500);
  }, []);

  return (
    <>
      <div className="field-group">
        <label>
          <span>First name</span>
          <Field type="text" name="firstName" />
          <ErrorMessage
            name="firstName"
            component="p"
            className="error-message"
          />
        </label>
        <label>
          <span>Last name</span>
          <Field type="text" name="lastName" />
          <ErrorMessage
            name="lastName"
            component="p"
            className="error-message"
          />
        </label>
      </div>
      <div className="field-group">
        <label>
          <span>Email address</span>
          <Field type="email" name="email" />
          <ErrorMessage name="email" component="p" className="error-message" />
        </label>
        <label>
          <span>Mobile phone</span>
          <InputMask
            type="text"
            className="max-w-[170px]"
            mask="+1 (999) 999-9999"
            name="phone"
            onChange={e => {
              const value = e.target.value || "";
              setFieldValue("phone", value);
            }}
          />
          <ErrorMessage name="phone" component="p" className="error-message" />
        </label>
      </div>
      <hr />
      <h4 className="sofia-pro-r mb-3 text-[24px] text-gl-blue">
        Select your favorite scent
      </h4>
      <div className="relative grid w-full grid-cols-2 gap-[8px] lg:grid-cols-4">
        {products[0]?.scents?.map(product => (
          <label key={product.value} className="product-radio">
            <Field hidden type="radio" name="product" value={product.value} />
            <div className="hover:border-bl-blue flex h-[60px] w-full cursor-pointer select-none flex-row items-center space-x-[10.5px] rounded-8 border border-[#D7E1E4] bg-white py-[13px] px-[8px] lg:h-fit">
              <div className="h-[12px] w-[12px]">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="6.13672"
                    cy="6.521"
                    r="6"
                    fill={product.fill_svg}
                  />
                </svg>
              </div>
              <h3 className="sofia-pro-m text-[14px] leading-[1.3]">
                {render(product.text)}
              </h3>
            </div>
          </label>
        ))}
        <ErrorMessage name="product" component="p" className="error-message" />
      </div>
      <hr />
      <h4 className="sofia-pro-r mb-2 text-[24px] text-gl-blue">
        Where should we drop your samples off?
      </h4>
      <label className="mb-4">
        <span>Street address</span>
        <Field
          type="text"
          name="address"
          className="address-input"
          placeholder=""
        />
        <ErrorMessage name="address" component="p" className="error-message" />
      </label>
      <label className="mb-4">
        <span>Apartment/suite # (Optional)</span>
        <Field type="text" name="apartment" />
        <ErrorMessage
          name="apartment"
          component="p"
          className="error-message"
        />
      </label>
      <div className="field-group">
        <label>
          <span>City</span>
          <Field type="text" name="city" />
          <ErrorMessage name="city" component="p" className="error-message" />
        </label>
        <label>
          <span>State</span>
          <Field autofill={false} disabled type="text" name="state" />
          <ErrorMessage name="state" component="p" className="error-message" />
        </label>
      </div>
      <div className="field-group">
        <label>
          <span>Zip code</span>
          <Field type="text" name="zipCode" />
          <ErrorMessage
            name="zipCode"
            component="p"
            className="error-message"
          />
        </label>
        <label>
          <span>Any notes?</span>
          <Field
            type="text"
            name="place"
            placeholder="(IE: leave at front door)"
          />
          <ErrorMessage name="place" component="p" className="error-message" />
        </label>
      </div>
      <hr />
      <h5 className="mb-3 text-[18px]">Are you ok with the fine print?</h5>
      <label className="checkbox">
        <Field type="checkbox" name="agree" />
        <span className="sofia-pro-r text-[16px]">
          I agree to the{" "}
          <Link href="/terms-of-service">
            <a
              className="sofia-pro-s underline hover:no-underline"
              target="_blank">
              Terms of Service
            </a>
          </Link>{" "}
          + it’s ok to send me text messages!
        </span>
        <ErrorMessage name="agree" component="p" className="error-message" />
      </label>
      <div className="sofia-pro-r mb-4 text-[13px] leading-[1.2]">
        {render(notice)}
      </div>
      <Button
        isDisable={isSubmitting}
        type="submit"
        variant="blue"
        className="!ml-0 border !border-[#15233c] !py-1 !text-[18px] hover:bg-transparent hover:text-[#15233c]">
        Good to go
      </Button>
      {/* Google Maps API */}
      <Script
        id="google-maps-api"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places`}
      />
    </>
  );
};

import { isEmpty } from "lodash";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { useMediaQuery } from "@hooks/index";

const FourBlockRow = ({
  blok: { items_list, title, pictures_size = 55 },
  blok
}) => {
  const isExtraSmall = useMediaQuery("(max-width: 360px)");

  if (isEmpty(items_list)) {
    return null;
  }
  return (
    <div
      className="mx-auto mb-[40px] w-full max-w-[120rem] px-[18px] lg:mb-[130px] lg:px-[48px] xl:px-[48px]"
      {...storyblokEditable(blok)}
    >
      {title && (
        <div className="mb-[20px] flex w-full justify-center text-center lg:mb-[15px]">
          <h2
            className={`sofia-pro-b ${
              !isExtraSmall ? "px-[20px]" : ""
            } pb-[24px] pt-[32px] text-[32px] leading-[1.1] lg:px-[unset] lg:pb-[48px] lg:pt-[0] lg:text-[44px]`}
          >
            {title}
          </h2>
        </div>
      )}
      <div
        className={`mx-auto grid w-full max-w-[81.5rem] grid-cols-1 justify-between gap-[16px] sm:grid-cols-2 md:grid-cols-2 
        lg:grid-cols-${items_list.length}`}
      >
        {items_list.map(item => {
          const { _uid, title, text, image } = item;
          return (
            <div
              key={_uid}
              className="flex flex-col items-center py-2 text-center"
              {...storyblokEditable(item)}
            >
              <div
                className={`relative`}
                style={{
                  height: `${pictures_size}px`,
                  width: `${pictures_size}px`
                }}
              >
                <Image
                  src={image?.filename}
                  layout="fill"
                  objectFit="contain"
                />{" "}
              </div>
              <div className="mt-[24px]">
                <h3 className="sofia-pro-s text-[22px]">{title}</h3>
              </div>
              <div className="mx-auto mt-[10px] flex max-w-[19rem]">
                <p className="sofia-pro-r text-18 leading-[21px] text-gl-black">
                  {text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FourBlockRow;

import { useState } from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { Accordion } from "@components/UI";

const AccordionWithNested = ({ blok, isOpenedAccordionsWithNested }) => {
  const [isActive, setIsActive] = useState<boolean>(
    isOpenedAccordionsWithNested
  );

  return (
    <div className="flex w-full cursor-pointer select-none flex-col">
      <div
        onClick={() => setIsActive(!isActive)}
        className="flex w-full flex-col px-[20px] pt-[20px] lg:px-[58px] lg:pt-[30px]"
      >
        <div
          className={`flex w-full pb-[20px] lg:pb-[30px] ${
            isActive ? "border-b-0" : "border-b-2 border-[#F5F9FF]"
          }`}
        >
          <div className="flex h-full w-full flex-row items-center justify-between">
            <div className="flex w-full text-left">
              <h3
                className={`sofia-pro-b text-[22px] leading-[1.1] transition-all lg:text-[30px] ${
                  isActive ? "text-[#2589BD]" : "text-[#15233C]"
                }`}
                {...storyblokEditable(blok)}
              >
                {blok.title}
              </h3>
            </div>
            <div
              className="ml-2 flex h-full transition-all"
              style={{
                transform: isActive ? "rotate(180deg)" : "rotate(0deg)"
              }}
            >
              <svg
                width="23"
                height="13"
                viewBox="0 0 23 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L11.5 11.5L22 1"
                  stroke="#15233C"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Accordion
        isOpened={isActive}
        classList={"accordion-last-2 bg-[#E2F5FF80] px-[25px] lg:px-[58px]"}
      >
        {blok?.body?.map(blok => (
          <StoryblokComponent blok={blok} key={blok._uid} />
        ))}
      </Accordion>
    </div>
  );
};

export default AccordionWithNested;

import { storyblokEditable } from "@storyblok/react";
import { useMediaQuery } from "@hooks/index";
import Image from "next/image";
import React from "react";

export default function NoticedImages({ blok }) {
  const matches = useMediaQuery(`(min-width: ${1024}px)`);
  const {
    height_mobile = 33,
    height = 27,
    width = 103,
    width_mobile = 126
  } = blok;

  return (
    <div
      className="relative flex flex-shrink-0 "
      style={{
        height: matches ? `${height}px` : `${height_mobile}px`,
        width: matches ? `${width}px` : `${width_mobile}px`
      }}
    >
      <Image
        src={`${blok?.image?.filename}` + "/m/"}
        layout="fill"
        objectFit="contain"
      />
    </div>
  );
}

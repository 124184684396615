import { render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

export default function Difference({ blok }) {
  return (
    <div className="mx-auto mb-[90px] flex w-full max-w-[120rem] overflow-hidden lg:mb-[140px]">
      <div className="relative flex h-full w-full flex-col justify-center text-center">
        <div className="px-[18px] text-left lg:px-[48px] lg:text-center xl:px-[48px]">
          <h2
            className="sofia-pro-b mb-[13px] text-center text-[30px] leading-[1.1] lg:text-[44px]"
            {...storyblokEditable(blok)}>
            {blok.title}
          </h2>
          <p className="sofia-pro-m text-center text-[16px] leading-[1.1] md:text-[20px] lg:text-[20px]">
            {blok.sub_title}
          </p>
        </div>

        <section className="flex flex-col md:flex-row">
          <div className="mx-auto flex h-full w-full md:mt-[20px] md:max-w-[81.5rem]">
            <div className="mx-auto flex h-full max-h-[718px] max-w-[81.5rem] justify-end md:w-full">
              <img
                src={`${blok?.main_image?.filename}` + "/m/"}
                className="max-h-[710px] w-full max-w-[1000px] lg:flex lg:w-auto"
                {...storyblokEditable(blok)}
                alt=""
              />
            </div>
          </div>

          <div className="mx-auto ml-[10px] mt-[30px] grid h-full w-full max-w-[82rem] grid-cols-1 gap-[10px] px-[18px] md:mt-[174px] lg:h-[20rem] lg:grid-cols-1 lg:justify-center lg:px-[5px]">
            <div className="flex h-fit flex-row items-start justify-end align-top lg:pt-[0px]">
              <div className="ml-[-200px] mt-[36px] hidden justify-center lg:flex lg:w-[65px] xl:w-[91px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="96"
                  height="97"
                  viewBox="0 0 96 97"
                  fill="none">
                  <path
                    d="M22.5221 67.5769C25.9949 57.0237 32.6465 48.0986 41.3091 41.1856C45.504 37.8387 50.1808 35.0604 55.0912 32.903C60.0871 30.7095 65.8796 28.6693 71.3903 28.6037C73.1973 28.5803 74.2619 31.4755 72.1951 31.9842C67.2316 33.2011 62.3168 34.0157 57.5387 35.9201C52.5761 37.8977 47.9352 40.5559 43.6636 43.7528C35.2227 50.0747 28.432 58.7054 24.0164 68.2633C23.5599 69.248 22.1959 68.5622 22.5153 67.5775L22.5221 67.5769Z"
                    fill="#2589BD"
                  />
                  <path
                    d="M27.3905 45.8275C26.2841 54.2107 24.837 62.5459 23.7516 70.9339L20.7568 69.5685C23.9631 66.1357 27.5086 63.0115 31.2321 60.1574C34.446 57.6902 38.6062 53.8602 42.7182 53.2426C43.8731 53.072 44.539 54.5199 43.864 55.3757C42.6042 56.9618 40.7445 57.8267 39.0652 58.9202C37.2252 60.1189 35.4265 61.3889 33.6747 62.7158C30.0314 65.4797 26.6717 68.5441 23.3854 71.7245C22.2483 72.8254 20.0591 72.1812 20.3906 70.3592C21.9069 62.0308 23.4978 53.6949 25.8427 45.5645C26.118 44.6185 27.5213 44.8069 27.3838 45.8282L27.3905 45.8275Z"
                    fill="#2589BD"
                  />
                  <path
                    d="M79.7276 29.515C79.8228 30.465 79.0793 31.4579 78.0913 31.5157C77.1033 31.5736 76.1925 30.8972 76.0906 29.8794C75.9954 28.9294 76.7389 27.9365 77.7269 27.8787C78.7148 27.8208 79.6256 28.4971 79.7276 29.515Z"
                    fill="#2589BD"
                  />
                </svg>
              </div>
              <div className="flex w-full flex-col items-center text-center md:ml-[20px]  md:items-start md:text-left">
                <h3
                  className="sofia-pro-b mb-[4px] max-w-[200px] text-[22px] leading-[1.2] md:max-w-[100%]"
                  {...storyblokEditable(blok)}>
                  {blok.title_1}
                </h3>
                <div
                  className="mt-[5px] text-18 leading-6 text-[#424B5B]"
                  {...storyblokEditable(blok)}>
                  <div className="m-auto max-w-[500px] md:m-0">
                    {render(blok?.paragraph_1)}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex h-fit w-full flex-row justify-center ">
              <div className="mt-[68px] hidden justify-start lg:ml-[-90px] lg:flex lg:w-[65px] xl:ml-[-119px] xl:w-[88px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="82"
                  height="79"
                  viewBox="0 0 82 79"
                  fill="none">
                  <path
                    d="M69.1479 34.0016C53.2981 36.7406 28.3059 42.3442 13.3059 48.1182C10.8285 49.0727 9.67674 45.1904 12.0859 44.1993C26.878 38.0835 53.1882 33.5716 69.0664 32.2426C70.2156 32.1451 70.2195 33.8163 69.1413 34.0001L69.1479 34.0016Z"
                    fill="#2589BD"
                  />
                  <path
                    d="M30.3207 27.0324C24.9712 34.1227 18.8119 40.5804 13.389 47.626C13.119 46.7297 12.8424 45.8319 12.5739 44.9289C16.3377 46.0343 20.0954 47.1665 23.8203 48.3825C27.5201 49.5858 31.13 51.5547 34.9596 52.247C37.0586 52.6244 35.9363 56.1037 33.9112 55.0623C30.4416 53.2726 26.2683 52.6849 22.5624 51.5083C18.9924 50.3765 15.4269 49.2248 11.8488 48.0982C10.6473 47.7207 10.2289 46.4047 11.0338 45.401C16.5867 38.5182 22.394 31.3421 29.3128 25.8004C30.0777 25.1877 30.8276 26.3612 30.3207 27.0324Z"
                    fill="#2589BD"
                  />
                  <path
                    d="M75.8453 32.6631C75.3961 34.6457 72.3388 33.9529 72.7881 31.9703C73.2373 29.9877 76.2945 30.6805 75.8453 32.6631Z"
                    fill="#2589BD"
                  />
                </svg>
              </div>
              <div className="flex w-full flex-col items-center pt-[45px] text-center md:ml-[20px]  md:items-start md:text-left">
                <h3
                  className="sofia-pro-b mb-[4px] max-w-[209px]  text-[22px] leading-[1.2] md:max-w-[100%]"
                  {...storyblokEditable(blok)}>
                  {blok.title_2}
                </h3>
                <div
                  className="mt-[5px] text-18 leading-6 text-[#424B5B]"
                  {...storyblokEditable(blok)}>
                  <div className="m-auto max-w-[500px] md:m-0">
                    {render(blok?.paragraph_2)}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex h-full w-full flex-row justify-start">
              <div className="hidden justify-start pt-[73px] lg:ml-[-92px] lg:flex lg:w-[65px] xl:ml-[-109px] xl:w-[84px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="97"
                  height="96"
                  viewBox="0 0 97 96"
                  fill="none">
                  <path
                    d="M19.6898 34.6401C25.2378 44.2655 33.5667 51.6501 43.455 56.6552C48.2433 59.0782 53.3877 60.8465 58.6344 61.9594C63.9721 63.0901 70.0587 63.9087 75.4673 62.8513C77.2413 62.5065 77.6943 59.4551 75.5672 59.3778C70.4599 59.1965 65.4823 59.3993 60.4166 58.5073C55.1553 57.5811 50.0706 55.9231 45.2377 53.6625C35.6868 49.1909 27.2816 42.123 21.0131 33.6639C20.3658 32.7927 19.1698 33.7418 19.683 34.6408L19.6898 34.6401Z"
                    fill="#2589BD"
                  />
                  <path
                    d="M28.8833 54.9434C26.0938 46.9608 22.9805 39.0946 20.2106 31.1031L17.5564 33.0494C21.3942 35.7579 25.5015 38.095 29.7278 40.1315C33.3766 41.893 38.2293 44.7961 42.3809 44.5639C43.5464 44.4958 43.9037 42.9426 43.0686 42.2421C41.5124 40.9457 39.5155 40.4774 37.6489 39.7486C35.6034 38.9495 33.5839 38.0722 31.5987 37.1295C27.469 35.1651 23.556 32.8486 19.6911 30.4036C18.3538 29.5572 16.3415 30.6335 17.0369 32.3499C20.2166 40.1954 23.4708 48.0329 27.4214 55.5159C27.8835 56.386 29.2191 55.916 28.8765 54.9441L28.8833 54.9434Z"
                    fill="#2589BD"
                  />
                  <path
                    d="M83.4442 60.2616C83.3441 59.3121 82.414 58.4913 81.4349 58.6357C80.4559 58.7801 79.7018 59.6277 79.8091 60.645C79.9093 61.5945 80.8393 62.4152 81.8184 62.2708C82.7975 62.1264 83.5515 61.2788 83.4442 60.2616Z"
                    fill="#2589BD"
                  />
                </svg>
              </div>
              <div className="flex w-full flex-col pt-[45px] text-center md:ml-[20px] md:text-left">
                <h3
                  className="sofia-pro-b mb-[4px] text-[22px]  leading-[1.2]"
                  {...storyblokEditable(blok)}>
                  {blok.title_3}
                </h3>
                <div
                  className="mt-[5px] text-18 leading-6 text-[#424B5B]"
                  {...storyblokEditable(blok)}>
                  <div className="m-auto max-w-[500px] md:m-0">
                    {render(blok?.paragraph_3)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-[30px] flex justify-center lg:mt-0">
          <a
            href={blok?.button?.url}
            className="sofia-pro-m f-full xs:max-w-[192px] xs:w-fit h-max w-[90%] rounded-8 bg-gl-blue py-3 px-[55px] text-center text-white transition-all hover:bg-[#07496c] lg:w-fit">
            Get started
          </a>
        </section>
      </div>
    </div>
  );
}

import { NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer";
import React from "react";

export default function AccordionText({ blok }) {
  const descriptionOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return <p className="mb-4">{children}</p>;
      }
    }
  };

  return (
    <div className="line-through-[1.1] py-6 text-[18px] md:text-[22px]">
      {render(blok.description, descriptionOptions)}
    </div>
  );
}

import DynamicComponent from "../../../services/DynamicComponentsRenderer";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { storyblokEditable } from "@storyblok/react";

export default function ProductContainer({ blok, data }) {
  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: "snap",
    rtl: false,
    slides: { perView: "auto" }
  });

  return (
    <>
      <div className="relative mx-auto mt-[55px] mb-[60px] flex h-full w-full max-w-[120rem] flex-col !overflow-hidden rounded-8 px-[18px] lg:mb-[94px] lg:px-[48px] xl:px-[48px]">
        <div className="mx-auto flex w-full max-w-[81.5rem] flex-col justify-start overflow-hidden rounded-8 align-middle">
          <div {...storyblokEditable(blok)} className="h-fit w-fit">
            <h3 className="sofia-pro-s text-[28px] leading-[1.1] lg:text-[34px]">
              {blok.title}
            </h3>
          </div>
        </div>
        <div className="mx-auto flex w-full max-w-[81.5rem] flex-col pr-[40px] lg:pr-0">
          <div
            ref={sliderRef}
            className="keen-slider mt-[40px] grid grid-cols-3 gap-[20px] !overflow-visible overflow-x-scroll scrollbar-hide"
            {...storyblokEditable(blok)}
          >
            {blok?.products?.map(customBlok => (
              <DynamicComponent
                key={customBlok._uid}
                blok={customBlok}
                {...data}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

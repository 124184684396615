const domain = process.env.SHOPIFY_STORE_DOMAIN;
const storefrontAccessToken = process.env.SHOPIFY_STOREFRONT_ACCESSTOKEN;

export class ShopifyService {
  constructor() {
    this.domain = domain;
    this.storefrontAccessToken = storefrontAccessToken;
  }

  async fetchShopifyData(query) {
    const URL = `https://${domain}/api/2022-10/graphql.json`;

    const options = {
      endpoint: URL,
      method: "POST",
      headers: {
        "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ query })
    };

    try {
      const data = await fetch(URL, options).then(response => {
        return response.json();
      });

      return data;
    } catch (error) {
      throw new Error("Products not fetched");
    }
  }
}

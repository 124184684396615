import React from "react";

type BannerProps = {
  blok: {
    banner_content: string;
  };
};

const Banner = ({ blok: { banner_content } }: BannerProps) => {
  return (
    <header className="bg-[#15233c] px-2 py-2.5 text-center">
      <p className="text-[13px] font-semibold uppercase leading-[20px] text-white">
        {banner_content}
      </p>
    </header>
  );
};

export default Banner;

const LeftChevron = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98427 12.87C7.81753 13.0283 7.59866 13.1032 7.32767 13.0949C7.05677 13.0866 6.83377 12.9991 6.65866 12.8325L1.44344 7.91982C1.20998 7.70316 1.05365 7.4448 0.974449 7.14476C0.895236 6.84475 0.895236 6.54475 0.974449 6.24474C1.05365 5.94473 1.20998 5.68639 1.44344 5.46972L6.65866 0.569571C6.85041 0.386235 7.07342 0.290399 7.32767 0.282062C7.58201 0.273733 7.79674 0.357068 7.97185 0.532066C8.1636 0.707072 8.26362 0.927913 8.2719 1.19459C8.28027 1.46126 8.18858 1.68627 7.99682 1.86961L2.85666 6.69475L7.99682 11.5323C8.18021 11.7074 8.2719 11.9282 8.2719 12.1949C8.2719 12.4616 8.17603 12.6866 7.98427 12.87Z"
      fill="#182345"
    />
  </svg>
);
export default LeftChevron;

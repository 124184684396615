import { motion } from "framer-motion";
import { LinkVariant } from "@components/UI";

import DynamicComponent from "@services/DynamicComponentsRenderer";

const ContactCardsSection = ({ blok }) => {
  const openChat = () => {
    // @ts-ignore
    if (window?.GorgiasChat && !window?.GorgiasChat.isOpen()) {
      // @ts-ignore
      window.GorgiasChat.open();
    }
  };
  const { cards_list, photos_image } = blok;
  return (
    <>
      <div className="mx-auto mt-[30px] mb-[20px] flex w-full max-w-[65rem] max-w-[120rem] flex-col px-[16px] lg:mt-[50px] lg:mb-[100px]">
        <div className="mx-auto w-full max-w-[65rem]  align-middle">
          <div className="flex w-full flex-row justify-center px-[18px] lg:px-[48px] xl:px-[48px] ">
            {photos_image?.filename && (
              <img
                src={photos_image?.filename}
                className="z-[2] h-[68px] lg:h-[90px]"
              />
            )}
          </div>
        </div>
        <div className="mt-[25px] grid h-full w-full grid-cols-1 gap-[15px] lg:mt-[-45px] lg:grid-cols-2 lg:gap-[20px]">
          {cards_list.map(card => {
            const {
              icon_image,
              back_ground_color,
              blocks,
              button_text,
              button_link,
              is_gorgiasChat_btn
            } = card;
            return (
              <div key={card._uid}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.4,
                    delay: 1.4
                  }}
                  className={`flex flex-col items-center justify-center rounded-8 py-[60px] px-[20px] text-center align-middle`}
                  style={{
                    backgroundColor: back_ground_color?.color || "white"
                  }}
                >
                  <div>
                    <img src={icon_image?.filename} />
                  </div>
                  <div className=" mx-auto max-w-[20rem] space-y-[10px] lg:my-[30px]">
                    {blocks?.map(customBlok => (
                      <DynamicComponent
                        key={customBlok._uid}
                        blok={customBlok}
                      />
                    ))}
                  </div>
                  <div>
                    {is_gorgiasChat_btn ? (
                      <button
                        className="sofia-pro-r w-[191px] items-center rounded-8 bg-[#2589BD] py-[10px] text-18 text-white"
                        onClick={openChat}
                      >
                        Chat online
                      </button>
                    ) : (
                      <LinkVariant
                        href={button_link?.cached_url}
                        linkType={"buttonLink"}
                        variant={"blue"}
                        className="sofia-pro-r block w-[191px] items-center rounded-8 py-[10px] text-18 text-white"
                      >
                        {button_text}
                      </LinkVariant>
                    )}
                  </div>
                </motion.div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default ContactCardsSection;

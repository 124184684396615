import { storyblokEditable } from "@storyblok/react";

export default function Instruction({ blok }) {
  return (
    <>
      <div
        className="flex w-full flex-row items-center gap-[25px] border-b border-b-[#E9E9E9] py-[20px] lg:gap-[10px]"
        {...storyblokEditable(blok)}
      >
        <div className="flex h-full w-[50px] lg:w-[40px]">
          <h2 className="sofia-pro-b text-[59px] leading-[1] text-[#2489CA80]">
            {blok.number}
          </h2>
        </div>
        <div className="flex flex-col space-y-[8px]">
          <h3 className="sofia-pro-r text-[20px] leading-[1]">{blok.title}</h3>
          <p className="sofia-pro-r text-[20px] leading-[1] text-[#424B5B]">
            {blok.paragraph}
          </p>
        </div>
      </div>
    </>
  );
}

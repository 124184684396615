import React, { useContext, useEffect } from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";

import { ShopContext } from "@context/shopContext";
import { QuizResultsProcessor } from "@services/QuizResultsService";
import { answersClickManager } from "../utils";

const AnswerVariantItem = props => {
  const { answer, isSelected, onSelect, isStretchedMobileCard } = props;

  const wrapperClass = isSelected
    ? " selected border-[3px] border-gl-blue"
    : "border-[3px] border-transparent";

  const variants = {
    enter: {
      opacity: 1,
      y: -20,
      transition: { staggerChildren: 0.5, delayChildren: 0.2 }
    },
    exit: {
      y: 0,
      opacity: 0,
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const image = answer?.answer_img;
  return (
    <motion.div
      variants={variants}
      className={`${wrapperClass} cursor-pointer rounded-8 hover:border-gl-blue ${
        isStretchedMobileCard ? "w-full" : "mb-[16px] w-[30%] lg:w-[18%]"
      } bg-white  sm:w-fit`}
      onClick={onSelect}
      {...storyblokEditable(answer)}
    >
      <div
        className={`flex rounded-8 text-center align-middle transition-colors sm:h-[250px]  sm:flex-col  sm:items-start sm:justify-around ${
          isStretchedMobileCard
            ? "h-[97px]  items-center px-[17px] sm:w-[250px] "
            : "flex-col items-center px-[22px] pt-[14px] pb-[27px] sm:w-[195px]"
        }`}
      >
        <div
          className={`relative flex h-[65px] w-[65px] sm:mx-auto sm:h-[124px] sm:w-[124px] ${
            isStretchedMobileCard && "sm:items-center"
          }`}
        >
          <Image src={image?.filename} alt={image?.alt} layout="fill" />
        </div>
        <div
          className={`flex w-fit flex-col  justify-center md:text-left ${
            isStretchedMobileCard
              ? "ml-3 text-left sm:ml-0"
              : "pt-[14px] text-center sm:pt-0"
          } `}
        >
          <h3 className="sofia-pro-s text-[20px] lg:text-[22px]">
            {" "}
            {answer.answer_value}
          </h3>
          <p className="text-[20px] sm:text-18">{answer?.description}</p>
        </div>
      </div>
    </motion.div>
  );
};

export default AnswerVariantItem;

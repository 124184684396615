import QuestionAnswersListSingle from "@components/QuestionAnswersListSingle";
import QuestionAnswersListMultiple from "@components/QuestionAnswersListMultiple";

const AnswersVariantsRenderer = props => {
  const { isMultipleAnswers, answers } = props;
  return isMultipleAnswers ? (
    <QuestionAnswersListMultiple variant={answers} {...props} />
  ) : (
    <QuestionAnswersListSingle variant={answers} {...props} />
  );
};
export default AnswersVariantsRenderer;

import { isEmpty } from "lodash";

import { formatter } from "@utils/helpers";

export class ShopifyCartLineDAO {
  #title;
  #handle;
  #optionName;
  #attributes;
  #quantity;
  #merchandise;
  #sellingPlanAllocation;
  #tagsList;

  constructor(shopifyCartLineData, cartCurrency) {
    if (isEmpty(shopifyCartLineData)) {
      return null;
    }
    this.currency = cartCurrency;
    this.cartLine = shopifyCartLineData;
    this.#merchandise = shopifyCartLineData.merchandise;
    this.#title = this.#merchandise.product.title;
    this.#handle = this.#merchandise.product.handle;
    this.#tagsList = this.#merchandise.product.tags;
    this.#optionName = this.#merchandise.title;
    this.#attributes = shopifyCartLineData.attributes;
    this.#sellingPlanAllocation = shopifyCartLineData.sellingPlanAllocation;
    this.#quantity = shopifyCartLineData.quantity;
  }

  get handle() {
    return this.#handle;
  }

  get title() {
    return this.#title;
  }

  get optionName() {
    return this.#optionName;
  }

  get price() {
    const subscriptionPrice =
      this.#sellingPlanAllocation?.checkoutChargeAmount.amount;
    const cartLineItemPrice = this.#merchandise.priceV2.amount;
    return formatter(subscriptionPrice || cartLineItemPrice, this.currency);
  }

  get availableSubscritions() {
    const list =
      this.#merchandise?.product?.sellingPlanGroups?.edges[0]?.node
        ?.sellingPlans?.edges || [];
    return list.map(({ node }) => {
      const number = Number(node.name.replace(/[^0-9\.]+/g, "")); // eslint-disable-line
      return {
        id: node.id,
        name: `Every ${number} month${number > 1 ? "s" : ""}`
      };
    });
  }

  get selectedSellingPlanId() {
    return this.#sellingPlanAllocation?.sellingPlan?.id;
  }

  get quantity() {
    return this.#quantity;
  }

  get sellingPlanName() {
    return this.cartLine.sellingPlanAllocation?.sellingPlan.name;
  }

  get attributeString() {
    return (
      this.#attributes.length &&
      `${this.attributes[0].key}:${this.attributes[0].value}`
    );
  }

  get cartLineDataForActions() {
    return {
      productCartData: this.#merchandise,
      sellingPlanAllocation: this.#sellingPlanAllocation
    };
  }

  get image() {
    return {
      url: this.#merchandise.image?.url,
      alt: this.#merchandise.image?.altTeat
    };
  }

  get firstTagValueAndHandle() {
    if (isEmpty(this.#tagsList)) {
      return {};
    }
    const tag = this.#tagsList.length ? this.#tagsList?.[0] : null;
    const handle = this.#handle;
    return { tag, handle };
  }
}

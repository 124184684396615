import Image from "next/image";
import { isEmpty } from "lodash";

const QuizSearchingView = ({
  search_inprogress_image: searchInProgressImage
}) => {
  if (isEmpty(searchInProgressImage)) {
    return null;
  }
  return (
    <div className="relative mx-auto flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-[#edf2f4]">
      <h3 className="sofia-pro-b grid place-items-center text-[30px] lg:text-[34px]">
        Preparing your results...
      </h3>
      <div className="mt-[10px]">
        <img src="/quiz_sloth_loading.svg" alt={searchInProgressImage?.alt} />
      </div>
    </div>
  );
};

export default QuizSearchingView;

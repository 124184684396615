import {
  render,
  MARK_BOLD,
  MARK_LINK
} from "storyblok-rich-text-react-renderer";
import { useMediaQuery } from "@hooks/index";

const Title = props => {
  const isMobile = useMediaQuery(`(max-width: ${580}px)`);
  const renderOptionsDefault = {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { href } = props;
        return (
          <a
            href={href}
            className="underline-[#2589BD] underline-2 text-[#2589BD] underline-offset-2"
          >
            {children}
          </a>
        );
      },
      [MARK_BOLD]: children => {
        return <b className="sofia-pro-b text-[#424B5B]">{children}</b>;
      }
    }
  };
  const {
    blok: {
      alignment = "center",
      font_size: fontSize = 34,
      block_title: blokTitle,
      margin_bottom: marginBottom = 0,
      max_width: maxWidth = 50
    },
    renderOptions = renderOptionsDefault
  } = props;
  const textFontSize = isMobile ? Math.round(fontSize * 0.9) : fontSize;
  return (
    <div
      className={`sofia-pro-b mb-[15px] flex leading-[1.1] justify-${alignment}`}
      style={{
        marginBottom: `${isMobile ? marginBottom * 0.8 : marginBottom}px`,
        fontSize: `${textFontSize}px`
      }}
    >
      <div className={`w-[${maxWidth}rem] text-${alignment}`}>
        {render(blokTitle, renderOptions)}
      </div>
    </div>
  );
};

export default Title;

/* eslint-disable react/jsx-key */
/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";

export default function Article({ article }) {
  return (
    <>
      <div
        className="relative flex h-fit w-full flex-col lg:flex-row"
        {...storyblokEditable(article)}
      >
        <div className="flex h-full w-full items-end justify-end lg:hidden">
          <a href={`${article.full_slug}`}>
            <Image
              width="860"
              height="500"
              src={article?.thumbnail_image?.filename}
              alt=""
              className="flex h-full w-full cursor-pointer rounded-8 object-cover"
            />
          </a>
        </div>
        <div className="mb-[15px] mt-[10px] flex w-full max-w-[25rem] flex-col items-start justify-center lg:mb-0">
          <div>
            {article?.tag_list?.map(tag => (
              <div
                key={`${tag}`}
                className="mt-[11px] mb-[14px] h-[26px] justify-center rounded-4 bg-[#F5F9FF] px-[12px] text-center"
              >
                <span className="sofia-pro-s text-[16px] leading-[1]">
                  {tag}
                </span>
              </div>
            ))}
          </div>
          <div>
            <a href={`${article.full_slug}`}>
              <h1 className="sofia-pro-s text-[26px] leading-[1.1] lg:text-[28px]">
                {article?.title}
              </h1>
            </a>
          </div>
          <div className="mt-[12px]">
            {" "}
            <a href={`${article.full_slug}`}>
              <span className="sofia-pro-r text-18 leading-[1.1] text-[#15233C99]">
                Read more {">"}
              </span>
            </a>
          </div>
        </div>
        <div className="hidden h-full w-full items-end justify-end lg:flex">
          <a href={`${article.full_slug}`}>
            <Image
              width="860"
              height="500"
              src={`${article?.thumbnail_image?.filename}` + "/m/"}
              alt=""
              className="flex h-full w-full cursor-pointer rounded-8 object-cover"
            />
          </a>
        </div>
      </div>
    </>
  );
}

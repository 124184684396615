import React, { useContext, useState, useEffect } from "react";
import { isEqual } from "lodash";
import { Button } from "@components/UI";

import { IProduct } from "@interfaces/product";
import { ShopContext } from "@context/shopContext";
import { ShopifyProductDAO } from "@services/ShopifyProductDAO";

const ProductCardOptions = ({ data }: ProductCardOptionsProps) => {
  const { product, setPrice, price } = data;
  const { addToCart, cartCurrency } = useContext(ShopContext);
  const shopifyProductDAO = new ShopifyProductDAO(product, cartCurrency);
  const { options, firstVariant, allVariants, defaultOptionsObject } =
    shopifyProductDAO;
  const [available, setAvailable] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(firstVariant);
  const [selectedOptions, setSelectedOptions] = useState(defaultOptionsObject);

  const setOptions = (name: string, value: string) => {
    const selection = {
      ...selectedOptions,
      [name]: value
    };
    setSelectedOptions(selection);
    const newSelectedVariant = allVariants.find(({ options }) =>
      isEqual(options, selection)
    );
    if (newSelectedVariant) {
      setSelectedVariant(newSelectedVariant);
      setPrice(newSelectedVariant.variantPrice);
      setAvailable(newSelectedVariant.availableForSale);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-wrap items-center"></div>
      {available && (
        <a
          className="sofia-pro-s h-fit w-full items-center justify-center rounded-8 bg-[#2589BD] px-[5px] py-[10px] text-center text-[14px] text-white"
          onClick={() => addToCart(selectedVariant)}
        >
          ADD TO CART • <span className="sofia-pro-r">{price}</span>
        </a>
      )}
    </>
  );
};

export default ProductCardOptions;

type ProductCardOptionsProps = {
  data: {
    price: number;
    product: IProduct;
    shown_options_number: number;
    sold_out_button_label: string;
    quick_add_button_label: string;
    setPrice: (price: string) => void;
  };
};

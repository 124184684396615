import React, { useState, useEffect } from "react";

import { useKeenSlider } from "keen-slider/react";
import { storyblokEditable } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";

import ReviewSliderCard from "../ReviewSliderCard";

import { fetchAllReviewsAPI } from "../../../../lib/api";

import "keen-slider/keen-slider.min.css";

const ThumbnailPlugin = mainRef => {
  return slider => {
    function removeActive() {
      slider.slides.forEach(slide => {
        slide.classList.remove("bg-active");
      });
    }

    function addActive(idx) {
      slider.slides[idx].classList.add("bg-active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", main => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
};

const ReviewsSlider = ({ blok, data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 1,
    loop: true,
    slides: {
      origin: "center",
      perView: 3,
      spacing: 25
    },
    breakpoints: {
      "(max-width: 600px)": {
        slides: {
          perView: 1
        }
      }
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });

  const getReviews = async () => {
    const reviews_ = await fetchAllReviewsAPI();
    setReviews(reviews_.reviews);
  };
  useEffect(() => {
    getReviews().then();
  }, []);

  const [thumbnailRef] = useKeenSlider(
    {
      initial: 1,

      slides: {
        origin: "center",
        perView: 3,
        spacing: 25
      },
      breakpoints: {
        "(max-width: 600px)": {
          slides: {
            perView: 1
          }
        }
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      }
    },
    [ThumbnailPlugin(instanceRef)]
  );

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }, []);

  const renderOptions = {
    markResolvers: {
      [MARK_LINK]: (children, { href, target }) => {
        return (
          <a
            href={href}
            target={target}
            className={`cursor-pointer underline hover:no-underline`}>
            {children}
          </a>
        );
      }
    }
  };

  return (
    <div className="mx-auto mb-[60px] flex w-full max-w-[120rem] overflow-hidden lg:mb-[100px]">
      <div className="mx-auto flex w-full flex-col justify-center">
        <div className="flex w-full justify-center px-[18px] lg:px-[48px] xl:px-[48px]">
          {blok?.blocks?.map(blok => (
            <StoryblokComponent
              blok={blok}
              key={blok._uid}
              {...storyblokEditable(blok)}
            />
          ))}
        </div>
        <div>
          <div className="relative mx-auto mt-[44px] flex w-full max-w-[120rem] flex-col">
            {reviews.length && (
              <div ref={thumbnailRef}>
                <div
                  ref={sliderRef}
                  className="keen-slider thumbnail-test max-w-[120rem]">
                  {reviews?.map(review => (
                    <ReviewSliderCard
                      key={review.id}
                      review={review}
                      parent={blok}
                      {...data}
                    />
                  ))}
                </div>
              </div>
            )}
            {loaded && instanceRef?.current && (
              <div className="mt-[40px] flex w-full justify-center gap-[8px]">
                {[
                  //@ts-ignore
                  ...Array(
                    instanceRef?.current?.track?.details?.slides?.length
                  ).keys()
                ].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={
                        "dot-reviews h-[12px] w-[12px] rounded-full !border border-[#1d7919] bg-white" +
                        (currentSlide === idx ? " active !bg-[#15233C]" : "")
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {blok.description && (
          <div className="mt-10 px-4 text-center text-[20px]">
            {render(blok.description, renderOptions)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewsSlider;

import DynamicComponent from "@services/DynamicComponentsRenderer";

export default function SmallBoxContainer({ blok }) {
  return (
    <>
      <div className="mt-[20px] grid grid-cols-2 justify-between gap-[16px] lg:gap-[20px]">
        {blok?.products?.map(blok => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}
      </div>
    </>
  );
}

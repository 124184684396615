import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import QuestionSection from "../QuizQuestionSection";

export default function CalculateBlock({ blok }) {
  const [familySize, setFamilySize] = useState("Small");
  const [washingFrequency, setWashingFrequency] = useState("Often");
  const [plasticPieces, setPlasticPieces] = useState(0);
  const [previousPlasticPieces, setPreviousPlasticPieces] = useState(0);

  const getAnswer = (idx: number, value: string) => {
    return blok.questions[idx].answers.find(
      item => item.answer_value === value
    );
  };

  useEffect(() => {
    if (familySize && washingFrequency) {
      const size = getAnswer(0, familySize);
      const pices = getAnswer(1, washingFrequency);

      const plastic = size.carbon_index * pices.carbon_index;
      setPreviousPlasticPieces(plasticPieces);
      setPlasticPieces(plastic);
    }
  }, [familySize, washingFrequency]);

  const displayQuestions = () => {
    return blok.questions.map((question, idx) => {
      const questionBlock = {
        ...question,
        id: idx,
        is_multi_answers_allowed: false
      };
      return (
        <QuestionSection
          key={question._uid}
          blok={questionBlock}
          data={{
            isActivePage: null,
            setShowNextBtn: null,
            page: "earth",
            value: question.question.includes("family")
              ? familySize
              : washingFrequency,
            setValue: question.question.includes("family")
              ? setFamilySize
              : setWashingFrequency
          }}
        />
      );
    });
  };

  return (
    <>
      <div className="mx-auto mb-[40px] flex w-full max-w-[120rem] px-[0] lg:mb-[100px] lg:px-[48px] xl:px-[48px]">
        <div className="relative mx-auto flex h-full w-full max-w-[81.5rem] flex-col justify-center overflow-hidden bg-[#F5F9FA] px-[25px] py-[100px] text-center lg:rounded-8">
          <div className="mb-[25px] lg:mb-[45px]">
            <h3 className="sofia-pro-b text-[36px] leading-[1.1] lg:text-[44px]">
              {blok.title}
            </h3>
          </div>
          {displayQuestions()}
          <div className="mt-[10px]">
            <div className="flex h-full w-full flex-col items-center justify-center space-y-[8px] align-middle">
              <p className="sofia-pro-s text-18 uppercase leading-[1] text-[#2589BD] lg:text-[20px]">
                {blok.result_text}
              </p>
              <h3 className="sofia-pro-b max-w-[20rem] text-[34px] leading-[1] lg:max-w-full lg:text-[44px]">
                <CountUp
                  start={previousPlasticPieces}
                  end={plasticPieces}
                  duration={1}
                  separator=","
                />
                {blok.result_mask}
              </h3>
              <div className="pt-[25px] lg:pt-[35px]">
                <a
                  href="/quiz"
                  className="sofia-pro-m w-[190px] rounded-8 bg-[#2589BD] px-[12px] py-[10px] text-18 leading-[1] text-white"
                >
                  {blok.link_text}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

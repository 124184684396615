import { render } from "storyblok-rich-text-react-renderer";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { storyblokEditable } from "@storyblok/react";

import DynamicComponent from "@services/DynamicComponentsRenderer";

const CollectionSlider = ({ blok }) => {
  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: "snap",
    rtl: false,
    slides: { perView: "auto" }
  });
  return (
    <div className="mx-auto mb-[70px] flex w-full max-w-[120rem] flex-col !overflow-hidden px-[18px] lg:mb-[140px] lg:px-[48px] xl:px-[48px]">
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col">
        <div className="flex flex-col space-y-[10px]">
          <h3
            className="sofia-pro-s text-[30px] leading-[1] lg:text-[34px]"
            {...storyblokEditable(blok)}
          >
            {blok?.title}
          </h3>
          <div
            className="sofia-pro-r flex flex-row items-center text-18 text-[#424B5B]"
            {...storyblokEditable(blok)}
          >
            {render(blok?.paragraph)}{" "}
            <a
              href={blok?.link?.cached_url}
              className="underline-blue relative ml-[6px] inline-flex items-center"
              {...storyblokEditable(blok)}
            >
              {blok?.link_text}
              <span className="mt-1 h-full items-center pl-1">
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.884 5.36606L9.74235 5.29515L9.74237 5.29486H9.73305H1.53331C1.32952 5.29486 1.152 5.36168 1.01457 5.50192C0.877974 5.6413 0.812891 5.81886 0.812891 6.02176C0.812891 6.22466 0.877974 6.40221 1.01457 6.54159C1.152 6.68184 1.32952 6.74866 1.53331 6.74866H9.73305V6.74894L9.74235 6.74837L10.8895 6.67711L9.01637 8.38039L9.01621 8.38022L9.01028 8.38625L7.55926 9.86322C7.5591 9.86338 7.55893 9.86354 7.55877 9.86371C7.49111 9.9315 7.44072 10.0118 7.40765 10.1028L7.40764 10.1028C7.37559 10.1909 7.35972 10.2828 7.35972 10.3776C7.35972 10.5753 7.42816 10.7476 7.56677 10.8834C7.70458 11.0185 7.87699 11.0851 8.07366 11.0851C8.2729 11.0851 8.44957 11.006 8.59921 10.8615L8.59923 10.8615L8.60092 10.8598L12.9106 6.56308C13.0658 6.41272 13.15 6.23046 13.15 6.02176C13.15 5.81306 13.0658 5.6308 12.9106 5.48044L8.614 1.19678C8.53466 1.11746 8.44887 1.05515 8.35587 1.01569L8.29728 1.15377L8.35587 1.01569C8.26596 0.977539 8.17149 0.958398 8.07366 0.958398C7.877 0.958398 7.70459 1.02504 7.56678 1.16006L7.56676 1.16008C7.42817 1.29591 7.35972 1.46822 7.35972 1.66585C7.35972 1.76067 7.37559 1.85259 7.40764 1.94074M10.884 5.36606L9.01622 3.66295L9.01012 3.6574L9.01028 3.65724L7.55927 2.1803C7.55909 2.18013 7.55892 2.17995 7.55875 2.17978C7.49109 2.11199 7.44071 2.03169 7.40764 1.94074M10.884 5.36606L11.1229 5.3809L9.11728 3.55211L7.66531 2.07422M10.884 5.36606L7.66531 2.07422M7.40764 1.94074L7.54861 1.88948M7.40764 1.94074C7.40764 1.94074 7.40764 1.94074 7.40764 1.94075L7.54861 1.88948M7.54861 1.88948C7.57454 1.96078 7.61344 2.02236 7.66531 2.07422M7.54861 1.88948C7.52268 1.81818 7.50972 1.74363 7.50972 1.66585C7.50972 1.50596 7.56373 1.37308 7.67175 1.26721L7.66531 2.07422"
                    fill="#424B5B"
                    stroke="#424B5B"
                    strokeWidth="0.3"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col pr-[40px] lg:pr-0">
        <div
          ref={sliderRef}
          className="keen-slider mt-[15px] flex flex-row justify-start gap-[16px] !overflow-visible overflow-y-scroll scrollbar-hide lg:mt-[30px] lg:gap-[18px]"
        >
          {blok?.products?.map(customBlok => (
            <DynamicComponent key={customBlok._uid} blok={customBlok} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CollectionSlider;

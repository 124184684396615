import ProductCard from "@components/ProductCard";

const ProductCardsGrid = ({ productsList, data = {} }) => (
  <>
    {productsList.map(({ shopifyProductData, uuid }) => (
      <ProductCard data={data} key={uuid} product={shopifyProductData} />
    ))}
  </>
);

export default ProductCardsGrid;

import React from "react";
import MiniCart from "../components/Cart";

const Layout = ({ children }) => {
  return (
    <div className="flex w-full flex-col justify-between">
      <main className="">{children}</main>
      <MiniCart />
    </div>
  );
};
export default Layout;

import React, { useState } from "react";

import Image from "next/image";
import CountUp from "react-countup";
import { storyblokEditable } from "@storyblok/react";
import VisibilitySensor from "react-visibility-sensor";
import { useMediaQuery } from "@src/hooks";
import { render } from "storyblok-rich-text-react-renderer";

export const Number = ({
  rest,
  blok,
  matches,
  number,
  before_number_sign,
  paragraph
}) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${640}px)`);
  const { after_number_sign, number_block_image } = blok;
  const number_fontsize = blok?.number_fontsize || 80;
  const number_paragraph_fontsize = blok?.number_paragraph_fontsize || 80;
  const easingFn = function (t, b, c, d) {
    return (c * (-Math.pow(2, (-10 * t) / d) + 1) * 1024) / 1023 + b;
  };

  return (
    <div className="relative flex flex-col items-center">
      {!isMobile && !!number_block_image?.filename && (
        <div className="absolute  left-[-17vw] top-[-2vh] h-[130px] w-[130px] lg:left-[-18vw] lg:top-[-10vh] lg:h-[300px] lg:w-[300px] 2xl:top-[-10vh] 2xl:left-[-13vw]">
          <Image
            src={number_block_image?.filename}
            layout="fill"
            objectFit="cover"
          />
        </div>
      )}
      {blok.number_title && (
        <div
          className={`flex flex-col gap-[5px] text-[${blok?.number_title_color?.color}] lg:gap-[15px]`}
        >
          <span className="sofia-pro-b text-[32px] leading-[1.1] lg:text-[34px]">
            {blok.number_title}
          </span>
        </div>
      )}
      <div
        className={`flex w-full justify-center text-[${blok?.number_color?.color}]`}
      >
        <CountUp
          {...rest}
          start={viewPortEntered ? 0 : number}
          end={number}
          {...storyblokEditable(blok)}
          useEasing={true}
          separator=","
          easingFn={easingFn}
        >
          {({ countUpRef }) => {
            return (
              <VisibilitySensor
                active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall
              >
                <div className="flex flex-col">
                  <span className="h-[0] select-none" ref={countUpRef}>
                    {"ㅤ"}
                  </span>
                  <div className="flex flex-row">
                    <span
                      className={`sofia-pro-b ml-[5px] leading-[1]`}
                      style={{
                        fontSize: `${
                          matches ? number_fontsize : number_fontsize / 2
                        }px`
                      }}
                    >
                      {before_number_sign}
                    </span>
                    <span
                      className={`sofia-pro-b leading-[1.1] `}
                      ref={countUpRef}
                      style={{
                        fontSize: `${
                          matches ? number_fontsize : number_fontsize / 2
                        }px`
                      }}
                    />
                    <span
                      className={`sofia-pro-b ml-[5px]  leading-[1]`}
                      style={{
                        fontSize: `${
                          matches ? number_fontsize : number_fontsize / 2
                        }px`
                      }}
                    >
                      {after_number_sign}
                    </span>
                  </div>
                </div>
              </VisibilitySensor>
            );
          }}
        </CountUp>
      </div>
      {paragraph && (
        <div className="mx-auto mt-[10px] mb-[10px] flex w-full max-w-[35rem] justify-center px-[22px] lg:mt-[20px]">
          <h3
            className={`sofia-pro-b px-[2rem] leading-[1.3] text-[${blok?.paragraph_color?.color}] lg:px-0`}
            {...storyblokEditable(blok)}
            style={{
              fontSize: `${
                matches
                  ? number_paragraph_fontsize
                  : number_paragraph_fontsize / 2
              }px`
            }}
          >
            {render(paragraph)}
          </h3>
        </div>
      )}
    </div>
  );
};

export default function Banner({ blok }) {
  return (
    <>
      <header className="sofia-pro-m bg-blue-green-gradient mx-auto flex w-full items-center justify-center overflow-hidden px-2 py-2.5 text-center">
        <div className="flex flex-col items-center gap-[12px] sm:flex-row md:flex-row lg:flex-row">
          <p className="sofia-pro-m leading-[20px] text-white lg:text-18">
            {blok.text}
          </p>
          <a
            href={blok.link.cached_url}
            className="sofia-pro-s  flex rounded-8 bg-[#ffffff] px-4 py-1 text-[14px] uppercase transition-all hover:bg-[#b5d7ed]"
          >
            {blok.link_text}
          </a>
        </div>
      </header>
    </>
  );
}

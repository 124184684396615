import { render } from "storyblok-rich-text-react-renderer";
import Link from "next/link";

export default function Tab({ blok }) {
  return (
    <>
      {blok.is_active ? (
        <div className="flex h-[62px] w-full cursor-default select-none flex-row items-center space-x-[10.5px] rounded-8 border-2 border-[#2589BD] py-[13px] px-[14px] lg:h-fit">
          <div className="h-[12px] w-[12px]">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6.65625" cy="6.521" r="6" fill={blok.fill_svg} />
            </svg>
          </div>
          <div className="flex">
            <h3 className="sofia-pro-m text-[14px] leading-[1.3]">
              {render(blok.text)}
            </h3>
          </div>
        </div>
      ) : (
        <Link scroll={false} href={blok.link.cached_url} passHref>
          <a className="flex h-full w-full rounded-8 border border-[#D7E1E4]">
            <div className="flex h-[60px] w-full cursor-pointer select-none flex-row items-center space-x-[10.5px] rounded-8 border border-transparent py-[13px] px-[14px] lg:h-fit">
              <div className="h-[12px] w-[12px]">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6.13672" cy="6.521" r="6" fill={blok.fill_svg} />
                </svg>
              </div>
              <div className="flex">
                <h3 className="sofia-pro-m text-[14px] leading-[1.3]">
                  {render(blok.text)}
                </h3>
              </div>
            </div>
          </a>
        </Link>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";

// libs
import { storyblokEditable } from "@storyblok/react";
import cn from "classnames";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LazyLoad from "react-lazyload";
import { useInView } from "react-intersection-observer";

declare var ratingSnippet;

// components
import { Product } from "@components/Home/WatchTheGood/Product/Product";

// Import Swiper styles
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);
export default function WatchTheGood({ blok }) {
  const [active, setActive] = useState(null);
  const [isTransition, setIsTransition] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  useEffect(() => {
    if (active !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    setTimeout(() => {
      setIsTransition(false);
    }, 500);
  }, [active]);

  useEffect(() => {
    const intervalIdRating = setInterval(() => {
      const selector = document.querySelector(".ruk_rating_snippet");
      if (selector && selector.getAttribute("data-sku")) {
        clearInterval(intervalIdRating);
        ratingSnippet("ruk_rating_snippet", {
          store: "goodlaundry.com",
          mode: "default",
          color: "#F1C532",
          linebreak: false,
          text: "Reviews",
          singularText: "Review",
          lang: "en",
          usePolaris: false,
          showEmptyStars: true
        });
      }
    }, 500);
  }, [active]);

  return (
    <div
      ref={ref}
      className="mx-auto mb-[50px] mt-[32px] flex w-full max-w-[120rem] px-[18px] lg:mb-[100px] lg:mt-[75px] lg:px-[48px] xl:px-[48px]">
      {blok.videos[active]?.video_id && (
        <div className="fixed left-0 top-0 z-[60] h-full w-full">
          <div
            className="absolute left-4 top-4 z-[70] h-10 w-10 cursor-pointer rounded-full bg-white/20 p-3 transition-all hover:opacity-50"
            onClick={() => setActive(null)}>
            <img className="h-full w-full" src="/close.svg" alt="" />
          </div>
          <div className="absolute left-0 top-0 z-10 h-full w-full bg-[#15233C]/30 backdrop-blur-xl" />
          <div
            className={cn(
              "relative z-10 flex h-full w-full flex-col md:flex-row",
              { "animation-opacity": isTransition }
            )}>
            <div className="relative mx-auto h-[calc(100%-213px)] w-full md:h-full md:px-20">
              <div
                className="absolute top-0 left-0 z-10 hidden h-full w-20 cursor-pointer items-center justify-center transition-all hover:opacity-50 md:flex"
                onClick={() => {
                  setIsTransition(true);
                  setActive(active <= 0 ? blok?.videos.length - 1 : active - 1);
                }}>
                <img
                  className="w-3/12 select-none"
                  src="/arrow-long.svg"
                  alt=""
                />
              </div>
              <div
                className="absolute top-0 right-0 z-10 flex hidden h-full w-20 cursor-pointer items-center justify-center transition-all hover:opacity-50 md:flex"
                onClick={() => {
                  setIsTransition(true);
                  setActive(active >= blok?.videos.length - 1 ? 0 : active + 1);
                }}>
                <img
                  className="w-3/12 rotate-180 select-none"
                  src="/arrow-long.svg"
                  alt=""
                />
              </div>
              {!isTransition && (
                <div className="mx-auto flex h-full items-center justify-center">
                  <LazyLoad once offset={100}>
                    <video
                      className="video-modal"
                      controls={true}
                      preload=""
                      poster=""
                      autoPlay
                      muted={false}
                      loop={false}
                      playsInline>
                      <source
                        src={`https://res.cloudinary.com/dfw0vrsfj/video/upload/w_auto/f_auto,q_auto/videos/${blok.videos[active]?.video_id}.mp4`}
                      />
                    </video>
                  </LazyLoad>
                </div>
              )}
            </div>
            {blok.videos[active]?.products.length > 0 && (
              <div className="w-full min-w-[365px] px-4 py-4 md:w-[365px] md:bg-white md:px-7">
                <h3 className="sofia-pro-s mb-0 border-[#E9E9E9] text-center text-[22px] md:border-b md:pb-4">
                  {blok.videos[active]?.products.length}{" "}
                  {blok.videos[active]?.description}
                </h3>

                <div className="flex gap-4 overflow-auto py-4 md:block">
                  {blok.videos[active]?.products.map((product, index) => (
                    <Product key={index} product={product} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col">
        <div className="mb-[22px]">
          <h3
            className="sofia-pro-b text-[30px] text-[#15233C] lg:text-[34px]"
            {...storyblokEditable(blok)}>
            {blok.title}
          </h3>
        </div>
        <div className="no-scrollbar flex items-start gap-4 overflow-auto sm:gap-5">
          <Swiper
            className="watch-slider"
            slidesPerView="auto"
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: true
            }}>
            {blok?.videos.map((video, index) => (
              <SwiperSlide
                onClick={() => setActive(index)}
                key={video._uid}
                {...storyblokEditable(blok)}>
                <img className="play-icon" src="/play.svg" alt="" />
                <div className="absolute left-0 top-0 z-20 h-full w-full cursor-pointer bg-black opacity-0 transition-all hover:opacity-30"></div>
                {inView && (
                  <video
                    className="h-full w-full object-cover"
                    controls={false}
                    preload=""
                    poster=""
                    autoPlay
                    muted
                    loop
                    playsInline>
                    <source
                      src={`https://res.cloudinary.com/dfw0vrsfj/video/upload/w_auto/f_auto,q_auto/videos/${video.video_thumbnail_id}.mp4`}
                    />
                  </video>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

import { render } from "storyblok-rich-text-react-renderer";

export default function Policy({ blok }) {
  return (
    <>
      <div>
        <div className="mb-[10px]">
          <h3 className="sofia-pro-b text-[32px] lg:text-[34px]">
            {blok.title}
          </h3>
        </div>
        <div className="sofia-pro-r flex flex-col gap-[20px] text-[18px]">
          {render(blok?.text)}
        </div>
      </div>
    </>
  );
}

import {
  NODE_BR,
  NODE_HEADING,
  NODE_LI,
  NODE_PARAGRAPH,
  NODE_UL,
  render
} from "storyblok-rich-text-react-renderer";
import React from "react";

const Want = ({ blok: { title, description, image, content, notice } }) => {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h3
            className={`sofia-pro-s mb-5 text-[32px] leading-[1.15] lg:mb-10 lg:text-[34px]`}>
            {children}
          </h3>
        );
      }
    }
  };

  const renderDescriptionOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h4 className={`mb-5 text-[24px] leading-[1.15]`}>{children}</h4>
        );
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r mb-5 text-[20px] leading-[1.2]`}>
            {children}
          </p>
        );
      }
    }
  };

  const renderContentOptions = {
    nodeResolvers: {
      [NODE_BR]: () => {
        return <br className={`hidden lg:block`} />;
      },
      [NODE_HEADING]: children => {
        return <h4 className={`text-[24px] leading-[1.15]`}>{children}</h4>;
      },
      [NODE_LI]: children => {
        return (
          <li className={`sofia-pro-r mb-0 text-[20px] leading-[1.34]`}>
            {children}
          </li>
        );
      },
      [NODE_UL]: children => {
        return <ul className={`mt-1 mb-9 list-disc pl-7`}>{children}</ul>;
      }
    }
  };

  return (
    <div
      id="want-to-laundry-tester"
      className="relative z-[1] bg-[#F5EDED] pt-16 pb-20 md:pt-24 md:pb-32 md:text-center">
      <div className="custom-container">
        {render(title, renderTitleOptions)}
        <div className="mx-auto max-w-[860px]">
          {render(description, renderDescriptionOptions)}
        </div>
        <div className="mt-8 mb-5 flex flex-wrap items-center justify-between md:mt-16 md:mb-14">
          <div className="w-full text-left md:w-[46%]">
            {render(content, renderContentOptions)}
          </div>
          <div className="j flex w-full flex-wrap justify-between gap-y-3 md:w-[49.5%]">
            <img className="w-full rounded" src={image.filename} alt="" />
          </div>
        </div>
        <p className="sofia-pro-r text-[20px] md:text-[24px]">{notice}</p>
      </div>
    </div>
  );
};

export default Want;

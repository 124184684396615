import { storyblokEditable } from "@storyblok/react";

export default function Title({ blok }) {
  return (
    <>
      <h3
        className="sofia-pro-b text-[28px] leading-[1] lg:text-[44px]"
        {...storyblokEditable(blok)}
      >
        {blok.title}
      </h3>
    </>
  );
}

import XSArticle from "./XSArticle/XSArticle";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import storyblokInstance from "@services/storyblok-service";
import { storyblokEditable } from "@storyblok/react";

export default function MostPopular({ blok }) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      const { data } = await storyblokInstance.get("cdn/stories", {
        starts_with: "blog/",
        resolve_relations: "author",
        filter_query: { component: { in: "post" } }
      });

      const filteredArticles = data.stories.filter(
        article => article.tag_list[0] != ""
      );

      setArticles(prev =>
        filteredArticles.map(article => {
          article.content.full_slug = article.full_slug;
          article.content.tag_list = article.tag_list;
          return article;
        })
      );
    };

    getArticles();
  }, []);

  return (
    <>
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 30,
          delay: 0.8,
          duration: 0.4
        }}
        className="z-[-10] mx-auto flex h-full w-full flex-col bg-[#F5F9FF]"
      >
        <div className="z-[0] mx-auto flex h-full w-full max-w-[84rem] flex-col overflow-auto scrollbar-hide">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.8,
              duration: 0.4
            }}
            className="flex flex-row py-[10px]"
          >
            <div>
              <div className="relative flex h-full max-h-full w-[200px] flex-shrink-0 flex-col px-[18px]">
                <div className="flex h-full flex-shrink-0 items-center align-middle">
                  <h1
                    className="sofia-pro-s text-[16px] leading-[1.4]"
                    {...storyblokEditable(blok)}
                  >
                    {blok.left_text}
                  </h1>
                </div>
              </div>
            </div>
            {blok.articles.map(article => {
              article.content.full_slug = article.full_slug;
              return (
                <XSArticle
                  article={article.content}
                  key={article.uuid}
                  {...storyblokEditable(article)}
                />
              );
            })}
          </motion.div>
        </div>
      </motion.div>
    </>
  );
}

import React from "react";

export default function NavButton({ blok }) {
  return (
    <>
      <a
        href={blok.link.cached_url}
        className="flex flex-shrink-0 items-center rounded-8 border bg-gl-blue py-[3px] px-[14px] leading-[1] text-white hover:bg-[#07496c]"
      >
        {blok.link_text}
      </a>
    </>
  );
}

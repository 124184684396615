import { storyblokEditable } from "@storyblok/react";

export default function Try({ blok }) {
  return (
    <>
      <div className="mx-auto flex w-full max-w-[120rem]">
        <div className="relative mx-auto flex h-full w-full flex-col overflow-hidden bg-[#f0f0f2]">
          <div className="mt-[200px] min-h-[400px] lg:mt-0 lg:h-full">
            <img
              src={`${blok?.image?.filename}` + "/m/"}
              className="lg:object-fit hidden h-full min-h-[400px] w-full object-cover lg:flex"
              {...storyblokEditable(blok)}
            />
            <img
              src={`${blok?.image_mobile?.filename}` + "/m/"}
              className="flex h-full min-h-[400px] w-full object-cover lg:hidden"
              {...storyblokEditable(blok)}
            />
          </div>
          <div className="absolute mx-auto w-full overflow-hidden px-[16px] pt-[100px] pb-[95px] lg:pt-[150px]">
            <div className="my-auto flex h-full w-full justify-center text-center align-middle">
              <div className="flex h-full w-full flex-col items-center justify-center space-y-[14px] align-middle">
                <h3
                  className="sofia-pro-b max-w-[20rem] text-[30px] leading-[1] lg:max-w-full lg:text-[34px]"
                  {...storyblokEditable(blok)}
                >
                  {blok.title}
                </h3>
                <p
                  className="sofia-pro-r text-18 leading-[1] text-gl-dark-blue lg:text-[20px]"
                  {...storyblokEditable(blok)}
                >
                  {blok.description}
                </p>
                <div className="pt-[10px] lg:pt-[25px]">
                  <a
                    href={blok.link.cached_url}
                    className="sofia-pro-m w-[290px] rounded-8 bg-[#2589BD] px-[12px] py-[10px] text-18 leading-[1] text-white"
                    {...storyblokEditable(blok)}
                  >
                    {blok.link_text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useMediaQuery } from "@src/hooks";

const ReviewSliderCard = ({ parent: { width, width_mobile }, review }) => {
  const isMedium = useMediaQuery("(max-width: 992px)");

  return (
    <div
      style={{
        maxWidth: isMedium
          ? width_mobile
            ? `${width_mobile}px`
            : "310px"
          : width
          ? `${width}px`
          : "630px",
        minWidth: isMedium
          ? width_mobile
            ? `${width_mobile}px`
            : "310px"
          : width
          ? `${width}px`
          : "630px"
      }}
      className="keen-slider__slide flex w-full justify-center overflow-hidden rounded-8 bg-[#FDFBFB]">
      <div className="flex h-fit w-full flex-shrink-0 flex-col justify-center rounded-8 px-[32px] py-[40px] text-center">
        <div className="flex flex-row justify-start space-x-[5px] text-center lg:justify-center">
          <div className="flex items-center">
            {Array.from(Array(5).keys()).map((i, index) => (
              <React.Fragment key={index}>
                {i < review.score ? (
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>First star</title>
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ) : (
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-300 dark:text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Fifth star</title>
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="mt-[10px] mb-[14px] justify-start space-y-[8px] text-left lg:justify-center lg:text-center">
          <h3 className={`sofia-pro-s text-[22px]`}>{review.title}</h3>
          <div className="sofia-pro-m w-full text-[17px] leading-[1.2]">
            {review.content}
          </div>
        </div>
        <div className="flex flex-row items-center justify-start space-x-[10px] text-center lg:justify-center">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.1445" r="10" fill="#83B052" />
            <path
              d="M9.77169 13.8544C9.58164 14.1459 9.32503 14.2916 9.00186 14.2916C8.84345 14.2916 8.6993 14.2568 8.56941 14.1871C8.43951 14.1174 8.31436 14.0065 8.19397 13.8544L5.87486 11.003C5.72912 10.8066 5.65625 10.6102 5.65625 10.4137C5.65625 10.2046 5.72754 10.0256 5.87011 9.87673C6.01267 9.72783 6.18534 9.65338 6.3881 9.65338C6.52116 9.65338 6.64314 9.68031 6.75403 9.73417C6.86492 9.78803 6.97739 9.89099 7.09144 10.0431L8.96384 12.4667L12.9082 6.13669C13.0856 5.85155 13.3074 5.70898 13.5736 5.70898C13.77 5.70898 13.9474 5.77235 14.1058 5.89907C14.2642 6.0258 14.3434 6.19371 14.3434 6.40282C14.3434 6.51053 14.3197 6.61667 14.2722 6.72122C14.2246 6.82577 14.1723 6.9224 14.1153 7.01111L9.77169 13.8544Z"
              fill="white"
            />
          </svg>
          <h4 className="sofia-pro-r text-[16px]">
            {review.name}, Verified Review
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ReviewSliderCard;

import { createContext, useState, useEffect, useCallback } from "react";

import { ShopifyCartService } from "@services/ShopifyCartService";
import { QuizResultsProcessor } from "@services/QuizResultsService";
import {
  cartServiceDataAdapter,
  buildSelectedBundleObject,
  getQuizProductsRecommendations
} from "@utils/helpers";
import { fetchData } from "../services";

import { updateItemCartMutationQuery } from "@services/ShopifyCartService/queries";

const domain = process.env.SHOPIFY_STORE_DOMAIN;

const ShopContext = createContext();

const shopifyCartService = new ShopifyCartService();

export default function ShopProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [cartId, setCartId] = useState(null);
  const [cartSubtotalAmount, setCartSubtotalAmount] = useState("");
  const [cartTotalAmount, setCartTotalAmount] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [cartLoading, setCartLoading] = useState(false);
  const [cartCurrency, setCartCurrency] = useState("");
  const [quizResults, setQuizResults] = useState([]);
  const [isAnyOptionChosen, setIsAnyOptionChosen] = useState(false);
  const [bundleProductItems, setBundleProductItems] = useState([]);
  const [maxBundleItemsQuantity, setMaxBundleItemsQuantity] = useState(0);
  const [openedBundleProduct, setOpenedBundleProduct] = useState({});
  const [rechargeSdk, setRechargeSdk] = useState(null);
  const [selection, setSelection] = useState(null);
  const [rechargeBundleId, setRechargeBundleId] = useState(null);
  const [cartAddonsList, setCartAddonsList] = useState([]);
  const [discountsPercentage] = useState(0);
  const [cartDiscountObject] = useState({});
  const [discountCodes, setDiscountCodes] = useState([]);
  const [cartDiscountsSetups, setCartDiscountsSetups] = useState({});
  const [activeQuizStep, setActiveQuizStep] = useState(null);
  const [quizQuestionsProgress, setQuizQuestionsProgress] = useState(null);
  const [totalCartItemsNumber, setTotalCartItemsNumber] = useState(null);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const [searchInProgress, setSearchLoading] = useState(false);

  const [currentBundleItemsQuantity, setCurrentBundleItemsQuantity] =
    useState(0);
  const initRecharge = async () => {
    // unstable library, import didn't work with nextjs.After library fix move as usual import
    const rechargeSdkInstance = await import("@rechargeapps/storefront-client");
    rechargeSdkInstance.initRecharge({
      storeIdentifier: domain
    });
    setRechargeSdk(rechargeSdkInstance);
  };

  const setExistingCart = async () => {
    const response = await shopifyCartService.latestCartData();

    if (response?.data?.cart && shopifyCartService.getCartDataFromStorage()) {
      cartDrawerDataUpdate();
      await shopifyCartService.setCheckoutUrlToFront(setCheckoutUrl);
    } else {
      await shopifyCartService.cartCreate();
      await shopifyCartService.setCheckoutUrlToFront(setCheckoutUrl);
      cartDrawerDataUpdate();
    }
    setCartCurrency(shopifyCartService.cartCurrency);
    setTotalCartItemsNumber(shopifyCartService.totalCartItems);
  };

  useEffect(() => {
    setExistingCart();
    initRecharge();
  }, []);

  useEffect(() => {
    rechargeSdk && getBundleId();
  }, [currentBundleItemsQuantity]);

  const cartDrawerDataUpdate = () => {
    setDiscountCodes(shopifyCartService.discountCodes);
    setCartId(shopifyCartService.getCartDataFromStorage()?.id);
    setCartTotalAmount(shopifyCartService.cartTotal);
    setCartSubtotalAmount(shopifyCartService.cartSubtotal);
    setCart(shopifyCartService.cartLinesList);
    setTotalCartItemsNumber(shopifyCartService.totalCartItems);
  };

  const addToCart = useCallback(
    async addedItem => {
      const { sellingPlanId, id, quantity = 1 } = addedItem;

      const alreadyHave = cart.find(item => item.node.merchandise.id === id);

      if (alreadyHave) {
        const cart = shopifyCartService.getCartDataFromStorage();

        const queryParams = {
          cartId: cart.id,
          lineId: alreadyHave.node.id,
          quantity: alreadyHave.node.quantity + quantity,
          sellingPlanId
        };

        const query = updateItemCartMutationQuery(queryParams);
        const response = await shopifyCartService.fetchShopifyData(query);
        const updatedCart = response.data.cartLinesUpdate.cart
          ? response.data.cartLinesUpdate.cart
          : [];

        shopifyCartService.setCartDataToStorage(updatedCart);
      } else {
        const attributes = shopifyCartService.getBundleProductCartAttributes(
          bundleProductItems,
          rechargeBundleId
        );
        await shopifyCartService.cartAddItem({
          variantId: addedItem.id,
          quantity,
          sellingPlanId: addedItem.sellingPlanId,
          attributes
        });
      }

      setCartOpen(true);
      cartDrawerDataUpdate();
    },
    [cart, bundleProductItems, rechargeBundleId]
  );

  const addManyToToCart = useCallback(
    async items => {
      const attributes = shopifyCartService.getBundleProductCartAttributes(
        bundleProductItems,
        rechargeBundleId
      );

      const allItemsIds = items.map(d => d.id);
      const itemsToUpdate = cart.filter(item =>
        allItemsIds.includes(item.node.merchandise.id)
      );
      const updateItemsIds = itemsToUpdate.map(
        item => item.node.merchandise.id
      );
      const itemsToCreate = items.filter(
        item => !updateItemsIds.includes(item.id)
      );

      if (itemsToUpdate.length) {
        await shopifyCartService.updateCartItems(
          itemsToUpdate.map(item => {
            const found = items.find(i => i.id === item.node.merchandise.id);
            return {
              id: item.node.id,
              quantity: item.node.quantity + found.quantity,
              sellingPlanId: found.sellingPlanId,
              attributes
            };
          })
        );
      }
      if (itemsToCreate.length) {
        await shopifyCartService.cartAddItems(
          itemsToCreate.map(item => ({
            variantId: item.id,
            quantity: item.quantity,
            sellingPlanId: item.sellingPlanId,
            attributes
          }))
        );
      }

      setCartOpen(true);
      cartDrawerDataUpdate();
    },
    [cart, bundleProductItems, rechargeBundleId]
  );

  const removeCartItem = async itemToRemove => {
    const formattedProductData = cartServiceDataAdapter(itemToRemove);
    await shopifyCartService.cartRemoveItem(formattedProductData);
    setCartLoading(true);
    cartDrawerDataUpdate();
    setCartLoading(false);
    if (cart.length === 1) {
      setCartOpen(false);
    }
  };

  const handleUpdatePlan = async (sellingPlanId, cartLineDAO) => {
    await shopifyCartService.updateCartLinePlan({
      ...cartLineDAO,
      productSellingPlanData: sellingPlanId
    });
    cartDrawerDataUpdate();
  };

  const handleRemovePlan = async cartLineDAO => {
    await shopifyCartService.updateCartLinePlan({
      ...cartLineDAO,
      productSellingPlanData: null
    });
    cartDrawerDataUpdate();
  };

  const changeQuantity = async (item, actionType) => {
    const formattedProductData = cartServiceDataAdapter(item);
    setCartLoading(true);
    if (actionType === "decrement" && item.variantQuantity === 1) {
      await shopifyCartService.cartRemoveItem(formattedProductData);
    } else {
      await shopifyCartService.updateCartLine(formattedProductData, actionType);
    }
    setCartLoading(false);
    if (cart.length === 0) {
      setCartOpen(false);
    }
    cartDrawerDataUpdate();
  };

  const clearCart = async () => {
    await shopifyCartService.clearCart();
    cartDrawerDataUpdate();
    setCartLoading(false);
    setCartOpen(false);
  };

  const updateQuizAnswers = async (chosenVariant, isLastPage) => {
    const { question } = chosenVariant;
    const filteredResultsByQuestion = quizResults.filter(
      ({ question: itemQuestion }) => itemQuestion !== question
    );
    const updatedAnswerResults = [...filteredResultsByQuestion, chosenVariant];
    setQuizResults(updatedAnswerResults);
    localStorage.setItem(
      "presetProductQuery",
      JSON.stringify(
        new QuizResultsProcessor().getPresetUrlQuery(updatedAnswerResults)
      )
    );
    if (isLastPage) {
      setSearchLoading(true);
      const url = "/api/v1/product/concatenated";
      const productsList = await fetchData(url);
      const getRecommendationsIncomingData = {
        productsList,
        quizResults: updatedAnswerResults,
        maximumResultsForLayout: 1
      };
      const quizRecommendations = getQuizProductsRecommendations(
        getRecommendationsIncomingData
      );
      localStorage.setItem(
        "quizProductsRecommendations",
        JSON.stringify(quizRecommendations)
      );
      setSearchLoading(false);
    }
  };

  const setMaximumBundleItemsQuantity = maxCommonBundleItemsQuantity =>
    setMaxBundleItemsQuantity(maxCommonBundleItemsQuantity);

  const updateBundleItemsList = async bundleItem => {
    const filteredBundleItems = bundleProductItems.filter(
      ({ id, quantity }) => id !== bundleItem.id && quantity !== 0
    );
    const updatedBundle =
      bundleItem.quantity !== 0
        ? [...filteredBundleItems, bundleItem]
        : filteredBundleItems;

    const selections = buildSelectedBundleObject({
      ...openedBundleProduct,
      selectedBundleItemsList: updatedBundle
    });
    setSelection(selections);
    setBundleProductItems(updatedBundle);
  };

  const getBundleId = async () => {
    if (
      currentBundleItemsQuantity &&
      maxBundleItemsQuantity === currentBundleItemsQuantity
    ) {
      const data =
        rechargeSdk && (await rechargeSdk.getBundleId(selection, {}));
      setRechargeBundleId(data);
    }
  };

  const addDiscountToCart = async discountCode => {
    await shopifyCartService.addDiscountCode(discountCode);
    cartDrawerDataUpdate();
  };

  const paramsObject = {
    cart,
    cartSubtotalAmount,
    cartOpen,
    checkoutUrl,
    cartLoading,
    cartCurrency,
    quizResults,
    isAnyOptionChosen,
    maxBundleItemsQuantity,
    currentBundleItemsQuantity,
    openedBundleProduct,
    cartAddonsList,
    discountCodes,
    discountsPercentage,
    cartDiscountObject,
    cartDiscountsSetups,
    activeQuizStep,
    quizQuestionsProgress,
    totalCartItemsNumber,
    selectedSubscriptionId,
    shopifyCartService,
    searchInProgress,
    setSearchLoading,
    setSelectedSubscriptionId,
    setCartAddonsList,
    setQuizQuestionsProgress,
    setActiveQuizStep,
    setOpenedBundleProduct,
    setCartOpen,
    addToCart,
    removeCartItem,
    clearCart,
    handleUpdatePlan,
    handleRemovePlan,
    changeQuantity,
    updateQuizAnswers,
    setIsAnyOptionChosen,
    updateBundleItemsList,
    setMaximumBundleItemsQuantity,
    setCurrentBundleItemsQuantity,
    removeExistingCart: shopifyCartService.removeExistingCart,
    setCartDiscountsSetups,
    cartDrawerDataUpdate,
    cartId,
    addManyToToCart,
    addDiscountToCart,
    cartTotalAmount,
    cartSubtotalNumber: shopifyCartService.cartSubtotalNumber,
    discountSum: shopifyCartService.discountSum
  };

  return (
    <ShopContext.Provider value={paramsObject}>{children}</ShopContext.Provider>
  );
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import React from "react";

const AlreadyDo = ({
  blok: {
    title,
    image,
    description1,
    description2,
    description3,
    description4
  },
  blok
}) => {
  const renderOptions = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <b className={`sofia-pro-b block text-[48px]`}>{children}</b>;
      }
    }
  };
  return (
    <div
      className="mx-auto  w-full max-w-[120rem] bg-[#2589BD] px-4 pt-20 pb-0 text-center xl:px-[48px]"
      {...storyblokEditable(blok)}
    >
      <div className={`relative mx-auto mb-7 h-[143px] w-[188px]`}>
        <Image src={image?.filename} layout="fill" objectFit="contain" />
      </div>
      {title && (
        <h2
          className={`sofia-pro-b mb-10 text-[28px] leading-[1.1] text-white lg:text-[34px]`}
        >
          {title}
        </h2>
      )}
      <div className="mx-auto flex max-w-5xl flex-wrap justify-center gap-y-6 text-[22px]  leading-[1.3] text-white md:text-[24px]">
        <div className="flex w-full items-center justify-center justify-center lg:w-1/2">
          <div className="sofia-pro-r mt-5 w-[137px]">{description1}</div>
          <img src="/equal.svg" className="mx-6 mt-5 w-7" alt="" />
          <div className="sofia-pro-r w-[237px]">
            {render(description2, renderOptions)}
          </div>
        </div>
        <div className="flex w-full items-center justify-center justify-center lg:w-1/2 lg:border-l">
          <div className="sofia-pro-r mt-5 w-[117px]">{description3}</div>
          <img src="/equal.svg" className="mx-6 mt-5 w-7" alt="" />
          <div className="sofia-pro-r w-[237px]">
            {render(description4, renderOptions)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlreadyDo;

import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import DynamicComponent from "@services/DynamicComponentsRenderer";

export default function AdditionalProducts({ blok }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      "(max-width: 900px)": {
        slides: { perView: "auto", origin: "center" }
      }
    },
    slides: { perView: "auto", origin: "auto" },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });

  return (
    <div className="mx-auto mb-[100px] flex w-full max-w-[120rem]">
      <div className="mx-auto flex w-full max-w-[81.5rem] flex-col">
        <div className="px-[18px] lg:px-[48px] xl:px-[48px]">
          <h3 className="sofia-pro-b text-[34px]">{blok.title}</h3>
          <p className="sofia-pro-r text-18 leading-[1.1] text-[#424B5B]">
            The same scent you’ve chosen is also available across our range
          </p>
        </div>
        <div
          ref={sliderRef}
          className="keen-slider mt-[30px] flex flex-row gap-[18px] px-[0px] lg:px-[48px] xl:px-[48px]"
          style={{ maxWidth: 1600 }}
        >
          {blok?.products?.map(customBlok => (
            <DynamicComponent key={customBlok._uid} blok={customBlok} />
          ))}
        </div>
        {loaded && instanceRef?.current && (
          <div className="dots mt-[40px] flex flex-row justify-center gap-[6px] lg:hidden">
            {[
              //@ts-ignore
              ...Array(
                instanceRef?.current?.track?.details?.slides?.length
              ).keys()
            ].map(idx => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";

import ButtonLink from "./ButtonLink";
import InternalLink from "./InternalLink";
import { getIsInternalLink, getLinkKey } from "./utils";

const LinkVariant = props => {
  const { href, linkType, isHideLink = false } = props;
  const isInternalLink = getIsInternalLink(href);
  const linkKey = getLinkKey(isInternalLink);
  const componentType = linkType || linkKey;

  const components = {
    internal: <InternalLink {...props} />,
    buttonLink: <ButtonLink {...props} />
  };

  if (!href || isHideLink) {
    return null;
  }
  return components[componentType];
};

export default LinkVariant;

import { isEmpty } from "lodash";

import { BLOCK_WIDTH } from "@styles/shared/styles";
import DynamicComponent from "@services/DynamicComponentsRenderer";

const Grid = props => {
  const { blok, pageIndex } = props;
  if (isEmpty(blok)) {
    return null;
  }
  const { items_list: itemsList, items_in_row: itemsInRow = "width_full" } =
    blok;

  const renderItemsList = itemsList.map((gridItem, index) => {
    const manageLayout = pageIndex === index;
    return (
      <li
        key={gridItem._uid}
        className={`${BLOCK_WIDTH[itemsInRow]} flex h-full w-full flex-col
        overflow-hidden overflow-x-hidden first:mt-[30px] lg:flex-row`}
      >
        <DynamicComponent blok={gridItem} manageLayout={manageLayout} />
      </li>
    );
  });

  return <ul className="w-full">{renderItemsList}</ul>;
};

export default Grid;

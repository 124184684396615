import React, { useContext } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import DynamicComponent from "@services/DynamicComponentsRenderer";
import { FreeShipping } from "@components/SVG";
import { ShopContext } from "@context/shopContext";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    fetchpriority?: string;
  }
}

function ThumbnailPlugin(mainRef) {
  return slider => {
    function removeActive() {
      slider.slides.forEach(slide => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", main => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

export default function ProductImages({ blok }) {
  const { cartDiscountsSetups } = useContext(ShopContext);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0
  });
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 1,
        spacing: 0
      }
    },
    [ThumbnailPlugin(instanceRef)]
  );
  return (
    <div className="flex h-full w-full flex-col bg-white">
      <div className="relative flex h-full w-full bg-white lg:max-h-[770px] lg:max-w-[770px] lg:rounded-8">
        <div className="flex h-full w-full flex-shrink overflow-hidden lg:h-[410px] lg:w-[410px] xl:h-[622px] xl:w-[622px]">
          <div ref={sliderRef} className="keen-slider flex flex-row rounded-8">
            {blok?.main_images?.map(blok => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>
        <div className="absolute z-[10] hidden h-fit w-fit flex-col justify-between px-[21px] py-[10px] lg:flex">
          <div
            ref={thumbnailRef}
            className="keen-slider thumbnail min-w-[45px] max-w-[45px]"
          >
            {blok?.main_images?.map(blok => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>
        <div className="absolute right-0 z-[10] flex h-fit w-fit justify-between px-[21px] py-[18px]">
          {" "}
          <div>
            <div className="shadow-md lg:shadow-none flex select-none items-center justify-end rounded-8 bg-white py-[8px] px-[16px]">
              <span className="sofia-pro-r flex flex-row items-center gap-[6px] text-[14px]">
                <FreeShipping />
                Free shipping over $
                {cartDiscountsSetups?.free_shopping_min_amount}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={thumbnailRef}
        className="keen-slider thumbnail !flex min-w-[45px] max-w-[45px] !flex-row gap-[13px] !overflow-visible px-[18px] pt-[10px] lg:!hidden"
      >
        {blok?.main_images?.map(blok => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}
      </div>
    </div>
  );
}

import { isEmpty } from "lodash";
import { useMediaQuery } from "@hooks/index";

const TitleWithSubtitle = ({ blok }) => {
  if (isEmpty(blok)) {
    return null;
  }
  const isMobile = useMediaQuery(`(max-width: ${580}px)`);
  const {
    title,
    subtitle,
    title_size,
    margin_bottom,
    subtitle_font_size = 20
  } = blok;
  return (
    <div
      className={`mx-auto mb-[20px] flex w-full flex-col items-center justify-center text-center lg:mb-[${margin_bottom}px] px-[18px] sm:px-[unset]`}
    >
      <div className="mt-[15px] flex flex-col gap-[15px]">
        <h3
          className={`sofia-pro-b text-[28px] leading-[1.1]`}
          style={{
            fontSize: `${
              isMobile ? Math.round(title_size * 0.9) : title_size
            }px`
          }}
        >
          {title}
        </h3>
        {blok.subtitle && (
          <p
            className={`sofia-pro-r  leading-[1.1]`}
            style={{
              fontSize: `${
                isMobile
                  ? Math.round(subtitle_font_size * 0.9)
                  : subtitle_font_size
              }px`
            }}
          >
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};
export default TitleWithSubtitle;

export const viewItemGAEvent = (productDAO, sellingPlan) => {
  window.dataLayer.push({
    event: "view_item_stape",
    ecommerce: {
      items: [
        {
          item_brand: "GoodLaundryCo",
          item_category: productDAO.productType,
          item_id: productDAO.productId,
          item_name: productDAO.title,
          item_sku: productDAO?.variantsSku?.[0],
          item_variant: productDAO?.firstVariant?.id?.split("/")?.[4],
          price: sellingPlan
            ? (
                Number(
                  productDAO?.firstVariant?.variantPrice.replace("$", "")
                ) * 0.8
              )?.toFixed(2)
            : Number(productDAO?.firstVariant?.variantPrice.replace("$", "")),
          quantity: "1"
        }
      ]
    }
  });
};

export const addToCartGAEvent = (productDAO, sellingPlan) => {
  window.dataLayer.push({
    event: "add_to_cart_stape",
    ecommerce: {
      items: [
        {
          item_brand: "GoodLaundryCo",
          item_category: productDAO.productType,
          item_id: productDAO.productId,
          item_name: productDAO.title,
          item_sku: productDAO?.variantsSku?.[0],
          item_variant: productDAO?.firstVariant?.id?.split("/")?.[4],
          price: sellingPlan
            ? (
                Number(
                  productDAO?.firstVariant?.variantPrice.replace("$", "")
                ) * 0.8
              )?.toFixed(2)
            : Number(productDAO?.firstVariant?.variantPrice.replace("$", "")),
          quantity: "1"
        }
      ]
    }
  });
};

import React, { useState, useContext, useEffect, useCallback } from "react";
import { storyblokEditable } from "@storyblok/react";
import { motion } from "framer-motion";
import AnswerVariantItem from "./AnswerVariantItem";
import { ShopContext } from "@context/shopContext";
import { QuizResultsProcessor } from "@services/QuizResultsService";
import { answersClickManager } from "./utils";

const QuestionAnswersListMultiple = props => {
  const { quizResults, setIsAnyOptionChosen, updateQuizAnswers } =
    useContext(ShopContext);
  const [answersList, setUpdatedAnswersList] = useState([]);
  const {
    blok: { answers, question },
    blok
  } = props;

  const isQuestionSelected = !!answersList.length;
  useEffect(() => {
    setIsAnyOptionChosen(isQuestionSelected);
  }, [answersList]);

  const onOptionSelect = useCallback(
    (answer: any, blok: any) => {
      let list = answersClickManager(answer.answer_value, answersList);

      if (answer.answer_value === "Just Adults") {
        list = ["Just Adults"];
      } else {
        list = list.filter(f => f !== "Just Adults");
      }

      setUpdatedAnswersList(list);
      const imgs = answers
        .filter(d => list.includes(d.answer_value))
        .map(a => a.answer_img.filename);
      const { question, question_briefly_for_results } = blok;

      const currentValueObject = {
        question,
        answer: list,
        ...new QuizResultsProcessor().getOption(answer),
        carbonIndex: answer?.carbon_index || 1,
        questionBriefly: question_briefly_for_results,
        answerImg: imgs
      };

      updateQuizAnswers(currentValueObject);
    },
    [answersList]
  );

  useEffect(() => {
    const questionDataFromResults = quizResults.find(
      ({ question: addedQuestion }) => addedQuestion === question
    );
    questionDataFromResults &&
      setUpdatedAnswersList(questionDataFromResults.answer);
  }, []);

  const isStretchedMobileCard = answers.length <= 4;
  const cardTypeStyles = isStretchedMobileCard
    ? "gap-[14px] sm:gap-[20px] justify-center "
    : "gap-[4vw] md:gap-1 justify-center md:justify-around";

  const variants = {
    enter: {
      transition: { staggerChildren: 0.2, delayChildren: 0.2 }
    },
    exit: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  return (
    <motion.div
      variants={variants}
      initial="exit"
      animate="enter"
      exit="exit"
      className={`mx-auto flex h-max w-full flex-wrap pb-10 lg:mt-[30px] lg:h-fit ${cardTypeStyles} `}
      {...storyblokEditable(blok)}
    >
      {answers.map(answer => (
        <AnswerVariantItem
          isSelected={answersList.includes(answer.answer_value)}
          key={answer._uid}
          answer={answer}
          onSelect={() => {
            onOptionSelect(answer, props.blok);
          }}
          isStretchedMobileCard={isStretchedMobileCard}
          updateAnswersList={{ answersList, setUpdatedAnswersList }}
          {...props}
          {...storyblokEditable(answer)}
        />
      ))}
    </motion.div>
  );
};

export default QuestionAnswersListMultiple;

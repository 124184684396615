import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import Router from "next/router";
import Image from "next/image";

import { Button } from "@components/UI";
import { ShopContext } from "@context/shopContext";
import klaviyoService from "@services/KlaviyoService";
import config from "@src/config.json";

const QuizLastStepSection = ({ setSearchLoading }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailAddress, setEmailAddress] = useState(false);
  const { quizResults } = useContext(ShopContext);
  const getQuizResults = async () => {
    setEmailSent(true);
    setSearchLoading(true);
    await klaviyoService.handleQuizResultsSubmit({
      email: emailAddress,
      quizResults
    });
    localStorage.setItem("quizResults", JSON.stringify(quizResults));
    Router.push("/results");
  };
  const validateInput = e => {
    const regex = new RegExp(config.regexValidation.email);
    setIsValidated(regex.test(e.target.value));
    isValidated && setEmailAddress(e.target.value);
  };
  return (
    <>
      {!emailSent && (
        <div className="relative flex w-full flex-col items-center bg-[#edf2f4]">
          <div className="sm:min-h-full mx-auto flex min-h-[63vh] w-full max-w-[81.5rem] flex-1 flex-col justify-center px-[18px] sm:min-h-[60vh] lg:px-[48px] xl:px-[48px]">
            <div className="flex w-full items-start justify-center pt-[75px] align-top max-sm:items-center lg:pt-[100px]">
              <div className="mx-auto flex min-h-[105px] w-full max-w-[36rem] flex-col justify-center text-center">
                <div className="h-full overflow-hidden pt-[12px] lg:pt-[19px]">
                  <h1 className="sofia-pro-b text-[28px] leading-[1.1] sm:text-[32px] lg:text-[34px]">
                    Enter your email for 10% off and your results
                  </h1>
                </div>
                <div className="h-full pt-[9px] lg:pt-[10px]">
                  <p className="sofia-pro-r text-[18px] leading-[26px] text-[#15233C99] lg:text-[24px]">
                    See your environmental impact and get a limited-time
                    discount on Good Laundry
                  </p>
                </div>
                <input
                  placeholder="your@email.com"
                  type="email"
                  className="sofia-pro-m mt-[13px] flex-shrink-0 rounded-[8px] bg-white px-[24px] py-[8px] text-18 leading-[30px] text-gl-dark-blue placeholder:text-gl-dark-blue placeholder:opacity-50"
                  onChange={validateInput}
                />
                <Button
                  isDisable={!isValidated}
                  variant={"lightBlue"}
                  onClick={getQuizResults}
                  className={
                    "mt-5 w-full px-[12px] py-[14px] leading-[1] text-white"
                  }
                >
                  Get my results*
                </Button>
                <p className="sofia-pro-m mt-[26px] text-[14px] text-gl-dark-blue">
                  *I agree to receive GoodLaundry emails
                </p>
              </div>
            </div>
            <div className="absolute right-0 top-0  h-[20vh] w-[20vh] lg:h-[30vh] lg:w-[30vh] xl:h-[40vh] xl:w-[40vh]">
              <Image src="/quiz_email_image.svg" alt="alt text" layout="fill" />
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.2,
              duration: 0.2
            }}
            className="h-fit w-full items-start justify-center md:pt-[20px] lg:pt-[40px]"
          >
            <div className="footer_decorator h-6 w-full"></div>
            <div className="flex h-fit h-[400px] w-full items-center justify-center !bg-white max-md:flex-col max-md:justify-evenly md:h-[180px]">
              <div className="md:left-[1vw]lg:left-[5vw] w-[392px]  max-lg:w-[200px] max-md:order-2 max-md:mt-2  max-md:w-[300px] sm:bottom-10 sm:left-[0vw] md:absolute">
                <img src="/52_verification_footer.png" alt="alt text" />
              </div>
              <div className="mx-auto flex flex-col items-end max-md:order-1 max-md:items-center sm:items-center  sm:justify-start  md:w-2/3 lg:w-full">
                <p className="sofia-pro-r coloredText w-[82%] items-center justify-center text-center text-[28px] leading-[1.1] lg:text-[34px]">
                  <b className="font-bold">
                    You can try Good Laundry risk free
                  </b>
                  {/* {render(footerText)} */}
                  <p className="sofia-pro-r mx-auto w-full  justify-center text-center text-[20px] leading-[1.1] md:mt-[7px] lg:text-[22px]">
                    <span className="text-gl-light-blue">
                      Make a noticable difference in
                    </span>{" "}
                    of a cause you're passionate
                    <br /> about, just by doing laundry.
                  </p>
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};
export default QuizLastStepSection;

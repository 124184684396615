import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DynamicComponent from "@services/DynamicComponentsRenderer";

export default function NavMobileMenu({ blok }) {
  const [isNavItemOpen, setIsNavItemOpen] = useState(false);

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (isNavItemOpen) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }
    return (): void => {
      html.classList.remove("lock-scroll");
    };
  }, [isNavItemOpen]);
  return (
    <>
      <button
        onClick={() => setIsNavItemOpen(!isNavItemOpen)}
        className="flex h-fit flex-shrink-0 select-none items-center outline-none"
      >
        <div
          className={
            isNavItemOpen
              ? "nav-hamburger-open nav-icon nav-hamburger"
              : "nav-icon nav-hamburger"
          }
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      {/* 
// @ts-ignore */}
      <AnimatePresence>
        {isNavItemOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.1 }}
            className={
              isNavItemOpen
                ? "absolute top-[100%] left-[0] z-30 flex h-[calc(100vh-132px)] w-full  !overflow-y-scroll border-t border-[#fffff0D] bg-white lg:top-[132px]"
                : "hidden"
            }
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.1 }}
              className="z-40 mx-auto flex h-full w-full max-w-[120rem] flex-col !overflow-x-hidden px-[18px] "
            >
              <div className="webkit-fill mx-auto flex max-h-full min-h-[55rem] w-full max-w-[82rem] flex-col gap-[26px] !overflow-y-auto !overflow-x-hidden pb-[4rem] pt-[19px] scrollbar-hide">
                <div className="grid w-full max-w-[23rem] grid-cols-3 gap-[16px]">
                  {blok?.box_links?.map(customBlok => (
                    <DynamicComponent key={customBlok._uid} blok={customBlok} />
                  ))}
                </div>
                <div className="flex flex-col">
                  {blok?.links?.map(customBlok => (
                    <DynamicComponent key={customBlok._uid} blok={customBlok} />
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

import { isEmpty } from "lodash";

import {
  formatter,
  getShopifyShortId,
  formatString,
  capitalizeFirstLetter,
  isStartsWithCapital,
  isObjectsEquals
} from "@utils/helpers";

export class ShopifyProductDAO {
  #title;
  #handle;
  #options;
  #description;
  #productSellingPlanData;
  #requiresSellingPlan;
  #productType;
  #sbProductData;

  constructor(shopifyProductData, cartCurrency) {
    if (isEmpty(shopifyProductData)) {
      return null;
    }
    this.currency = cartCurrency;
    this.product = shopifyProductData;
    this.#title = shopifyProductData.title;
    this.#handle = shopifyProductData.handle;
    this.#options = shopifyProductData.options;
    this.#description = shopifyProductData.description;
    this.#requiresSellingPlan = shopifyProductData.requiresSellingPlan;
    this.#productSellingPlanData = shopifyProductData.sellingPlanGroups;
    this.#productType = shopifyProductData?.productType || "";
    this.#sbProductData = shopifyProductData?.sbProductData || "";
  }

  get sellingPlanData() {
    const sellingPlanObject = this.#getSellingPlanFullData();
    if (!sellingPlanObject) {
      return null;
    }
    return sellingPlanObject?.subscriptionData;
  }

  get variantsSku() {
    return this.product.variants.edges.map(e => e.node.sku);
  }

  get availableSubscritions() {
    const list =
      this.product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges ||
      [];
    return list.map(({ node }) => {
      const number = Number(node.name.replace(/[^0-9\.]+/g, "")); // eslint-disable-line
      return {
        id: node.id,
        name: `Every ${number} month${number > 1 ? "s" : ""}`,
        month: number
      };
    });
  }

  get handle() {
    return this.#handle;
  }

  get title() {
    return this.#title;
  }

  get price() {
    return formatter(
      this.product.variants.edges[0].node.priceV2.amount,
      this.currency
    );
  }

  get minPrice() {
    return formatter(
      this.product.priceRange.minVariantPrice.amount,
      this.currency
    );
  }

  get compareAtPrice() {
    return formatter(
      this.product.variants.edges[0].node.compareAtPrice.amount,
      this.currency
    );
  }

  get description() {
    return this.#description;
  }

  get sbDescriptionBlok() {
    return this.#description;
  }

  get options() {
    return this.#options;
  }

  get allVariants() {
    if (!this.product?.variants?.edges) {
      return [];
    }
    return this.product.variants.edges?.map(variant => {
      const allOptions = {};
      variant.node.selectedOptions.forEach(item => {
        allOptions[item.name] = item.value;
      });
      return {
        title: this.title,
        variantQuantity: 1,
        options: allOptions,
        id: variant.node.id,
        sku: variant.node.sku,
        handle: this.product.handle,
        image: variant.node.image?.url,
        variantTitle: variant.node.title,
        variantPrice: variant.node.priceV2.amount,
        availableForSale: variant.node.availableForSale,
        compareAtPrice: variant.node.compareAtPrice?.amount
      };
    });
  }

  get defaultOptionsObject() {
    const options = this.options;
    const defaultValues = {};
    options.forEach(item => {
      defaultValues[item.name] = item.values[0];
    });
    return defaultValues;
  }

  get firstVariant() {
    return this.allVariants[0];
  }

  get mainImage() {
    return (
      this.product?.images?.edges[0]?.node?.url ||
      this.product?.images?.edges[0]?.node?.originalSrc
    );
  }

  get productId() {
    const productId = this.product.id;
    const shortProductId = getShopifyShortId(productId);
    return Number(shortProductId);
  }

  get fullSellingPlanData() {
    return this.#getSellingPlanFullData();
  }

  get requiresSellingPlan() {
    return this.#requiresSellingPlan;
  }

  get subscriptionOptionsString() {
    const fullSellingPlanData = this.#getSellingPlanFullData();
    if (!fullSellingPlanData) {
      return null;
    }
    const {
      subscriptionData: {
        options: [{ name }]
      }
    } = fullSellingPlanData;
    return name;
  }

  get subscriptionFirstOptionDiscount() {
    return this.subscriptionFirstOption?.priceAdjustments[0]?.adjustmentValue
      ?.adjustmentPercentage;
  }

  get subscriptionFirstOption() {
    const fullSellingPlanData = this.#getSellingPlanFullData();
    if (!fullSellingPlanData) {
      return null;
    }
    const {
      subscriptionData: {
        sellingPlans: {
          edges: [{ node: firstSellingPlanData }]
        }
      }
    } = fullSellingPlanData;
    return firstSellingPlanData;
  }

  get productType() {
    return this.#productType;
  }

  get sbFormBlocks() {
    return this.#sbProductData?.content?.form_sections;
  }

  subscriptionFirstOptionPrice(selectedVariant) {
    return formatter(
      this.subscriptionFirstOptionPriceValue(selectedVariant),
      this.currency
    );
  }

  subscriptionFirstOptionPriceValue(selectedVariant) {
    const priceDiscountValue = this.subscriptionFirstOptionDiscount;
    const { priceInNumber } = this.getProductVariantPrice(selectedVariant);
    const discountedPrice = (priceInNumber * (100 - priceDiscountValue)) / 100;
    return discountedPrice;
  }

  getProductVariantPrice(selectedVariant) {
    const {
      variants: { edges: variantsList }
    } = this.product;
    const chosenVariant = variantsList.find(
      ({ node: { id } }) => id === selectedVariant.id
    );
    const {
      node: {
        priceV2: { amount: price },
        compareAtPrice
      }
    } = chosenVariant;

    return {
      regularPrice: formatter(price, this.currency),
      compareAtPrice: compareAtPrice
        ? formatter(compareAtPrice?.amount, this.currency)
        : null,
      discountedPrice: formatter(price, this.currency),
      priceInNumber: price
    };
  }

  getFirstVariantId() {
    return this.allVariants[0].id;
  }

  #getSellingPlanFullData() {
    if (isEmpty(this.#productSellingPlanData)) {
      return null;
    }
    const { edges: plansNodesList } = this.#productSellingPlanData;
    if (isEmpty(plansNodesList)) {
      return null;
    }

    const [{ node: subscriptionData }] = plansNodesList;
    return { subscriptionData, requiresSellingPlan: this.#requiresSellingPlan };
  }

  setPresetOptions(option) {
    const preSetOptionKeysArray = Object.keys(option);
    const preSetOptionKeysArrayInLowerCase = preSetOptionKeysArray.map(key =>
      formatString(key)
    );
    const updatedOptions = {};
    for (const optionKey in this.defaultOptionsObject) {
      const isFirstLetterCapitalized = isStartsWithCapital(
        this.defaultOptionsObject[optionKey]
      );
      if (preSetOptionKeysArrayInLowerCase.includes(formatString(optionKey))) {
        const incomingOptionValue =
          option[optionKey] || option[formatString(optionKey)];

        const incomingOptionValueFormattedByCapitalization =
          isFirstLetterCapitalized
            ? capitalizeFirstLetter(incomingOptionValue)
            : incomingOptionValue;

        updatedOptions[optionKey] =
          incomingOptionValueFormattedByCapitalization;
      } else {
        updatedOptions[optionKey] = this.defaultOptionsObject[optionKey];
      }
    }
    return updatedOptions;
  }

  getVAriantByOptions(selectedOptionsObject) {
    return this.allVariants.find(({ options }) =>
      isObjectsEquals(options, selectedOptionsObject)
    );
  }
}

import DynamicComponent from "../../../services/DynamicComponentsRenderer";

export default function ProductInfo({ blok }) {
  return (
    <>
      <div className="grid grid-cols-1 gap-[24px] border-b border-[#E9E9E9] py-[25px] lg:grid-cols-2 lg:gap-0">
        {blok.info.map(customBlok => (
          <DynamicComponent key={customBlok._uid} blok={customBlok} />
        ))}
      </div>
    </>
  );
}

const Account = ({ logoColorIndex }) => {
  const colorVariant = ["#69B645", "#F1C532", "#E36877"];
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1935_21732)">
        <rect
          x="0.709106"
          y="0.0209961"
          width="40.7783"
          height="40.7783"
          rx="20.3891"
          fill="white"
        />
        <mask
          id="mask0_1935_21732"
          maskUnits="userSpaceOnUse"
          x="4"
          y="3"
          width="34"
          height="35"
        >
          <rect
            x="4.20325"
            y="3.94653"
            width="33.7855"
            height="33.7855"
            rx="16.8928"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1935_21732)">
          <rect
            x="4.20325"
            y="3.94653"
            width="33.7855"
            height="33.7855"
            rx="16.8928"
            fill={colorVariant[logoColorIndex]}
          />
          <g style={{ mixBlendMode: "soft-light" }}>
            <path
              d="M20.7511 9.17627C16.3872 9.17627 13.4107 12.3771 13.4107 17.0089C13.4107 20.1712 15.0026 23.6295 17.0809 25.6875V28.7508C15.8575 32.421 6.07022 29.9742 6.07022 38.538H35.432C35.432 29.9742 25.6447 32.421 24.4213 28.7508V25.6875C26.4996 23.6295 28.0915 20.1712 28.0915 17.0089C28.0915 12.3771 25.115 9.17627 20.7511 9.17627Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1935_21732"
          x="0.709106"
          y="0.0209961"
          width="40.7783"
          height="41.7783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1935_21732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1935_21732"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default Account;

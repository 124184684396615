const FreeShipping = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.96094 19.9316C11.3216 19.9316 12.6026 19.6712 13.8037 19.1504C15.0049 18.6295 16.0645 17.9101 16.9824 16.9922C17.9004 16.0742 18.6198 15.0146 19.1406 13.8135C19.6615 12.6123 19.9219 11.3314 19.9219 9.9707C19.9219 8.61003 19.6615 7.3291 19.1406 6.12793C18.6198 4.92676 17.9004 3.86719 16.9824 2.94922C16.0645 2.03125 15.0033 1.31185 13.7988 0.791016C12.5944 0.270182 11.3119 0.00976562 9.95117 0.00976562C8.5905 0.00976562 7.30957 0.270182 6.1084 0.791016C4.90723 1.31185 3.84928 2.03125 2.93457 2.94922C2.01986 3.86719 1.30208 4.92676 0.78125 6.12793C0.260417 7.3291 0 8.61003 0 9.9707C0 11.3314 0.260417 12.6123 0.78125 13.8135C1.30208 15.0146 2.02148 16.0742 2.93945 16.9922C3.85742 17.9101 4.91699 18.6295 6.11816 19.1504C7.31933 19.6712 8.60026 19.9316 9.96094 19.9316Z"
      fill="#2589BD"
    />
    <path
      d="M6.19948 4.68457C6.09434 4.68463 5.99106 4.71222 5.8999 4.7646C5.80875 4.81698 5.73291 4.89233 5.67992 4.98314L4.62672 6.78864C4.57292 6.88066 4.54452 6.98531 4.54443 7.09191V14.3139C4.54443 14.9718 5.09026 15.5176 5.7481 15.5176H14.1738C14.8316 15.5176 15.3774 14.9718 15.3774 14.3139V7.09191C15.3774 6.98531 15.349 6.88066 15.2952 6.78864L14.242 4.98314C14.189 4.89233 14.1131 4.81698 14.022 4.7646C13.9308 4.71222 13.8275 4.68463 13.7224 4.68457H6.19948ZM6.54506 5.88824H9.3591V7.09191H5.84331L6.54506 5.88824ZM10.5628 5.88824H13.3768L14.0786 7.09191H10.5628V5.88824ZM5.7481 8.29557H14.1738V14.3139H5.7481V8.29557ZM8.15544 9.49924V10.7029H11.7664V9.49924H8.15544Z"
      fill="white"
    />
  </svg>
);
export default FreeShipping;

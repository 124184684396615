import React, { useState } from "react";
import Link from "next/link";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { boolean, date, object, string } from "yup";
import InputMask from "react-input-mask";
import { subscribeLaundryTester } from "@src/services";
import Button from "../../UI/button";
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import { LinkVariant } from "@components/UI";
import Image from "next/image";

let applyFromSchema = object({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  email: string()
    .email("Invalid email address")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
    .required("Email is required"),
  phone: string()
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
      "Invalid phone format: +1 (123) 456-7890"
    )
    .required("Phone is required"),
  city: string().required("City is required"),
  state: string().required("State is required"),
  date: date()
    .nullable()
    .test("dob", "Date is invalid", function (value, ctx) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getTime() - dob.getTime() >= 0;
      return !valid ? ctx.createError() : valid;
    })
    .required("Date is required"),
  agree: boolean()
    .oneOf([true], "You must accept the terms and conditions.")
    .required("You must accept the terms and conditions.")
});

export default function Apply({
  blok: { title, notice, success_title, success_description, social_media }
}) {
  const [serverError, setServerError] = useState(null);
  const [successForm, setSuccessForm] = useState(false);

  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h3
            className={`sofia-pro-s mb-9 text-[32px] leading-[1.15] lg:mb-14 lg:text-[34px]`}>
            {children}
          </h3>
        );
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r text-[32px] lg:text-[34px]`}>{children}</p>
        );
      }
    }
  };

  return (
    <section id="apply-to-laundry-tester" className="py-16 md:py-24">
      <div className="custom-container">
        <Formik
          validationSchema={applyFromSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setServerError(null);
            setSubmitting(true);
            try {
              const url = "/api/v1/newsLetters/subscribeLaundryTester";
              const res = await subscribeLaundryTester(url, values.email, {
                ...values,
                phone: values.phone.replace(/[^\d]/g, "")
              });
              if (res.detail) {
                setServerError(res.detail);
              } else {
                setSuccessForm(true);
              }
              setSubmitting(false);
            } catch (error) {
              console.log(error, 3);
              setSubmitting(false);
            }
          }}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            date: "",
            following: "Kids",
            washingMachine: "Front loader",
            agree: false
          }}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              {successForm ? (
                <>
                  <div className="text-center">
                    <h3 className="sofia-pro-s mb-3 text-[32px] leading-[1.15] lg:text-[34px]">
                      🔗 <br />
                      Share the contest!
                    </h3>
                    <div className="mb-9 flex justify-center gap-6 lg:mb-14">
                      {social_media?.map(item => {
                        return (
                          <LinkVariant
                            key={item._uid}
                            href={item.link?.url || `/${item.link.cached_url}`}
                            linkType={"internal"}
                            target="_blank"
                            className={
                              "relative h-[30px] w-[30px] transition-all hover:scale-110"
                            }>
                            <Image
                              src={item?.svg?.filename}
                              width={50}
                              height={50}
                              objectFit="contain"
                            />
                          </LinkVariant>
                        );
                      })}
                    </div>
                  </div>
                  <div className="simple-form mx-auto max-w-[846px] rounded-lg bg-[#DBF3FF] py-14 px-4 sm:py-[7%]">
                    <div className="text-center">
                      <h2 className="text-blue-gradient sofia-pro-b mb-3 text-[32px] text-gl-dark-blue md:text-[48px]">
                        {success_title}
                      </h2>
                      <div className="text-[22px] leading-[1.2] text-gl-dark-blue md:text-[24px]">
                        {render(success_description)}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="md:text-center">
                    {render(title, renderTitleOptions)}
                  </div>
                  <div className="simple-form mx-auto max-w-[846px] rounded-lg bg-[#DBF3FF] py-14 px-4 sm:py-[7%]">
                    <div className="mx-auto max-w-[519px]">
                      <div className="field-group">
                        <label>
                          <span>First name</span>
                          <Field
                            type="text"
                            name="firstName"
                            placeholder="First name"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="p"
                            className="error-message"
                          />
                        </label>
                        <label>
                          <span>Last name</span>
                          <Field
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="p"
                            className="error-message"
                          />
                        </label>
                      </div>
                      <div className="field-group">
                        <label>
                          <span>Email address</span>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email address"
                          />
                          <ErrorMessage
                            name="email"
                            component="p"
                            className="error-message"
                          />
                        </label>
                        <label>
                          <span>Mobile phone</span>
                          <InputMask
                            type="text"
                            className="max-w-[204px]"
                            placeholder="+1 (___) ___-____"
                            mask="+1 (999) 999-9999"
                            name="phone"
                            onChange={e => {
                              const value = e.target.value || "";
                              setFieldValue("phone", value);
                            }}
                          />
                          <ErrorMessage
                            name="phone"
                            component="p"
                            className="error-message"
                          />
                        </label>
                      </div>
                      <div className="field-group">
                        <label>
                          <span>City</span>
                          <Field type="text" name="city" placeholder="City" />
                          <ErrorMessage
                            name="city"
                            component="p"
                            className="error-message"
                          />
                        </label>
                        <label>
                          <span>State</span>
                          <Field type="text" name="state" placeholder="State" />
                          <ErrorMessage
                            name="state"
                            component="p"
                            className="error-message"
                          />
                        </label>
                      </div>
                      <div className="field-group">
                        <label>
                          <span>Date of birth</span>
                          <Field
                            type="date"
                            className="max-w-[170px]"
                            name="date"
                            placeholder="dd/mm/yyyy"
                          />
                          <ErrorMessage
                            name="date"
                            component="p"
                            className="error-message"
                          />
                        </label>
                      </div>
                      <div className="mb-6 mt-6 md:mb-8">
                        <h4 className="sofia-pro-r mb-4 text-[24px] leading-[1.2] text-gl-blue">
                          Do you have any of the following in your home?
                        </h4>
                        <label className="radio">
                          <Field type="radio" name="following" value="Kids" />
                          <span>Kids</span>
                        </label>
                        <label className="radio">
                          <Field type="radio" name="following" value="Pets" />
                          <span>Pets</span>
                        </label>
                        <label className="radio">
                          <Field
                            type="radio"
                            name="following"
                            value="None of the above"
                          />
                          <span>None of the above</span>
                        </label>
                      </div>
                      <div className="mb-7 md:mb-10">
                        <h4 className="sofia-pro-r mb-4 text-[24px] leading-[1.2] text-gl-blue">
                          What kind of washing machine do you have?
                        </h4>
                        <label className="radio">
                          <Field
                            type="radio"
                            name="washingMachine"
                            value="Front loader"
                          />
                          <span>Front loader</span>
                        </label>
                        <label className="radio">
                          <Field
                            type="radio"
                            name="washingMachine"
                            value="Top Loader"
                          />
                          <span>Top Loader</span>
                        </label>
                        <label className="radio">
                          <Field
                            type="radio"
                            name="washingMachine"
                            value="Unsure"
                          />
                          <span>Unsure</span>
                        </label>
                      </div>
                      <h4 className="sofia-pro-r mb-4 text-[24px] leading-[1.2] text-gl-blue md:mb-6">
                        Is it OK to communicate with you via SMS?
                      </h4>
                      <label className="checkbox">
                        <Field type="checkbox" name="agree" />
                        <span className="sofia-pro-r text-[16px]">
                          Yes, it’s OK to send me text messages
                        </span>
                        <ErrorMessage
                          name="agree"
                          component="p"
                          className="error-message"
                        />
                      </label>
                      <div className="sofia-pro-r mb-6 pl-6 text-[13px] leading-[1.14] md:mb-10">
                        {render(notice)}
                      </div>
                      <p className="sofia-pro-r mb-6 text-[16px] leading-[1.2]">
                        By submitting this application, I agree to the campaign{" "}
                        <Link href="/terms-of-service">
                          <a
                            className="sofia-pro-s underline hover:no-underline"
                            target="_blank">
                            Terms of Service
                          </a>
                        </Link>{" "}
                        +{" "}
                        <Link href="/privacy-policy">
                          <a
                            className="sofia-pro-s underline hover:no-underline"
                            target="_blank">
                            Privacy Policy
                          </a>
                        </Link>
                      </p>
                      <Button
                        isDisable={isSubmitting}
                        type="submit"
                        variant="blue"
                        className="!ml-0 border !border-[#15233c] !py-1 !text-[18px] hover:bg-transparent hover:text-[#15233c]">
                        Submit application
                      </Button>
                      {serverError && (
                        <p className="server-error">{serverError}</p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

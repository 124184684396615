import DynamicComponent from "../../../services/DynamicComponentsRenderer";

export default function ProductScent({ blok }) {
  return (
    <div
      id="choose-scent"
      className="mb-[20px] mt-[20px] flex flex-col space-y-[10px]"
    >
      <div>
        <h4 className="sofia-pro-s text-18">{blok.title}</h4>
      </div>
      <div className="grid w-full grid-cols-2 gap-[8px] lg:grid-cols-4">
        {blok?.scents?.map(blok => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}
      </div>
    </div>
  );
}

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { StoryblokComponent } from "@storyblok/react";

import CarouselItem from "./CarouselItem";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";
import React from "react";

const CarouselSection = ({
  blok: {
    button_text,
    items_list,
    blocks,
    background_color,
    text_color,
    description_text,
    margin_bottom,
    padding_bottom
  }
}) => {
  const [sliderRef] = useKeenSlider({
    loop: true,
    mode: "free",
    rtl: false,
    breakpoints: {
      "(min-width: 400px)": {
        slides: { origin: "center", perView: "auto", spacing: 20 }
      },
      "(min-width: 1000px)": {
        slides: { perView: "auto", spacing: 20 }
      },
      "(min-width: 1200px)": {
        slides: { perView: 4, spacing: 20 }
      }
    },
    slides: { origin: "center", perView: "auto", spacing: 15 }
  });

  const renderOptions = {
    markResolvers: {
      [MARK_LINK]: (children, { href, target }) => {
        return (
          <a
            href={href}
            target={target}
            className={`cursor-pointer underline hover:no-underline`}
          >
            {children}
          </a>
        );
      }
    }
  };

  return (
    <div
      style={{
        background: background_color ? background_color : "#F5F9FA",
        color: text_color?.color ? text_color.color : "#000000",
        marginBottom: margin_bottom ? `${margin_bottom}px` : "40px",
        paddingBottom: padding_bottom ? `${padding_bottom}px` : "100px"
      }}
      className="relative mx-auto flex h-full w-full max-w-[120rem] flex-col overflow-hidden"
    >
      <div className="min-h-full mx-auto flex w-full flex-col">
        <div className="mx-auto flex h-full w-full max-w-5xl flex-col items-start justify-center pt-[50px] align-top md:pt-[60px]">
          {blocks?.map(blok => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
        <div className="flex w-full pt-[40px]">
          <div
            ref={sliderRef}
            className="keen-slider h-full w-full overflow-visible"
            style={{ minHeight: 276 }}
          >
            {items_list.map(item => (
              <CarouselItem key={item._uid} {...item} />
            ))}
          </div>
        </div>
        {button_text && (
          <div className="mt-10 flex w-full justify-center px-[18px] lg:px-[48px] xl:px-[48px]">
            <a className="sofia-pro-m rounded-8 bg-[#2589BD] px-8 py-[14px] text-[18px] leading-[1] text-white">
              {button_text}
            </a>
          </div>
        )}
        {description_text && (
          <p className="mx-auto mt-14 max-w-5xl px-4 text-center text-[20px] text-black">
            {render(description_text, renderOptions)}
          </p>
        )}
      </div>
    </div>
  );
};

export default CarouselSection;

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { StoryblokComponent } from "@storyblok/react";
import { isEmpty } from "lodash";
import {
  MARK_BOLD,
  MARK_UNDERLINE,
  NODE_PARAGRAPH,
  NODE_HEADING,
  render
} from "storyblok-rich-text-react-renderer";

import { Button, LinkVariant } from "@components/UI";
import PaymentMethods from "@components/PaymentMethods";
import { subscribeNewsLetters } from "@src/services";
import config from "@src/config.json";

const Footer = ({
  blok: {
    navy_blocks,
    technical_menu,
    logo,
    socialmedia_section,
    payment_section_title,
    payment_method_list,
    after_payment_methods_text = "",
    after_payment_methods_img,
    before_input_title,
    before_input_description
  }
}) => {
  const [isValidated, setIsValidated] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const subscribeByEmail = async () => {
    setLoading(true);
    const url = "/api/v1/newsLetters/subscribeNewsLetters";
    await subscribeNewsLetters(url, emailAddress);
    fetch("https://node.goodlaundry.com/api/coupon")
      .then(response => response.json())
      .then(data => {
        const code =
          data.discountCodeBasicCreate.codeDiscountNode.codeDiscount.codes
            .edges[0].node.code;
        setCoupon(code);
        localStorage.setItem("coupon", code);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const validateInput = e => {
    const regex = new RegExp(config.regexValidation.email);
    setIsValidated(regex.test(e.target.value));
    setEmailAddress(e.target.value);
  };

  const paragraphMaskRender = {
    markResolvers: {
      [MARK_BOLD]: children => {
        return <b className="sofia-pro-b text-bold">{children}</b>;
      },
      [MARK_UNDERLINE]: children => {
        return (
          <span className="text-underline-blue pb-[5px] text-[#EDF2F4]">
            {children}
          </span>
        );
      }
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r text-[20px] text-[#EDF2F4]">{children}</p>
        );
      },
      [NODE_HEADING]: children => {
        return (
          <h3 className="sofia-pro-b mb-2 text-[34px] text-[#EDF2F4]">
            {children}
          </h3>
        );
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(coupon);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  useEffect(() => {
    setCoupon(localStorage.getItem("coupon"));
  }, []);

  return (
    <footer className="relative z-10 mx-auto flex h-full w-full flex-col overflow-hidden bg-[#15233C] px-[18px] lg:px-[48px] xl:px-[48px]">
      <div className="relative mx-auto flex h-full w-full max-w-[81.5rem] flex-col justify-center pt-[70px] pb-[24px] text-center lg:pb-[130px]">
        <div className="lg-[5rem] absolute top-[17%] z-[40] h-[111px] w-[115px] lg:top-[22%] lg:h-[165px] lg:w-[171px] xl:left-[8rem]">
          <img src="/img_91.png" className="flex h-full w-full" alt="" />
        </div>
        <div className="absolute top-[48%] left-[-3rem] z-[35] h-[165px] w-[171px] lg:top-[25%] lg:left-[-5rem] xl:left-[1rem]">
          <svg
            width="139"
            height="18"
            viewBox="0 0 139 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="M0.0305176 17.4819H138.158C138.158 17.4819 113.761 9.7986 104.436 11.3859C84.441 14.7874 82.2934 -0.138938 60.9507 0.67474C42.796 1.35503 47.2113 10.3455 37.5404 11.9729C32.3514 12.8399 21.48 10.7857 16.2777 11.6261C11.8757 12.3464 0.0305176 17.4952 0.0305176 17.4952V17.4819Z"
                fill="#223e67"
              />
            </g>
          </svg>
        </div>
        <div className="absolute top-[20%] right-[1rem] z-[35] h-[165px] w-[171px] lg:top-[65%]">
          <svg
            width="295"
            height="42"
            viewBox="0 0 295 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="M0.914673 41.0482H331.913C331.913 41.0482 273.449 22.6366 251.105 26.4404C203.19 34.5913 198.043 -1.17697 146.899 0.772871C103.395 2.40306 113.975 23.9472 90.8005 27.8468C78.3661 29.9245 52.3145 25.002 39.8481 27.0158C29.2997 28.7419 0.914673 41.0802 0.914673 41.0802V41.0482Z"
                fill="#223e67"
              />
            </g>
          </svg>
        </div>
        <div className="z-[100] pt-[50px] lg:pt-[0px]">
          <div className="flex w-full flex-col space-x-[13px]">
            {render(before_input_title, paragraphMaskRender)}
            {coupon ? (
              <p className="sofia-pro-r text-[20px] text-[#EDF2F4]">
                You're in!
              </p>
            ) : (
              render(before_input_description, paragraphMaskRender)
            )}
          </div>
          <div className="mt-[20px] flex w-full flex-shrink-0 flex-row justify-center">
            {!coupon ? (
              <>
                <input
                  placeholder="your@email.com"
                  value={emailAddress}
                  type="email"
                  className="sofia-pro-m min-w-0 rounded-tl-[8px] rounded-bl-[8px] rounded-tr-[0px] rounded-br-[0px] bg-white px-[24px] py-[8px] text-18 leading-[30px] text-gl-dark-blue placeholder:text-gl-dark-blue placeholder:opacity-50"
                  onChange={validateInput}
                />
                <Button
                  isDisable={!isValidated || loading}
                  onClick={subscribeByEmail}
                  className={
                    "sofia-pro-m flex-shrink-0 rounded-tl-[0px] rounded-bl-[0px] rounded-tr-[8px] rounded-br-[8px] bg-[#2589BD] px-[24px] py-[8px] text-18 leading-[30px] text-[#FFFFFF]"
                  }
                  variant={"lightBlue"}>
                  Sign up
                </Button>
              </>
            ) : (
              <div className="flex overflow-hidden rounded-8">
                <div className="sofia-pro-m w-[180px] flex-shrink-0 overflow-hidden text-ellipsis bg-white/10 px-[24px] py-[8px] text-18 leading-[30px] text-white md:w-[300px]">
                  {coupon}
                </div>
                <div
                  className="min-w-[90px] cursor-pointer bg-gl-blue py-2 text-[18px] text-white transition-all hover:opacity-70"
                  onClick={handleCopy}>
                  {isCopied ? "Copied" : "Copy"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto flex h-full w-full max-w-[81.5rem] flex-col pb-[30px] text-white lg:flex-row lg:pb-[150px] ">
        <div className="relative  flex h-full flex-col items-center align-middle max-lg:border-t-[0.5px] max-lg:border-[#D7F2F8] max-lg:py-[30px] lg:pr-[60px]">
          <div className="mb-[25px] flex h-full w-full max-lg:justify-center">
            <Image
              src={logo?.filename}
              width={197}
              height={112}
              objectFit="contain"
            />
          </div>
          <div className="flex flex-row gap-[30px]">
            {socialmedia_section.map(socialItem => {
              return (
                <LinkVariant
                  key={socialItem._uid}
                  href={
                    socialItem.link?.url || `/${socialItem.link.cached_url}`
                  }
                  linkType={"internal"}
                  className={"relative h-[30px] w-[30px]"}>
                  <Image
                    src={socialItem?.svg?.filename}
                    width={50}
                    height={50}
                    objectFit="contain"
                  />
                </LinkVariant>
              );
            })}
          </div>
          <div className="absolute right-0 top-0 mb-auto hidden h-full lg:flex">
            <svg
              width="6"
              height="213"
              viewBox="0 0 6 213"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.69497 0.638184C2.8362 2.89971 2.96008 5.80494 3.06051 9.20942C3.13435 11.7818 3.18846 14.6901 3.21994 17.7801C3.37938 32.8287 2.93218 40.3033 2.50831 47.6781C2.28277 51.8638 2.03389 55.95 2.04556 63.9228C2.08833 88.4392 3.65547 85.2498 3.83046 109.168C3.9199 121.028 3.53492 129.1 3.20049 134.781C2.67941 144.049 2.1 156.507 2.30221 174.645C2.35089 178.951 2.43142 182.978 2.53942 186.505C2.69152 191.293 2.87591 195.344 3.08383 198.464C3.38715 203.447 3.70602 207.732 4.02489 212.018"
                stroke="#2589BD"
                strokeWidth="2.33295"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:gap-[50px] lg:pl-[50px]">
          {navy_blocks?.map(blok => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
        {!isEmpty(payment_method_list) && (
          <div className="border-t-[0.5px] border-[#D7F2F8] pt-[12px] lg:border-t-0 lg:pl-[80px] lg:pt-0">
            <h3 className="sofia-pro-l hidden text-[24px] leading-[1] text-white lg:flex">
              {payment_section_title}
            </h3>
            <PaymentMethods
              additionClasses={"mt-[25px]"}
              paymentMethodsList={payment_method_list}
            />
            {after_payment_methods_text && (
              <div className="mt-[25px] flex min-w-[260px] flex-shrink-0 flex-row items-center space-x-[10px]">
                <div className="relative flex h-[35px] w-[35px]">
                  <Image
                    src={after_payment_methods_img?.filename}
                    layout="fill"
                  />
                </div>
                <span className="sofia-pro-s block text-18 text-white">
                  {after_payment_methods_text}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mx-auto flex h-full w-full max-w-[81.5rem] flex-col justify-between gap-[5px] pb-[85px] lg:flex-row lg:gap-0 lg:pb-[100px]">
        <div>
          <h3 className="sofia-pro-r text-[16px] text-[#53738d]">
            {`© ${new Date().getFullYear()} Good Laundry, LLC. All Rights Reserved`}
          </h3>
        </div>
        <div className="space-x-[25px] text-[#53738d]">
          {!isEmpty(technical_menu) &&
            technical_menu.map(item => (
              <LinkVariant
                isAccessibility={item.is_accessibility}
                key={item._uid}
                href={item.link?.url || `/${item.link.cached_url}`}
                linkType={"internal"}
                target="_blank"
                className={
                  "cursor-pointer text-[16px] underline decoration-[0.5px] underline-offset-2 hover:no-underline"
                }>
                {item.link_text}
              </LinkVariant>
            ))}
        </div>
      </div>
    </footer>
  );
};
export default Footer;

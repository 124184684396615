import { useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

export default function Accordion({ blok }) {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  return (
    <div className="border-b border-b-[#E9E9E9]">
      <button
        className={"px-1 py-2 md:py-3"}
        style={{
          width: "100%",
          position: "relative",
          textAlign: "left",
          border: "none",
          background: "transparent",
          outline: "none",
          cursor: "pointer"
        }}
        onClick={toggle}
        type="button"
      >
        <div className="flex w-full flex-row items-center justify-between">
          <h3
            className="sofia-pro-r text-18 lg:text-[20px]"
            {...storyblokEditable(blok)}
          >
            {blok.title}
          </h3>
          <div>
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: isShowing ? "rotate(90deg)" : "rotate(0deg)"
              }}
              className="transition-all"
            >
              <path
                opacity="0.6"
                d="M10.2957 8.99022C10.2946 8.86652 10.2692 8.74793 10.2193 8.63443C10.1695 8.52093 10.0987 8.42226 10.0068 8.33842L2.19699 0.847712C2.01969 0.673453 1.81059 0.587374 1.56972 0.589476C1.40045 0.590953 1.2478 0.631355 1.11177 0.710678C0.975733 0.79 0.869239 0.896734 0.792285 1.03088C0.715327 1.16502 0.677587 1.31672 0.679064 1.48598C0.681166 1.72684 0.767646 1.93766 0.938504 2.11844L8.64997 9.53195L8.64085 8.48706L1.05994 16.034C0.892209 16.2112 0.809422 16.4235 0.811581 16.6709C0.813058 16.8402 0.85344 16.9912 0.932727 17.124C1.01201 17.2568 1.12035 17.3616 1.25775 17.4386C1.39514 17.5155 1.54848 17.5532 1.71774 17.5517C1.84143 17.5507 1.95679 17.5269 2.06381 17.4803C2.17083 17.4338 2.26629 17.3679 2.35018 17.2825L10.0183 9.65672C10.2054 9.45978 10.2979 9.23761 10.2957 8.99022Z"
                fill="black"
                fillOpacity="0.85"
              />
            </svg>
          </div>
        </div>
      </button>
      <div
        style={{ display: isShowing ? "block" : "none" }}
        className="pr-5 pb-2 pl-1 text-[16px] leading-[1.1] text-[#424B5B] md:pb-5 md:text-18"
        {...storyblokEditable(blok)}
      >
        {render(blok.text)}
      </div>
    </div>
  );
}

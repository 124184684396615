import {
  MARK_LINK,
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import { motion } from "framer-motion";

const PressKitBanner = ({ blok: { title, description, image } }) => {
  const descriptionOptions = {
    markResolvers: {
      [MARK_LINK]: children => {
        return (
          <a className="cursor-pointer text-gl-blue no-underline hover:underline">
            {children}
          </a>
        );
      }
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r mb-4 text-[20px] leading-[1.2] text-gl-black">
            {children}
          </p>
        );
      },
      [NODE_HEADING]: children => {
        return (
          <h4 className="text-[24px] leading-[1.2] text-gl-black">
            {children}
          </h4>
        );
      }
    }
  };

  return (
    <section className="relative pt-0 pb-12 md:py-20">
      <div className="gradient-facts absolute top-0 left-0 -z-10 h-[200%] w-full" />
      <div className="custom-container pr-0">
        <div className="min-h-full top-0 right-0 z-10 ml-auto w-full max-w-[904px] overflow-hidden md:absolute md:w-[70%]">
          <div className="gradient-facts-revert absolute bottom-0 left-0 z-10 h-[20%] w-full" />
          <motion.img
            initial={{ x: 80, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 600,
              damping: 20,
              delay: 0.6,
              duration: 0.8
            }}
            className="w-full object-contain"
            src={image?.filename}
            alt=""
          />
        </div>
        <motion.div
          initial={{ y: 40, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 20,
            delay: 0.3,
            duration: 0.8
          }}
          className="relative z-20 -mt-20 w-full pr-4 md:mt-0 md:w-[58%]">
          <h1 className="sofia-pro-b mb-4 text-[32px] lg:text-[44px]">
            {title}
          </h1>
          {render(description, descriptionOptions)}
        </motion.div>
      </div>
    </section>
  );
};

export default PressKitBanner;

import { motion } from "framer-motion";
import { storyblokEditable } from "@storyblok/react";

export default function BigBox({ blok }) {
  const imageMotion = {
    rest: {
      opacity: 1,
      scale: 1,
      duration: 0.4,
      transition: {
        duration: 0.3
      }
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <motion.a
        initial="rest"
        whileHover="hover"
        animate="rest"
        href={blok?.link?.cached_url}>
        <div className="relative inline-flex  h-full min-h-[320px] w-full flex-col overflow-hidden overflow-hidden rounded-8 bg-[#F7F7F7] py-[20px] lg:py-[23px]">
          <button className="sofia-pro-s z-[1] ml-[25px] flex w-max rounded-8 bg-[#83B052BF] px-[16px] py-1.5 text-center text-[14px] uppercase text-white lg:py-2 lg:text-[16px]">
            {blok.button_text}
          </button>
          <div className="absolute top-0 z-[0] flex h-full w-full">
            <motion.img
              variants={imageMotion}
              src={`${blok?.image?.filename}` + "/m/"}
              className="mx-auto h-full w-full justify-center object-cover"
              {...storyblokEditable(blok)}
            />
          </div>
          <div className="z-[1] mt-auto flex flex-col px-[25px]">
            <motion.div
              variants={{
                hover: {
                  y: -40,
                  transition: { duration: 0.7, ease: "backInOut" }
                }
              }}>
              <h2
                className="sofia-pro-s text-[22px]"
                {...storyblokEditable(blok)}>
                {blok.title}
              </h2>
              <p
                className="sofia-pro-r text-18 text-[#424B5B]"
                {...storyblokEditable(blok)}>
                {blok.paragraph}
              </p>
            </motion.div>
            <motion.div
              initial={{ y: 60 }}
              variants={{
                hover: {
                  y: -30,
                  transition: { duration: 0.7, ease: "backInOut" }
                }
              }}>
              <p className="sofia-pro-m absolute h-max w-fit rounded-8 bg-[#2589BD] py-2 px-[20px] text-center text-white transition-all hover:bg-[#07496c] lg:w-fit">
                Shop now
              </p>
            </motion.div>
          </div>
        </div>
      </motion.a>
    </>
  );
}

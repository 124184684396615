import React from "react";

type AccordionProps = {
  isOpened: boolean;
  children: any;
  classList?: string;
};

const Accordion = ({ isOpened, classList, children }: AccordionProps) =>
  isOpened && <div className={classList}>{children}</div>;

export default Accordion;

import Image from "next/image";

export default function ProductImageBig({ blok }) {
  return (
    <>
      <div className="keen-slider__slide">
        <Image
          layout="fill"
          priority
          src={`${blok?.image?.filename}` + "/m/"}
          className="hidden h-full w-full object-cover lg:flex"
        />
        <img
          src={`${blok?.image?.filename}` + "/m/"}
          className="flex h-full w-full object-cover lg:hidden"
        />
      </div>
    </>
  );
}

import { isEmpty } from "lodash";

import { ShopifyProductService } from "../../services/ShopifyProductService";
import { ShopifyProductDAO } from "../../services/ShopifyProductDAO";
import { getShopifyShortId } from "../../utils/helpers";

export class RechargeBundleProductDAO {
  constructor(bundleProductVariantsList, parentBundleProductDAO) {
    this.parentBundleProductDAO = parentBundleProductDAO;
    this.bundleProductVariantsList = bundleProductVariantsList || null;
  }

  getExternalCollectionId() {
    const variants = this.bundleProductVariantsList?.variants;
    if (isEmpty(variants)) {
      return null;
    }
    const [
      {
        option_sources: [{ collection_id: externalCollectionId }]
      }
    ] = variants;
    return externalCollectionId || null;
  }

  #getBundleVariants() {
    const { variants } = this.bundleProductVariantsList;
    return variants || [];
  }

  getMaxQuantity(variantId) {
    const shortId = getShopifyShortId(variantId);
    const bundleVariants = this.#getBundleVariants();
    const chosenBundleVariant = bundleVariants.find(
      ({ external_variant_id: externalVariantId }) =>
        externalVariantId === shortId
    );
    if (!chosenBundleVariant) {
      return null;
    }
    const { items_count: quantity } = chosenBundleVariant;
    return quantity;
  }

  async getIncludedBundleProductsList() {
    const shopifyProductService = new ShopifyProductService();
    const externalCollectionId = this.getExternalCollectionId();
    const response = await shopifyProductService.getCollectionProductsList(
      `gid://shopify/Collection/${externalCollectionId}`
    );
    if (isEmpty(response)) {
      return [];
    }
    const {
      products: { edges: productsList }
    } = response;
    return productsList.filter(({ node }) =>
      this.#checkSellingPlansMatching(node)
    );
  }

  #checkSellingPlansMatching(product) {
    const bundleItemProductDAO = new ShopifyProductDAO(product);
    const requiresSellingPlan = bundleItemProductDAO.requiresSellingPlan;
    const subscriptionOptionsString =
      bundleItemProductDAO.subscriptionOptionsString;
    const parentRequiresSellingPlan =
      this.parentBundleProductDAO.requiresSellingPlan;
    const parentSubscriptionOptions =
      this.parentBundleProductDAO.subscriptionOptionsString;
    if (requiresSellingPlan === parentRequiresSellingPlan) {
      return subscriptionOptionsString === parentSubscriptionOptions;
    }
    return false;
  }

  get bundleProductId() {
    return getShopifyShortId(
      this.bundleProductVariantsList.external_product_id
    );
  }
}

import React from "react";

import AccordionItem from "../../AccordionItem";

type AccordionSectionProps = {
  blok: {
    accordions: [];
  };
};

const AccordionsBlok = ({
  blok: { accordions: accordions_list }
}: AccordionSectionProps) => {
  return accordions_list.map(({ content, content: { title } }, index) => (
    <AccordionItem key={`${title}-${index}`} content={content} />
  ));
};

export default AccordionsBlok;

import { motion } from "framer-motion";
import { isEmpty } from "lodash";

import DynamicComponent from "@services/DynamicComponentsRenderer";
import { useMediaQuery } from "@src/hooks";

const AccordionSection = ({ blok, isFAQpage }) => {
  if (isEmpty(blok)) {
    return null;
  }
  const {
    is_accordions_opened: isOpenedAccordionsWithNested,
    bg_color: bgColor,
    is_additional_padding_needed: isAdditionalPadding
  } = blok;
  const wrapperClasses = isFAQpage
    ? " px-[18px] "
    : "relative mt-[55px] h-full !overflow-hidden rounded-8 px-[18px]";

  const isMedium = useMediaQuery("(max-width: 992px)");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.8,
        duration: 0.4
      }}
      className={`${wrapperClasses}  bg-[${bgColor?.color}] mx-auto mb-[50px] flex w-full max-w-[120rem] flex-col lg:mb-[100px] lg:px-[48px]`}
    >
      {blok.title && (
        <h2
          style={{
            marginTop: isMedium
              ? blok.margin_top_title_mobile
                ? `${blok.margin_top_title_mobile}px`
                : "80px"
              : blok.margin_top_title
              ? `${blok.margin_top_title}px`
              : "40px"
          }}
          className="sofia-pro-b mb-16 text-center text-[32px] leading-[1.1] lg:text-[44px]"
        >
          {blok.title}
        </h2>
      )}
      <div
        className={`mx-auto w-full max-w-[81.5rem] overflow-hidden rounded-8 border border-[#B9BDC580]
        ${isAdditionalPadding && "py-[20px]"}`}
      >
        <div className="accordion-last flex h-full w-full flex-col justify-center">
          {blok?.body?.map(blok => (
            <DynamicComponent
              blok={blok}
              key={blok._uid}
              isOpenedAccordionsWithNested={isOpenedAccordionsWithNested}
            />
          ))}
        </div>
      </div>
      {isFAQpage && (
        <div className="mt-[50px] flex w-full justify-center text-center lg:mt-[70px]">
          <p className="sofia-pro-s text-[26px] leading-[1.2] text-black lg:text-[30px]">
            Can’t find what you’re looking for?{" "}
            <a
              href="/"
              className="underline-[#2589BD] text-[#2589BD] underline decoration-2 underline-offset-4"
            >
              Get in touch!
            </a>
          </p>
        </div>
      )}
    </motion.div>
  );
};
export default AccordionSection;

import {
  MARK_BOLD,
  NODE_PARAGRAPH,
  NODE_HEADING,
  render
} from "storyblok-rich-text-react-renderer";

const paragraphMaskRender = {
  markResolvers: {
    [MARK_BOLD]: children => {
      return <span className="sofia-pro-b">{children}</span>;
    }
  },
  nodeResolvers: {
    [NODE_PARAGRAPH]: children => {
      return (
        <p className="sofia-pro-r mb-[20px] leading-[28px] text-[#424B5B] md:text-[17px] lg:text-[22px]">
          {children}
        </p>
      );
    },
    [NODE_HEADING]: (children, { level }) => {
      switch (level) {
        case 1:
          return (
            <h1 className="sofia-pro-b line-through-[1.1] mt-[30px] text-[28px] text-white lg:mt-[90px] lg:text-[44px]">
              {children}
            </h1>
          );
        case 3:
          return (
            <h3 className="sofia-pro-s line-through-[1] lg:line-through-[1.1] text-[25px] text-[#15233C] md:text-[25px] lg:text-[30px] lg:text-[34px] xl:text-[34px]">
              {children}
            </h3>
          );
        case 4:
          return (
            <h4 className="sofia-pro-r line-through-[1] lg:line-through-[1.1] max-w-[36rem] text-[20px] lg:text-[24px]">
              {children}
            </h4>
          );
        default:
          <div>{children}</div>;
      }
    }
  }
};

const WhoWeAre = ({ blok }) => {
  return (
    <div className="bg-gradient-to-b from-[#F5F9FA] pt-[50px] md:pt-[100px] lg:pt-[100px]">
      <section className="mx-auto flex w-full max-w-[81.5rem] flex-col md:flex-row lg:flex-row">
        <div className="flex-1 overflow-hidden rounded-[7px] px-[18px]">
          <img className="w-full" src={blok.image}></img>
        </div>
        <div className="flex flex-1 flex-col justify-center px-[18px] pt-[50px] text-center md:ml-[15px] md:pt-[0px] md:text-left lg:ml-[32px] lg:pt-[0px] lg:text-left">
          {render(blok?.content, paragraphMaskRender)}
        </div>
      </section>
    </div>
  );
};

export default WhoWeAre;

import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

export default function DiscountApplied({ appliedText, richtextOptions }) {
  return (
    <>
      <div className="flex w-full items-center justify-center bg-gl-blue">
        <div className="sofia-pro-s px-[75px] pt-[12px] pb-[18px] text-center text-[18px] leading-[1.2] text-white sm:px-[0] sm:text-left lg:text-[20px]">
          {render(appliedText, richtextOptions)}
        </div>
      </div>
    </>
  );
}

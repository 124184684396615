import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import ProductCard from "@components/ProductCard";
import { StoryblokProductDAO } from "@services/StoryblokProductDAO";
import { BLOCK_ROUNDED, BLOCK_PADDING } from "@styles/shared/styles";
import { ShopifyProductService } from "@services/ShopifyProductService";

const SingleProductBlock = ({ blok = {} }: any) => {
  if (isEmpty(blok)) {
    return null;
  }
  const {
    block_padding: blockPaddingProp = "none",
    block_border_radius: blockBorderRadius = "none"
  } = blok;
  const [productData, setProductData] = useState({});
  const blockPadding = BLOCK_PADDING[blockPaddingProp];
  const blockRounded = BLOCK_ROUNDED[blockBorderRadius];

  useEffect(() => {
    (async () => {
      const storyblokProductDAO = new StoryblokProductDAO();
      const { content } = await storyblokProductDAO.getProductsDataByUuids(
        blok.product
      );
      const shopifyProductService = new ShopifyProductService();
      const shopifyProductData = await shopifyProductService.getProductById(
        content?.shopify_product_id
      );
      setProductData(shopifyProductData);
    })();
  }, []);

  return (
    <div
      className={`${blockRounded} ${blockPadding} relative flex h-full w-full`}
    >
      <ProductCard
        data={blok}
        product={productData}
        cardVariant="quickAdd"
        blok={undefined}
      />
    </div>
  );
};

export default SingleProductBlock;

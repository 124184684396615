import React, { useContext, useEffect, useState, useMemo } from "react";
import { uniq, map } from "lodash";
import cn from "classnames";
import { ShopContext } from "@context/shopContext";
import AddonProductCard from "./AddonItem";
import { ShopifyProductService } from "@services/ShopifyProductService";
import { useKeenSlider } from "keen-slider/react"; // import from 'keen-slider/react.es' for to get an ES module

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

const CartAddonsList = ({}) => {
  const [isWheel, setIsWheel] = useState(true);

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      breakpoints: {
        "(max-width: 567px)": {
          slides: {
            perView: 2.0,
            spacing: 15
          }
        },
        "(max-width: 450px)": {
          slides: {
            perView: 1.5,
            spacing: 15
          }
        }
      },
      slides: {
        perView: 2.4,
        spacing: 15
      }
    },
    [
      // add plugins here
    ]
  );
  const [items, setItems] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const random = useMemo(() => getRandomInt(0, 10000), [items]);

  const service = new ShopifyProductService();

  const { cart } = useContext(ShopContext);

  useEffect(() => {
    service
      .getAllProducts()
      .then(p => {
        return p.filter(p => p.productType !== "Bundles");
      })
      .then(products => {
        if (cart.length > 0) {
          const product = cart[cart.length - 1].node.merchandise.product;
          const {
            handle,
            productType,
            tags: [tag]
          } = product;

          let filtered = [];

          const cartHandles = cart.map(i => i.node.merchandise.product.handle);

          if (productType === "Detergent Sheets") {
            const otheInScent = products.filter(
              p => p.handle !== handle && p?.tags?.includes(tag)
            );
            const travelPacks = products.filter(
              p => p.productType === "Travel Packs" && !p.tags.includes(tag)
            );
            const otherDeteregents = products.filter(
              p => p.productType == productType && p.handle !== handle
            );
            filtered = [
              ...otheInScent,
              ...travelPacks,
              ...otherDeteregents
            ].filter(p => !cartHandles.includes(p.handle));
          } else if (productType === "Bundles") {
            const travelPacks = products.filter(
              p => p.productType === "Travel Packs"
            );
            const otherBundles = products.filter(
              p => p.productType === productType && p.handle !== handle
            );
            filtered = [...travelPacks, ...otherBundles].filter(
              p => !cartHandles.includes(p.handle)
            );
          } else {
            const remainingProducts = uniq(map(products, "productType")).filter(
              d =>
                d !== "Bundles" &&
                d !== "Travel Packs" &&
                d !== "" &&
                d !== productType
            );
            const other = products.filter(
              p =>
                remainingProducts.includes(p.productType) &&
                p.tags.includes(tag)
            );
            const travelPacks = products.filter(
              p => p.productType === "Travel Packs"
            );
            filtered = [...other, ...travelPacks].filter(
              p => !cartHandles.includes(p.handle)
            );
          }

          setItems(filtered);
          setIsLoadingMore(false);
        } else {
          let list = [];
          products.forEach(p => {
            const {
              tags: [tag],
              productType
            } = p;
            const found = list.find(
              d => d.productType === productType || d.tags.includes(tag)
            );
            if (!found && list.length < 3) {
              list.push(p);
            }
          });
          setItems(list);
          setIsLoadingMore(false);
        }
      });
  }, [cart]);

  return items.length > 0 ? (
    <div className="flow-root w-full">
      <div className="mb-[15px] flex h-full flex-col border-b border-t-[#e3e7eb] px-[15px] py-[15px]">
        <section className={cn("relative", { "min-h-[135px]": isLoadingMore })}>
          <div
            ref={sliderRef}
            key={random}
            className="keen-slider"
            onWheel={e => {
              if (isWheel) {
                setIsWheel(false);
                setTimeout(() => {
                  setIsWheel(true);
                }, 1000);

                if (e.deltaY < 0) {
                  instanceRef.current?.prev();
                } else {
                  instanceRef.current?.next();
                }
              }
            }}
          >
            {items.map(node => (
              <div
                key={node.id}
                className="keen-slider__slide flex items-center rounded-[8px] bg-[#F9F9F9]"
              >
                <AddonProductCard key={node.id} productData={node} />
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  ) : null;
};
export default CartAddonsList;

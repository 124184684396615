import DynamicComponent from "@services/DynamicComponentsRenderer";

const ContactSection = ({ blok: { body } }) => {
  return (
    <div className="mx-auto mt-[30px] mb-[20px] flex w-full max-w-[120rem] flex-col px-[16px] lg:mt-[50px] lg:mb-[100px]">
      <div className="mx-auto w-full  overflow-hidden align-middle">
        {body?.map(customBlok => (
          <DynamicComponent
            key={customBlok._uid}
            blok={customBlok}
            data={{ isContactPage: true }}
          />
        ))}
      </div>
    </div>
  );
};
export default ContactSection;

import { motion } from "framer-motion";
import { storyblokEditable } from "@storyblok/react";

export default function SmallBox({ blok }) {
  const imageMotion = {
    rest: {
      opacity: 1,
      scale: 1,
      duration: 0.4,
      transition: {
        duration: 0.3
      }
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <motion.a
        initial="rest"
        whileHover="hover"
        animate="rest"
        href={blok?.link?.cached_url}>
        <div className="relative flex h-[240px] max-h-[240px] flex-col overflow-hidden rounded-8 bg-gl_grey pt-7 pb-[22px] align-middle">
          <div className="absolute top-3 mx-auto  my-auto flex max-h-[170px] min-h-[130px] w-full items-center justify-center px-4 py-4">
            <motion.img
              variants={imageMotion}
              src={`${blok?.image?.filename}` + "/m/"}
              style={{ maxHeight: blok.height }}
              className="min-h-[40px] object-cover"
              {...storyblokEditable(blok)}
            />
          </div>
          <div className="mt-auto flex  flex-shrink-0 flex-col px-6 pt-2 pb-[5px] align-middle">
            <motion.div
              variants={{
                hover: {
                  y: -40,
                  transition: { duration: 0.7, ease: "backInOut" }
                }
              }}>
              <h3
                className="sofia-pro-s text-[22px] leading-[1.1]"
                {...storyblokEditable(blok)}>
                {blok?.title}
              </h3>
            </motion.div>
            <motion.div
              initial={{ y: 60 }}
              variants={{
                hover: {
                  y: -30,
                  transition: { duration: 0.7, ease: "backInOut" }
                }
              }}>
              <p className="sofia-pro-m absolute h-max w-fit rounded-8 bg-[#2589BD] py-2 px-[20px] text-center text-white transition-all hover:bg-[#07496c] lg:w-fit">
                Shop now
              </p>
            </motion.div>
          </div>
        </div>
      </motion.a>
    </>
  );
}

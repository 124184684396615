import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

export default function Instagram() {
  const ref = useRef();
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true
  });

  return (
    <div className="mx-auto mb-[33px] flex w-full max-w-[120rem] overflow-hidden lg:mb-[100px]">
      <div className="mx-auto flex w-full flex-col justify-center">
        <div className="flex w-full justify-center px-[18px] lg:px-[48px] xl:px-[48px]">
          <h3 className="sofia-pro-s text-[34px]">@goodlaundry</h3>
        </div>
        <div
          {...events}
          ref={ref}
          className="mt-[25px] ml-auto flex h-full w-full select-none flex-row gap-[16px] overflow-x-scroll px-[18px] scrollbar-hide lg:mt-[20px] lg:px-[48px] xl:px-[48px]"
        >
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
          <div className="flex h-fit max-h-[320px] w-fit max-w-[320px] flex-shrink-0">
            <img src="img_74.png" className="flex h-full w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";

export default function Paradise({ blok }) {
  return (
    <div className="relative mx-auto mb-[32px] flex h-full w-full max-w-[120rem] lg:mb-[80px]">
      <div className="relative h-[350px] w-full lg:h-[615px]">
        <Image
          src={blok?.image?.filename}
          alt="Try background"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div className="absolute flex h-full w-full justify-center overflow-hidden py-[42px] px-[18px] text-center lg:py-[60px] lg:px-[48px] xl:px-[48px]">
        <div className="max-w-[45rem]">
          <h2
            style={{ color: blok?.text_colour?.color }}
            className="sofia-pro-b text-[28px] leading-[1.2] lg:text-[58px]"
            {...storyblokEditable(blok)}
          >
            {blok?.title}
          </h2>
        </div>
      </div>
    </div>
  );
}

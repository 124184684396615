import { useContext } from "react";
import React, { useState, useEffect } from "react";
import { storyblokEditable } from "@storyblok/react";

export default function NavLink({ blok }) {
  return (
    <>
      <a
        href={blok.link.cached_url}
        {...storyblokEditable(blok)}
        className="relative mx-[14px] flex flex-shrink-0 items-center gap-[5px]"
      >
        <span>{blok.link_text}</span>
        {blok?.has_svg && <img src={blok.svg.filename} />}

        {blok?.has_scribble && <div className="scribble"></div>}
      </a>
    </>
  );
}

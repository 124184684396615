import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import SbEditable from "storyblok-react";

import { concatProductData } from "@utils/helpers";
import ProductCardsGrid from "../../ProductCardsGrid";
import { ShopifyProductService } from "@services/ShopifyProductService";

const shopifyProductService = new ShopifyProductService();

const ProductList = ({ blok } = {}) => {
  const { products } = blok;
  const [allShopifyProduct, setAllShopifyProduct] = useState(null);

  const concatedDataProductsList = concatProductData(
    products,
    allShopifyProduct
  );

  useEffect(() => {
    const getProduct = async () => {
      const shopifyProductData = await shopifyProductService.getAllProducts();
      setAllShopifyProduct(shopifyProductData);
    };
    getProduct();
  }, []);

  if (isEmpty(blok)) {
    return null;
  }

  return (
    <SbEditable content={blok}>
      <ProductCardsGrid {...blok} productsList={concatedDataProductsList} />
    </SbEditable>
  );
};

export default ProductList;

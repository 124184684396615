import React from "react";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";

import {
  BLOCK_WIDTH,
  BLOCK_ROUNDED,
  BLOCK_PADDING,
  BLOCK_TEXT_ALIGN,
  BLOCK_TEXT_POSITION
} from "@styles/shared/styles";
import AnimatedText from "@components/AnimatedText";
import { Link } from "@components/UI";
import BackgroundMedia from "./BackgroundMedia";

type TButton = {
  _uid: string;
  link_title: string;
  link_url?: { url: string; cached_url: string };
  button_color: string;
  text_color: string;
};

type ImageWithTextAndLinkProps = {
  blok: {
    title: string;
    link_type: string;
    sub_title: string;
    media_type: string;
    text_align: string;
    block_width: string;
    button_color: string;
    block_padding: string;
    text_position: string;
    media: { filename: string };
    media_mob: { filename: string };
    block_border_radius: string;
    buttons_list: [TButton];
  };
};

const ImageWithTextAndLink = ({
  blok: {
    title,
    sub_title,
    text_align,
    media_type,
    media: { filename },
    media_mob,
    block_padding = "none",
    text_position = "center",
    block_width = "width_full",
    block_border_radius = "none",
    buttons_list
  },
  blok
}: ImageWithTextAndLinkProps) => {
  const blockWidth = BLOCK_WIDTH[block_width];
  const blockPadding = BLOCK_PADDING[block_padding];
  const blockTextAlign = BLOCK_TEXT_ALIGN[text_align];
  const blockRounded = BLOCK_ROUNDED[block_border_radius];
  const blockTextPosition = BLOCK_TEXT_POSITION[text_position];
  return (
    <div
      className={`${blockWidth} ${blockRounded} ${blockPadding} ${blockTextAlign} relative mb-[50px] flex h-full bg-gl_light_grey`}
    >
      <BackgroundMedia
        data={{ media_mob, filename, blockRounded, media_type }}
      />
      <div className="absolute	top-0 right-0 z-10 flex h-full w-full">
        <div className={`${blockTextPosition} absolute sm:p-[5px] md:p-[35px]`}>
          <div className="flex w-full max-w-[35rem] flex-col">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.4
              }}
              className="h-full overflow-hidden pt-[12px] lg:pt-[19px] lg:pb-[5px]"
            >
              <AnimatedText text={title} />
            </motion.div>
            <div className="h-full pt-[9px] lg:pt-[15px]">
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  delay: 0.6,
                  duration: 0.4
                }}
                className="sofia-pro-r text-[22px] leading-[26px] lg:text-[24px]"
              >
                {sub_title}
              </motion.p>
            </div>
            <div className="flex flex-wrap items-center gap-[15px] pr-[2px] pt-[25px]">
              {!isEmpty(buttons_list) &&
                buttons_list.map(button => (
                  <motion.div
                    key={button._uid}
                    whileHover={{ scale: 1.07 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{
                      delay: 0.1
                    }}
                  >
                    <Link
                      linkType="buttonLink"
                      variant={button.button_color}
                      href={button.link_url.cached_url || button.link_url.url}
                      className={`sofia-pro-m flex w-[170px] justify-center rounded-8 px-[12px] py-[14px] text-center text-18 leading-[1] text-${button.text_color} lg:w-[190px]`}
                    >
                      {button.link_title}
                    </Link>
                  </motion.div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithTextAndLink;

import {
  MARK_LINK,
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";

const OurPeople = ({ blok: { title, peoples } }) => {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h4 className="sofia-pro-s text-[22px] text-gl-black">
            {children}
          </h4>
        );
      }
    }
  };

  const renderDescriptionOptions = {
    markResolvers: {
      [MARK_LINK]: children => {
        return (
          <a className="sofia-pro-s cursor-pointer text-gl-blue underline hover:no-underline">
            {children}
          </a>
        );
      }
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <p className="sofia-pro-r mb-0 text-[20px] text-gl-black">
            {children}
          </p>
        );
      }
    }
  };

  return (
    <section className="py-12 md:py-20">
      <div className="mx-auto max-w-[1100px] px-4">
        <h3 className="sofia-pro-b mb-10 text-center text-[32px] md:mb-14 lg:text-[44px]">
          {title}
        </h3>
        {peoples.length > 0 && (
          <ul className="flex flex-wrap items-start justify-between gap-y-10">
            {peoples.map(people => (
              <li className="flex w-full flex-col items-center justify-center text-center md:flex-row md:text-left lg:w-[48%] lg:justify-start">
                <img
                  style={{ width: `${people.icon_size}px` }}
                  src={people.icon_image.filename}
                  alt=""
                />
                <div className="mt-5 md:ml-7">
                  {render(people.title, renderTitleOptions)}
                  {render(people.subtitle, renderDescriptionOptions)}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default OurPeople;

export const Purchase = ({ cart }) => {
  return (
    <div className="bg-clear-skies mb-4 flex h-fit items-center overflow-hidden rounded-8 px-4 py-3">
      <img
        src="/globe.png"
        className="mr-[15px] max-h-[32px] max-w-[32px]"
        alt=""
      />
      <div className="flex flex-col">
        <p className="sofia-pro-b text-[14px] leading-[1] text-[##182345]">
          Your order donates ${cart.length} and 10 loads of detergent.
        </p>
        <p className="sofia-pro-r text-[14px] text-[##182345]">
          This donation is{" "}
          <span className="sofia-pro-b text-[#2589BD]">100% free to you.</span>
        </p>
      </div>
    </div>
  );
};

import { storyblokEditable } from "@storyblok/react";

export default function XSArticle({ article }) {
  return (
    <>
      <a
        href={`${article.full_slug}`}
        className="flex h-full w-fit flex-shrink-0"
      >
        <div className="relative flex h-full max-h-full w-fit max-w-[414px] flex-shrink-0 flex-col border-l-[2px] border-[#D3E7F2] px-[33px]">
          <div className="flex h-full w-full max-w-[230px] items-center py-[13px] align-middle">
            <h1
              className="sofia-pro-s text-[16px] leading-[1.4]"
              {...storyblokEditable(article)}
            >
              {article?.title}
            </h1>
          </div>
        </div>
      </a>
    </>
  );
}

export const createCartMutationQuery = () => `
mutation {
	cartCreate {
		${cartQuery}
	}
}
	`;

export const addItemToCartMutationQuery = ({
  cartId,
  variantId,
  quantity,
  sellingPlanId,
  attributes
}) => {
  const sellingPlanData = sellingPlanId
    ? `sellingPlanId: "${sellingPlanId}",`
    : "";
  const attributesData = attributes.length
    ? `attributes: [{key:"${attributes[0]?.key}", value: "${attributes[0]?.value}"}, {key:"${attributes[1]?.key}", value: "${attributes[1]?.value}"}],`
    : "";
  return `
		mutation {
			cartLinesAdd(cartId: "${cartId}", lines:[{
				quantity: ${quantity},
				merchandiseId: "${variantId}",
				${sellingPlanData}
				${attributesData}
			}]) {
				${cartQuery}
			userErrors {
			 field
			 message
		 }
		}
	 }
			`;
};

export const updateCartItemsMutationQuery = (cartId, items = []) => {
  const lineItems = items.map(({ id, quantity, sellingPlanId, attributes }) => {
    const sellingPlanData = `sellingPlanId: ${
      sellingPlanId ? `"${sellingPlanId}"` : null
    },`;
    const attributesData = attributes.length
      ? `attributes: [{key:"${attributes[0]?.key}", value: "${attributes[0]?.value}"}, {key:"${attributes[1]?.key}", value: "${attributes[1]?.value}"}],`
      : "";

    return `{ quantity: ${quantity}, id: "${id}", ${sellingPlanData}${attributesData} }`;
  });

  return `mutation {
		  cartLinesUpdate(cartId: "${cartId}", lines:[${lineItems.join(",")}]) {
			  ${cartQuery}
			  userErrors {
				  field
				  message
			  }
		  }
	  }`;
};

export const addItemsToCartMutationQuery = (cartId, items = []) => {
  const lineItems = items.map(
    ({ variantId, quantity, sellingPlanId, attributes }) => {
      const sellingPlanData = sellingPlanId
        ? `sellingPlanId: "${sellingPlanId}",`
        : "";
      const attributesData = attributes.length
        ? `attributes: [{key:"${attributes[0]?.key}", value: "${attributes[0]?.value}"}, {key:"${attributes[1]?.key}", value: "${attributes[1]?.value}"}],`
        : "";
      return `{ quantity: ${quantity}, merchandiseId: "${variantId}", ${sellingPlanData}${attributesData} }`;
    }
  );

  return `
		  mutation {
			  cartLinesAdd(cartId: "${cartId}", lines:[${lineItems.join(",")}]) {
				  ${cartQuery}
			  userErrors {
			   field
			   message
		   }
		  }
	   }
			  `;
};

export const removeItemCartMutationQuery = ({ cartId, lineIds }) => `mutation {
		cartLinesRemove(cartId: "${cartId}", lineIds:[ ${lineIds}]) {
				${cartQuery}
				userErrors {
					field
					message
				}
			}
		}`;

export const updateItemCartMutationQuery = ({
  cartId,
  lineId,
  quantity,
  attributes,
  sellingPlanId
}) => {
  const attributesData = attributes?.value
    ? `attributes: [{key:"${attributes.key}", value: "${attributes.value}"}],`
    : "";
  return `mutation {
		cartLinesUpdate(cartId: "${cartId}", lines:{ id: "${lineId}", quantity:${quantity}, ${`sellingPlanId:${
    sellingPlanId ? `"${sellingPlanId}"` : null
  },`} ${attributesData}}) {
			${cartQuery}
			userErrors {
				field
				message
			}
		}
	}`;
};

const cartQuery = `
			cart {
				id
				discountAllocations {
					discountedAmount {
						amount
						currencyCode
					  }
			   }
			   discountCodes {
				applicable
				code
			  }

				lines(first: 50) {
					edges {
						node {
							id
							quantity
							discountAllocations {
								discountedAmount {
									amount
									currencyCode
								  }
						   }
							attributes {
								key
								value
						}
							sellingPlanAllocation{
								sellingPlan{
										id
										name
								}
								checkoutChargeAmount{
									amount
								}
						}
							merchandise {
								... on ProductVariant {
									id
									title
									image{
										url
										altText
									}
									priceV2{
										amount
										currencyCode
									}
									product{
										title
										handle
										tags
										productType
										sellingPlanGroups(first: 1) {
											edges {
												node {
													name
													options {
															name
															values
													}
									
													sellingPlans(first: 10) {
														edges {
															node {
															id
															name
															description
															recurringDeliveries
															priceAdjustments {
																orderCount
																adjustmentValue {
																	__typename
																	... on SellingPlanPercentagePriceAdjustment {
																		adjustmentPercentage
																	}
																	... on SellingPlanFixedAmountPriceAdjustment {
																		adjustmentAmount {
																			amount
																			currencyCode
																		}
																	}
																	... on SellingPlanFixedPriceAdjustment {
																			price {
																				amount
																				currencyCode
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				cost {
					totalAmount {
						amount
					}
					subtotalAmount {
						amount
					}
					totalTaxAmount {
						amount
					}
					totalDutyAmount {
						amount
					}
				}
			}`;

export const getCartdataMutationQuery = cartId =>
  `
	{
		cart(
			id: "${cartId}"
		) {
			id
			createdAt
			updatedAt
			checkoutUrl
			lines(first: 10) {
				edges {
					node {
						id
						quantity
						merchandise {
							... on ProductVariant {
								id
							}
						}
						attributes {
							key
							value
						}
					}
				}
			}
			attributes {
				key
				value
			}
			cost {
				totalAmount {
					amount
					currencyCode
				}
				subtotalAmount {
					amount
					currencyCode
				}
				totalTaxAmount {
					amount
					currencyCode
				}
				totalDutyAmount {
					amount
					currencyCode
				}
			}
			buyerIdentity {
				email
				phone
				customer {
					id
				}
				countryCode
			}
			discountCodes {
				applicable
				code
			}
		}
	}
    `;

export const checkoutUrlQuery = cartId =>
  `{cart(id: "${cartId}") {
checkoutUrl
}
}`;

export const addDiscountCodeToCart = ({ cartId, discountCode }) =>
  `mutation {
    cartDiscountCodesUpdate(cartId: "${cartId}", discountCodes: ${
    discountCode ? `["${discountCode}"]` : "[]"
  } ) {
        ${cartQuery}
        userErrors {
            field
            message
        }   
    }
}`;

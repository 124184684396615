import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Color from "color";
import {
  NODE_PARAGRAPH,
  NODE_UL,
  NODE_LI,
  render
} from "storyblok-rich-text-react-renderer";

export default function Product(props) {
  const { blok, activeScentColor } = props;
  const descriptionOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return <p className="mb-4">{children}</p>;
      },
      [NODE_UL]: children => {
        return <ul className="list-outside list-disc pl-[20px]">{children}</ul>;
      }
    }
  };
  return (
    <div
      className="keen-slider__slide flex h-full w-full min-w-[305px] max-w-[305px] flex-shrink-0 flex-col lg:min-w-[421px] lg:max-w-[421px]"
      {...storyblokEditable(blok)}
    >
      <div className="flex h-fit w-full ">
        <div
          className={`relative flex h-[310px] w-[100%] rounded-8`}
          style={{
            background: activeScentColor
              ? Color(activeScentColor).alpha(0.2).string()
              : "transparent"
          }}
        >
          <Image
            src={blok?.image?.filename}
            alt="bundleContentItem"
            layout="fill"
            objectFit="contain"
            objectPosition="bottom"
            className="!max-h-[84%] !min-h-[84%] !min-w-[60%] !max-w-[60%]"
          />
        </div>
      </div>
      <div className="mt-[22px] flex h-fit flex-col gap-[13px]">
        <h3 className="sofia-pro-b text-[22px] leading-[1]">{blok?.title}</h3>

        <p className="sofia-pro-r text-[18px] leading-[1.1] ">
          {render(blok.description, descriptionOptions)}
        </p>
      </div>
    </div>
  );
}

export const addedToCartFBEvent = (productDAO, sellingPlan) => {
  const postData = {
    content_ids: [productDAO.productId],
    content_type: "product",
    value: sellingPlan
      ? productDAO.subscriptionFirstOptionPrice(productDAO.firstVariant)
      : productDAO.price,
    currency: productDAO.currency,
    content_name: productDAO.title,
    content_category: productDAO.productType
  };
  window.fbq("track", "AddedToCart", postData);
};

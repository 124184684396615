import React from "react";

import "../styles/global.css";
import "tailwindcss/tailwind.css";

import { useRouter } from "next/router";
import Script from "next/script";
import { storyblokInit, apiPlugin } from "@storyblok/react";

import components from "@services/blokComponents";
import ShopProvider from "@context/shopContext";
import Layout from "../layouts";

const gtm = process.env.GTM_ACCOUNT_ID || "GTM-5BH6PDQ";

storyblokInit({
  accessToken: process.env.STORY_BLOK_API_ACCESSTOKEN,
  apiOptions: {},
  use: [apiPlugin],
  components: { ...components, layout: Layout }
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  return (
    <ShopProvider>
      {/* Google Tag Manager */}
      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtm}');`
        }}
      />
      {/* Meta Pixel Code */}
      <Script
        id="meta-pixel-code"
        dangerouslySetInnerHTML={{
          __html: `
                  !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1595528317541686');
                    fbq('track', 'PageView');
                  `
        }}
      />
      {/* Affiliate and Influencer conversion tracking */}
      <Script
        id="conversion tracking"
        src={"https://d2xrtfsb9f45pw.cloudfront.net/js/bg-thirdparty-track.js"}
      />
      {/* Web Accessibility */}
      <Script
        id="web-accessibility"
        dangerouslySetInnerHTML={{
          __html:
            "(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '20' } }); }; h.appendChild(s); })();"
        }}
      />
      <Layout>
        <Component {...pageProps} key={router.asPath} />
      </Layout>
      <script
        src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/${process.env.NEXT_PUBLIC_YOTPO_APP_KEY}`}
        async
      />
      {/* Facebook Pixel Code */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1595528317541686&ev=PageView&noscript=1"
          alt=""/>
      </noscript>
      {/* Google Tag Manager (noscript) */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }}
      />
    </ShopProvider>
  );
}

export default MyApp;

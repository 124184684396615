import { useEffect, useRef } from "react";
import { animate, easeOut } from "popmotion";
import { storyblokEditable } from "@storyblok/react";

function Word({ character, index, elapsed }) {
  const ref = useRef(null);

  useEffect(() => {
    const controls = animate({
      from: 200,
      to: 0,
      velocity: -400,
      stiffness: 70,
      elapsed: -index * 150,
      onUpdate: y => {
        if (ref.current) {
          ref.current.style.transform = `translateY(${y}%) translateZ(0)`;
        }
      }
    });

    const controlsOpacity = animate({
      from: 0,
      to: 1,
      ease: easeOut,
      duration: 150,
      elapsed: -index * 170,
      onUpdate: opacity => (ref.current.style.opacity = opacity)
    });

    return () => {
      controls.stop();
      controlsOpacity.stop();
    };
  }, [index]);

  return (
    <span
      ref={ref}
      style={{
        display: "inline-block"
      }}
    >
      {character}
    </span>
  );
}

export function AnimatedText(props) {
  const { text = "" } = props;
  const tagline = text.split(" ");

  // Create storage array
  const words = [];

  // Push each word into words array
  // @ts-ignore
  for (const [, item] of tagline.entries()) {
    words.push([...item]);
  }

  // Add a space ("\u00A0") to the end of each word
  words.map(word => {
    return word.push("\u00A0");
  });

  return (
    <h2
      className="sofia-pro-b text-[32px] leading-[1.1] text-gl-dark-blue lg:text-[44px]"
      {...storyblokEditable(text)}
    >
      {words.map((character, i) => (
        <Word index={i} key={i} character={character} elapsed={undefined} />
      ))}
    </h2>
  );
}

const Cross = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.162 8.60573C10.3583 8.80398 10.4685 9.07173 10.4685 9.35077C10.4685 9.6298 10.3583 9.89756 10.162 10.0958C9.96211 10.2891 9.69496 10.3971 9.41694 10.3971C9.13891 10.3971 8.87177 10.2891 8.6719 10.0958L5.23425 6.64509L1.7966 10.0958C1.59673 10.2891 1.32958 10.3971 1.05156 10.3971C0.77353 10.3971 0.506384 10.2891 0.306514 10.0958C0.110156 9.89756 0 9.6298 0 9.35077C0 9.07173 0.110156 8.80398 0.306514 8.60573L3.75723 5.16808L0.306514 1.73043C0.139768 1.52726 0.0545535 1.26933 0.0674467 1.00681C0.0803399 0.74429 0.190419 0.495953 0.376271 0.3101C0.562124 0.124248 0.810461 0.0141688 1.07298 0.00127556C1.3355 -0.0116177 1.59343 0.0735965 1.7966 0.240343L5.23425 3.69106L8.6719 0.240343C8.87507 0.0735965 9.13299 -0.0116177 9.39551 0.00127556C9.65803 0.0141688 9.90637 0.124248 10.0922 0.3101C10.2781 0.495953 10.3882 0.74429 10.401 1.00681C10.4139 1.26933 10.3287 1.52726 10.162 1.73043L6.71126 5.16808L10.162 8.60573Z"
      fill="#15233C"
    />
  </svg>
);
export default Cross;

import React from "react";

type ButtonProps = {
  children: any;
  variant: "outline" | "primary" | "blue" | "lightBlue" | "white";
  className?: string;
  isDisable?: boolean;
  onClick?: (a: Object) => void;
  type?: "button" | "submit" | "reset";
  fontSize?: string;
};

const Button = ({
  onClick,
  children,
  className,
  variant = "blue",
  isDisable = false,
  type,
  fontSize
}: ButtonProps) => {
  const variants = {
    primary: `sofia-pro-m rounded-8 bg-gl-blue py-2.5 px-8 ${fontSize} text-center text-white transition-all hover:bg-[#07496c]`,
    outline: `sofia-pro-m rounded-8  border border-gl-blue py-2.5 px-8 ${fontSize} text-center text-gl-blue transition-all hover:text-white hover:bg-gl-blue`,
    blue: `sofia-pro-m mt-3 w-full rounded-8 bg-[#15233C] 
    py-1.5 text-[24px] leading-[40px] tracking-[2%] select-none text-white g:mr-2 
    lg:ml-5 lg:w-[100%] xl:mr-0 xl:ml-8 xl:w-[100%] ${
      isDisable && "opacity-70 cursor-not-allowed"
    }`,
    lightBlue: `sofia-pro-m rounded-8 bg-[#2589BD] hover:bg-dark select-none flex justify-center text-center ${
      isDisable && "opacity-70 cursor-not-allowed"
    }`,
    white: `sofia-pro-s bg-white text-black rounded-8 ${
      isDisable && "opacity-70 cursor-not-allowed"
    }`
  };

  return (
    <button
      onClick={onClick}
      className={`${variants[variant]} ${className}`}
      disabled={isDisable}
      type={type}>
      {children}
    </button>
  );
};

export default Button;

export const COLLECTIONS_QUERY = `
	id
	title
	handle
	products(first: 50) {
		edges {
			node {
				id
				title
        handle
				description
				productType
				requiresSellingPlan
				tags
				priceRange {
					minVariantPrice {
						amount
					}
				}
        color_hex: metafield( namespace: "custom" key: "color_hex") {
          value
        }
        options {
          name
          values
          id
        }
				images(first: 10) {
					edges {
						node {
							url
							altText
						}
					}
				}
				variants(first: 25) {
					edges {
						node {
							selectedOptions {
								name
								value
							}
							image {
								url
								altText
							}
							title
							id
							sku
							availableForSale
							priceV2 {
								amount
							}
							compareAtPrice{
								amount
							}
						}
					}
				}
				sellingPlanGroups(first: 4) {
					edges {
						node {
							name
							options {
								name
								values
							}
							sellingPlans(first: 10) {
								edges {
									node {
										id
										name
										description
										recurringDeliveries
									}
								}
							}
						}
					}
				}
			}
		}
	}`;

export const ALL_COLLECTIONS_QUERY = `
	query {
		collections(first: 50) {
			edges {
				node {
					${COLLECTIONS_QUERY}
				}
			}
		}
	}
`;

export const ALL_COLLECTIONS_HANDLES_QUERY = `
	query {
		collections(first: 50) {
			edges {
				node {
					handle
				}
			}
		}
	}
`;

export const getCollectionQueryByHandle = handle => `{
  collection(handle: "${handle}") {
    ${COLLECTIONS_QUERY}
  }
}`;

export const getCollectionQueryById = collectionId => `{
	collection(id: "gid://shopify/Collection/${collectionId}") {
		${COLLECTIONS_QUERY}
	}
}`;

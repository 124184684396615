const PlusSign = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16211 6.1626V10.3696H4.35742V6.1626H0.115234V4.27588H4.35742V0.0688477H6.16211V4.27588H10.3809V6.1626H6.16211Z"
      fill="black"
    />
  </svg>
);

export default PlusSign;

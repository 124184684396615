import React, { useEffect, useState } from "react";
import "keen-slider/keen-slider.min.css";
import { storyblokEditable } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react";
import { CompanyItem } from "@components/bloks/CompanyItem";
import { useKeenSlider } from "keen-slider/react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";

const ThumbnailPlugin = mainRef => {
  return slider => {
    function removeActive() {
      slider.slides.forEach(slide => {
        slide.classList.remove("bg-active");
      });
    }

    function addActive(idx) {
      slider.slides[idx].classList.add("bg-active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", main => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
};

const CompanySlider = ({ blok, blok: { company, description } }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 1,
    loop: true,
    slides: {
      origin: "center",
      perView: 4,
      spacing: 25
    },
    breakpoints: {
      "(max-width: 1024px)": {
        slides: {
          origin: "center",
          spacing: 25,
          perView: 2
        }
      }
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });

  const [thumbnailRef] = useKeenSlider(
    {
      initial: 1,

      slides: {
        origin: "center",
        perView: 4,
        spacing: 25
      },
      breakpoints: {
        "(max-width: 1024px)": {
          slides: {
            origin: "center",
            spacing: 25,
            perView: 2
          }
        }
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      }
    },
    [ThumbnailPlugin(instanceRef)]
  );

  const renderOptions = {
    markResolvers: {
      [MARK_LINK]: (children, { href, target }) => {
        return (
          <a
            href={href}
            target={target}
            className={`cursor-pointer underline hover:no-underline`}
          >
            {children}
          </a>
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }, []);

  return (
    <div className="mx-auto mb-[60px] flex w-full max-w-[120rem] overflow-hidden lg:mb-[100px]">
      <div className="mx-auto flex w-full flex-col justify-center">
        <div className="flex w-full justify-center px-[18px] lg:px-[48px] xl:px-[48px]">
          {company?.blocks?.map(blok => (
            <StoryblokComponent
              blok={blok}
              key={blok._uid}
              {...storyblokEditable(blok)}
            />
          ))}
        </div>
        <div>
          <div className="company-slider relative mx-auto mt-[44px] flex w-full max-w-[120rem] flex-col">
            {company.length && (
              <div ref={thumbnailRef}>
                <div
                  ref={sliderRef}
                  className="keen-slider thumbnail-test max-w-[120rem]"
                >
                  {company?.map(customBlok => (
                    <CompanyItem
                      {...customBlok._source}
                      blok={blok}
                      customBlok={customBlok}
                    />
                  ))}
                </div>
              </div>
            )}
            {loaded && instanceRef?.current && (
              <div className="mt-[40px] flex w-full justify-center gap-[8px]">
                {[
                  //@ts-ignore
                  ...Array(
                    instanceRef?.current?.track?.details?.slides?.length
                  ).keys()
                ].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={
                        "dot-reviews h-[12px] w-[12px] rounded-full !border border-[#1d7919] bg-white" +
                        (currentSlide === idx ? " active !bg-[#15233C]" : "")
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {description && (
          <div className="mt-10 px-4 text-center text-[20px]">
            {render(description, renderOptions)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySlider;

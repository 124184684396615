import axios from "axios";

export const fetchInventory = (url, id) =>
  axios
    .get(url, {
      params: {
        id
      }
    })
    .then(res => res.data?.variants?.edges);

export const fetchData = url =>
  axios.get(url).then(res => {
    return res.data;
  });

export const subscribeNewsLetters = (url, email) =>
  axios
    .get(url, {
      params: {
        email
      }
    })
    .then(res => res.data);

export const subscribeLaundryTester = (url, email, values) => {
  return axios
    .get(url, {
      params: {
        email,
        body: values
      }
    })
    .then(res => res.data);
};

export const tryGLAPI = (url, values) => {
  return axios.get(url, {
    params: {
      body: values
    }
  });
};

import { useMediaQuery } from "@hooks/index";

const Spacer = ({ blok }) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div
      className="w-full"
      style={{
        height: isMobile
          ? `${blok.mobile_height}px`
          : `${blok.desktop_height}px`
      }}
    ></div>
  );
};

export default Spacer;

import { FormSimple } from "../FormSimple/FormSimple";
import { FormSuccess } from "../FormSuccess/FormSuccess";

export default function TryForm({
  blok,
  successForm,
  setSuccessForm,
  socials
}) {
  return (
    <section className="relative overflow-hidden py-6">
      <div className="mx-auto flex max-w-[1300px] flex-wrap px-4">
        {successForm ? (
          <FormSuccess blok={blok} socials={socials} />
        ) : (
          <FormSimple blok={blok} setSuccessForm={setSuccessForm} />
        )}
      </div>
    </section>
  );
}

import React from "react";
import NextImage from "next/image";
import { isEmpty } from "lodash";

const Image = ({ src, ...rest }) => {
  if (isEmpty(src)) {
    return null;
  }
  return <NextImage {...rest} src={src} />;
};

export default Image;

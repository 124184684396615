import DynamicComponent from "../../../services/DynamicComponentsRenderer";
import { motion } from "framer-motion";

export default function PolicyContainer({ blok }) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1,
          duration: 0.4
        }}
        className="mx-auto mb-[50px] flex w-full max-w-[120rem] flex-col px-[18px] lg:mb-[100px] lg:px-[48px] xl:px-[48px]"
      >
        <div className="mx-auto w-full max-w-[630px] overflow-hidden">
          <div className="flex h-full w-full flex-col justify-center gap-[30px] lg:gap-[70px]">
            {blok?.body?.map(blok => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
}

import { motion } from "framer-motion";

export default function Header() {
  return (
    <>
      <div className="mx-auto flex w-full max-w-[120rem] px-[18px] lg:px-[48px] xl:px-[48px]">
        <div className="justify-left mx-auto flex w-full max-w-[81.5rem] flex-col items-center overflow-hidden pb-[70px] pt-[60px] lg:pb-[100px] lg:pt-[90px]">
          <div className="grid w-full grid-cols-1 justify-start xl:grid-cols-2 xl:justify-between">
            <motion.h2
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 20,
                delay: 1,
                duration: 0.4
              }}
              className="sofia-pro-b flex flex-row items-center text-[30px] leading-[1.1] lg:text-[44px]"
            >
              the{" "}
              <img
                src="/good.svg"
                className="mx-[15px] max-h-[70px] max-w-[156px]"
              />{" "}
              launder
            </motion.h2>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 20,
                delay: 1.2,
                duration: 0.4
              }}
              className="sofia-pro-r mt-[10px] w-full justify-start pl-0 text-left text-[22px] leading-[1.3] lg:text-[24px] xl:mt-0 xl:ml-auto xl:max-w-[37rem] xl:justify-end xl:text-right"
            >
              Learn about everything laundry, our{" "}
              <span className="blog-scribble-orange relative pb-[4px]">
                love of animals
              </span>
              ,{" "}
              <span className="blog-scribble-pink relative pb-[4px]">
                the Earth,
              </span>{" "}
              and our mission to make a difference through sustainable
              <span className="blog-scribble-blue relative pb-[4px]">
                {" "}
                laundry products.
              </span>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}

import { isEmpty } from "lodash";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import {
  MARK_UNDERLINE,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import React from "react";

const HowDoes = ({ blok: { items_list, title, desctiption }, blok }) => {
  if (isEmpty(items_list)) {
    return null;
  }

  const desctiptionOptions = {
    markResolvers: {
      [MARK_UNDERLINE]: children => {
        return (
          <span className="text-underline-blue relative ml-2 text-[#2589BD]">
            <br />
            {children}
          </span>
        );
      }
    }
  };

  const subtitleOptions = {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => {
        return (
          <div className="sofia-pro-r py-6 pl-7 pr-2 text-[18px] leading-[1.1] lg:text-[22px]">
            <p className="max-w-[230px]">{children}</p>
          </div>
        );
      }
    }
  };

  return (
    <div
      className="mx-auto mb-[40px] w-full max-w-[120rem] px-[18px] lg:mb-[130px] lg:px-[48px] xl:px-[48px]"
      {...storyblokEditable(blok)}
    >
      {title && (
        <div className="mx-auto max-w-[81.5rem] text-center">
          <h2
            className={`sofia-pro-b pb-4 text-[32px] leading-[1.1] lg:text-[44px]`}
          >
            {title}
          </h2>
          <h3 className={`mb-32 text-[26px] leading-[1.1] lg:text-[34px]`}>
            {render(desctiption, desctiptionOptions)}
          </h3>
        </div>
      )}
      <div
        className={`justify-space-evenly mx-auto flex w-full max-w-[81.5rem] flex-wrap justify-between gap-y-20`}
      >
        {items_list.map((item, index) => {
          const { _uid, title, subtitle, icon_image } = item;
          return (
            <div
              key={_uid}
              className={`flex w-full flex-col items-center rounded-lg text-left sm:w-[48%] lg:w-[24%] ${
                index === 3 ? "bg-[#83B052]" : "bg-[#F5F9FA]"
              }`}
              {...storyblokEditable(item)}
            >
              <div
                className={`relative mr-auto ml-7 ${
                  index === 3
                    ? "mt-10 h-[32px] w-7/12 max-w-[180px]"
                    : "-mt-16 mb-2 h-[86px] w-[80px]"
                }`}
              >
                <Image
                  src={icon_image?.filename}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <h3 className="sofia-pro-s mb-2 w-full pl-7 text-[26px] text-[#15233C] lg:text-[34px]">
                {render(title)}
              </h3>
              <div
                className={`product-paragraph-border-b w-full ${
                  index === 3 ? "text-white" : "text-gl-black"
                }`}
              >
                {render(subtitle, subtitleOptions)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default HowDoes;

import Image from "next/image";

const CarouselItem = ({ image, title }) => {
  return (
    <div className="keen-slider__slide lg::max-w-[415px] w-[320px] min-w-[320px] max-w-[320px] lg:w-[415px] lg:min-w-[415px]">
      {title && (
        <h4 className="sofia-pro-s mb-4 text-[20px] md:text-[24px]">{title}</h4>
      )}
      <div className="relative h-[276px] max-h-[276px] min-h-[276px] w-full overflow-hidden rounded-8">
        <Image
          src={image?.filename}
          layout="fill"
          className="flex h-full w-full !object-cover"
        />
      </div>
    </div>
  );
};

export default CarouselItem;

import { render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

export default function ProductDescription({ blok, classList }) {
  return (
    <div
      className={`${
        classList
          ? classList
          : "sofia-pro-r pdp-description mt-[22px] flex w-full flex-col text-[18px]"
      }`}
      {...storyblokEditable(blok)}
    >
      {render(blok.description)}
    </div>
  );
}

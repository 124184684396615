import React, { useState } from "react";

import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";
import Accordion from "../../UI/accordionWrapper";

const FAQ = ({ blok: { title, questions } }) => {
  const renderTitleOptions = {
    nodeResolvers: {
      [NODE_HEADING]: children => {
        return (
          <h3
            className={`sofia-pro-s mb-10 text-[32px] leading-[1.15] lg:mb-14 lg:text-[34px]`}>
            {children}
          </h3>
        );
      },
      [NODE_PARAGRAPH]: children => {
        return (
          <p className={`sofia-pro-r text-[32px] lg:text-[34px]`}>{children}</p>
        );
      }
    }
  };

  return (
    <section className="pt-2 pb-12 md:pb-24 md:text-center">
      <div className="custom-container">
        {render(title, renderTitleOptions)}
        <div className="rounded border border-[#B9BDC5]/50 text-left">
          {questions.map(question => {
            const [isActive, setIsActive] = useState<boolean>(false);
            return (
              <div className="flex w-full cursor-pointer select-none flex-col">
                <div
                  onClick={() => setIsActive(!isActive)}
                  className="flex w-full flex-col px-[20px] pt-[20px] lg:px-[58px] lg:pt-[30px]">
                  <div
                    className={`flex w-full pb-[20px] lg:pb-[30px] ${
                      isActive ? "border-b-0" : "border-b-2 border-[#F5F9FF]"
                    }`}>
                    <div className="flex h-full w-full flex-row items-center justify-between">
                      <div className="flex w-full text-left">
                        <h3
                          className={`sofia-pro-b text-[24px] leading-[1.1] transition-all ${
                            isActive ? "text-[#2589BD]" : "text-[#15233C]"
                          }`}
                          {...storyblokEditable(question)}>
                          {question.title}
                        </h3>
                      </div>
                      <div
                        className="ml-2 flex h-full transition-all"
                        style={{
                          transform: isActive
                            ? "rotate(180deg)"
                            : "rotate(0deg)"
                        }}>
                        <svg
                          width="23"
                          height="13"
                          viewBox="0 0 23 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 1L11.5 11.5L22 1"
                            stroke="#15233C"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <Accordion
                  isOpened={isActive}
                  classList={
                    "accordion-last-2 py-4 md:py-8 bg-[#E2F5FF80] px-[25px] lg:px-[58px] text-[20px] leading-[1.2]"
                  }>
                  {render(question.accordion_content)}
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQ;

export default function Landing({
  blok: { title, description, logo },
  successForm
}) {
  return (
    <section className="pt-8 pb-0 text-center md:pt-10 md:pb-10">
      <div className="mx-auto max-w-[1300px] px-4">
        {logo[0] && (
          <img
            className="mx-auto mb-3 w-[190px] md:mb-12"
            src={logo[0].filename}
            alt=""
          />
        )}
        {!successForm && (
          <>
            <h2 className="text-blue-gradient sofia-pro-b mb-2 text-[32px] leading-[1.4] text-gl-dark-blue md:text-[48px]">
              {title}
            </h2>
            <p className="text-[22px] leading-[1.2] text-gl-dark-blue md:text-[24px]">
              {description}
            </p>
          </>
        )}
      </div>
    </section>
  );
}

import {
  allProductsQuery,
  getProductQueryById,
  recursiveCatalogQuery,
  getProductQueryByHandle,
  productsInCollectionQuery,
  getCollectionProductsListById
} from "./queries";

import { ShopifyService } from "../ShopifyService";

export class ShopifyProductService extends ShopifyService {
  async getProductsInCollection() {
    const response = await this.fetchShopifyData(productsInCollectionQuery);
    const allProducts = response.data.collection.products.edges
      ? response.data.collection.products.edges
      : [];
    return allProducts;
  }

  async getAllProducts() {
    const response = await this.fetchShopifyData(allProductsQuery);
    const allProducts = response.data.products.edges
      ? response.data.products.edges.map(({ node }) => node)
      : [];
    return allProducts;
  }

  async getProductByHandle(handle) {
    const query = getProductQueryByHandle(handle);
    const response = await this.fetchShopifyData(query);
    const product = response.data.product || [];
    return product;
  }

  async getProductById(id) {
    const query = getProductQueryById(id);
    const response = await this.fetchShopifyData(query);
    const product = response?.data?.product || [];
    return product;
  }

  async recursiveCatalog(cursor = "") {
    const query = recursiveCatalogQuery(cursor);
    const response = await this.fetchShopifyData(query);
    const data = response.data.products.edges
      ? response.data.products.edges
      : [];

    if (response.data.products.pageInfo.hasNextPage) {
      const num = response.data.products.edges.length;
      const cursor = response.data.products.edges[num - 1].cursor;

      return data.concat(await this.recursiveCatalog(cursor));
    } else {
      return data;
    }
  }

  async getCollectionProductsList(collectionId) {
    const query = getCollectionProductsListById(collectionId);
    const response = await this.fetchShopifyData(query);
    const collection = response.data.collection || [];
    return collection;
  }
}

import {
  Grid,
  Hero,
  Title,
  Banner,
  ProductList,
  AccordionsBlok,
  ProductInfoBlok,
  ImageWithTextAndLink,
  QuestionSection,
  FourBlockRow,
  BetterSection,
  ImageWithAsideBlok,
  Tabs,
  SingleProductBlock,
  TitleTextWithBgImage,
  IconTitleSubtitleBlock,
  QuizNav,
  QuizContent,
  Reviews,
  CarouselSection,
  TitleWithSubtitle,
  ReviewsSlider,
  ReviewSliderCard,
  CompanySlider,
  CompanyItem,
  AccordionSection,
  AccordionText,
  AccordionWithNested,
  AccordionItem,
  BannerWithButton,
  Footer,
  FooterNavyBlock,
  Saving,
  BlokWrapper,
  LandingWithAsideImg,
  Paralax,
  Impact,
  CalculateBlock,
  CollectionSlider,
  CollectionSliderItem,
  ContactSection,
  ContactCardsSection,
  Spacer,
  SimpleSection,
  ApplicationForm
} from "@components/bloks";

import Nav from "@components/Global/Nav/Nav";
import NavCart from "@components/Global/Nav/NavCart/NavCart";
import NavButton from "@components/Global/Nav/NavButton/NavButton";
import NavLink from "@components/Global/Nav/NavLink/NavLink";
import NavLinkDropdown from "@components/Global/Nav/NavDropdown/NavLinkDropdown/NavLinkDrowndown";
import NavDropdown from "@components/Global/Nav/NavDropdown/NavDropdown";
import NavBanner from "@components/Global/Nav/NavBanner/NavBanner";
import NavBoxLink from "@components/Global/Nav/NavDropdown/NavBoxLink/NavBoxLink";
import NavMobileMenu from "@components/Global/Nav/NavMobileMenu/NavMobileMenu";
import NavMobileLink from "@components/Global/Nav/NavMobileMenu/NavMobileLink/NavMobileLink";
import NavMobileBox from "@components/Global/Nav/NavMobileMenu/NavMobileBox/NavMobileBox";

import HomeLanding from "@components/Home/Landing/Landing.tsx";
import HomeInstagram from "@components/Home/Instagram/Instagram";
import HomeNoticed from "@components/Home/Noticed/Noticed";
import HomeBestSellers from "@components/Home/BestSellers/BestSellers";
import HomeDifference from "@components/Home/Difference/Difference";
import NoticedImage from "@components/Home/Noticed/NoticedImage/NoticedImage";
import HomeShowcase from "@components/Home/Showcase/Showcase";
import HomeWatchTheGood from "@components/Home/WatchTheGood/WatchTheGood";
import HomeShowcaseBigBox from "@components/Home/Showcase/BigBox/BigBox";
import HomeShowcaseSmallBoxContainer from "@components/Home/Showcase/SmallBoxContainer/SmallBoxContainer";
import HomeShowcaseSmallBox from "@components/Home/Showcase/SmallBoxContainer/SmallBox/SmallBox";
import HomeShowcaseSmallLongBox from "@components/Home/Showcase/SmallLongBox/SmallLongBox";

import ProductParadise from "@components/Product/Paradise/Paradise";
import ProductGivingBack from "@components/Product/GivingBack/GivingBack";
import ProductTwoGridSection from "@components/Product/2GridSection/2GridSection";
import ProductThreeGridStep from "@components/Product/ThreeGridStep/ThreeGridStep";
import ProductHowDoes from "@components/Product/HowDoes/HowDoes";
import ProductThreeGridSheet from "@components/Product/ThreeGridSheet/ThreeGridSheet";
import ProductStepSheetBundle from "@components/Product/StepSheetBundle/StepSheetBundle";
import TwoGridAccordions from "@components/Product/2GridSection/Accordions/Accordions";
import TwoGridAccordion from "@components/Product/2GridSection/Accordions/Accordion/Accordion";
import TwoGridTitle from "@components/Product/2GridSection/Title/Title";
import TwoGridParagraph from "@components/Product/2GridSection/Paragraph/Paragraph";
import TwoGridInstructions from "@components/Product/2GridSection/Instructions/Instructions";
import TwoGridInstruction from "@components/Product/2GridSection/Instructions/Instruction/Instruction";
import ProductReviews from "@components/Product/Reviews/Reviews";
import ProductAdditionalProducts from "@components/Product/AdditionalProducts/AdditionalProducts";
import ProductAdditionalProduct from "@components/Product/AdditionalProducts/AdditionalProduct/AdditionalProduct";
import ProductImages from "@components/Product/ProductImages/ProductImages/ProductImages";
import ProductImageBig from "@components/Product/ProductImages/ProductImages/ProductImageBig/ProductImageBig";
import ProductDescription from "@components/Product/ProductDescription/ProductDescription";
import ProductInfo from "@components/Product/ProductInfo/ProductInfo";
import BundleContainer from "@components/Product/BundleContainer/BundleContainer";
import BundleProduct from "@components/Product/BundleContainer/Product/Product";
import Info from "@components/Product/ProductInfo/Info/Info";
import ProductStars from "@components/Product/ProductStars/ProductStars";
import Star from "@components/Product/ProductStars/Star/Star";
import ProductScents from "@components/Product/ProductScents/ProductScents";
import Scent from "@components/Product/ProductScents/Scent/Scent";

import FAQLanding from "@components/faq/Landing/Landing";
import Text from "@components/BlogPost/Text/Text";
import BlogTry from "@components/Blog/Try/Try";
import BlogMostPopularHeader from "@components/Blog/MostPopularHeader/MostPopularHeader";
import BlogLatest from "@components/Blog/Latest/Latest";
import BlogHeader from "@components/Blog/Header/Header";
import BlogTagSection from "@components/Blog/TagSection/TagSection";
import BlogQuiz from "@components/Blog/Quiz/Quiz";
import ReviewsLanding from "@components/Reviews/Landing/Landing";
import ReviewsTry from "@components/Reviews/Try/Try";

import PolicyHeader from "@components/PolicyPages/Header/Header";
import PolicyContainer from "@components/PolicyPages/PolicyContainer/PolicyContainer";
import Policy from "@components/PolicyPages/PolicyContainer/Policy/Policy";

import DiscountBanner from "@components/DiscountBanner";
import AboutLanding from "@components/About/Landing/Landing";

import TryFooter from "@components/try/Footer/Footer";
import TryForm from "@components/try/TryForm/TryForm";
import TryLanding from "@components/try/Landing/Landing";

import WhoWeAre from "@components/bloks/WhoWeAre";
import WhyJoinUs from "@components/bloks/WhyJoinUs";
import WhoAreYou from "@components/bloks/WhoAreYou";
import ReadyToDoGood from "@components/bloks/ReadyToDoGood";
import AlreadyDo from "@components/GoodFund/AlreadyDo/AlreadyDo";
import InfluencersFaq from "@components/bloks/InfluencersFaq";
import WantedGoodPeople from "@components/bloks/WantedGoodPeople";

import LaundryTesterBanner from "@components/laundryTester/LaundryTesterBanner/LaundryTesterBanner";
import DoingGood from "@components/laundryTester/DoingGood/DoingGood";
import Want from "@components/laundryTester/Want/Want";
import LaundryTesterDifference from "@components/laundryTester/Difference/Difference";
import LaundryTesterApply from "@components/laundryTester/Apply/Apply";
import FAQ from "@components/laundryTester/FAQ/FAQ";

import PressKitBanner from "@components/pressKit/PressKitBanner/PressKitBanner";
import PressKitQuickFacts from "@components/pressKit/QuickFacts/QuickFacts";
import PressKitOurPeople from "@components/pressKit/OurPeople/OurPeople";
import PressKitBrandAssets from "@components/pressKit/BrandAssets/BrandAssets";
import PressKitBenefits from "@components/pressKit/Benefits/Benefits";
import PressKitGivingBack from "@components/pressKit/GivingBack/GivingBack";

const Components = {
  grid: Grid,
  title: Title,
  landing: Hero,
  banner: Banner,
  nav: Nav,
  "info-blok": ProductInfoBlok,
  image_with_text_end_link: ImageWithTextAndLink,
  question_with_variants: QuestionSection,
  four_block_row: FourBlockRow,
  better_section: BetterSection,
  image_with_aside_block: ImageWithAsideBlok,
  tabs: Tabs,
  "accordion-blok": AccordionsBlok,
  "featured-products": ProductList,
  "Single Product Block": SingleProductBlock,
  image_title_text: TitleTextWithBgImage,
  icon_title_subtitle_blok: IconTitleSubtitleBlock,
  reviews_widget: Reviews,
  reviewslanding: ReviewsLanding,
  reviewstry: ReviewsTry,
  footer: Footer,
  navcart: NavCart,
  navlink: NavLink,
  navlinkdropdown: NavLinkDropdown,
  navboxlink: NavBoxLink,
  navbutton: NavButton,
  navdropdown: NavDropdown,
  navbanner: NavBanner,
  navmobilemenu: NavMobileMenu,
  navmobilelink: NavMobileLink,
  navmobilebox: NavMobileBox,
  homelanding: HomeLanding,
  quiz_nav: QuizNav,
  quiz_content: QuizContent,
  reviews_slider: ReviewsSlider,
  review_item: ReviewSliderCard,
  company_slider: CompanySlider,
  company_item: CompanyItem,
  homeinstagram: HomeInstagram,
  homeimpact: Impact,
  homedifference: HomeDifference,
  homebestsellers: HomeBestSellers,
  homenoticed: HomeNoticed,
  noticedimage: NoticedImage,
  banner_with_button: BannerWithButton,
  collections_slider: CollectionSlider,
  collections_slider_item: CollectionSliderItem,
  homeshowcase: HomeShowcase,
  home_watch_the_good: HomeWatchTheGood,
  homeshowcasesmalllongbox: HomeShowcaseSmallLongBox,
  homeshowcasesmallboxcontainer: HomeShowcaseSmallBoxContainer,
  homeshowcasesmallbox: HomeShowcaseSmallBox,
  homeshowcasebigbox: HomeShowcaseBigBox,
  homesaving: Saving,
  productparadise: ProductParadise,
  productgivingback: ProductGivingBack,
  producttwogridsection: ProductTwoGridSection,
  "product-three-grid-step-section": ProductThreeGridStep,
  "product-how-does": ProductHowDoes,
  "product-three-grid-sheet": ProductThreeGridSheet,
  "product-step-sheet-bundle": ProductStepSheetBundle,
  twogridaccordions: TwoGridAccordions,
  twogridaccordion: TwoGridAccordion,
  twogridtitle: TwoGridTitle,
  twogridparagraph: TwoGridParagraph,
  twogridinstructions: TwoGridInstructions,
  twogridinstruction: TwoGridInstruction,
  productreviews: ProductReviews,
  productimages: ProductImages,
  productimagebig: ProductImageBig,
  productdescription: ProductDescription,
  bundleproducts: BundleContainer,
  bundleproduct: BundleProduct,
  productstars: ProductStars,
  star: Star,
  productinfo: ProductInfo,
  info: Info,
  productscents: ProductScents,
  scent: Scent,
  productadditionalproducts: ProductAdditionalProducts,
  productadditionalproduct: ProductAdditionalProduct,
  faqlanding: FAQLanding,
  influencer_faq: InfluencersFaq,
  accordion_section: AccordionSection,
  accordion_text: AccordionText,
  accordion_with_nested: AccordionWithNested,
  faqaccordion: AccordionItem,
  text: Text,
  blogtry: BlogTry,
  blogmostpopularheader: BlogMostPopularHeader,
  bloglatest: BlogLatest,
  blogheader: BlogHeader,
  blogtagsection: BlogTagSection,
  blogquiz: BlogQuiz,
  policyheader: PolicyHeader,
  policycontainer: PolicyContainer,
  policy: Policy,
  "carousel-section": CarouselSection,
  already_do_section: AlreadyDo,
  title_with_subtitle: TitleWithSubtitle,
  discount_banner: DiscountBanner,
  footer_navy_block: FooterNavyBlock,
  blok_wrapper: BlokWrapper,
  application_form: ApplicationForm,
  who_we_are: WhoWeAre,
  wanted_good_people: WantedGoodPeople,
  why_join_us: WhyJoinUs,
  who_are_you: WhoAreYou,
  ready_to_do_good: ReadyToDoGood,
  landing_with_aside_img: LandingWithAsideImg,
  paralax: Paralax,
  calculate: CalculateBlock,
  about_landing: AboutLanding,
  try_landing: TryLanding,
  try_form: TryForm,
  try_footer: TryFooter,
  contact_section: ContactSection,
  contact_cards_section: ContactCardsSection,
  spacer: Spacer,
  simple_section: SimpleSection,
  laundry_tester_banner: LaundryTesterBanner,
  laundry_tester_doing_good: DoingGood,
  laundry_tester_want: Want,
  laundry_tester_difference: LaundryTesterDifference,
  laundry_tester_apply: LaundryTesterApply,
  faq: FAQ,
  press_kit_banner: PressKitBanner,
  press_kit_quick_facts: PressKitQuickFacts,
  press_our_people: PressKitOurPeople,
  press_brand_assets: PressKitBrandAssets,
  press_kit_benefits: PressKitBenefits,
  press_kit_giving_back: PressKitGivingBack
};

export default Components;

import { render } from "storyblok-rich-text-react-renderer";

export default function Text({ blok, renderOptions = {} }) {
  return (
    <>
      <div
        className="blog-post line-through-[1.1]"
        style={{
          marginBottom: `${blok?.margin_bottom}`,
          fontSize: `${blok?.text_size}px`
        }}
      >
        {render(blok.text, renderOptions)}
      </div>
    </>
  );
}
